import { Redirect, Route, Switch } from "react-router-dom";

import { OnboardingLayout } from "../../../layouts/Onboarding";
import { onboardingRoutes } from "./routes";

export const OnboardingRouter = () => {
  return (
    <OnboardingLayout>
      <Switch>
        {onboardingRoutes.map((props) => (
          <Route key={props.id} {...props} />
        ))}
        <Route
          path="/integrations"
          render={() => (
            <Redirect
              to={{
                pathname: "/onboarding/integrate",
                search: window.location.search,
              }}
            />
          )}
        />
        <Route
          path="/settings/integrations"
          render={() => (
            <Redirect
              to={{
                pathname: "/onboarding/integrate",
                search: window.location.search,
              }}
            />
          )}
        />
        <Redirect to="/onboarding/welcome" />
      </Switch>
    </OnboardingLayout>
  );
};
