import { gql } from "urql";

export const CAN_READ_DEEPLINK_LOCATION_FRAGMENT = gql`
  fragment AuthenticatedLocationFields on Location {
    temp_entityDeeplink
    temp_canonicalLabel
  }
`;

export const CAN_USE_DETECTION_ACTIONS_FRAGMENT = gql`
  fragment DetectionActionFields on Detection {
    id
    actionState
  }
`;

export const FULL_DETECTION_FIELDS_FRAGMENT = gql`
  ${CAN_READ_DEEPLINK_LOCATION_FRAGMENT}
  fragment FullDetectionFields on Detection {
    id
    actionState @include(if: $canUseDetectionActions)
    pattern
    classifier {
      id
      name
      slug
    }
    atomId
    detectionHash
    obscuredDetection
    location {
      appType
      entityType
      entityId
      installationId
      fieldIdentifier
      ...AuthenticatedLocationFields @include(if: $canReadDeepLink)
    }
    actor {
      id
      displayName
      imageUrl
    }
    firstDetectedBackdate
    firstDetected
    lastDetected
  }
`;
