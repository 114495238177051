import {
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
  StorageAdapter as GraphCacheStorageAdapter,
} from "@urql/exchange-graphcache";
import { IntrospectionData } from "@urql/exchange-graphcache/dist/types/ast";

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Filter object for datapoint search */
  DatapointFilters: any;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Account = {
  __typename?: "Account";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  onboarding?: Maybe<AccountOnboarding>;
};

export type AccountMembership = {
  __typename?: "AccountMembership";
  accountId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  role?: Maybe<Scalars["String"]>;
  status?: Maybe<AccountMembershipStatus>;
  user?: Maybe<User>;
};

export type AccountMembershipConnection = {
  __typename?: "AccountMembershipConnection";
  edges?: Maybe<Array<Maybe<AccountMembershipEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AccountMembershipEdge = {
  __typename?: "AccountMembershipEdge";
  node?: Maybe<AccountMembership>;
};

export enum AccountMembershipStatus {
  Active = "ACTIVE",
  PendingInviteAcceptance = "PENDING_INVITE_ACCEPTANCE",
  Suspended = "SUSPENDED",
}

export type AccountOnboarding = {
  __typename?: "AccountOnboarding";
  currentStep?: Maybe<AccountOnboardingCurrentStep>;
  id?: Maybe<Scalars["ID"]>;
  status?: Maybe<AccountOnboardingStatus>;
};

export enum AccountOnboardingCurrentStep {
  DataThatMatters = "DATA_THAT_MATTERS",
  Finish = "FINISH",
  Insights = "INSIGHTS",
  Integrating = "INTEGRATING",
  InviteTeam = "INVITE_TEAM",
  Welcome = "WELCOME",
}

export enum AccountOnboardingStatus {
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  NotStarted = "NOT_STARTED",
}

export type ActionAlertAdmin = {
  __typename?: "ActionAlertAdmin";
  actionType?: Maybe<Scalars["String"]>;
  config?: Maybe<ActionAlertAdminConfig>;
  delayedExecutionConfig?: Maybe<DelayedExecutionConfig>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

export type ActionAlertAdminConfig = {
  __typename?: "ActionAlertAdminConfig";
  alertChannelIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["ID"]>;
};

export type ActionAlertAdminConfigInput = {
  alertChannelIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type ActionNotifyOwner = {
  __typename?: "ActionNotifyOwner";
  actionType?: Maybe<Scalars["String"]>;
  config?: Maybe<ActionNotifyOwnerConfig>;
  delayedExecutionConfig?: Maybe<DelayedExecutionConfig>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

export type ActionNotifyOwnerConfig = {
  __typename?: "ActionNotifyOwnerConfig";
  messageTemplate?: Maybe<Scalars["String"]>;
};

export type ActionNotifyOwnerConfigInput = {
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  messageTemplate?: InputMaybe<Scalars["String"]>;
};

export type ActionQuarantine = {
  __typename?: "ActionQuarantine";
  actionType?: Maybe<Scalars["String"]>;
  config?: Maybe<ActionQuarantineConfig>;
  delayedExecutionConfig?: Maybe<DelayedExecutionConfig>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

export type ActionQuarantineConfig = {
  __typename?: "ActionQuarantineConfig";
  messageContent?: Maybe<Scalars["String"]>;
};

export type ActionQuarantineConfigInput = {
  delayedExecutionConfig?: InputMaybe<DelayedExecutionConfigInput>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  messageContent?: InputMaybe<Scalars["String"]>;
};

export type ActionRedact = {
  __typename?: "ActionRedact";
  actionType?: Maybe<Scalars["String"]>;
  config?: Maybe<ActionRedactConfig>;
  delayedExecutionConfig?: Maybe<DelayedExecutionConfig>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

export type ActionRedactConfig = {
  __typename?: "ActionRedactConfig";
  dummyRedactSlack?: Maybe<Scalars["Boolean"]>;
  messageTemplate?: Maybe<Scalars["String"]>;
};

export type ActionRedactConfigInput = {
  delayedExecutionConfig?: InputMaybe<DelayedExecutionConfigInput>;
  dummyRedactSlack?: InputMaybe<Scalars["Boolean"]>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  messageTemplate?: InputMaybe<Scalars["String"]>;
};

export type ActionRevokeFilePermissions = {
  __typename?: "ActionRevokeFilePermissions";
  actionType?: Maybe<Scalars["String"]>;
  config?: Maybe<ActionRevokeFilePermissionsConfig>;
  delayedExecutionConfig?: Maybe<DelayedExecutionConfig>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

export type ActionRevokeFilePermissionsConfig = {
  __typename?: "ActionRevokeFilePermissionsConfig";
  id?: Maybe<Scalars["ID"]>;
  resolveViolation?: Maybe<Scalars["Boolean"]>;
  revokeInternalSharing?: Maybe<Scalars["Boolean"]>;
  revokePublicSharing?: Maybe<Scalars["Boolean"]>;
};

export type ActionRevokeFilePermissionsConfigInput = {
  delayedExecutionConfig?: InputMaybe<DelayedExecutionConfigInput>;
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  resolveViolation?: InputMaybe<Scalars["Boolean"]>;
  revokeInternalSharing?: InputMaybe<Scalars["Boolean"]>;
  revokePublicSharing?: InputMaybe<Scalars["Boolean"]>;
};

export type Actor = {
  __typename?: "Actor";
  displayName?: Maybe<Scalars["String"]>;
  groups?: Maybe<Array<Maybe<Group>>>;
  id?: Maybe<Scalars["ID"]>;
  imageUrl?: Maybe<Scalars["String"]>;
};

export type ActorActorConnection = {
  __typename?: "ActorActorConnection";
  edges?: Maybe<Array<Maybe<ActorActorEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ActorActorEdge = {
  __typename?: "ActorActorEdge";
  node?: Maybe<Actor>;
};

export type AlertChannel = {
  __typename?: "AlertChannel";
  accountId?: Maybe<Scalars["String"]>;
  config?: Maybe<Scalars["JSON"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  status?: Maybe<AlertChannelStatus>;
  type?: Maybe<AlertChannelType>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type AlertChannelCreateInput = {
  config?: InputMaybe<Scalars["JSON"]>;
  type?: InputMaybe<AlertChannelType>;
};

export enum AlertChannelStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
}

export enum AlertChannelType {
  Email = "EMAIL",
  Slack = "SLACK",
  Teams = "TEAMS",
}

export type AlertChannelUpdateInput = {
  status?: InputMaybe<AlertChannelStatus>;
};

export type AtomExposure = {
  __typename?: "AtomExposure";
  externalViewerCount?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  internalViewerCount?: Maybe<Scalars["Int"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type AtomLocation = {
  __typename?: "AtomLocation";
  appType?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Actor>;
  deeplink?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
  lastEditedBy?: Maybe<Actor>;
  parent?: Maybe<AtomLocation>;
  parentId?: Maybe<Scalars["ID"]>;
};

export type BulkAction = {
  __typename?: "BulkAction";
  accountId?: Maybe<Scalars["String"]>;
  cancelledAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdByUser?: Maybe<User>;
  id?: Maybe<Scalars["ID"]>;
  startedAt?: Maybe<Scalars["Date"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<BulkActionSummary>;
};

export type BulkActionConnection = {
  __typename?: "BulkActionConnection";
  edges?: Maybe<Array<Maybe<BulkActionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BulkActionEdge = {
  __typename?: "BulkActionEdge";
  node?: Maybe<BulkAction>;
};

export enum BulkActionRiskStatus {
  Active = "ACTIVE",
  Complete = "COMPLETE",
  Errored = "ERRORED",
}

export type BulkActionSummary = {
  __typename?: "BulkActionSummary";
  bulkActionId?: Maybe<Scalars["ID"]>;
  commands?: Maybe<Array<Maybe<BulkActionSummaryCommandCount>>>;
  detectionCount?: Maybe<Scalars["Int"]>;
};

export type BulkActionSummaryCommandCount = {
  __typename?: "BulkActionSummaryCommandCount";
  appType?: Maybe<Scalars["String"]>;
  command?: Maybe<RiskActionCommandEnum>;
  count?: Maybe<Scalars["Int"]>;
};

export type BulkActionsSummary = {
  __typename?: "BulkActionsSummary";
  gdriveRevokeFilePermissions?: Maybe<Scalars["Int"]>;
  resolveRisk?: Maybe<Scalars["Int"]>;
  slackRedactMessage?: Maybe<Scalars["Int"]>;
};

export type ClassificationResult = {
  __typename?: "ClassificationResult";
  length?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
};

export type Classifier = {
  __typename?: "Classifier";
  definition?: Maybe<ClassifierDefinition>;
  id?: Maybe<Scalars["ID"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  origin?: Maybe<ClassifierOriginEnum>;
  slug?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type ClassifierConnection = {
  __typename?: "ClassifierConnection";
  edges?: Maybe<Array<Maybe<ClassifierEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassifierCreateInput = {
  definition?: InputMaybe<ClassifierDefinitionInput>;
  name?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type ClassifierDefinition = {
  __typename?: "ClassifierDefinition";
  builtinClassifierOverrideID?: Maybe<Scalars["String"]>;
  exclusions?: Maybe<Scalars["String"]>;
  regex?: Maybe<Scalars["String"]>;
  surroundings?: Maybe<Array<Maybe<ClassifierDefinitionSurrounding>>>;
};

export type ClassifierDefinitionInput = {
  builtinClassifierOverrideID?: InputMaybe<Scalars["String"]>;
  exclusions?: InputMaybe<Scalars["String"]>;
  regex?: InputMaybe<Scalars["String"]>;
  surroundings?: InputMaybe<
    Array<InputMaybe<ClassifierDefinitionSurroundingInput>>
  >;
};

export type ClassifierDefinitionSurrounding = {
  __typename?: "ClassifierDefinitionSurrounding";
  regex?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
};

export type ClassifierDefinitionSurroundingInput = {
  regex?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type ClassifierEdge = {
  __typename?: "ClassifierEdge";
  node?: Maybe<Classifier>;
};

export type ClassifierExclusion = {
  __typename?: "ClassifierExclusion";
  accountId?: Maybe<Scalars["ID"]>;
  appType?: Maybe<Scalars["String"]>;
  classifierId?: Maybe<Scalars["String"]>;
  detectionHash?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  pattern?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ClassifierExclusionCreateInput = {
  accountId?: InputMaybe<Scalars["ID"]>;
  appType?: InputMaybe<Scalars["String"]>;
  classifierId?: InputMaybe<Scalars["String"]>;
  detectionHash?: InputMaybe<Scalars["String"]>;
  entityType?: InputMaybe<Scalars["String"]>;
  pattern?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type ClassifierExclusionHashBulkCreateInput = {
  classifierId?: InputMaybe<Scalars["String"]>;
  detectionHash?: InputMaybe<Scalars["String"]>;
};

export enum ClassifierOriginEnum {
  Custom = "CUSTOM",
  Metomic = "METOMIC",
}

export type ClassifierUpdateInput = {
  definition?: InputMaybe<ClassifierDefinitionInput>;
  id: Scalars["ID"];
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type CountedGroup_Of_Classifier = {
  __typename?: "CountedGroup_of_Classifier";
  count?: Maybe<Scalars["Int"]>;
  value?: Maybe<Classifier>;
};

export type CountedGroup_Of_Location = {
  __typename?: "CountedGroup_of_Location";
  count?: Maybe<Scalars["Int"]>;
  value?: Maybe<Location>;
};

export type CountedGroups_Of_Classifier = {
  __typename?: "CountedGroups_of_Classifier";
  countOther?: Maybe<Scalars["Int"]>;
  countSum?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<CountedGroup_Of_Classifier>>>;
};

export type CountedGroups_Of_Location = {
  __typename?: "CountedGroups_of_Location";
  countOther?: Maybe<Scalars["Int"]>;
  countSum?: Maybe<Scalars["Int"]>;
  groups?: Maybe<Array<Maybe<CountedGroup_Of_Location>>>;
};

export type Datapoint = {
  __typename?: "Datapoint";
  classifier?: Maybe<Classifier>;
  classifierType?: Maybe<Scalars["String"]>;
  detectionHash?: Maybe<Scalars["ID"]>;
  firstDetected?: Maybe<Scalars["Date"]>;
  firstDetectedBackdate?: Maybe<Scalars["Date"]>;
  impact?: Maybe<ImpactLevel>;
  lastDetected?: Maybe<Scalars["Date"]>;
  obscuredDetection?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<DatapointTag>>>;
};

export type DatapointConnection = {
  __typename?: "DatapointConnection";
  edges?: Maybe<Array<Maybe<DatapointEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DatapointEdge = {
  __typename?: "DatapointEdge";
  meta?: Maybe<DatapointEdgeMeta>;
  node?: Maybe<Datapoint>;
};

export type DatapointEdgeMeta = {
  __typename?: "DatapointEdgeMeta";
  appTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  occurrences?: Maybe<Scalars["Int"]>;
};

export type DatapointTag = {
  __typename?: "DatapointTag";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type DelayedExecutionConfig = {
  __typename?: "DelayedExecutionConfig";
  isEnabled?: Maybe<Scalars["Boolean"]>;
  unit?: Maybe<DelayedExecutionConfigUnitEnum>;
  value?: Maybe<Scalars["Int"]>;
};

export type DelayedExecutionConfigInput = {
  isEnabled?: InputMaybe<Scalars["Boolean"]>;
  unit?: InputMaybe<DelayedExecutionConfigUnitEnum>;
  value?: InputMaybe<Scalars["Int"]>;
};

export enum DelayedExecutionConfigUnitEnum {
  Days = "DAYS",
  Hours = "HOURS",
  Minutes = "MINUTES",
  Months = "MONTHS",
  Seconds = "SECONDS",
  Weeks = "WEEKS",
  Years = "YEARS",
}

export type Detection = {
  __typename?: "Detection";
  actionState?: Maybe<Scalars["JSON"]>;
  actor?: Maybe<Actor>;
  atomId?: Maybe<Scalars["String"]>;
  classifier?: Maybe<Classifier>;
  classifierType?: Maybe<Scalars["String"]>;
  detectionHash?: Maybe<Scalars["String"]>;
  firstDetected?: Maybe<Scalars["Date"]>;
  firstDetectedBackdate?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  lastDetected?: Maybe<Scalars["Date"]>;
  location?: Maybe<Location>;
  obscuredDetection?: Maybe<Scalars["String"]>;
  pattern?: Maybe<Scalars["String"]>;
};

export enum DetectionActionInput {
  CancelDelayedAction = "CANCEL_DELAYED_ACTION",
  MarkFalsePositive = "MARK_FALSE_POSITIVE",
  MarkResolved = "MARK_RESOLVED",
  Notify = "NOTIFY",
  Quarantine = "QUARANTINE",
  Redact = "REDACT",
  RestoreQuarantined = "RESTORE_QUARANTINED",
}

export type DetectionBundle = {
  __typename?: "DetectionBundle";
  appType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  impact?: Maybe<ImpactLevel>;
  location?: Maybe<Location>;
  otherClassifierCount?: Maybe<Scalars["Int"]>;
  primaryClassifierCount?: Maybe<Scalars["Int"]>;
  primaryClassifierType?: Maybe<Classifier>;
  timestamp?: Maybe<Scalars["Date"]>;
};

export type DetectionBundleConnection = {
  __typename?: "DetectionBundleConnection";
  edges?: Maybe<Array<Maybe<DetectionBundleEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DetectionBundleEdge = {
  __typename?: "DetectionBundleEdge";
  node?: Maybe<DetectionBundle>;
};

export type DetectionConnection = {
  __typename?: "DetectionConnection";
  edges?: Maybe<Array<Maybe<DetectionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DetectionEdge = {
  __typename?: "DetectionEdge";
  node?: Maybe<Detection>;
};

export type DirtyRiskCount = {
  __typename?: "DirtyRiskCount";
  total?: Maybe<Scalars["Int"]>;
};

export type Duration = {
  __typename?: "Duration";
  days?: Maybe<Scalars["Int"]>;
  hours?: Maybe<Scalars["Int"]>;
  milliseconds?: Maybe<Scalars["Int"]>;
  minutes?: Maybe<Scalars["Int"]>;
  months?: Maybe<Scalars["Int"]>;
  seconds?: Maybe<Scalars["Int"]>;
  weeks?: Maybe<Scalars["Int"]>;
  years?: Maybe<Scalars["Int"]>;
};

export type FeedbackReceived = {
  __typename?: "FeedbackReceived";
  success?: Maybe<Scalars["Boolean"]>;
};

export type FilePermissionByRiskRevoked = {
  __typename?: "FilePermissionByRiskRevoked";
  atomId?: Maybe<Scalars["String"]>;
};

export type FilePermissionRevoked = {
  __typename?: "FilePermissionRevoked";
  entityId?: Maybe<Scalars["String"]>;
  idsToInvalidate?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type GViolationRuleActionRecordConnection = {
  __typename?: "GViolationRuleActionRecordConnection";
  edges?: Maybe<Array<Maybe<GViolationRuleActionRecordEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type GViolationRuleActionRecordEdge = {
  __typename?: "GViolationRuleActionRecordEdge";
  node?: Maybe<RuleActionRecord>;
};

export type Group = {
  __typename?: "Group";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type GroupDelete = {
  __typename?: "GroupDelete";
  id?: Maybe<Scalars["ID"]>;
};

export type GroupUpdate = {
  __typename?: "GroupUpdate";
  success?: Maybe<Scalars["Boolean"]>;
};

export type Histogram_Of_CountedGroups_Of_Classifier = {
  __typename?: "Histogram_of_CountedGroups_of_Classifier";
  timebound?: Maybe<TimeBound>;
  timesliceDurationMillis?: Maybe<Scalars["Int"]>;
  timeslices?: Maybe<Array<Maybe<TimeSlice_Of_CountedGroups_Of_Classifier>>>;
};

export enum ImpactLevel {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type Installation = {
  __typename?: "Installation";
  id?: Maybe<Scalars["ID"]>;
  integration?: Maybe<Integration>;
  status?: Maybe<InstallationStatusEnum>;
};

export enum InstallationStatusEnum {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  Disable = "DISABLE",
  SetupInProgress = "SETUP_IN_PROGRESS",
}

export type Integration = {
  __typename?: "Integration";
  appType?: Maybe<Scalars["String"]>;
  comingSoon?: Maybe<Scalars["Boolean"]>;
  iconUrl?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  largeIconUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type IntegrationSchema = {
  __typename?: "IntegrationSchema";
  appType?: Maybe<Scalars["String"]>;
  schemaEntities?: Maybe<Array<Maybe<SchemaEntity>>>;
};

export type LabelledAtom = {
  __typename?: "LabelledAtom";
  appType?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  exposure?: Maybe<AtomExposure>;
  iconType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  installationId?: Maybe<Scalars["String"]>;
  location?: Maybe<AtomLocation>;
  primaryEntityName?: Maybe<Scalars["String"]>;
};

export type LabelledAtomConnection = {
  __typename?: "LabelledAtomConnection";
  edges?: Maybe<Array<Maybe<LabelledAtomEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LabelledAtomEdge = {
  __typename?: "LabelledAtomEdge";
  node?: Maybe<LabelledAtom>;
};

export type Location = {
  __typename?: "Location";
  appType?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  fieldIdentifier?: Maybe<Scalars["String"]>;
  installationId?: Maybe<Scalars["String"]>;
  temp_canonicalLabel?: Maybe<Scalars["String"]>;
  temp_entityDeeplink?: Maybe<Scalars["String"]>;
};

export type Me = {
  __typename?: "Me";
  account?: Maybe<Account>;
  /** @deprecated Please use `account.name` instead */
  accountName?: Maybe<Scalars["String"]>;
  avatarUrl?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  featureFlags?: Maybe<Scalars["JSON"]>;
  id?: Maybe<Scalars["ID"]>;
  membershipStatus?: Maybe<AccountMembershipStatus>;
  nickname?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primaryEmail?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type MessageByRiskRedacted = {
  __typename?: "MessageByRiskRedacted";
  atomId?: Maybe<Scalars["String"]>;
};

export type Monitor = {
  __typename?: "Monitor";
  actions?: Maybe<MonitorActions>;
  filters?: Maybe<Scalars["DatapointFilters"]>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  riskLevel?: Maybe<MonitorRiskLevel>;
  status?: Maybe<MonitorStatus>;
  violationSummary?: Maybe<ViolationSummary>;
  violations?: Maybe<MonitorViolationConnection>;
};

export type MonitorViolationsArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  pagination?: InputMaybe<PaginationInput>;
  status?: InputMaybe<ViolationStatus>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type MonitorActions = {
  __typename?: "MonitorActions";
  alertAdmin?: Maybe<ActionAlertAdmin>;
  id?: Maybe<Scalars["String"]>;
  notifyOwner?: Maybe<ActionNotifyOwner>;
  quarantine?: Maybe<ActionQuarantine>;
  redact?: Maybe<ActionRedact>;
  reduceExposure?: Maybe<ActionRevokeFilePermissions>;
};

export enum MonitorRiskLevel {
  Critical = "CRITICAL",
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export enum MonitorStatus {
  Archived = "ARCHIVED",
  Disabled = "DISABLED",
  Enabled = "ENABLED",
}

export type MonitorViolationConnection = {
  __typename?: "MonitorViolationConnection";
  edges?: Maybe<Array<Maybe<MonitorViolationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MonitorViolationEdge = {
  __typename?: "MonitorViolationEdge";
  node?: Maybe<Violation>;
};

export type OverviewBreakdown = {
  __typename?: "OverviewBreakdown";
  dataLocationsBreakdown?: Maybe<CountedGroups_Of_Location>;
  datapointTypeBreakdown?: Maybe<CountedGroups_Of_Classifier>;
  detectionHistogram?: Maybe<Histogram_Of_CountedGroups_Of_Classifier>;
  highFreqDatapointBreakdown?: Maybe<CountedGroups_Of_Classifier>;
};

export type OverviewBreakdownDetectionHistogramArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  sliceCount?: InputMaybe<Scalars["Int"]>;
  timebound: TimeBoundInput;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
};

/** Describes pagination requirements for a request */
export type PaginationInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type ReportCsv = {
  __typename?: "ReportCSV";
  data?: Maybe<Scalars["String"]>;
};

export type ReportViolation = {
  __typename?: "ReportViolation";
  actor?: Maybe<ReportViolationActor>;
  detection?: Maybe<Detection>;
  id?: Maybe<Scalars["ID"]>;
  monitorName?: Maybe<Scalars["String"]>;
  monitorVersion?: Maybe<Scalars["String"]>;
  riskLevel?: Maybe<MonitorRiskLevel>;
  status?: Maybe<ViolationStatus>;
  timestamp?: Maybe<Scalars["Date"]>;
};

export type ReportViolationActor = {
  __typename?: "ReportViolationActor";
  displayName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  imageUrl?: Maybe<Scalars["String"]>;
};

export type ReportViolationCounts = {
  __typename?: "ReportViolationCounts";
  falsePositive?: Maybe<Scalars["Int"]>;
  resolved?: Maybe<Scalars["Int"]>;
  unresolved?: Maybe<Scalars["Int"]>;
};

export type Risk = {
  __typename?: "Risk";
  atom?: Maybe<LabelledAtom>;
  atomId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  isDirty?: Maybe<Scalars["Boolean"]>;
  pendingActions?: Maybe<Array<Maybe<RiskAction>>>;
  sdf?: Maybe<Scalars["Int"]>;
  sdfLevel?: Maybe<RiskSdfLevelEnum>;
  sharedAt?: Maybe<Scalars["Date"]>;
  shortCode?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type RiskAction = {
  __typename?: "RiskAction";
  bulkActionId?: Maybe<Scalars["String"]>;
  command?: Maybe<RiskActionCommandEnum>;
  createdAt?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  risk?: Maybe<Risk>;
  riskId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export enum RiskActionCommandEnum {
  GdriveRevokeFilePermissions = "GDRIVE_REVOKE_FILE_PERMISSIONS",
  ResolveRisk = "RESOLVE_RISK",
  SlackRedactMessage = "SLACK_REDACT_MESSAGE",
}

export type RiskActionCommandInput = {
  command?: InputMaybe<RiskActionCommandEnum>;
  meta?: InputMaybe<RiskActionCommandMeta>;
  riskId?: InputMaybe<Scalars["String"]>;
};

export type RiskActionCommandMeta = {
  revokeInternalSharing?: InputMaybe<Scalars["Boolean"]>;
  revokePublicSharing?: InputMaybe<Scalars["Boolean"]>;
};

export type RiskActionCommandResult = {
  __typename?: "RiskActionCommandResult";
  success?: Maybe<Scalars["Boolean"]>;
};

export type RiskActionConnection = {
  __typename?: "RiskActionConnection";
  edges?: Maybe<Array<Maybe<RiskActionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type RiskActionEdge = {
  __typename?: "RiskActionEdge";
  node?: Maybe<RiskAction>;
};

/** Describes filter requirements for a risk action list request */
export type RiskActionFilterInput = {
  statuses?: InputMaybe<Array<InputMaybe<BulkActionRiskStatus>>>;
};

export enum RiskActionInput {
  Notify = "NOTIFY",
}

export type RiskConnection = {
  __typename?: "RiskConnection";
  edges?: Maybe<Array<Maybe<RiskEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum RiskCriticalityEnum {
  Critical = "CRITICAL",
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type RiskDataPointSummary = {
  __typename?: "RiskDataPointSummary";
  classifier?: Maybe<Classifier>;
  classifierId?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  level?: Maybe<Scalars["Int"]>;
};

export type RiskEdge = {
  __typename?: "RiskEdge";
  node?: Maybe<Risk>;
};

/** Describes filter requirements for a risk list request */
export type RiskFilterInput = {
  appTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  criticality?: InputMaybe<RiskCriticalityEnum>;
  entityType?: InputMaybe<Scalars["String"]>;
  riskGroupId?: InputMaybe<Scalars["ID"]>;
  shortCode?: InputMaybe<Scalars["String"]>;
};

export type RiskGroup = {
  __typename?: "RiskGroup";
  appType?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  cumulativeSdf?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  field?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  risks?: Maybe<RiskConnection>;
};

export type RiskGroupRisksArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RiskGroupConnection = {
  __typename?: "RiskGroupConnection";
  edges?: Maybe<Array<Maybe<RiskGroupEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type RiskGroupEdge = {
  __typename?: "RiskGroupEdge";
  node?: Maybe<RiskGroup>;
};

export enum RiskSdfLevelEnum {
  Critical = "CRITICAL",
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type RiskSummary = {
  __typename?: "RiskSummary";
  critical?: Maybe<Scalars["Int"]>;
  high?: Maybe<Scalars["Int"]>;
  low?: Maybe<Scalars["Int"]>;
  medium?: Maybe<Scalars["Int"]>;
};

export enum Role {
  Member = "MEMBER",
  Owner = "OWNER",
  Readonly = "READONLY",
}

export type RootMutationType = {
  __typename?: "RootMutationType";
  bulkChangeViolationStatus?: Maybe<Array<Maybe<Violation>>>;
  bulkCreateClassifierHashExclusions?: Maybe<Array<Maybe<ClassifierExclusion>>>;
  bulkCreateRiskAction?: Maybe<RiskActionCommandResult>;
  cancelBulkAction?: Maybe<BulkAction>;
  createAlertChannel?: Maybe<AlertChannel>;
  createClassifier?: Maybe<Classifier>;
  createClassifierExclusion?: Maybe<ClassifierExclusion>;
  createGroup?: Maybe<Group>;
  createMonitor?: Maybe<Monitor>;
  createQuery?: Maybe<SavedQuery>;
  deleteMonitor?: Maybe<Scalars["Boolean"]>;
  deleteQuery?: Maybe<Scalars["Boolean"]>;
  demoNotifyRisk?: Maybe<Risk>;
  demoRedactDetection?: Maybe<Detection>;
  demoResolveViolation?: Maybe<ViolationActionResponse>;
  generateViolationsOverTimeReport?: Maybe<ReportCsv>;
  insertActorsIntoGroups?: Maybe<GroupUpdate>;
  inviteUser?: Maybe<AccountMembership>;
  leaveSDFFeedback?: Maybe<FeedbackReceived>;
  markRiskAsResolved?: Maybe<Risk>;
  membershipAcceptInvitation?: Maybe<AccountMembership>;
  modifyGDrivePermissions?: Maybe<FilePermissionRevoked>;
  modifyGDrivePermissionsForRisk?: Maybe<FilePermissionByRiskRevoked>;
  redactSlackMessageForRisk?: Maybe<MessageByRiskRedacted>;
  removeGroup?: Maybe<GroupDelete>;
  rotateWebhookConfigurationSigningKey?: Maybe<WebhookSigningKey>;
  suspendUser?: Maybe<AccountMembership>;
  updateAccountOnboardingStatus?: Maybe<Account>;
  updateActor?: Maybe<Actor>;
  updateAlertChannel?: Maybe<AlertChannel>;
  updateClassifier?: Maybe<Classifier>;
  updateMembershipRole?: Maybe<AccountMembership>;
  updateMonitor?: Maybe<Monitor>;
  updateQuery?: Maybe<SavedQuery>;
  updateRuleActionAlertAdmin?: Maybe<ActionAlertAdmin>;
  updateRuleActionNotifyOwner?: Maybe<ActionNotifyOwner>;
  updateRuleActionQuarantine?: Maybe<ActionQuarantine>;
  updateRuleActionRedact?: Maybe<ActionRedact>;
  updateRuleActionRevokeFilePermissions?: Maybe<ActionRevokeFilePermissions>;
  updateWebhookConfiguration?: Maybe<WebhookConfiguration>;
};

export type RootMutationTypeBulkChangeViolationStatusArgs = {
  status?: InputMaybe<Scalars["String"]>;
  violationIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type RootMutationTypeBulkCreateClassifierHashExclusionsArgs = {
  inputs?: InputMaybe<
    Array<InputMaybe<ClassifierExclusionHashBulkCreateInput>>
  >;
};

export type RootMutationTypeBulkCreateRiskActionArgs = {
  actions?: InputMaybe<Array<InputMaybe<RiskActionCommandInput>>>;
};

export type RootMutationTypeCancelBulkActionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeCreateAlertChannelArgs = {
  input?: InputMaybe<AlertChannelCreateInput>;
};

export type RootMutationTypeCreateClassifierArgs = {
  input?: InputMaybe<ClassifierCreateInput>;
};

export type RootMutationTypeCreateClassifierExclusionArgs = {
  input?: InputMaybe<ClassifierExclusionCreateInput>;
};

export type RootMutationTypeCreateGroupArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeCreateMonitorArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  name?: InputMaybe<Scalars["String"]>;
  riskLevel?: InputMaybe<MonitorRiskLevel>;
  savedQueryId?: InputMaybe<Scalars["ID"]>;
  scanPastDetections?: InputMaybe<Scalars["Boolean"]>;
};

export type RootMutationTypeCreateQueryArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeDeleteMonitorArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeDeleteQueryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeDemoNotifyRiskArgs = {
  action?: InputMaybe<RiskActionInput>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeDemoRedactDetectionArgs = {
  action?: InputMaybe<DetectionActionInput>;
  id?: InputMaybe<Scalars["ID"]>;
  permissionGiven?: InputMaybe<Scalars["Boolean"]>;
};

export type RootMutationTypeDemoResolveViolationArgs = {
  action?: InputMaybe<DetectionActionInput>;
  id?: InputMaybe<Scalars["ID"]>;
  permissionGiven?: InputMaybe<Scalars["Boolean"]>;
  resolveAllViolationsInEntity?: InputMaybe<Scalars["Boolean"]>;
};

export type RootMutationTypeGenerateViolationsOverTimeReportArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  monitorIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  status?: InputMaybe<ViolationStatus>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootMutationTypeInsertActorsIntoGroupsArgs = {
  actorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type RootMutationTypeInviteUserArgs = {
  email?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Role>;
};

export type RootMutationTypeLeaveSdfFeedbackArgs = {
  approved?: InputMaybe<Scalars["Boolean"]>;
  comments?: InputMaybe<Scalars["String"]>;
  riskId?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeMarkRiskAsResolvedArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeModifyGDrivePermissionsArgs = {
  entityId?: InputMaybe<Scalars["String"]>;
  installationId?: InputMaybe<Scalars["String"]>;
  resolveAllViolationsInFile?: InputMaybe<Scalars["Boolean"]>;
  revokeInternalSharing?: InputMaybe<Scalars["Boolean"]>;
  revokePublicSharing?: InputMaybe<Scalars["Boolean"]>;
  violationId?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeModifyGDrivePermissionsForRiskArgs = {
  revokeInternalSharing?: InputMaybe<Scalars["Boolean"]>;
  revokePublicSharing?: InputMaybe<Scalars["Boolean"]>;
  riskId?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeRedactSlackMessageForRiskArgs = {
  riskId?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeRemoveGroupArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeSuspendUserArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateAccountOnboardingStatusArgs = {
  currentStep?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateActorArgs = {
  groupIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateAlertChannelArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  input?: InputMaybe<AlertChannelUpdateInput>;
};

export type RootMutationTypeUpdateClassifierArgs = {
  input?: InputMaybe<ClassifierUpdateInput>;
};

export type RootMutationTypeUpdateMembershipRoleArgs = {
  role?: InputMaybe<Role>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateMonitorArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  riskLevel?: InputMaybe<MonitorRiskLevel>;
  scanPastDetections?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<MonitorStatus>;
};

export type RootMutationTypeUpdateQueryArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type RootMutationTypeUpdateRuleActionAlertAdminArgs = {
  input?: InputMaybe<ActionAlertAdminConfigInput>;
  ruleId?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateRuleActionNotifyOwnerArgs = {
  input?: InputMaybe<ActionNotifyOwnerConfigInput>;
  ruleId?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateRuleActionQuarantineArgs = {
  input?: InputMaybe<ActionQuarantineConfigInput>;
  ruleId?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateRuleActionRedactArgs = {
  input?: InputMaybe<ActionRedactConfigInput>;
  ruleId?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateRuleActionRevokeFilePermissionsArgs = {
  input?: InputMaybe<ActionRevokeFilePermissionsConfigInput>;
  ruleId?: InputMaybe<Scalars["ID"]>;
};

export type RootMutationTypeUpdateWebhookConfigurationArgs = {
  input?: InputMaybe<WebhookConfigurationInput>;
};

export type RootQueryType = {
  __typename?: "RootQueryType";
  atomSearch?: Maybe<LabelledAtomConnection>;
  bulkActionsSummary?: Maybe<BulkActionsSummary>;
  datapointSearch?: Maybe<DatapointConnection>;
  detectionBundleConnection?: Maybe<DetectionBundleConnection>;
  detectionSearch?: Maybe<DetectionConnection>;
  findDetectionsForDatapoint?: Maybe<DetectionConnection>;
  getClassifier?: Maybe<Classifier>;
  getCountOfDirtyRisks?: Maybe<DirtyRiskCount>;
  getCustomClassifier?: Maybe<Classifier>;
  getCustomClassifierResult?: Maybe<Array<Maybe<ClassificationResult>>>;
  getDataPointSummaryForRisk?: Maybe<Array<Maybe<RiskDataPointSummary>>>;
  getDatapointTag?: Maybe<DatapointTag>;
  getIntegration?: Maybe<Integration>;
  getLabelledAtomById?: Maybe<LabelledAtom>;
  getLabelledAtomForFilterPill?: Maybe<LabelledAtom>;
  getMonitor?: Maybe<Monitor>;
  getOverviewBreakdown?: Maybe<OverviewBreakdown>;
  getPagedExamples?: Maybe<ThingConnection>;
  getRisk?: Maybe<Risk>;
  getSDFForHistogram?: Maybe<Array<Maybe<SdfTimeSlice>>>;
  getSDFTotal?: Maybe<SdfTotal>;
  getTotalDetectionsForQuery?: Maybe<Scalars["Int"]>;
  getViolationCountsByStatus?: Maybe<ReportViolationCounts>;
  listAccountMemberships?: Maybe<AccountMembershipConnection>;
  listActors?: Maybe<ActorActorConnection>;
  listAlertChannels?: Maybe<Array<Maybe<AlertChannel>>>;
  listBulkActions?: Maybe<BulkActionConnection>;
  listClassifiers?: Maybe<Array<Maybe<Classifier>>>;
  listCustomClassifiers?: Maybe<ClassifierConnection>;
  listDatapointTags?: Maybe<Array<Maybe<DatapointTag>>>;
  listGroups?: Maybe<Array<Maybe<Group>>>;
  listImpactLevels?: Maybe<Array<Maybe<ImpactLevel>>>;
  listInstallations?: Maybe<Array<Maybe<Installation>>>;
  listIntegrations?: Maybe<Array<Maybe<Integration>>>;
  listMonitors?: Maybe<Array<Maybe<Monitor>>>;
  listMonitorsOverTime?: Maybe<Array<Maybe<Monitor>>>;
  listQueries?: Maybe<SavedQueryConnection>;
  listRiskActionsByBulkActionId?: Maybe<RiskActionConnection>;
  listRiskGroups?: Maybe<RiskGroupConnection>;
  listRisks?: Maybe<RiskConnection>;
  listRisksByIds?: Maybe<Array<Maybe<Risk>>>;
  listRuleActionResultsForViolation?: Maybe<GViolationRuleActionRecordConnection>;
  listSchemaItems?: Maybe<Array<Maybe<IntegrationSchema>>>;
  listViolationsOverTime?: Maybe<Array<Maybe<ReportViolation>>>;
  me?: Maybe<Me>;
  retrieveUserAuditLogs?: Maybe<UserAuditLogUserAuditLogConnection>;
  riskSummary?: Maybe<RiskSummary>;
  searchRiskByAtomReference?: Maybe<Risk>;
  violationSummary?: Maybe<ViolationSummary>;
  webhookConfiguration?: Maybe<WebhookConfiguration>;
};

export type RootQueryTypeAtomSearchArgs = {
  appType?: InputMaybe<Scalars["String"]>;
  entityType?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeBulkActionsSummaryArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeDatapointSearchArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  pagination?: InputMaybe<PaginationInput>;
  term?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeDetectionBundleConnectionArgs = {
  detectionHash?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeDetectionSearchArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeFindDetectionsForDatapointArgs = {
  detectionHash: Scalars["String"];
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeGetClassifierArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetCustomClassifierArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetCustomClassifierResultArgs = {
  classifierDefinition?: InputMaybe<ClassifierDefinitionInput>;
  inputText?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetDataPointSummaryForRiskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeGetDatapointTagArgs = {
  id: Scalars["ID"];
};

export type RootQueryTypeGetIntegrationArgs = {
  appType?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetLabelledAtomByIdArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeGetLabelledAtomForFilterPillArgs = {
  appType?: InputMaybe<Scalars["String"]>;
  entityId?: InputMaybe<Scalars["String"]>;
  entityType?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetMonitorArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeGetOverviewBreakdownArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
};

export type RootQueryTypeGetRiskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeGetSdfForHistogramArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeGetTotalDetectionsForQueryArgs = {
  filters?: InputMaybe<Scalars["DatapointFilters"]>;
};

export type RootQueryTypeGetViolationCountsByStatusArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  monitorIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeListAccountMembershipsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListActorsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeListBulkActionsArgs = {
  filters?: InputMaybe<RiskActionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListCustomClassifiersArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListIntegrationsArgs = {
  isAccountRestricted?: InputMaybe<Scalars["Boolean"]>;
};

export type RootQueryTypeListMonitorsArgs = {
  currentlyTriggered?: InputMaybe<Scalars["Boolean"]>;
  falsePositive?: InputMaybe<Scalars["Boolean"]>;
  resolved?: InputMaybe<Scalars["Boolean"]>;
};

export type RootQueryTypeListMonitorsOverTimeArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeListQueriesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListRiskActionsByBulkActionIdArgs = {
  bulkActionId?: InputMaybe<Scalars["ID"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListRiskGroupsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type RootQueryTypeListRisksArgs = {
  filters?: InputMaybe<RiskFilterInput>;
  from?: InputMaybe<Scalars["Date"]>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeListRisksByIdsArgs = {
  ids: Array<InputMaybe<Scalars["ID"]>>;
};

export type RootQueryTypeListRuleActionResultsForViolationArgs = {
  pagination?: InputMaybe<PaginationInput>;
  violationId?: InputMaybe<Scalars["ID"]>;
};

export type RootQueryTypeListViolationsOverTimeArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  monitorIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  status?: InputMaybe<ViolationStatus>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeRetrieveUserAuditLogsArgs = {
  from?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
  to?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
};

export type RootQueryTypeRiskSummaryArgs = {
  from?: InputMaybe<Scalars["Date"]>;
  to?: InputMaybe<Scalars["Date"]>;
};

export type RootQueryTypeSearchRiskByAtomReferenceArgs = {
  appType?: InputMaybe<Scalars["String"]>;
  entityId?: InputMaybe<Scalars["String"]>;
  entityType?: InputMaybe<Scalars["String"]>;
  installationId?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export type RootSubscriptionType = {
  __typename?: "RootSubscriptionType";
  sdfTotalUpdated?: Maybe<SdfTotal>;
};

export type RuleActionRecord = {
  __typename?: "RuleActionRecord";
  actionType?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  result?: Maybe<RuleActionResult>;
};

export type RuleActionResult = {
  __typename?: "RuleActionResult";
  id?: Maybe<Scalars["ID"]>;
  meta?: Maybe<Scalars["JSON"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SdfTimeSlice = {
  __typename?: "SDFTimeSlice";
  date?: Maybe<Scalars["Date"]>;
  sdf?: Maybe<Scalars["Int"]>;
};

export type SdfTotal = {
  __typename?: "SDFTotal";
  health?: Maybe<Scalars["Int"]>;
  sdf?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type SavedQuery = {
  __typename?: "SavedQuery";
  accountId?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  createdAt?: Maybe<Scalars["String"]>;
  filters?: Maybe<Scalars["DatapointFilters"]>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type SavedQueryConnection = {
  __typename?: "SavedQueryConnection";
  edges?: Maybe<Array<Maybe<SavedQueryEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SavedQueryEdge = {
  __typename?: "SavedQueryEdge";
  node?: Maybe<SavedQuery>;
};

export type SchemaEntity = {
  __typename?: "SchemaEntity";
  appType?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  schemaFields?: Maybe<Array<Maybe<SchemaEntityField>>>;
};

export type SchemaEntityField = {
  __typename?: "SchemaEntityField";
  appType?: Maybe<Scalars["String"]>;
  entityType?: Maybe<Scalars["String"]>;
  entityTypeOfReferencedField?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  propertyLabel?: Maybe<Scalars["String"]>;
  propertyName?: Maybe<Scalars["String"]>;
  propertyType?: Maybe<Scalars["String"]>;
};

export type Thing = {
  __typename?: "Thing";
  name?: Maybe<Scalars["String"]>;
};

export type ThingConnection = {
  __typename?: "ThingConnection";
  edges?: Maybe<Array<Maybe<ThingEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ThingEdge = {
  __typename?: "ThingEdge";
  meta?: Maybe<ThingSummaryMeta>;
  node?: Maybe<Thing>;
};

export type ThingSummaryMeta = {
  __typename?: "ThingSummaryMeta";
  otherLocations?: Maybe<Scalars["Int"]>;
  stuff?: Maybe<Scalars["String"]>;
};

export type TimeBound = {
  __typename?: "TimeBound";
  duration?: Maybe<Duration>;
  timeEnd?: Maybe<Scalars["Date"]>;
  timeMid?: Maybe<Scalars["Date"]>;
  timeStart?: Maybe<Scalars["Date"]>;
};

export type TimeBoundInput = {
  duration?: InputMaybe<Scalars["Int"]>;
  timeEnd?: InputMaybe<Scalars["Date"]>;
  timeStart?: InputMaybe<Scalars["Date"]>;
};

export type TimeSlice_Of_CountedGroups_Of_Classifier = {
  __typename?: "TimeSlice_of_CountedGroups_of_Classifier";
  content?: Maybe<CountedGroups_Of_Classifier>;
  timebound?: Maybe<TimeBound>;
};

export type User = {
  __typename?: "User";
  avatarUrl?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  nickname?: Maybe<Scalars["String"]>;
  primaryEmail?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type UserAuditLog = {
  __typename?: "UserAuditLog";
  accountId?: Maybe<Scalars["String"]>;
  action?: Maybe<Scalars["String"]>;
  event?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  ip?: Maybe<Scalars["String"]>;
  resource?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
  userAgent?: Maybe<Scalars["String"]>;
  userEmail?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  userName?: Maybe<Scalars["String"]>;
};

export type UserAuditLogUserAuditLogConnection = {
  __typename?: "UserAuditLogUserAuditLogConnection";
  edges?: Maybe<Array<Maybe<UserAuditLogUserAuditLogEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserAuditLogUserAuditLogEdge = {
  __typename?: "UserAuditLogUserAuditLogEdge";
  node?: Maybe<UserAuditLog>;
};

export type Violation = {
  __typename?: "Violation";
  detection?: Maybe<Detection>;
  id?: Maybe<Scalars["ID"]>;
  monitorVersion?: Maybe<Scalars["String"]>;
  status?: Maybe<ViolationStatus>;
  timestamp?: Maybe<Scalars["Date"]>;
};

export type ViolationActionResponse = {
  __typename?: "ViolationActionResponse";
  idsToInvalidate?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  violation?: Maybe<Violation>;
};

export type ViolationHistory = {
  __typename?: "ViolationHistory";
  date?: Maybe<Scalars["String"]>;
  violations?: Maybe<ViolationRiskLevel>;
};

export type ViolationRiskLevel = {
  __typename?: "ViolationRiskLevel";
  critical?: Maybe<Scalars["Int"]>;
  high?: Maybe<Scalars["Int"]>;
  low?: Maybe<Scalars["Int"]>;
  medium?: Maybe<Scalars["Int"]>;
};

export enum ViolationStatus {
  FalsePositive = "FALSE_POSITIVE",
  Pending = "PENDING",
  RequiresAction = "REQUIRES_ACTION",
  Resolved = "RESOLVED",
  Unresolved = "UNRESOLVED",
}

export type ViolationSummary = {
  __typename?: "ViolationSummary";
  currentThreatLevel?: Maybe<MonitorRiskLevel>;
  currentViolations?: Maybe<ViolationRiskLevel>;
  history?: Maybe<Array<Maybe<ViolationHistory>>>;
  id?: Maybe<Scalars["ID"]>;
  triggeredRules?: Maybe<Scalars["Int"]>;
};

export type WebhookConfiguration = {
  __typename?: "WebhookConfiguration";
  endpoints?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type WebhookConfigurationInput = {
  endpoints?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type WebhookSigningKey = {
  __typename?: "WebhookSigningKey";
  signingKey?: Maybe<Scalars["String"]>;
};

export type WithTypename<T extends { __typename?: any }> = {
  [K in Exclude<keyof T, "__typename">]?: T[K];
} & { __typename: NonNullable<T["__typename"]> };

export type GraphCacheKeysConfig = {
  Account?: (data: WithTypename<Account>) => null | string;
  AccountMembership?: (data: WithTypename<AccountMembership>) => null | string;
  AccountMembershipConnection?: (
    data: WithTypename<AccountMembershipConnection>
  ) => null | string;
  AccountMembershipEdge?: (
    data: WithTypename<AccountMembershipEdge>
  ) => null | string;
  AccountOnboarding?: (data: WithTypename<AccountOnboarding>) => null | string;
  ActionAlertAdmin?: (data: WithTypename<ActionAlertAdmin>) => null | string;
  ActionAlertAdminConfig?: (
    data: WithTypename<ActionAlertAdminConfig>
  ) => null | string;
  ActionNotifyOwner?: (data: WithTypename<ActionNotifyOwner>) => null | string;
  ActionNotifyOwnerConfig?: (
    data: WithTypename<ActionNotifyOwnerConfig>
  ) => null | string;
  ActionQuarantine?: (data: WithTypename<ActionQuarantine>) => null | string;
  ActionQuarantineConfig?: (
    data: WithTypename<ActionQuarantineConfig>
  ) => null | string;
  ActionRedact?: (data: WithTypename<ActionRedact>) => null | string;
  ActionRedactConfig?: (
    data: WithTypename<ActionRedactConfig>
  ) => null | string;
  ActionRevokeFilePermissions?: (
    data: WithTypename<ActionRevokeFilePermissions>
  ) => null | string;
  ActionRevokeFilePermissionsConfig?: (
    data: WithTypename<ActionRevokeFilePermissionsConfig>
  ) => null | string;
  Actor?: (data: WithTypename<Actor>) => null | string;
  ActorActorConnection?: (
    data: WithTypename<ActorActorConnection>
  ) => null | string;
  ActorActorEdge?: (data: WithTypename<ActorActorEdge>) => null | string;
  AlertChannel?: (data: WithTypename<AlertChannel>) => null | string;
  AtomExposure?: (data: WithTypename<AtomExposure>) => null | string;
  AtomLocation?: (data: WithTypename<AtomLocation>) => null | string;
  BulkAction?: (data: WithTypename<BulkAction>) => null | string;
  BulkActionConnection?: (
    data: WithTypename<BulkActionConnection>
  ) => null | string;
  BulkActionEdge?: (data: WithTypename<BulkActionEdge>) => null | string;
  BulkActionSummary?: (data: WithTypename<BulkActionSummary>) => null | string;
  BulkActionSummaryCommandCount?: (
    data: WithTypename<BulkActionSummaryCommandCount>
  ) => null | string;
  BulkActionsSummary?: (
    data: WithTypename<BulkActionsSummary>
  ) => null | string;
  ClassificationResult?: (
    data: WithTypename<ClassificationResult>
  ) => null | string;
  Classifier?: (data: WithTypename<Classifier>) => null | string;
  ClassifierConnection?: (
    data: WithTypename<ClassifierConnection>
  ) => null | string;
  ClassifierDefinition?: (
    data: WithTypename<ClassifierDefinition>
  ) => null | string;
  ClassifierDefinitionSurrounding?: (
    data: WithTypename<ClassifierDefinitionSurrounding>
  ) => null | string;
  ClassifierEdge?: (data: WithTypename<ClassifierEdge>) => null | string;
  ClassifierExclusion?: (
    data: WithTypename<ClassifierExclusion>
  ) => null | string;
  CountedGroup_of_Classifier?: (
    data: WithTypename<CountedGroup_Of_Classifier>
  ) => null | string;
  CountedGroup_of_Location?: (
    data: WithTypename<CountedGroup_Of_Location>
  ) => null | string;
  CountedGroups_of_Classifier?: (
    data: WithTypename<CountedGroups_Of_Classifier>
  ) => null | string;
  CountedGroups_of_Location?: (
    data: WithTypename<CountedGroups_Of_Location>
  ) => null | string;
  Datapoint?: (data: WithTypename<Datapoint>) => null | string;
  DatapointConnection?: (
    data: WithTypename<DatapointConnection>
  ) => null | string;
  DatapointEdge?: (data: WithTypename<DatapointEdge>) => null | string;
  DatapointEdgeMeta?: (data: WithTypename<DatapointEdgeMeta>) => null | string;
  DatapointTag?: (data: WithTypename<DatapointTag>) => null | string;
  DelayedExecutionConfig?: (
    data: WithTypename<DelayedExecutionConfig>
  ) => null | string;
  Detection?: (data: WithTypename<Detection>) => null | string;
  DetectionBundle?: (data: WithTypename<DetectionBundle>) => null | string;
  DetectionBundleConnection?: (
    data: WithTypename<DetectionBundleConnection>
  ) => null | string;
  DetectionBundleEdge?: (
    data: WithTypename<DetectionBundleEdge>
  ) => null | string;
  DetectionConnection?: (
    data: WithTypename<DetectionConnection>
  ) => null | string;
  DetectionEdge?: (data: WithTypename<DetectionEdge>) => null | string;
  DirtyRiskCount?: (data: WithTypename<DirtyRiskCount>) => null | string;
  Duration?: (data: WithTypename<Duration>) => null | string;
  FeedbackReceived?: (data: WithTypename<FeedbackReceived>) => null | string;
  FilePermissionByRiskRevoked?: (
    data: WithTypename<FilePermissionByRiskRevoked>
  ) => null | string;
  FilePermissionRevoked?: (
    data: WithTypename<FilePermissionRevoked>
  ) => null | string;
  GViolationRuleActionRecordConnection?: (
    data: WithTypename<GViolationRuleActionRecordConnection>
  ) => null | string;
  GViolationRuleActionRecordEdge?: (
    data: WithTypename<GViolationRuleActionRecordEdge>
  ) => null | string;
  Group?: (data: WithTypename<Group>) => null | string;
  GroupDelete?: (data: WithTypename<GroupDelete>) => null | string;
  GroupUpdate?: (data: WithTypename<GroupUpdate>) => null | string;
  Histogram_of_CountedGroups_of_Classifier?: (
    data: WithTypename<Histogram_Of_CountedGroups_Of_Classifier>
  ) => null | string;
  Installation?: (data: WithTypename<Installation>) => null | string;
  Integration?: (data: WithTypename<Integration>) => null | string;
  IntegrationSchema?: (data: WithTypename<IntegrationSchema>) => null | string;
  LabelledAtom?: (data: WithTypename<LabelledAtom>) => null | string;
  LabelledAtomConnection?: (
    data: WithTypename<LabelledAtomConnection>
  ) => null | string;
  LabelledAtomEdge?: (data: WithTypename<LabelledAtomEdge>) => null | string;
  Location?: (data: WithTypename<Location>) => null | string;
  Me?: (data: WithTypename<Me>) => null | string;
  MessageByRiskRedacted?: (
    data: WithTypename<MessageByRiskRedacted>
  ) => null | string;
  Monitor?: (data: WithTypename<Monitor>) => null | string;
  MonitorActions?: (data: WithTypename<MonitorActions>) => null | string;
  MonitorViolationConnection?: (
    data: WithTypename<MonitorViolationConnection>
  ) => null | string;
  MonitorViolationEdge?: (
    data: WithTypename<MonitorViolationEdge>
  ) => null | string;
  OverviewBreakdown?: (data: WithTypename<OverviewBreakdown>) => null | string;
  PageInfo?: (data: WithTypename<PageInfo>) => null | string;
  ReportCSV?: (data: WithTypename<ReportCsv>) => null | string;
  ReportViolation?: (data: WithTypename<ReportViolation>) => null | string;
  ReportViolationActor?: (
    data: WithTypename<ReportViolationActor>
  ) => null | string;
  ReportViolationCounts?: (
    data: WithTypename<ReportViolationCounts>
  ) => null | string;
  Risk?: (data: WithTypename<Risk>) => null | string;
  RiskAction?: (data: WithTypename<RiskAction>) => null | string;
  RiskActionCommandResult?: (
    data: WithTypename<RiskActionCommandResult>
  ) => null | string;
  RiskActionConnection?: (
    data: WithTypename<RiskActionConnection>
  ) => null | string;
  RiskActionEdge?: (data: WithTypename<RiskActionEdge>) => null | string;
  RiskConnection?: (data: WithTypename<RiskConnection>) => null | string;
  RiskDataPointSummary?: (
    data: WithTypename<RiskDataPointSummary>
  ) => null | string;
  RiskEdge?: (data: WithTypename<RiskEdge>) => null | string;
  RiskGroup?: (data: WithTypename<RiskGroup>) => null | string;
  RiskGroupConnection?: (
    data: WithTypename<RiskGroupConnection>
  ) => null | string;
  RiskGroupEdge?: (data: WithTypename<RiskGroupEdge>) => null | string;
  RiskSummary?: (data: WithTypename<RiskSummary>) => null | string;
  RuleActionRecord?: (data: WithTypename<RuleActionRecord>) => null | string;
  RuleActionResult?: (data: WithTypename<RuleActionResult>) => null | string;
  SDFTimeSlice?: (data: WithTypename<SdfTimeSlice>) => null | string;
  SDFTotal?: (data: WithTypename<SdfTotal>) => null | string;
  SavedQuery?: (data: WithTypename<SavedQuery>) => null | string;
  SavedQueryConnection?: (
    data: WithTypename<SavedQueryConnection>
  ) => null | string;
  SavedQueryEdge?: (data: WithTypename<SavedQueryEdge>) => null | string;
  SchemaEntity?: (data: WithTypename<SchemaEntity>) => null | string;
  SchemaEntityField?: (data: WithTypename<SchemaEntityField>) => null | string;
  Thing?: (data: WithTypename<Thing>) => null | string;
  ThingConnection?: (data: WithTypename<ThingConnection>) => null | string;
  ThingEdge?: (data: WithTypename<ThingEdge>) => null | string;
  ThingSummaryMeta?: (data: WithTypename<ThingSummaryMeta>) => null | string;
  TimeBound?: (data: WithTypename<TimeBound>) => null | string;
  TimeSlice_of_CountedGroups_of_Classifier?: (
    data: WithTypename<TimeSlice_Of_CountedGroups_Of_Classifier>
  ) => null | string;
  User?: (data: WithTypename<User>) => null | string;
  UserAuditLog?: (data: WithTypename<UserAuditLog>) => null | string;
  UserAuditLogUserAuditLogConnection?: (
    data: WithTypename<UserAuditLogUserAuditLogConnection>
  ) => null | string;
  UserAuditLogUserAuditLogEdge?: (
    data: WithTypename<UserAuditLogUserAuditLogEdge>
  ) => null | string;
  Violation?: (data: WithTypename<Violation>) => null | string;
  ViolationActionResponse?: (
    data: WithTypename<ViolationActionResponse>
  ) => null | string;
  ViolationHistory?: (data: WithTypename<ViolationHistory>) => null | string;
  ViolationRiskLevel?: (
    data: WithTypename<ViolationRiskLevel>
  ) => null | string;
  ViolationSummary?: (data: WithTypename<ViolationSummary>) => null | string;
  WebhookConfiguration?: (
    data: WithTypename<WebhookConfiguration>
  ) => null | string;
  WebhookSigningKey?: (data: WithTypename<WebhookSigningKey>) => null | string;
};

export type GraphCacheResolvers = {
  RootQueryType?: {
    me?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<Me> | string
    >;
    listSchemaItems?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<IntegrationSchema> | string>
    >;
    datapointSearch?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeDatapointSearchArgs,
      WithTypename<DatapointConnection> | string
    >;
    detectionSearch?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeDetectionSearchArgs,
      WithTypename<DetectionConnection> | string
    >;
    atomSearch?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeAtomSearchArgs,
      WithTypename<LabelledAtomConnection> | string
    >;
    findDetectionsForDatapoint?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeFindDetectionsForDatapointArgs,
      WithTypename<DetectionConnection> | string
    >;
    getPagedExamples?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<ThingConnection> | string
    >;
    getLabelledAtomForFilterPill?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetLabelledAtomForFilterPillArgs,
      WithTypename<LabelledAtom> | string
    >;
    getLabelledAtomById?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetLabelledAtomByIdArgs,
      WithTypename<LabelledAtom> | string
    >;
    getOverviewBreakdown?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetOverviewBreakdownArgs,
      WithTypename<OverviewBreakdown> | string
    >;
    detectionBundleConnection?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeDetectionBundleConnectionArgs,
      WithTypename<DetectionBundleConnection> | string
    >;
    getTotalDetectionsForQuery?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetTotalDetectionsForQueryArgs,
      Scalars["Int"] | string
    >;
    getClassifier?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetClassifierArgs,
      WithTypename<Classifier> | string
    >;
    getCustomClassifier?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetCustomClassifierArgs,
      WithTypename<Classifier> | string
    >;
    listClassifiers?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<Classifier> | string>
    >;
    listCustomClassifiers?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListCustomClassifiersArgs,
      WithTypename<ClassifierConnection> | string
    >;
    getCustomClassifierResult?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetCustomClassifierResultArgs,
      Array<WithTypename<ClassificationResult> | string>
    >;
    getIntegration?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetIntegrationArgs,
      WithTypename<Integration> | string
    >;
    listIntegrations?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListIntegrationsArgs,
      Array<WithTypename<Integration> | string>
    >;
    listInstallations?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<Installation> | string>
    >;
    listImpactLevels?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<ImpactLevel | string>
    >;
    getDatapointTag?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetDatapointTagArgs,
      WithTypename<DatapointTag> | string
    >;
    listDatapointTags?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<DatapointTag> | string>
    >;
    listMonitors?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListMonitorsArgs,
      Array<WithTypename<Monitor> | string>
    >;
    listMonitorsOverTime?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListMonitorsOverTimeArgs,
      Array<WithTypename<Monitor> | string>
    >;
    getMonitor?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetMonitorArgs,
      WithTypename<Monitor> | string
    >;
    listAccountMemberships?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListAccountMembershipsArgs,
      WithTypename<AccountMembershipConnection> | string
    >;
    listQueries?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListQueriesArgs,
      WithTypename<SavedQueryConnection> | string
    >;
    violationSummary?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<ViolationSummary> | string
    >;
    listViolationsOverTime?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListViolationsOverTimeArgs,
      Array<WithTypename<ReportViolation> | string>
    >;
    getViolationCountsByStatus?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetViolationCountsByStatusArgs,
      WithTypename<ReportViolationCounts> | string
    >;
    listRuleActionResultsForViolation?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListRuleActionResultsForViolationArgs,
      WithTypename<GViolationRuleActionRecordConnection> | string
    >;
    listAlertChannels?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<AlertChannel> | string>
    >;
    retrieveUserAuditLogs?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeRetrieveUserAuditLogsArgs,
      WithTypename<UserAuditLogUserAuditLogConnection> | string
    >;
    listActors?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListActorsArgs,
      WithTypename<ActorActorConnection> | string
    >;
    listGroups?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      Array<WithTypename<Group> | string>
    >;
    getRisk?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetRiskArgs,
      WithTypename<Risk> | string
    >;
    listRisks?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListRisksArgs,
      WithTypename<RiskConnection> | string
    >;
    listRisksByIds?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListRisksByIdsArgs,
      Array<WithTypename<Risk> | string>
    >;
    getCountOfDirtyRisks?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<DirtyRiskCount> | string
    >;
    searchRiskByAtomReference?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeSearchRiskByAtomReferenceArgs,
      WithTypename<Risk> | string
    >;
    getDataPointSummaryForRisk?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetDataPointSummaryForRiskArgs,
      Array<WithTypename<RiskDataPointSummary> | string>
    >;
    listRiskGroups?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListRiskGroupsArgs,
      WithTypename<RiskGroupConnection> | string
    >;
    riskSummary?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeRiskSummaryArgs,
      WithTypename<RiskSummary> | string
    >;
    getSDFTotal?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<SdfTotal> | string
    >;
    getSDFForHistogram?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeGetSdfForHistogramArgs,
      Array<WithTypename<SdfTimeSlice> | string>
    >;
    listBulkActions?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListBulkActionsArgs,
      WithTypename<BulkActionConnection> | string
    >;
    listRiskActionsByBulkActionId?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeListRiskActionsByBulkActionIdArgs,
      WithTypename<RiskActionConnection> | string
    >;
    bulkActionsSummary?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      RootQueryTypeBulkActionsSummaryArgs,
      WithTypename<BulkActionsSummary> | string
    >;
    webhookConfiguration?: GraphCacheResolver<
      WithTypename<RootQueryType>,
      Record<string, never>,
      WithTypename<WebhookConfiguration> | string
    >;
  };
  Account?: {
    id?: GraphCacheResolver<
      WithTypename<Account>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Account>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    onboarding?: GraphCacheResolver<
      WithTypename<Account>,
      Record<string, never>,
      WithTypename<AccountOnboarding> | string
    >;
  };
  AccountMembership?: {
    id?: GraphCacheResolver<
      WithTypename<AccountMembership>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<AccountMembership>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<AccountMembership>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    role?: GraphCacheResolver<
      WithTypename<AccountMembership>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<AccountMembership>,
      Record<string, never>,
      AccountMembershipStatus | string
    >;
  };
  AccountMembershipConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<AccountMembershipConnection>,
      Record<string, never>,
      Array<WithTypename<AccountMembershipEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<AccountMembershipConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  AccountMembershipEdge?: {
    node?: GraphCacheResolver<
      WithTypename<AccountMembershipEdge>,
      Record<string, never>,
      WithTypename<AccountMembership> | string
    >;
  };
  AccountOnboarding?: {
    id?: GraphCacheResolver<
      WithTypename<AccountOnboarding>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<AccountOnboarding>,
      Record<string, never>,
      AccountOnboardingStatus | string
    >;
    currentStep?: GraphCacheResolver<
      WithTypename<AccountOnboarding>,
      Record<string, never>,
      AccountOnboardingCurrentStep | string
    >;
  };
  ActionAlertAdmin?: {
    id?: GraphCacheResolver<
      WithTypename<ActionAlertAdmin>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<ActionAlertAdmin>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<ActionAlertAdmin>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    delayedExecutionConfig?: GraphCacheResolver<
      WithTypename<ActionAlertAdmin>,
      Record<string, never>,
      WithTypename<DelayedExecutionConfig> | string
    >;
    config?: GraphCacheResolver<
      WithTypename<ActionAlertAdmin>,
      Record<string, never>,
      WithTypename<ActionAlertAdminConfig> | string
    >;
  };
  ActionAlertAdminConfig?: {
    id?: GraphCacheResolver<
      WithTypename<ActionAlertAdminConfig>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    alertChannelIds?: GraphCacheResolver<
      WithTypename<ActionAlertAdminConfig>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >;
  };
  ActionNotifyOwner?: {
    id?: GraphCacheResolver<
      WithTypename<ActionNotifyOwner>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<ActionNotifyOwner>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<ActionNotifyOwner>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    delayedExecutionConfig?: GraphCacheResolver<
      WithTypename<ActionNotifyOwner>,
      Record<string, never>,
      WithTypename<DelayedExecutionConfig> | string
    >;
    config?: GraphCacheResolver<
      WithTypename<ActionNotifyOwner>,
      Record<string, never>,
      WithTypename<ActionNotifyOwnerConfig> | string
    >;
  };
  ActionNotifyOwnerConfig?: {
    messageTemplate?: GraphCacheResolver<
      WithTypename<ActionNotifyOwnerConfig>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ActionQuarantine?: {
    id?: GraphCacheResolver<
      WithTypename<ActionQuarantine>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<ActionQuarantine>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<ActionQuarantine>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    delayedExecutionConfig?: GraphCacheResolver<
      WithTypename<ActionQuarantine>,
      Record<string, never>,
      WithTypename<DelayedExecutionConfig> | string
    >;
    config?: GraphCacheResolver<
      WithTypename<ActionQuarantine>,
      Record<string, never>,
      WithTypename<ActionQuarantineConfig> | string
    >;
  };
  ActionQuarantineConfig?: {
    messageContent?: GraphCacheResolver<
      WithTypename<ActionQuarantineConfig>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ActionRedact?: {
    id?: GraphCacheResolver<
      WithTypename<ActionRedact>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<ActionRedact>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<ActionRedact>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    delayedExecutionConfig?: GraphCacheResolver<
      WithTypename<ActionRedact>,
      Record<string, never>,
      WithTypename<DelayedExecutionConfig> | string
    >;
    config?: GraphCacheResolver<
      WithTypename<ActionRedact>,
      Record<string, never>,
      WithTypename<ActionRedactConfig> | string
    >;
  };
  ActionRedactConfig?: {
    dummyRedactSlack?: GraphCacheResolver<
      WithTypename<ActionRedactConfig>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    messageTemplate?: GraphCacheResolver<
      WithTypename<ActionRedactConfig>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ActionRevokeFilePermissions?: {
    id?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissions>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissions>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissions>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    delayedExecutionConfig?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissions>,
      Record<string, never>,
      WithTypename<DelayedExecutionConfig> | string
    >;
    config?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissions>,
      Record<string, never>,
      WithTypename<ActionRevokeFilePermissionsConfig> | string
    >;
  };
  ActionRevokeFilePermissionsConfig?: {
    id?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissionsConfig>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    revokePublicSharing?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissionsConfig>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    revokeInternalSharing?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissionsConfig>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    resolveViolation?: GraphCacheResolver<
      WithTypename<ActionRevokeFilePermissionsConfig>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  Actor?: {
    id?: GraphCacheResolver<
      WithTypename<Actor>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    displayName?: GraphCacheResolver<
      WithTypename<Actor>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    imageUrl?: GraphCacheResolver<
      WithTypename<Actor>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    groups?: GraphCacheResolver<
      WithTypename<Actor>,
      Record<string, never>,
      Array<WithTypename<Group> | string>
    >;
  };
  ActorActorConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<ActorActorConnection>,
      Record<string, never>,
      Array<WithTypename<ActorActorEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<ActorActorConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  ActorActorEdge?: {
    node?: GraphCacheResolver<
      WithTypename<ActorActorEdge>,
      Record<string, never>,
      WithTypename<Actor> | string
    >;
  };
  AlertChannel?: {
    type?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      AlertChannelType | string
    >;
    status?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      AlertChannelStatus | string
    >;
    id?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    config?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      Scalars["JSON"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<AlertChannel>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
  };
  AtomExposure?: {
    id?: GraphCacheResolver<
      WithTypename<AtomExposure>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    isPublic?: GraphCacheResolver<
      WithTypename<AtomExposure>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    internalViewerCount?: GraphCacheResolver<
      WithTypename<AtomExposure>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    externalViewerCount?: GraphCacheResolver<
      WithTypename<AtomExposure>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    score?: GraphCacheResolver<
      WithTypename<AtomExposure>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  AtomLocation?: {
    id?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    deeplink?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdBy?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      WithTypename<Actor> | string
    >;
    lastEditedBy?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      WithTypename<Actor> | string
    >;
    parent?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      WithTypename<AtomLocation> | string
    >;
    parentId?: GraphCacheResolver<
      WithTypename<AtomLocation>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
  };
  BulkAction?: {
    id?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    startedAt?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    cancelledAt?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdBy?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdByUser?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      WithTypename<User> | string
    >;
    summary?: GraphCacheResolver<
      WithTypename<BulkAction>,
      Record<string, never>,
      WithTypename<BulkActionSummary> | string
    >;
  };
  BulkActionConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<BulkActionConnection>,
      Record<string, never>,
      Array<WithTypename<BulkActionEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<BulkActionConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  BulkActionEdge?: {
    node?: GraphCacheResolver<
      WithTypename<BulkActionEdge>,
      Record<string, never>,
      WithTypename<BulkAction> | string
    >;
  };
  BulkActionSummary?: {
    bulkActionId?: GraphCacheResolver<
      WithTypename<BulkActionSummary>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    commands?: GraphCacheResolver<
      WithTypename<BulkActionSummary>,
      Record<string, never>,
      Array<WithTypename<BulkActionSummaryCommandCount> | string>
    >;
    detectionCount?: GraphCacheResolver<
      WithTypename<BulkActionSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  BulkActionSummaryCommandCount?: {
    appType?: GraphCacheResolver<
      WithTypename<BulkActionSummaryCommandCount>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    command?: GraphCacheResolver<
      WithTypename<BulkActionSummaryCommandCount>,
      Record<string, never>,
      RiskActionCommandEnum | string
    >;
    count?: GraphCacheResolver<
      WithTypename<BulkActionSummaryCommandCount>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  BulkActionsSummary?: {
    slackRedactMessage?: GraphCacheResolver<
      WithTypename<BulkActionsSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    gdriveRevokeFilePermissions?: GraphCacheResolver<
      WithTypename<BulkActionsSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    resolveRisk?: GraphCacheResolver<
      WithTypename<BulkActionsSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  ClassificationResult?: {
    value?: GraphCacheResolver<
      WithTypename<ClassificationResult>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    offset?: GraphCacheResolver<
      WithTypename<ClassificationResult>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    length?: GraphCacheResolver<
      WithTypename<ClassificationResult>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  Classifier?: {
    id?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    version?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    origin?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      ClassifierOriginEnum | string
    >;
    definition?: GraphCacheResolver<
      WithTypename<Classifier>,
      Record<string, never>,
      WithTypename<ClassifierDefinition> | string
    >;
  };
  ClassifierConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<ClassifierConnection>,
      Record<string, never>,
      Array<WithTypename<ClassifierEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<ClassifierConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  ClassifierDefinition?: {
    builtinClassifierOverrideID?: GraphCacheResolver<
      WithTypename<ClassifierDefinition>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    regex?: GraphCacheResolver<
      WithTypename<ClassifierDefinition>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    exclusions?: GraphCacheResolver<
      WithTypename<ClassifierDefinition>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    surroundings?: GraphCacheResolver<
      WithTypename<ClassifierDefinition>,
      Record<string, never>,
      Array<WithTypename<ClassifierDefinitionSurrounding> | string>
    >;
  };
  ClassifierDefinitionSurrounding?: {
    width?: GraphCacheResolver<
      WithTypename<ClassifierDefinitionSurrounding>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    regex?: GraphCacheResolver<
      WithTypename<ClassifierDefinitionSurrounding>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ClassifierEdge?: {
    node?: GraphCacheResolver<
      WithTypename<ClassifierEdge>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
  };
  ClassifierExclusion?: {
    id?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    classifierId?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    detectionHash?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    pattern?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<ClassifierExclusion>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  CountedGroup_of_Classifier?: {
    count?: GraphCacheResolver<
      WithTypename<CountedGroup_Of_Classifier>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<CountedGroup_Of_Classifier>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
  };
  CountedGroup_of_Location?: {
    count?: GraphCacheResolver<
      WithTypename<CountedGroup_Of_Location>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<CountedGroup_Of_Location>,
      Record<string, never>,
      WithTypename<Location> | string
    >;
  };
  CountedGroups_of_Classifier?: {
    groups?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Classifier>,
      Record<string, never>,
      Array<WithTypename<CountedGroup_Of_Classifier> | string>
    >;
    countSum?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Classifier>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    countOther?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Classifier>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  CountedGroups_of_Location?: {
    groups?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Location>,
      Record<string, never>,
      Array<WithTypename<CountedGroup_Of_Location> | string>
    >;
    countSum?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Location>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    countOther?: GraphCacheResolver<
      WithTypename<CountedGroups_Of_Location>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  Datapoint?: {
    detectionHash?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    obscuredDetection?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    classifierType?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    classifier?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
    impact?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      ImpactLevel | string
    >;
    firstDetected?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    firstDetectedBackdate?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    lastDetected?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    tags?: GraphCacheResolver<
      WithTypename<Datapoint>,
      Record<string, never>,
      Array<WithTypename<DatapointTag> | string>
    >;
  };
  DatapointConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<DatapointConnection>,
      Record<string, never>,
      Array<WithTypename<DatapointEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<DatapointConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  DatapointEdge?: {
    node?: GraphCacheResolver<
      WithTypename<DatapointEdge>,
      Record<string, never>,
      WithTypename<Datapoint> | string
    >;
    meta?: GraphCacheResolver<
      WithTypename<DatapointEdge>,
      Record<string, never>,
      WithTypename<DatapointEdgeMeta> | string
    >;
  };
  DatapointEdgeMeta?: {
    occurrences?: GraphCacheResolver<
      WithTypename<DatapointEdgeMeta>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    appTypes?: GraphCacheResolver<
      WithTypename<DatapointEdgeMeta>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >;
  };
  DatapointTag?: {
    id?: GraphCacheResolver<
      WithTypename<DatapointTag>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<DatapointTag>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<DatapointTag>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  DelayedExecutionConfig?: {
    isEnabled?: GraphCacheResolver<
      WithTypename<DelayedExecutionConfig>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    unit?: GraphCacheResolver<
      WithTypename<DelayedExecutionConfig>,
      Record<string, never>,
      DelayedExecutionConfigUnitEnum | string
    >;
    value?: GraphCacheResolver<
      WithTypename<DelayedExecutionConfig>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  Detection?: {
    id?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    atomId?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    detectionHash?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    pattern?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    obscuredDetection?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    location?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      WithTypename<Location> | string
    >;
    firstDetected?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    lastDetected?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    firstDetectedBackdate?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    classifierType?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    classifier?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
    actionState?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      Scalars["JSON"] | string
    >;
    actor?: GraphCacheResolver<
      WithTypename<Detection>,
      Record<string, never>,
      WithTypename<Actor> | string
    >;
  };
  DetectionBundle?: {
    id?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    location?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      WithTypename<Location> | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    impact?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      ImpactLevel | string
    >;
    otherClassifierCount?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    primaryClassifierCount?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    primaryClassifierType?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
    timestamp?: GraphCacheResolver<
      WithTypename<DetectionBundle>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
  };
  DetectionBundleConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<DetectionBundleConnection>,
      Record<string, never>,
      Array<WithTypename<DetectionBundleEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<DetectionBundleConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  DetectionBundleEdge?: {
    node?: GraphCacheResolver<
      WithTypename<DetectionBundleEdge>,
      Record<string, never>,
      WithTypename<DetectionBundle> | string
    >;
  };
  DetectionConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<DetectionConnection>,
      Record<string, never>,
      Array<WithTypename<DetectionEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<DetectionConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  DetectionEdge?: {
    node?: GraphCacheResolver<
      WithTypename<DetectionEdge>,
      Record<string, never>,
      WithTypename<Detection> | string
    >;
  };
  DirtyRiskCount?: {
    total?: GraphCacheResolver<
      WithTypename<DirtyRiskCount>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  Duration?: {
    milliseconds?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    seconds?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    minutes?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    hours?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    days?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    weeks?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    months?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    years?: GraphCacheResolver<
      WithTypename<Duration>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  FeedbackReceived?: {
    success?: GraphCacheResolver<
      WithTypename<FeedbackReceived>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  FilePermissionByRiskRevoked?: {
    atomId?: GraphCacheResolver<
      WithTypename<FilePermissionByRiskRevoked>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  FilePermissionRevoked?: {
    entityId?: GraphCacheResolver<
      WithTypename<FilePermissionRevoked>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    idsToInvalidate?: GraphCacheResolver<
      WithTypename<FilePermissionRevoked>,
      Record<string, never>,
      Array<Scalars["ID"] | string>
    >;
  };
  GViolationRuleActionRecordConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<GViolationRuleActionRecordConnection>,
      Record<string, never>,
      Array<WithTypename<GViolationRuleActionRecordEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<GViolationRuleActionRecordConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  GViolationRuleActionRecordEdge?: {
    node?: GraphCacheResolver<
      WithTypename<GViolationRuleActionRecordEdge>,
      Record<string, never>,
      WithTypename<RuleActionRecord> | string
    >;
  };
  Group?: {
    id?: GraphCacheResolver<
      WithTypename<Group>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Group>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  GroupDelete?: {
    id?: GraphCacheResolver<
      WithTypename<GroupDelete>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
  };
  GroupUpdate?: {
    success?: GraphCacheResolver<
      WithTypename<GroupUpdate>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  Histogram_of_CountedGroups_of_Classifier?: {
    timebound?: GraphCacheResolver<
      WithTypename<Histogram_Of_CountedGroups_Of_Classifier>,
      Record<string, never>,
      WithTypename<TimeBound> | string
    >;
    timesliceDurationMillis?: GraphCacheResolver<
      WithTypename<Histogram_Of_CountedGroups_Of_Classifier>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    timeslices?: GraphCacheResolver<
      WithTypename<Histogram_Of_CountedGroups_Of_Classifier>,
      Record<string, never>,
      Array<WithTypename<TimeSlice_Of_CountedGroups_Of_Classifier> | string>
    >;
  };
  Installation?: {
    id?: GraphCacheResolver<
      WithTypename<Installation>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Installation>,
      Record<string, never>,
      InstallationStatusEnum | string
    >;
    integration?: GraphCacheResolver<
      WithTypename<Installation>,
      Record<string, never>,
      WithTypename<Integration> | string
    >;
  };
  Integration?: {
    id?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    logoUrl?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    iconUrl?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    largeIconUrl?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    comingSoon?: GraphCacheResolver<
      WithTypename<Integration>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  IntegrationSchema?: {
    appType?: GraphCacheResolver<
      WithTypename<IntegrationSchema>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    schemaEntities?: GraphCacheResolver<
      WithTypename<IntegrationSchema>,
      Record<string, never>,
      Array<WithTypename<SchemaEntity> | string>
    >;
  };
  LabelledAtom?: {
    id?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityId?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    installationId?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    primaryEntityName?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    iconType?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    exposure?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      WithTypename<AtomExposure> | string
    >;
    location?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      WithTypename<AtomLocation> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<LabelledAtom>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
  };
  LabelledAtomConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<LabelledAtomConnection>,
      Record<string, never>,
      Array<WithTypename<LabelledAtomEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<LabelledAtomConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  LabelledAtomEdge?: {
    node?: GraphCacheResolver<
      WithTypename<LabelledAtomEdge>,
      Record<string, never>,
      WithTypename<LabelledAtom> | string
    >;
  };
  Location?: {
    appType?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityId?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    installationId?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    fieldIdentifier?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    temp_entityDeeplink?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    temp_canonicalLabel?: GraphCacheResolver<
      WithTypename<Location>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  Me?: {
    id?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    customerId?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    nickname?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    avatarUrl?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    primaryEmail?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    role?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    accountName?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    account?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      WithTypename<Account> | string
    >;
    permissions?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >;
    featureFlags?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      Scalars["JSON"] | string
    >;
    membershipStatus?: GraphCacheResolver<
      WithTypename<Me>,
      Record<string, never>,
      AccountMembershipStatus | string
    >;
  };
  MessageByRiskRedacted?: {
    atomId?: GraphCacheResolver<
      WithTypename<MessageByRiskRedacted>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  Monitor?: {
    id?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    filters?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      Scalars["DatapointFilters"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      MonitorStatus | string
    >;
    riskLevel?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      MonitorRiskLevel | string
    >;
    violations?: GraphCacheResolver<
      WithTypename<Monitor>,
      MonitorViolationsArgs,
      WithTypename<MonitorViolationConnection> | string
    >;
    violationSummary?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      WithTypename<ViolationSummary> | string
    >;
    actions?: GraphCacheResolver<
      WithTypename<Monitor>,
      Record<string, never>,
      WithTypename<MonitorActions> | string
    >;
  };
  MonitorActions?: {
    id?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    redact?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      WithTypename<ActionRedact> | string
    >;
    quarantine?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      WithTypename<ActionQuarantine> | string
    >;
    notifyOwner?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      WithTypename<ActionNotifyOwner> | string
    >;
    alertAdmin?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      WithTypename<ActionAlertAdmin> | string
    >;
    reduceExposure?: GraphCacheResolver<
      WithTypename<MonitorActions>,
      Record<string, never>,
      WithTypename<ActionRevokeFilePermissions> | string
    >;
  };
  MonitorViolationConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<MonitorViolationConnection>,
      Record<string, never>,
      Array<WithTypename<MonitorViolationEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<MonitorViolationConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  MonitorViolationEdge?: {
    node?: GraphCacheResolver<
      WithTypename<MonitorViolationEdge>,
      Record<string, never>,
      WithTypename<Violation> | string
    >;
  };
  OverviewBreakdown?: {
    highFreqDatapointBreakdown?: GraphCacheResolver<
      WithTypename<OverviewBreakdown>,
      Record<string, never>,
      WithTypename<CountedGroups_Of_Classifier> | string
    >;
    dataLocationsBreakdown?: GraphCacheResolver<
      WithTypename<OverviewBreakdown>,
      Record<string, never>,
      WithTypename<CountedGroups_Of_Location> | string
    >;
    datapointTypeBreakdown?: GraphCacheResolver<
      WithTypename<OverviewBreakdown>,
      Record<string, never>,
      WithTypename<CountedGroups_Of_Classifier> | string
    >;
    detectionHistogram?: GraphCacheResolver<
      WithTypename<OverviewBreakdown>,
      OverviewBreakdownDetectionHistogramArgs,
      WithTypename<Histogram_Of_CountedGroups_Of_Classifier> | string
    >;
  };
  PageInfo?: {
    endCursor?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    hasNextPage?: GraphCacheResolver<
      WithTypename<PageInfo>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  ReportCSV?: {
    data?: GraphCacheResolver<
      WithTypename<ReportCsv>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ReportViolation?: {
    id?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    detection?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      WithTypename<Detection> | string
    >;
    monitorVersion?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    timestamp?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      ViolationStatus | string
    >;
    actor?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      WithTypename<ReportViolationActor> | string
    >;
    monitorName?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    riskLevel?: GraphCacheResolver<
      WithTypename<ReportViolation>,
      Record<string, never>,
      MonitorRiskLevel | string
    >;
  };
  ReportViolationActor?: {
    id?: GraphCacheResolver<
      WithTypename<ReportViolationActor>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    displayName?: GraphCacheResolver<
      WithTypename<ReportViolationActor>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    imageUrl?: GraphCacheResolver<
      WithTypename<ReportViolationActor>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ReportViolationCounts?: {
    resolved?: GraphCacheResolver<
      WithTypename<ReportViolationCounts>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    unresolved?: GraphCacheResolver<
      WithTypename<ReportViolationCounts>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    falsePositive?: GraphCacheResolver<
      WithTypename<ReportViolationCounts>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  Risk?: {
    id?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    atomId?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    atom?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      WithTypename<LabelledAtom> | string
    >;
    sdf?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    sdfLevel?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      RiskSdfLevelEnum | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    shortCode?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    sharedAt?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    isDirty?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
    pendingActions?: GraphCacheResolver<
      WithTypename<Risk>,
      Record<string, never>,
      Array<WithTypename<RiskAction> | string>
    >;
  };
  RiskAction?: {
    id?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    bulkActionId?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    riskId?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    command?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      RiskActionCommandEnum | string
    >;
    status?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    risk?: GraphCacheResolver<
      WithTypename<RiskAction>,
      Record<string, never>,
      WithTypename<Risk> | string
    >;
  };
  RiskActionCommandResult?: {
    success?: GraphCacheResolver<
      WithTypename<RiskActionCommandResult>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  RiskActionConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<RiskActionConnection>,
      Record<string, never>,
      Array<WithTypename<RiskActionEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<RiskActionConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  RiskActionEdge?: {
    node?: GraphCacheResolver<
      WithTypename<RiskActionEdge>,
      Record<string, never>,
      WithTypename<RiskAction> | string
    >;
  };
  RiskConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<RiskConnection>,
      Record<string, never>,
      Array<WithTypename<RiskEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<RiskConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  RiskDataPointSummary?: {
    count?: GraphCacheResolver<
      WithTypename<RiskDataPointSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    level?: GraphCacheResolver<
      WithTypename<RiskDataPointSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    classifier?: GraphCacheResolver<
      WithTypename<RiskDataPointSummary>,
      Record<string, never>,
      WithTypename<Classifier> | string
    >;
    classifierId?: GraphCacheResolver<
      WithTypename<RiskDataPointSummary>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  RiskEdge?: {
    node?: GraphCacheResolver<
      WithTypename<RiskEdge>,
      Record<string, never>,
      WithTypename<Risk> | string
    >;
  };
  RiskGroup?: {
    id?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    field?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    cumulativeSdf?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    risks?: GraphCacheResolver<
      WithTypename<RiskGroup>,
      RiskGroupRisksArgs,
      WithTypename<RiskConnection> | string
    >;
  };
  RiskGroupConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<RiskGroupConnection>,
      Record<string, never>,
      Array<WithTypename<RiskGroupEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<RiskGroupConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  RiskGroupEdge?: {
    node?: GraphCacheResolver<
      WithTypename<RiskGroupEdge>,
      Record<string, never>,
      WithTypename<RiskGroup> | string
    >;
  };
  RiskSummary?: {
    low?: GraphCacheResolver<
      WithTypename<RiskSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    medium?: GraphCacheResolver<
      WithTypename<RiskSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    high?: GraphCacheResolver<
      WithTypename<RiskSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    critical?: GraphCacheResolver<
      WithTypename<RiskSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  RuleActionRecord?: {
    id?: GraphCacheResolver<
      WithTypename<RuleActionRecord>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    actionType?: GraphCacheResolver<
      WithTypename<RuleActionRecord>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    result?: GraphCacheResolver<
      WithTypename<RuleActionRecord>,
      Record<string, never>,
      WithTypename<RuleActionResult> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<RuleActionRecord>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
  };
  RuleActionResult?: {
    id?: GraphCacheResolver<
      WithTypename<RuleActionResult>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<RuleActionResult>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    meta?: GraphCacheResolver<
      WithTypename<RuleActionResult>,
      Record<string, never>,
      Scalars["JSON"] | string
    >;
  };
  SDFTimeSlice?: {
    date?: GraphCacheResolver<
      WithTypename<SdfTimeSlice>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    sdf?: GraphCacheResolver<
      WithTypename<SdfTimeSlice>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  SDFTotal?: {
    sdf?: GraphCacheResolver<
      WithTypename<SdfTotal>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    health?: GraphCacheResolver<
      WithTypename<SdfTotal>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<SdfTotal>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
  };
  SavedQuery?: {
    id?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    filters?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["DatapointFilters"] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    archived?: GraphCacheResolver<
      WithTypename<SavedQuery>,
      Record<string, never>,
      Scalars["Boolean"] | string
    >;
  };
  SavedQueryConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<SavedQueryConnection>,
      Record<string, never>,
      Array<WithTypename<SavedQueryEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<SavedQueryConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  SavedQueryEdge?: {
    node?: GraphCacheResolver<
      WithTypename<SavedQueryEdge>,
      Record<string, never>,
      WithTypename<SavedQuery> | string
    >;
  };
  SchemaEntity?: {
    appType?: GraphCacheResolver<
      WithTypename<SchemaEntity>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<SchemaEntity>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    schemaFields?: GraphCacheResolver<
      WithTypename<SchemaEntity>,
      Record<string, never>,
      Array<WithTypename<SchemaEntityField> | string>
    >;
  };
  SchemaEntityField?: {
    id?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    appType?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityType?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    propertyName?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    propertyLabel?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    propertyType?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    entityTypeOfReferencedField?: GraphCacheResolver<
      WithTypename<SchemaEntityField>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  Thing?: {
    name?: GraphCacheResolver<
      WithTypename<Thing>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  ThingConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<ThingConnection>,
      Record<string, never>,
      Array<WithTypename<ThingEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<ThingConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  ThingEdge?: {
    node?: GraphCacheResolver<
      WithTypename<ThingEdge>,
      Record<string, never>,
      WithTypename<Thing> | string
    >;
    meta?: GraphCacheResolver<
      WithTypename<ThingEdge>,
      Record<string, never>,
      WithTypename<ThingSummaryMeta> | string
    >;
  };
  ThingSummaryMeta?: {
    otherLocations?: GraphCacheResolver<
      WithTypename<ThingSummaryMeta>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    stuff?: GraphCacheResolver<
      WithTypename<ThingSummaryMeta>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  TimeBound?: {
    timeStart?: GraphCacheResolver<
      WithTypename<TimeBound>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    timeMid?: GraphCacheResolver<
      WithTypename<TimeBound>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    timeEnd?: GraphCacheResolver<
      WithTypename<TimeBound>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    duration?: GraphCacheResolver<
      WithTypename<TimeBound>,
      Record<string, never>,
      WithTypename<Duration> | string
    >;
  };
  TimeSlice_of_CountedGroups_of_Classifier?: {
    timebound?: GraphCacheResolver<
      WithTypename<TimeSlice_Of_CountedGroups_Of_Classifier>,
      Record<string, never>,
      WithTypename<TimeBound> | string
    >;
    content?: GraphCacheResolver<
      WithTypename<TimeSlice_Of_CountedGroups_Of_Classifier>,
      Record<string, never>,
      WithTypename<CountedGroups_Of_Classifier> | string
    >;
  };
  User?: {
    id?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    customerId?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    nickname?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    avatarUrl?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    primaryEmail?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    role?: GraphCacheResolver<
      WithTypename<User>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  UserAuditLog?: {
    id?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    userName?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    userEmail?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    userId?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    accountId?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    ip?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    userAgent?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    action?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    resource?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    resourceId?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    timestamp?: GraphCacheResolver<
      WithTypename<UserAuditLog>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
  UserAuditLogUserAuditLogConnection?: {
    edges?: GraphCacheResolver<
      WithTypename<UserAuditLogUserAuditLogConnection>,
      Record<string, never>,
      Array<WithTypename<UserAuditLogUserAuditLogEdge> | string>
    >;
    pageInfo?: GraphCacheResolver<
      WithTypename<UserAuditLogUserAuditLogConnection>,
      Record<string, never>,
      WithTypename<PageInfo> | string
    >;
  };
  UserAuditLogUserAuditLogEdge?: {
    node?: GraphCacheResolver<
      WithTypename<UserAuditLogUserAuditLogEdge>,
      Record<string, never>,
      WithTypename<UserAuditLog> | string
    >;
  };
  Violation?: {
    id?: GraphCacheResolver<
      WithTypename<Violation>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    detection?: GraphCacheResolver<
      WithTypename<Violation>,
      Record<string, never>,
      WithTypename<Detection> | string
    >;
    monitorVersion?: GraphCacheResolver<
      WithTypename<Violation>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    timestamp?: GraphCacheResolver<
      WithTypename<Violation>,
      Record<string, never>,
      Scalars["Date"] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<Violation>,
      Record<string, never>,
      ViolationStatus | string
    >;
  };
  ViolationActionResponse?: {
    violation?: GraphCacheResolver<
      WithTypename<ViolationActionResponse>,
      Record<string, never>,
      WithTypename<Violation> | string
    >;
    idsToInvalidate?: GraphCacheResolver<
      WithTypename<ViolationActionResponse>,
      Record<string, never>,
      Array<Scalars["ID"] | string>
    >;
  };
  ViolationHistory?: {
    date?: GraphCacheResolver<
      WithTypename<ViolationHistory>,
      Record<string, never>,
      Scalars["String"] | string
    >;
    violations?: GraphCacheResolver<
      WithTypename<ViolationHistory>,
      Record<string, never>,
      WithTypename<ViolationRiskLevel> | string
    >;
  };
  ViolationRiskLevel?: {
    low?: GraphCacheResolver<
      WithTypename<ViolationRiskLevel>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    medium?: GraphCacheResolver<
      WithTypename<ViolationRiskLevel>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    high?: GraphCacheResolver<
      WithTypename<ViolationRiskLevel>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    critical?: GraphCacheResolver<
      WithTypename<ViolationRiskLevel>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
  };
  ViolationSummary?: {
    id?: GraphCacheResolver<
      WithTypename<ViolationSummary>,
      Record<string, never>,
      Scalars["ID"] | string
    >;
    currentViolations?: GraphCacheResolver<
      WithTypename<ViolationSummary>,
      Record<string, never>,
      WithTypename<ViolationRiskLevel> | string
    >;
    triggeredRules?: GraphCacheResolver<
      WithTypename<ViolationSummary>,
      Record<string, never>,
      Scalars["Int"] | string
    >;
    currentThreatLevel?: GraphCacheResolver<
      WithTypename<ViolationSummary>,
      Record<string, never>,
      MonitorRiskLevel | string
    >;
    history?: GraphCacheResolver<
      WithTypename<ViolationSummary>,
      Record<string, never>,
      Array<WithTypename<ViolationHistory> | string>
    >;
  };
  WebhookConfiguration?: {
    endpoints?: GraphCacheResolver<
      WithTypename<WebhookConfiguration>,
      Record<string, never>,
      Array<Scalars["String"] | string>
    >;
  };
  WebhookSigningKey?: {
    signingKey?: GraphCacheResolver<
      WithTypename<WebhookSigningKey>,
      Record<string, never>,
      Scalars["String"] | string
    >;
  };
};

export type GraphCacheOptimisticUpdaters = {
  createMonitor?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateMonitorArgs,
    Maybe<WithTypename<Monitor>>
  >;
  deleteMonitor?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeDeleteMonitorArgs,
    Maybe<Scalars["Boolean"]>
  >;
  updateMonitor?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateMonitorArgs,
    Maybe<WithTypename<Monitor>>
  >;
  createQuery?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateQueryArgs,
    Maybe<WithTypename<SavedQuery>>
  >;
  updateQuery?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateQueryArgs,
    Maybe<WithTypename<SavedQuery>>
  >;
  deleteQuery?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeDeleteQueryArgs,
    Maybe<Scalars["Boolean"]>
  >;
  inviteUser?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeInviteUserArgs,
    Maybe<WithTypename<AccountMembership>>
  >;
  updateAccountOnboardingStatus?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateAccountOnboardingStatusArgs,
    Maybe<WithTypename<Account>>
  >;
  updateMembershipRole?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateMembershipRoleArgs,
    Maybe<WithTypename<AccountMembership>>
  >;
  suspendUser?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeSuspendUserArgs,
    Maybe<WithTypename<AccountMembership>>
  >;
  membershipAcceptInvitation?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Maybe<WithTypename<AccountMembership>>
  >;
  demoResolveViolation?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeDemoResolveViolationArgs,
    Maybe<WithTypename<ViolationActionResponse>>
  >;
  demoRedactDetection?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeDemoRedactDetectionArgs,
    Maybe<WithTypename<Detection>>
  >;
  demoNotifyRisk?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeDemoNotifyRiskArgs,
    Maybe<WithTypename<Risk>>
  >;
  bulkChangeViolationStatus?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeBulkChangeViolationStatusArgs,
    Maybe<Array<WithTypename<Violation>>>
  >;
  createClassifier?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateClassifierArgs,
    Maybe<WithTypename<Classifier>>
  >;
  updateClassifier?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateClassifierArgs,
    Maybe<WithTypename<Classifier>>
  >;
  updateWebhookConfiguration?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateWebhookConfigurationArgs,
    Maybe<WithTypename<WebhookConfiguration>>
  >;
  rotateWebhookConfigurationSigningKey?: GraphCacheOptimisticMutationResolver<
    Record<string, never>,
    Maybe<WithTypename<WebhookSigningKey>>
  >;
  updateRuleActionRedact?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateRuleActionRedactArgs,
    Maybe<WithTypename<ActionRedact>>
  >;
  updateRuleActionQuarantine?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateRuleActionQuarantineArgs,
    Maybe<WithTypename<ActionQuarantine>>
  >;
  updateRuleActionNotifyOwner?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateRuleActionNotifyOwnerArgs,
    Maybe<WithTypename<ActionNotifyOwner>>
  >;
  updateRuleActionAlertAdmin?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateRuleActionAlertAdminArgs,
    Maybe<WithTypename<ActionAlertAdmin>>
  >;
  updateRuleActionRevokeFilePermissions?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateRuleActionRevokeFilePermissionsArgs,
    Maybe<WithTypename<ActionRevokeFilePermissions>>
  >;
  generateViolationsOverTimeReport?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeGenerateViolationsOverTimeReportArgs,
    Maybe<WithTypename<ReportCsv>>
  >;
  createAlertChannel?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateAlertChannelArgs,
    Maybe<WithTypename<AlertChannel>>
  >;
  updateAlertChannel?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateAlertChannelArgs,
    Maybe<WithTypename<AlertChannel>>
  >;
  createGroup?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateGroupArgs,
    Maybe<WithTypename<Group>>
  >;
  removeGroup?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeRemoveGroupArgs,
    Maybe<WithTypename<GroupDelete>>
  >;
  insertActorsIntoGroups?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeInsertActorsIntoGroupsArgs,
    Maybe<WithTypename<GroupUpdate>>
  >;
  updateActor?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeUpdateActorArgs,
    Maybe<WithTypename<Actor>>
  >;
  markRiskAsResolved?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeMarkRiskAsResolvedArgs,
    Maybe<WithTypename<Risk>>
  >;
  bulkCreateRiskAction?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeBulkCreateRiskActionArgs,
    Maybe<WithTypename<RiskActionCommandResult>>
  >;
  createClassifierExclusion?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCreateClassifierExclusionArgs,
    Maybe<WithTypename<ClassifierExclusion>>
  >;
  bulkCreateClassifierHashExclusions?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeBulkCreateClassifierHashExclusionsArgs,
    Maybe<Array<WithTypename<ClassifierExclusion>>>
  >;
  leaveSDFFeedback?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeLeaveSdfFeedbackArgs,
    Maybe<WithTypename<FeedbackReceived>>
  >;
  modifyGDrivePermissions?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeModifyGDrivePermissionsArgs,
    Maybe<WithTypename<FilePermissionRevoked>>
  >;
  cancelBulkAction?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeCancelBulkActionArgs,
    Maybe<WithTypename<BulkAction>>
  >;
  modifyGDrivePermissionsForRisk?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeModifyGDrivePermissionsForRiskArgs,
    Maybe<WithTypename<FilePermissionByRiskRevoked>>
  >;
  redactSlackMessageForRisk?: GraphCacheOptimisticMutationResolver<
    RootMutationTypeRedactSlackMessageForRiskArgs,
    Maybe<WithTypename<MessageByRiskRedacted>>
  >;
};

export type GraphCacheUpdaters = {
  Mutation?: {
    createMonitor?: GraphCacheUpdateResolver<
      { createMonitor: Maybe<WithTypename<Monitor>> },
      RootMutationTypeCreateMonitorArgs
    >;
    deleteMonitor?: GraphCacheUpdateResolver<
      { deleteMonitor: Maybe<Scalars["Boolean"]> },
      RootMutationTypeDeleteMonitorArgs
    >;
    updateMonitor?: GraphCacheUpdateResolver<
      { updateMonitor: Maybe<WithTypename<Monitor>> },
      RootMutationTypeUpdateMonitorArgs
    >;
    createQuery?: GraphCacheUpdateResolver<
      { createQuery: Maybe<WithTypename<SavedQuery>> },
      RootMutationTypeCreateQueryArgs
    >;
    updateQuery?: GraphCacheUpdateResolver<
      { updateQuery: Maybe<WithTypename<SavedQuery>> },
      RootMutationTypeUpdateQueryArgs
    >;
    deleteQuery?: GraphCacheUpdateResolver<
      { deleteQuery: Maybe<Scalars["Boolean"]> },
      RootMutationTypeDeleteQueryArgs
    >;
    inviteUser?: GraphCacheUpdateResolver<
      { inviteUser: Maybe<WithTypename<AccountMembership>> },
      RootMutationTypeInviteUserArgs
    >;
    updateAccountOnboardingStatus?: GraphCacheUpdateResolver<
      { updateAccountOnboardingStatus: Maybe<WithTypename<Account>> },
      RootMutationTypeUpdateAccountOnboardingStatusArgs
    >;
    updateMembershipRole?: GraphCacheUpdateResolver<
      { updateMembershipRole: Maybe<WithTypename<AccountMembership>> },
      RootMutationTypeUpdateMembershipRoleArgs
    >;
    suspendUser?: GraphCacheUpdateResolver<
      { suspendUser: Maybe<WithTypename<AccountMembership>> },
      RootMutationTypeSuspendUserArgs
    >;
    membershipAcceptInvitation?: GraphCacheUpdateResolver<
      { membershipAcceptInvitation: Maybe<WithTypename<AccountMembership>> },
      Record<string, never>
    >;
    demoResolveViolation?: GraphCacheUpdateResolver<
      { demoResolveViolation: Maybe<WithTypename<ViolationActionResponse>> },
      RootMutationTypeDemoResolveViolationArgs
    >;
    demoRedactDetection?: GraphCacheUpdateResolver<
      { demoRedactDetection: Maybe<WithTypename<Detection>> },
      RootMutationTypeDemoRedactDetectionArgs
    >;
    demoNotifyRisk?: GraphCacheUpdateResolver<
      { demoNotifyRisk: Maybe<WithTypename<Risk>> },
      RootMutationTypeDemoNotifyRiskArgs
    >;
    bulkChangeViolationStatus?: GraphCacheUpdateResolver<
      { bulkChangeViolationStatus: Maybe<Array<WithTypename<Violation>>> },
      RootMutationTypeBulkChangeViolationStatusArgs
    >;
    createClassifier?: GraphCacheUpdateResolver<
      { createClassifier: Maybe<WithTypename<Classifier>> },
      RootMutationTypeCreateClassifierArgs
    >;
    updateClassifier?: GraphCacheUpdateResolver<
      { updateClassifier: Maybe<WithTypename<Classifier>> },
      RootMutationTypeUpdateClassifierArgs
    >;
    updateWebhookConfiguration?: GraphCacheUpdateResolver<
      { updateWebhookConfiguration: Maybe<WithTypename<WebhookConfiguration>> },
      RootMutationTypeUpdateWebhookConfigurationArgs
    >;
    rotateWebhookConfigurationSigningKey?: GraphCacheUpdateResolver<
      {
        rotateWebhookConfigurationSigningKey: Maybe<
          WithTypename<WebhookSigningKey>
        >;
      },
      Record<string, never>
    >;
    updateRuleActionRedact?: GraphCacheUpdateResolver<
      { updateRuleActionRedact: Maybe<WithTypename<ActionRedact>> },
      RootMutationTypeUpdateRuleActionRedactArgs
    >;
    updateRuleActionQuarantine?: GraphCacheUpdateResolver<
      { updateRuleActionQuarantine: Maybe<WithTypename<ActionQuarantine>> },
      RootMutationTypeUpdateRuleActionQuarantineArgs
    >;
    updateRuleActionNotifyOwner?: GraphCacheUpdateResolver<
      { updateRuleActionNotifyOwner: Maybe<WithTypename<ActionNotifyOwner>> },
      RootMutationTypeUpdateRuleActionNotifyOwnerArgs
    >;
    updateRuleActionAlertAdmin?: GraphCacheUpdateResolver<
      { updateRuleActionAlertAdmin: Maybe<WithTypename<ActionAlertAdmin>> },
      RootMutationTypeUpdateRuleActionAlertAdminArgs
    >;
    updateRuleActionRevokeFilePermissions?: GraphCacheUpdateResolver<
      {
        updateRuleActionRevokeFilePermissions: Maybe<
          WithTypename<ActionRevokeFilePermissions>
        >;
      },
      RootMutationTypeUpdateRuleActionRevokeFilePermissionsArgs
    >;
    generateViolationsOverTimeReport?: GraphCacheUpdateResolver<
      { generateViolationsOverTimeReport: Maybe<WithTypename<ReportCsv>> },
      RootMutationTypeGenerateViolationsOverTimeReportArgs
    >;
    createAlertChannel?: GraphCacheUpdateResolver<
      { createAlertChannel: Maybe<WithTypename<AlertChannel>> },
      RootMutationTypeCreateAlertChannelArgs
    >;
    updateAlertChannel?: GraphCacheUpdateResolver<
      { updateAlertChannel: Maybe<WithTypename<AlertChannel>> },
      RootMutationTypeUpdateAlertChannelArgs
    >;
    createGroup?: GraphCacheUpdateResolver<
      { createGroup: Maybe<WithTypename<Group>> },
      RootMutationTypeCreateGroupArgs
    >;
    removeGroup?: GraphCacheUpdateResolver<
      { removeGroup: Maybe<WithTypename<GroupDelete>> },
      RootMutationTypeRemoveGroupArgs
    >;
    insertActorsIntoGroups?: GraphCacheUpdateResolver<
      { insertActorsIntoGroups: Maybe<WithTypename<GroupUpdate>> },
      RootMutationTypeInsertActorsIntoGroupsArgs
    >;
    updateActor?: GraphCacheUpdateResolver<
      { updateActor: Maybe<WithTypename<Actor>> },
      RootMutationTypeUpdateActorArgs
    >;
    markRiskAsResolved?: GraphCacheUpdateResolver<
      { markRiskAsResolved: Maybe<WithTypename<Risk>> },
      RootMutationTypeMarkRiskAsResolvedArgs
    >;
    bulkCreateRiskAction?: GraphCacheUpdateResolver<
      { bulkCreateRiskAction: Maybe<WithTypename<RiskActionCommandResult>> },
      RootMutationTypeBulkCreateRiskActionArgs
    >;
    createClassifierExclusion?: GraphCacheUpdateResolver<
      { createClassifierExclusion: Maybe<WithTypename<ClassifierExclusion>> },
      RootMutationTypeCreateClassifierExclusionArgs
    >;
    bulkCreateClassifierHashExclusions?: GraphCacheUpdateResolver<
      {
        bulkCreateClassifierHashExclusions: Maybe<
          Array<WithTypename<ClassifierExclusion>>
        >;
      },
      RootMutationTypeBulkCreateClassifierHashExclusionsArgs
    >;
    leaveSDFFeedback?: GraphCacheUpdateResolver<
      { leaveSDFFeedback: Maybe<WithTypename<FeedbackReceived>> },
      RootMutationTypeLeaveSdfFeedbackArgs
    >;
    modifyGDrivePermissions?: GraphCacheUpdateResolver<
      { modifyGDrivePermissions: Maybe<WithTypename<FilePermissionRevoked>> },
      RootMutationTypeModifyGDrivePermissionsArgs
    >;
    cancelBulkAction?: GraphCacheUpdateResolver<
      { cancelBulkAction: Maybe<WithTypename<BulkAction>> },
      RootMutationTypeCancelBulkActionArgs
    >;
    modifyGDrivePermissionsForRisk?: GraphCacheUpdateResolver<
      {
        modifyGDrivePermissionsForRisk: Maybe<
          WithTypename<FilePermissionByRiskRevoked>
        >;
      },
      RootMutationTypeModifyGDrivePermissionsForRiskArgs
    >;
    redactSlackMessageForRisk?: GraphCacheUpdateResolver<
      { redactSlackMessageForRisk: Maybe<WithTypename<MessageByRiskRedacted>> },
      RootMutationTypeRedactSlackMessageForRiskArgs
    >;
  };
  Subscription?: {
    sdfTotalUpdated?: GraphCacheUpdateResolver<
      { sdfTotalUpdated: Maybe<WithTypename<SdfTotal>> },
      Record<string, never>
    >;
  };
};

export type GraphCacheConfig = {
  schema?: IntrospectionData;
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
  storage?: GraphCacheStorageAdapter;
};
