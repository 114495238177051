import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { ContextMenuButton } from "../../atoms/Button";
import { ContextMenuContainer } from "../ContextMenuContainer";

export const ContextMenu = () => {
  const { logout } = useAuth0();
  return (
    <ContextMenuContainer isUnderneath>
      <ContextMenuButton
        onClick={() => {
          logout({
            returnTo: window.location.origin,
          });
        }}
      >
        Logout
      </ContextMenuButton>
    </ContextMenuContainer>
  );
};
