import { GraphCacheKeysConfig } from "../../generated/graphql.generated";

export const keys: GraphCacheKeysConfig = {
  Risk: (data) => {
    return data.id ? data.id : null;
  },
  AtomLocation: (data) => {
    return data.id ? data.id : null;
  },
  LabelledAtom: (data) => {
    return data.id ? data.id : null;
  },
  AtomExposure: (data) => {
    return data.id ? data.id : null;
  },
  DirtyRiskCount: () => "dirtyRiskCount",
  RiskDataPointSummary: () => null,
  ActionRedactConfig: () => null,
  ActionAlertAdminConfig: () => null,
  ActionRevokeFilePermissionsConfig: () => null,
  TimeBound: () => null,
  ClassifierDefinition: () => null,
  ClassifierDefinitionSurrounding: () => null,
  CountedGroups_of_Location: () => null,
  CountedGroup_of_Location: () => null,
  Histogram_of_CountedGroups_of_Classifier: () => null,
  TimeSlice_of_CountedGroups_of_Classifier: () => null,
  CountedGroups_of_Classifier: () => null,
  CountedGroup_of_Classifier: () => null,
  Datapoint: () => null,
  DatapointEdgeMeta: () => null,
  OverviewBreakdown: () => null,
  Location: (data) => {
    return `${data.appType}:${data.entityId}:${data.entityType}`;
  },
  ViolationRiskLevel: () => null,
  ViolationHistory: () => null,
  ViolationSummary: () => null,
  MonitorViolationEdge: (data) => {
    const dataNode = data.node;
    return `${dataNode?.id}`;
  },
  BulkActionEdge: (data) => {
    const dataNode = data.node;
    return `${dataNode?.id}`;
  },
  BulkActionSummary: () => null,
  AccountMembershipEdge: (data) => {
    const dataNode = data.node;
    return `${dataNode?.id}`;
  },
  RiskEdge: (data) => {
    const dataNode = data.node;
    return `${dataNode?.id}`;
  },
  ActionNotifyOwnerConfig: () => null,
  ReportViolationCounts: () => null,
  DelayedExecutionConfig: () => null,
  SchemaEntity: (data) => `${data.appType}-${data.entityType}`,
  SchemaEntityField: (data) =>
    `${data.appType}-${data.entityType}-${data.propertyName}-${data.propertyType}-${data.entityTypeOfReferencedField}`,
  IntegrationSchema: (data) => `${data.appType}`,
  SDFTimeSlice: () => null,
  SDFTotal: () => null,
};
