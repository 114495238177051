import {
  useMemo,
  useState,
  useCallback,
  FunctionComponent,
  MouseEventHandler,
} from "react";

import { Row } from "react-table";
import styled from "styled-components";
import { useQuery } from "urql";

import { ButtonPillPrimary, ButtonPillAlternate } from "../../../atoms/Button";
import { RadioGroup } from "../../../components/RadioGroup";
import { IModalBase, ModalWrapper } from "../../../components/modals/BaseModal";
import { IDetection } from "../../../definitions/types/detection";
import { useFalsePositive } from "../../../operations/classifiers/hooks/useFalsePositive";
import { GET_DETECTION_BY_HASH } from "../../../operations/detections/queries/getDetectionByHashQuery";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import theme from "../../../styling/theme";
import { TIncomingRelay } from "../../../urql/pagination/definitions/definitions";
import { useConnectionData } from "../../../utils/pagination/hooks/useConnectionData";
import { IDetectionWithContextRow } from "../columns";

export interface IFalsePositiveModal extends IModalBase {
  selectedRows: Row<IDetectionWithContextRow>[];
}

interface IFalsePositiveModalContent {
  onChange: (value: string) => void;
  exclusionData?: IDetectionWithContextRow | null;
}

const StyledFalsePositiveModal = styled.div`
  background-color: ${COLOR.BACKGROUND};
  color: ${COLOR.LABEL};
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
  padding: ${APPLICATION_SPACING(3)};
`;

const StyledOptionsTitle = styled.div`
  font-size: ${theme.fontSizes.md};
  padding-bottom: ${APPLICATION_SPACING(4)};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  padding: ${APPLICATION_SPACING(2)};
  padding-bottom: ${APPLICATION_SPACING(1)};
  justify-content: flex-end;

  button {
    margin-left: ${APPLICATION_SPACING(1)};
  }
`;

const DisabledButtonPillPrimary = styled(ButtonPillPrimary)`
  background: ${COLOR.LINE};
  cursor: default;
  pointer-events: none;
`;

const FalsePositiveModalSingle: FunctionComponent<
  IFalsePositiveModalContent
> = ({ exclusionData, onChange }) => (
  <>
    <StyledOptionsTitle>False positive options</StyledOptionsTitle>
    <RadioGroup
      name="fp"
      onChange={onChange}
      options={[
        {
          value: "pattern",
          label: `Ignore pattern ${exclusionData?.pattern} for all ${exclusionData?.classifier?.name} detections`,
        },
        {
          value: "pattern_entity",
          label: `Ignore pattern ${exclusionData?.pattern} for all ${
            exclusionData?.classifier?.name
          } detections in ${exclusionData?.location?.entityType + "s"} in ${
            exclusionData?.location?.appType
          }`,
        },
        {
          value: "detection_hash",
          label: "Ignore this particular datapoint",
        },
        {
          value: "one_off",
          label: "Do nothing - this is a once off",
        },
      ]}
    />
  </>
);

const FalsePositiveModalBulk: FunctionComponent<IFalsePositiveModalContent> = ({
  onChange,
}) => (
  <>
    <StyledOptionsTitle>False positive options</StyledOptionsTitle>
    <RadioGroup
      name="fp"
      onChange={onChange}
      options={[
        {
          value: "ignore_all_hashes",
          label: "Ignore all selected datapoints",
        },
        {
          value: "one_off",
          label: "Do nothing - this is a once off",
        },
      ]}
    />
  </>
);

export const FalsePositiveModal: FunctionComponent<IFalsePositiveModal> = ({
  showModal,
  toggleModal,
  selectedRows,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const onChange = useCallback(
    (value: string) => setSelectedOption(value),
    [setSelectedOption]
  );

  const [connectionResult] = useQuery<{
    findDetectionsForDatapoint: TIncomingRelay<IDetection, {}>;
  }>({
    query: GET_DETECTION_BY_HASH,
    pause: !showModal,
    variables: {
      hash: selectedRows[0]?.original?.detectionHash || "",
      canUseDetectionActions: false,
      canReadDeepLink: false,
      pause: selectedRows.length !== 1,
    },
  });

  const { loading, setExclusionsOrFalsePositives } = useFalsePositive();

  const { edges } = useConnectionData(
    connectionResult.data?.findDetectionsForDatapoint
  );

  const [exclusionData] = useMemo(
    () => edges?.map((edge) => ({ ...edge.node })) || [],
    [edges]
  );

  const onConfirm = async () => {
    await setExclusionsOrFalsePositives({
      selectedOption,
      selectedRows,
    });

    toggleModal();
  };

  return (
    <ModalWrapper showModal={showModal} toggleModal={toggleModal}>
      <StyledFalsePositiveModal>
        {selectedRows.length === 1 ? (
          <FalsePositiveModalSingle
            exclusionData={exclusionData}
            onChange={onChange}
          />
        ) : (
          <FalsePositiveModalBulk
            exclusionData={exclusionData}
            onChange={onChange}
          />
        )}
        <StyledButtonContainer>
          <ButtonPillAlternate onClick={toggleModal as MouseEventHandler}>
            CANCEL
          </ButtonPillAlternate>
          {selectedOption ? (
            <ButtonPillPrimary onClick={onConfirm} loading={loading}>
              CONFIRM
            </ButtonPillPrimary>
          ) : (
            <DisabledButtonPillPrimary>CONFIRM</DisabledButtonPillPrimary>
          )}
        </StyledButtonContainer>
      </StyledFalsePositiveModal>
    </ModalWrapper>
  );
};
