export const VIOLATION_ANALYTICS = {
  "VIOLATION: Expanded Detections": () => ({}),
  "VIOLATION: View More": () => ({}),
  "VIOLATION: Cancelled Pending Action": () => ({}),
  "VIOLATION: Resolved": ({
    how,
  }: {
    how: "Notify" | "Redact" | "Mark As Resolved" | "Mark As False Positive";
  }) => ({
    violation_resolution: how,
  }),
};
