import { atom } from "recoil";

import { guardRecoilDefaultValue } from "../utils";

export type TOAST_TYPES_UNION = typeof TOAST_TYPES[number];

type IToastTemplates = {
  [key in TOAST_TYPES_UNION]?: React.ElementType;
};

export interface IToast {
  type: TOAST_TYPES_UNION;
  message: string;
}

interface IToastWithId extends IToast {
  id: string;
}

export const TOAST_TEMPLATES: IToastTemplates = {};

const TOAST_TYPES = ["warning", "success", "info", "error"] as const;

const getDefaultFilterState = () => [];

export default atom<IToastWithId[]>({
  key: "Toasts",
  default: getDefaultFilterState(),
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      let timeout: NodeJS.Timeout;

      onSet((newState, oldState) => {
        if (
          guardRecoilDefaultValue(newState) ||
          guardRecoilDefaultValue(oldState)
        )
          return;

        if (newState.length > oldState.length) {
          const newestToastId = newState[newState.length - 1].id;

          timeout = setTimeout(
            () =>
              setSelf((toasts) => {
                if (guardRecoilDefaultValue(toasts)) return toasts;

                return toasts.filter((toast) => toast.id !== newestToastId);
              }),
            3000
          );
        }
      });

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    },
  ],
});
