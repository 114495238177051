import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ManageUsersListQueryVariables = Types.Exact<{
  afterCursor?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ManageUsersListQuery = {
  __typename?: "RootQueryType";
  listAccountMemberships?:
    | {
        __typename?: "AccountMembershipConnection";
        edges?:
          | Array<
              | {
                  __typename?: "AccountMembershipEdge";
                  node?:
                    | {
                        __typename?: "AccountMembership";
                        id?: string | null | undefined;
                        status?:
                          | Types.AccountMembershipStatus
                          | null
                          | undefined;
                        role?: string | null | undefined;
                        user?:
                          | {
                              __typename?: "User";
                              id?: string | null | undefined;
                              nickname?: string | null | undefined;
                              primaryEmail?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | {
              __typename?: "PageInfo";
              endCursor?: string | null | undefined;
              hasNextPage?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ManageUsersListDocument = gql`
  query ManageUsersList($afterCursor: String) {
    listAccountMemberships(pagination: { limit: 30, cursor: $afterCursor }) {
      edges {
        node {
          id
          user {
            id
            nickname
            primaryEmail
          }
          status
          role
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export function useManageUsersListQuery(
  options: Omit<Urql.UseQueryArgs<ManageUsersListQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ManageUsersListQuery>({
    query: ManageUsersListDocument,
    ...options,
  });
}
