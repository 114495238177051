import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { IGIClassifierLegend } from "../columns";

const StyledButton = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  &:hover {
    background: ${COLOR.GRAYSCALE_BACKGROUND};
  }

  border-bottom-width: 1px;
  border-color: ${COLOR.LINE};
  padding: ${APPLICATION_SPACING(2)} ${APPLICATION_SPACING(3)};
`;

const StyledChildrenContainer = styled.div`
  border-bottom-width: 1px;
  border-color: ${COLOR.LINE};
  padding: ${APPLICATION_SPACING(2)} ${APPLICATION_SPACING(3)};
`;

export const LegendRowWrapper: FunctionComponent<IGIClassifierLegend> = ({
  name,
  color,
  id,
  count,
  children,
  onLegendItemClick,
}) =>
  name !== "Other" && onLegendItemClick ? (
    <StyledButton
      key={`${color}-${name}`}
      onClick={() => {
        onLegendItemClick({ name, color, id, count });
      }}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledChildrenContainer>{children}</StyledChildrenContainer>
  );
