import { gql } from "urql";

import {
  DefinitionType,
  RunExtendedMutationPayload,
} from "../../hooks/useExtendedMutation";
import { SuspendUserMutationVariables } from "./suspendUser.generated";

type PayloadType = RunExtendedMutationPayload<SuspendUserMutationVariables>;
type TaskDefs = SuspendUserDefinition["tasks"];

export interface SuspendUserDefinition extends DefinitionType {
  tasks: {
    suspendUser: ({
      userId,
      nickname,
    }: {
      userId: string;
      nickname: string;
    }) => PayloadType;
  };
}

const suspendUser: TaskDefs["suspendUser"] = ({ userId, nickname }) => ({
  update: {
    id: userId,
  },
  successToast: {
    type: "success",
    message: `Successfully removed access for user ${nickname}`,
  },
  analytics: ["USERS: Suspend", () => ({})],
});

export const SUSPEND_USER: SuspendUserDefinition = {
  definition: gql`
    mutation SuspendUser($id: ID!) {
      suspendUser(id: $id) {
        id
        user {
          id
          nickname
          primaryEmail
        }
        role
      }
    }
  `,
  tasks: {
    suspendUser,
  },
};
