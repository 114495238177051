import React, { FunctionComponent, HTMLAttributes } from "react";

import { AlertTriangle, Check } from "react-feather";

import { Popover } from "../Popover";
import { LayoutContainer } from "../layout/LayoutContainer";
import { AnimatedFeatherLoader } from "../loading/AnimatedFeatherLoader";

interface IStatusIndicator extends HTMLAttributes<HTMLDivElement> {
  inProgress?: boolean;
  error?: boolean;
  queued?: boolean;
  completed?: string;
  isSuccess?: boolean;
  hideLabel?: boolean;
}

export const StatusIndicator: FunctionComponent<IStatusIndicator> = ({
  className,
  inProgress,
  error,
  queued,
  completed,
  isSuccess,
  hideLabel,
}) => {
  const iconMargin = hideLabel ? 0 : 1;
  return (
    <LayoutContainer className={className} fontSize="xsm">
      {error && (
        <LayoutContainer color="ERROR" display="flex" alignItems="center">
          <LayoutContainer mr={iconMargin}>
            <Popover content="Sorry, something went wrong with this action">
              <AlertTriangle size={16} />
            </Popover>
          </LayoutContainer>
          {!hideLabel && "errored"}
        </LayoutContainer>
      )}
      {inProgress && !error && (
        <LayoutContainer color="SECONDARY" display="flex" alignItems="center">
          <LayoutContainer mr={iconMargin}>
            <Popover content="In progress">
              <AnimatedFeatherLoader />
            </Popover>
          </LayoutContainer>
          {!hideLabel && "In Progress"}
        </LayoutContainer>
      )}
      {!inProgress && !error && completed && (
        <LayoutContainer color="LABEL" display="flex" alignItems="center">
          {!hideLabel && completed}
        </LayoutContainer>
      )}
      {!inProgress && !error && isSuccess && (
        <LayoutContainer color="SUCCESS" display="flex" alignItems="center">
          <LayoutContainer mr={iconMargin}>
            <Popover content="Action successful">
              <Check size={16} />
            </Popover>
          </LayoutContainer>
          {!hideLabel && "success"}
        </LayoutContainer>
      )}
    </LayoutContainer>
  );
};
