import mixpanel from "mixpanel-browser";

import { Me } from "../../../generated/graphql.generated";
import { getValueOrEmpty } from "../../../utils/string/getValueOrEmpty";

declare global {
  interface Window {
    hj: any;
  }
}

const MIXPANEL_ANALYTICS_TEST_ID = "5e3ff542960af8f5a2d9044738c67d59";
const MIXPANEL_ANALYTICS_PROD_ID = "d056d7fa21f719b016c2cdc9201c0c5a";

const getMixpanelId = (isProd = false) =>
  isProd ? MIXPANEL_ANALYTICS_PROD_ID : MIXPANEL_ANALYTICS_TEST_ID;

// EVENT:EVENTPROPERTIES

export const instantiateHotjar = (u: Me) => {
  window.hj("identify", u.id, {
    email: u.primaryEmail,
    metomicId: u.id,
    accountId: u.customerId,
  });
};

export const instantiateMixpanel = (u: Me, isProd: boolean) => {
  mixpanel.init(getMixpanelId(isProd));
  mixpanel.identify(getValueOrEmpty(u.id));
  mixpanel.people.set({
    email: u.primaryEmail,
    metomicId: u.id,
    accountId: u.customerId,
    username: u.nickname,
    accountName: u.accountName,
  });
};
