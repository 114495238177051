import React from "react";

import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { LoadingSpinner } from "../loading/LoadingSpinner";

interface IShowMoreButton {
  className?: string;
  onClick: () => void;
  loading: boolean;
  shouldShowLoader: boolean;
}

const StyledShowMoreButton = styled.button`
  border-width: 1px;
  border-color: ${COLOR.LINE};
  color: ${COLOR.BODY};
  padding: 0 ${APPLICATION_SPACING(8)};
  border-radius: 9999px;
  width: ${APPLICATION_SPACING(40)};
  max-width: 100%;
`;

export const ShowMoreButton = ({
  className,
  shouldShowLoader,
  loading,
  onClick,
}: IShowMoreButton) => (
  <StyledShowMoreButton
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
    className={className}
  >
    {shouldShowLoader && loading ? <LoadingSpinner /> : "Show me more"}
  </StyledShowMoreButton>
);
