import React, { Dispatch, SetStateAction } from "react";

import MultiSelect from "react-select";

import { customStyles } from "../../../../../atoms/form/MultiSelectFilter/theme";
import { InputNode } from "../../../../../definitions/types/integration";
import { LayoutContainer } from "../../../../layout/LayoutContainer";

export const IntegrationSelectBox = ({
  node,
  setDeploymentData,
  stepPayload,
}: {
  node: InputNode;
  setDeploymentData: Dispatch<SetStateAction<Record<string, any>>>;
  stepPayload: Record<string, any>;
}) => {
  return (
    <LayoutContainer mb={4}>
      <MultiSelect
        placeholder={node.config.placeholder}
        styles={customStyles}
        onChange={(selected) => {
          setDeploymentData((s: any) => ({
            ...s,
            [node.id]: selected?.value,
          }));
        }}
        options={node.config.values?.map(({ name, value }) => ({
          value,
          label: name,
        }))}
        value={
          stepPayload[node.id]
            ? {
                value: stepPayload[node.id],
                label:
                  node.config.values?.find(
                    ({ value }) => value === stepPayload[node.id]
                  )?.name || "",
              }
            : null
        }
        isSearchable={false}
      />
    </LayoutContainer>
  );
};
