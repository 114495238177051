import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import styled, { css } from "styled-components";

import { FullWidthTextButton } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { GenericTable } from "../../components/tables/GenericTable";
import { TableProvider } from "../../components/tables/GenericTable/TableProvider";
import { ITableTheme } from "../../components/tables/GenericTable/definitions";
import { TABLE_THEMES } from "../../components/tables/themes";
import { Risk, RiskGroup } from "../../generated/graphql.generated";
import { useGetRisksQuery } from "../../operations/risks/queries/getRisks.generated";
import { useListRiskGroupsQuery } from "../../operations/risks/queries/listRiskGroups.generated";
import { SelectedGroupRiskRowContext } from "../../page/Risks/RiskGroups";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { usePaginatedTableData } from "../../utils/table/usePaginatedTableData";
import { usePaginatedTableState } from "../../utils/table/useTableState";
import { columns } from "../RiskGroupsTable/columns";
import { RiskList } from "../RiskList";
import { columns as riskColumns } from "../RiskList/columns";

// I've absolutely hacked this together. I'm sorry.

const StyledNoResultsContainer = styled.div`
  min-height: calc(100vh - 23rem);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${APPLICATION_SPACING(4)};
`;

const StyledInnerNoResultsContainer = styled.div`
  max-width: 750px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${({ theme }) => theme.colors.LABEL};
`;

const RisksTableProvider: FunctionComponent<{
  riskGroupId: string;
  onRiskRowClick: (data: Risk) => void;
}> = ({ children, riskGroupId, onRiskRowClick }) => {
  const selectedRow = useContext(SelectedGroupRiskRowContext);
  const [riskListState, setRiskListState] = useState({
    afterCursor: "",
    showFullLoader: true,
    limit: 10,
  });

  const [result] = useGetRisksQuery({
    requestPolicy: "network-only",
    variables: {
      afterCursor: riskListState.afterCursor,
      limit: 10,
      riskGroupId,
    },
  });

  const data = result.data?.listRisks;
  const edges = data?.edges;
  const hasNextPage = Boolean(data?.pageInfo?.hasNextPage);
  const endCursor = data?.pageInfo?.endCursor || "";

  const showLoader = riskListState.showFullLoader && result.fetching;

  const rows = useMemo(
    () =>
      edges?.map((edge) => {
        const node = edge?.node;

        return {
          ...node,
        } as Risk;
      }) || [],
    [edges]
  );

  const onNextPage = useCallback(() => {
    setRiskListState((s) => ({
      ...s,
      afterCursor: endCursor,
      showFullLoader: false,
    }));
  }, [endCursor]);

  return (
    <TableProvider
      stopCheckboxPropagation
      selectable={true}
      data={rows}
      columns={riskColumns}
    >
      {children}
      <LayoutContainer borderTopWidth={"1px" as any} borderColor="TABLE_BORDER">
        <RiskList
          onRowClick={onRiskRowClick}
          selectedRowId={defaultTo(selectedRow, "")}
          loading={showLoader}
        />
        {hasNextPage && (
          <FullWidthTextButton
            loading={result.fetching && !riskListState.showFullLoader}
            onClick={onNextPage}
          >
            Show More
          </FullWidthTextButton>
        )}
      </LayoutContainer>
    </TableProvider>
  );
};

const tableTheme: ITableTheme = {
  table: css`
    height: 100%;
  `,
  row: css`
    border-width: 1px;
    border-color: ${COLOR.TABLE_BORDER};
    margin-bottom: ${APPLICATION_SPACING(2)};
    &:hover {
      background-color: #edeef5;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `,
  cell: css`
    border-width: 0px;
    font-size: ${theme.fontSizes.xsm};

    &:last-child {
      border-left-width: 1px;
      border-color: ${COLOR.TABLE_BORDER};
    }
  `,
  selectedRowStyles: css`
    background-color: #edeef5;
  `,
};

export const RecentRiskGroupList = ({
  onRiskRowClick,
}: {
  onRiskRowClick?: (data: Risk) => void;
}) => {
  const [riskListState] = usePaginatedTableState({
    cursor: "",
    shouldShowFullLoader: true,
    limit: 3,
  });

  const [result] = useListRiskGroupsQuery({
    requestPolicy: "network-only",
    variables: {
      afterCursor: riskListState.cursor,
      limit: riskListState.limit,
    },
  });

  const { rows } = usePaginatedTableData({
    data: result.data?.listRiskGroups,
    transformer: (data) =>
      data?.edges?.map((edge) => {
        const node = edge?.node;

        return {
          ...(node as RiskGroup),
        };
      }) || [],
  });

  // Mike why not use the expanded prop, this gives us the ability to do some 'select all' logic in the root row
  const RowWrapper = useCallback(({ id, isExpanded, children }) => {
    return isExpanded ? (
      <LayoutContainer>
        <RisksTableProvider riskGroupId={id} onRiskRowClick={() => {}}>
          {children}
        </RisksTableProvider>
      </LayoutContainer>
    ) : (
      <LayoutContainer>{children}</LayoutContainer>
    );
  }, []);

  return (
    <GenericTable
      noResults={
        <StyledNoResultsContainer>
          <StyledInnerNoResultsContainer>
            <StyledHeading Element="h1" type="display-small">
              You have no risk groups
            </StyledHeading>
          </StyledInnerNoResultsContainer>
        </StyledNoResultsContainer>
      }
      loadingSpinner={
        <StyledNoResultsContainer>
          <StyledInnerNoResultsContainer>
            <LoadingSpinner />
          </StyledInnerNoResultsContainer>
        </StyledNoResultsContainer>
      }
      loading={result.fetching && riskListState.shouldShowFullLoader}
      clickWholeRowToExpand
      data={rows}
      columns={columns}
      expanded={(row) => null}
      RowContainer={RowWrapper}
      theme={TABLE_THEMES.NO_HEADER_NO_GAP_TABLE}
      themeOverrides={tableTheme}
    />
  );
};
