import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { Heading } from "../../atoms/Heading";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import { OutlineColorPill } from "../OutlineColorPill";

export interface IBreadcrumbHeader {
  breadcrumb: string[];
  isAlpha?: boolean;
}

const BreadCrumbContainer = styled.div`
  padding: 0 ${APPLICATION_SPACING(3)};
  margin-bottom: auto;
  margin-top: auto;
`;

const AlphaPill = styled(OutlineColorPill)`
  margin-left: ${APPLICATION_SPACING(2)};
  margin-top: -${SPECIFIC_SPACING["4px"]};
  vertical-align: middle;
`;

const FadeOutSection = styled.span`
  opacity: 0.4;
`;

export const BreadcrumbHeader: FunctionComponent<IBreadcrumbHeader> = ({
  breadcrumb,
  isAlpha,
  children,
}) => (
  <BreadCrumbContainer>
    <Heading Element="h1" type="text-mdlg" isPrimary>
      {breadcrumb.map((crumb, i, arr) =>
        arr.length - 1 === i ? (
          crumb
        ) : (
          <FadeOutSection key={`${crumb}-${i}`}>{crumb} / </FadeOutSection>
        )
      )}
      {isAlpha && (
        <AlphaPill color={COLOR.SECONDARY} uppercase>
          Alpha
        </AlphaPill>
      )}{" "}
      {children}
    </Heading>
  </BreadCrumbContainer>
);
