import { cacheExchange } from "@urql/exchange-graphcache";
import { IntrospectionQuery } from "graphql";

import { GraphCacheConfig } from "../../generated/graphql.generated";
import schema from "../introspection.json";
import { keys } from "./keys";
import { optimistic } from "./optimistic";
import { resolvers } from "./resolvers";
import { updates } from "./updates";

type GraphCacheTypes = GraphCacheConfig & {
  updates?: any; //these are casted below in the cache definition to get around an early typegen bug
  optimistic?: any; //these are casted below in the cache definition to get around an early typegen bug
};

export const cache = cacheExchange<GraphCacheTypes>({
  schema: schema as unknown as IntrospectionQuery,
  resolvers,
  keys,
  updates,
  optimistic,
});
