import breakpoints from "./breakpoints";
import { COLOR } from "./colors";
import fontSizes from "./fonts";
import { APPLICATION_SPACING } from "./spacing";

const generateThemeSpacing = (length: number) =>
  Array.apply(null, Array(length)).map((undef, i) => APPLICATION_SPACING(i));

export const getGrid = (columns: number) =>
  `repeat(${columns}, minmax(0, 1fr))`;

export const span = (columns: number) => `span ${columns} / span ${columns}`;

const theme = {
  space: generateThemeSpacing(20),
  colors: COLOR,
  breakpoints: breakpoints,
  fontSizes: {
    xxsm: fontSizes[0],
    xsm: fontSizes[2],
    sm: fontSizes[3],
    md: fontSizes[4],
    mdlg: fontSizes[5],
    lg: fontSizes[6],
    displaymd: fontSizes[7],
    displaylg: fontSizes[8],
    displayxlg: fontSizes[9],
    "info-overline": fontSizes[1],
    "info-caption": fontSizes[1],
  },
  borderWidths: [0, "1px", "2px", "3px", "4px"],
};

export default theme;
