import { ExtendedViolationState } from "../../../../recoil/reports/atom";

export const REPORT_ANALYTICS = {
  "REPORT: Downloaded": ({
    violationState,
    rules,
    dates,
  }: {
    violationState: ExtendedViolationState;
    rules: string;
    dates: `${string}-${string}`;
  }) => ({
    downloaded_report_violation_status: violationState,
    downloaded_report_rule_ids: rules,
    downloaded_report_dates: dates,
  }),
};
