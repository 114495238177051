import { CombinedError } from "urql";

import { useGetMeQuery } from "../../../components/Navigation/queries.generated";
import { IFeatureFlag } from "../constants";

export type IFeatureFlagHookResult = {
  loading: boolean;
  error: CombinedError | undefined;
  enabled: boolean;
};

export const useFeatureFlag = <T extends IFeatureFlag>(
  name: T
): IFeatureFlagHookResult => {
  const [result] = useGetMeQuery();

  const { data, fetching, error } = result;

  const featureFlags = data?.me?.featureFlags;

  const enabled = featureFlags?.[name];

  return { loading: fetching, error, enabled: Boolean(enabled) };
};
