import { gql } from "urql";

import { IIntegration } from "../../integrations/queries/listIntegrations";

export interface IInstallation {
  id: string;
  status: string;
  integration: IIntegration;
}

export interface IListInstallations {
  listInstallations: IInstallation[];
}

export const LIST_INSTALLATIONS_QUERY = gql`
  query ListInstallations {
    listInstallations {
      id
      status
      integration {
        id
        name
      }
    }
  }
`;
