import { gql } from "urql";

export const RESOLVE_VIOLATION_MUTATION = gql`
  mutation ResolveViolation(
    $id: ID
    $action: DetectionActionInput
    $permissionGiven: Boolean
    $resolveAllViolationsInEntity: Boolean
  ) {
    demoResolveViolation(
      id: $id
      action: $action
      permissionGiven: $permissionGiven
      resolveAllViolationsInEntity: $resolveAllViolationsInEntity
    ) {
      violation {
        id
        detection {
          id
          actionState
        }
        status
      }
      idsToInvalidate
    }
  }
`;
