import React, {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useMemo,
} from "react";

import * as d3 from "d3";
import Gradient from "javascript-color-gradient";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

import { kFormatter } from "../../utils/number/kformatter";
import { LayoutContainer } from "../layout/LayoutContainer";

export const SAMPLE_POINTS = 300;

const colorGradient = new Gradient();

const color1 = "#567591";
const color2 = "#9D9A71";
const color3 = "#E5B95A";
const color4 = "#E4842A";
const color5 = "#FF0000";

colorGradient.setGradient(
  color1,
  color2,
  color3,
  color3,
  color4,
  color4,
  color5
);
colorGradient.setMidpoint(SAMPLE_POINTS);

export const SDFGradient = colorGradient;

interface ISDFCounter extends HTMLAttributes<HTMLSpanElement> {
  number: number;
  total: number;
  displayCount: number;
}

const StyledSVG = styled.svg`
  position: absolute;
  left: 23%;
  top: 33%;
  width: 54%;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.12));
`;

const StyledClippedGradient = styled.div`
  width: 100%;
  height: 100%;
  /* background: red; */
  background: conic-gradient(
    from 180deg,
    ${color1},
    ${color1},
    ${color1},
    ${color1},
    ${color2},
    ${color3},
    ${color3},
    ${color3},
    ${color3},
    ${color4},
    ${color4},
    ${color5},
    ${color5},
    ${color5}
  );
`;

const data = d3.range(SAMPLE_POINTS);

const radius = 90;
const radians = 2 * Math.PI;
const fullGraphRadius = radians * 0.62;

const getAngle = d3
  .scaleLinear()
  .domain([0, SAMPLE_POINTS - 1])
  .range([0, fullGraphRadius]);

const getLine = d3
  .lineRadial<number>()
  .curve(d3.curveBasis)
  .radius(radius)
  .angle((d, i) => {
    return getAngle(d);
  });

const axisPath = getLine(data);

const StyledText = styled(animated.text)`
  font-weight: 100;
`;

export const SDFCounter: FunctionComponent<ISDFCounter> = ({
  displayCount,
  number,
  total,
}) => {
  const [animatedProps, animate] = useSpring(() => ({
    val: displayCount,
    from: { val: 0, totalPointsIndex: 0 },
    config: { duration: 1500, delay: 0, easing: d3.easeSinOut },
  }));

  const { textColor, percentageOfSamplePoints } = useMemo(() => {
    const percentage = number / total;
    const percentageOfSamplePoints = Math.round(SAMPLE_POINTS * percentage);

    const textColor = colorGradient.getColor(
      Math.max(1, Math.min(percentageOfSamplePoints, SAMPLE_POINTS))
    );

    return {
      textColor,
      percentageOfSamplePoints,
    };
  }, [number, total]);

  useEffect(() => {
    animate.update({
      val: displayCount,
      totalPointsIndex: percentageOfSamplePoints + 1,
    });
    animate.start();
  }, [number, animate, percentageOfSamplePoints, displayCount]);

  return (
    <LayoutContainer position="relative">
      <div>
        <svg viewBox=" 0 0 220 180">
          <defs>
            <mask id="sdf-counter-mask">
              <animated.path
                className="line"
                fill="none"
                strokeDasharray="1.2 1.5"
                strokeWidth="20px"
                stroke="#FFFFFF"
                transform="translate(110,120) rotate(-110)"
                d={
                  animatedProps.totalPointsIndex.to(
                    (index) => getLine(data.filter((d, i) => i < index)) || ""
                  ) || ""
                }
              />
            </mask>
          </defs>
          <g>
            <path
              fill="none"
              strokeDasharray="1.1 1.6"
              strokeWidth="6px"
              stroke="#E6E8EF"
              transform="translate(110,120) rotate(-110)"
              opacity="1"
              d={axisPath || ""}
            />
          </g>
          <g mask="url(#sdf-counter-mask)">
            <foreignObject width="220" height="180">
              <StyledClippedGradient />
            </foreignObject>
          </g>
        </svg>
      </div>
      <StyledSVG
        viewBox="0 0 105 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.264509 46.7864C-0.652698 55.3503 0.862282 65.0807 4.08251 73.0666H100.304C103.407 65.3715 105.043 55.9711 104.303 47.6961C103.535 39.1174 100.667 30.861 95.9538 23.658C91.2405 16.455 84.827 10.5278 77.2813 6.40154C69.7357 2.27524 61.2909 0.0771544 52.695 0.00199454C44.099 -0.0731653 35.6172 1.97692 28.0009 5.97065C20.3845 9.96438 13.8688 15.7785 9.03073 22.898C4.19269 30.0174 1.18172 38.2225 0.264509 46.7864Z"
          fill="#F5F6FA"
        />
        <StyledText
          fill={textColor}
          y="65%"
          x="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={30}
        >
          {animatedProps.val &&
            animatedProps.val.to((val) => kFormatter(Number(Math.floor(val))))}
        </StyledText>
      </StyledSVG>
    </LayoutContainer>
  );
};
