import React, { useMemo } from "react";

import { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import * as d3 from "d3";
import { useTransition, to, animated } from "react-spring";

type AnimatedStyles = {
  startAngle: number;
  endAngle: number;
  opacity: number;
  innerRadius: number;
  outerRadius: number;
};

type ExtendedHoverArcs<Datum> = PieArcDatum<Datum> & {
  innerRadius: number;
  outerRadius: number;
  opacity: number;
};

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
  onMouseEnter: (d: PieArcDatum<Datum>) => void;
  onMouseExit: (d: PieArcDatum<Datum>) => void;
  onSegmentClick?: (d: Datum) => void;
  hoveredSegment: string;
  innerRadius: number;
  outerRadius: number;
};

type IPieTransitionPrimitive<Datum> = Partial<ProvidedProps<Datum>> &
  Pick<
    AnimatedPieProps<Datum>,
    "getKey" | "getColor" | "onMouseEnter" | "onMouseExit" | "onSegmentClick"
  > & {
    arcs: ExtendedHoverArcs<Datum>[];
  };

const fromLeaveTransition = ({
  endAngle,
  innerRadius,
  outerRadius,
}: ExtendedHoverArcs<any>) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
  innerRadius: innerRadius,
  outerRadius: outerRadius,
});

const enterUpdateTransition = ({
  startAngle,
  endAngle,
  innerRadius,
  outerRadius,
  opacity,
}: ExtendedHoverArcs<any>) => ({
  startAngle,
  endAngle,
  innerRadius,
  outerRadius,
  opacity,
});

export function AnimatedPie<Datum>({
  arcs,
  path,
  getKey,
  getColor,
  onMouseEnter,
  onMouseExit,
  onSegmentClick,
  hoveredSegment,
  pie,
  innerRadius,
  outerRadius,
}: AnimatedPieProps<Datum>) {
  const extendedArcs = useMemo(() => {
    const radiusFactor = 1.1;
    const innerRadiusExpanded = innerRadius * radiusFactor;
    const outerRadiusExpanded = outerRadius * radiusFactor;

    return arcs.map((arc) => {
      const isHovered = hoveredSegment && hoveredSegment === getKey(arc);
      return {
        ...arc,
        innerRadius: isHovered ? innerRadiusExpanded : innerRadius,
        outerRadius: isHovered ? outerRadiusExpanded : outerRadius,
        opacity: hoveredSegment && !isHovered ? 0.3 : 1,
      };
    });
  }, [arcs, hoveredSegment, getKey, innerRadius, outerRadius]);

  return (
    <PieTransitionPrimitive
      getKey={getKey}
      getColor={getColor}
      arcs={extendedArcs}
      path={path}
      onMouseEnter={onMouseEnter}
      onMouseExit={onMouseExit}
      onSegmentClick={onSegmentClick}
    />
  );
}

function PieTransitionPrimitive<Datum>({
  arcs,
  getKey,
  getColor,
  onMouseEnter,
  onMouseExit,
  onSegmentClick,
  path,
}: IPieTransitionPrimitive<Datum>) {
  const transitions = useTransition<ExtendedHoverArcs<Datum>, AnimatedStyles>(
    arcs,
    {
      from: fromLeaveTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: fromLeaveTransition,
      keys: getKey,
      config: {
        easing: d3.easeSinOut,
      },
    }
  );

  return transitions((props, arc, { key }) => {
    return (
      <g key={key}>
        <animated.path
          onClick={() => {
            if (onSegmentClick) {
              onSegmentClick(arc.data);
            }
          }}
          onMouseEnter={() => {
            onMouseEnter(arc);
          }}
          onMouseLeave={() => {
            onMouseExit(arc);
          }}
          fillOpacity={to([props.opacity], (opacity) => opacity)}
          d={to(
            [
              props.startAngle,
              props.endAngle,
              props.innerRadius,
              props.outerRadius,
            ],
            (
              startAngle,
              endAngle,
              interpolatedInnerRadius,
              interpolatedOuterRadius
            ) => {
              const renderPath = path
                ?.innerRadius(interpolatedInnerRadius)
                .outerRadius(interpolatedOuterRadius);

              return renderPath
                ? renderPath({
                    ...arc,
                    startAngle,
                    endAngle,
                  })
                : "";
            }
          )}
          fill={getColor(arc)}
        />
      </g>
    );
  });
}
