import React, { PropsWithChildren } from "react";

import ReactDOM from "react-dom";
import { useTransition, animated } from "react-spring";
import styled, { css } from "styled-components";

export interface IModalBase {
  fullHeight?: boolean;
  showModal: boolean;
  toggleModal: (data?: any) => void;
}

const StyledModalBackgroundOverlay = styled(animated.div)<{
  $showModal?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.$showModal &&
    css`
      pointer-events: none;
    `}
`;

export const ModalWrapper = ({
  children,
  showModal,
  toggleModal,
  fullHeight,
}: PropsWithChildren<IModalBase>) => {
  const modalTransition = useTransition(showModal, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    expires: true,
  });

  const innerTransition = useTransition(showModal, {
    from: { opacity: 0, transform: "translate(-50%, calc(-50% + 20px))" },
    enter: { opacity: 1, transform: "translate(-50%, calc(-50% + 0px))" },
    leave: { opacity: 0, transform: "translate(-50%, calc(-50% + 20px))" },
  });

  return modalTransition(
    (style, item) =>
      item &&
      ReactDOM.createPortal(
        <>
          <StyledModalBackgroundOverlay
            onClick={() => toggleModal()}
            $showModal={showModal}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              top: 0,
              left: 0,
              zIndex: 50,
              ...style,
            }}
          />
          {innerTransition(
            (style, item) =>
              item && (
                <animated.div
                  style={{
                    minWidth: 450,
                    height: fullHeight ? "80%" : "auto",
                    position: "fixed",
                    zIndex: 60,
                    top: "50%",
                    left: "50%",
                    display: "flex",
                    justifyContent: "center",
                    ...style,
                  }}
                >
                  {children}
                </animated.div>
              )
          )}
        </>,
        document.getElementById("modal-root") as any
      )
  );
};
