import React, { useCallback, useMemo } from "react";

import { css } from "styled-components";

import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { GenericTable } from "../../components/tables/GenericTable";
import { ITableTheme } from "../../components/tables/GenericTable/definitions";
import { TABLE_THEMES } from "../../components/tables/themes";
import { Risk } from "../../generated/graphql.generated";
import { useGetRisksQuery } from "../../operations/risks/queries/getRisks.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { RiskDetailPanel } from "../RiskDetailPanel";
import { columns } from "./columns";

const theme: ITableTheme = {
  table: css`
    height: 100%;
  `,
  row: css`
    border-width: 1px;
    border-color: ${COLOR.TABLE_BORDER};
    margin-bottom: ${APPLICATION_SPACING(2)};
    &:hover {
      background-color: #edeef5;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `,
  cell: css`
    border-width: 0px;

    &:last-child {
      font-size: 60px;
      padding-right: 0;
      border-right: 0;
      border-left-width: 1px;
      border-color: ${COLOR.TABLE_BORDER};
    }
  `,
  selectedRowStyles: css`
    background-color: #edeef5;
  `,
};

interface IRecentRiskList {
  onRowClick?: (risk: Risk) => void;
  selectedRowId?: string;
  onTableScrolled?: () => void;
}

export const RecentRiskList = ({
  onRowClick,
  selectedRowId,
  onTableScrolled,
}: IRecentRiskList) => {
  const [result] = useGetRisksQuery({
    requestPolicy: "network-only",
    variables: {
      afterCursor: "",
      limit: 3,
    },
  });

  const clickTableRow = useCallback(
    (risk) => {
      return onRowClick && onRowClick(risk);
    },
    [onRowClick]
  );

  const data = result.data?.listRisks;
  const edges = data?.edges;

  const rows = useMemo(
    () =>
      edges?.map((edge) => {
        const node = edge?.node;

        return {
          ...node,
        } as Risk;
      }) || [],
    [edges]
  );

  return (
    <GenericTable
      data={rows}
      columns={columns}
      onRowClick={clickTableRow}
      selectedRowId={selectedRowId}
      theme={TABLE_THEMES.NO_HEADER_NO_GAP_TABLE}
      loading={result.fetching}
      themeOverrides={theme}
      onTableScrolled={onTableScrolled}
      clickWholeRowToExpand
      expanded={(row) => (
        <LayoutContainer
          borderColor="TABLE_BORDER"
          borderTopWidth={"1px" as any}
          display="flex"
          justifyContent="center"
          backgroundColor="INSET_SPLIT_BACKGROUND"
        >
          <LayoutContainer width="80%" maxWidth="900px" position="relative">
            <RiskDetailPanel
              clearSelectedRow={() => {}}
              selectedRisk={row.original}
              hideTopBar
            />
          </LayoutContainer>
        </LayoutContainer>
      )}
    />
  );
};
