import React, { Dispatch, SetStateAction } from "react";

import MultiSelect from "react-select";

import { customStyles } from "../../../../../atoms/form/MultiSelectFilter/theme";
import { InputNode } from "../../../../../definitions/types/integration";
import { LayoutContainer } from "../../../../layout/LayoutContainer";

export const IntegrationMultiSelectBox = ({
  node,
  setDeploymentData,
  stepPayload,
}: {
  node: InputNode;
  setDeploymentData: Dispatch<SetStateAction<Record<string, any>>>;
  stepPayload: Record<string, any>;
}) => {
  return (
    <LayoutContainer mb={4}>
      <MultiSelect
        placeholder={node.config.placeholder}
        styles={customStyles}
        onChange={(selected) => {
          setDeploymentData((s: any) => ({
            ...s,
            [node.id]: Array.from(selected?.values()).map((kv) => kv.value),
          }));
        }}
        options={node.config.values?.map(({ name, value }) => ({
          value,
          label: name,
        }))}
        isMulti
        isSearchable
        value={
          stepPayload[node.id]
            ? stepPayload[node.id].map((v: any) => ({
                value: v,
                label: node.config.values?.find(
                  ({ value }) => value === v || ""
                )?.name,
              }))
            : null
        }
      />
    </LayoutContainer>
  );
};
