import React, { FunctionComponent, PropsWithChildren } from "react";

import styled, { FlattenSimpleInterpolation } from "styled-components";

import { Maybe } from "../../../generated/graphql.generated";
import { ITableTheme } from "./definitions";

const StyledRowWrapper = styled.div<{
  theme?: FlattenSimpleInterpolation;
  themeOverrides?: FlattenSimpleInterpolation;
  columnOverrides?: FlattenSimpleInterpolation;
  selectedRowStyles?: FlattenSimpleInterpolation;
}>`
  ${({ theme }) => theme};
  ${({ themeOverrides }) => themeOverrides};
  ${({ columnOverrides }) => columnOverrides};
`;

export const RowWrapper: FunctionComponent<{
  theme: ITableTheme;
  themeOverrides?: ITableTheme;
  id?: Maybe<string>;
}> = ({ children, theme, themeOverrides, id }) => (
  <StyledRowWrapper
    theme={theme.row}
    themeOverrides={themeOverrides?.row}
    selectedRowStyles={themeOverrides?.selectedRowStyles}
    data-performant-styling-id={id}
  >
    {children}
  </StyledRowWrapper>
);

export function DefaultRowWrapper({ children }: PropsWithChildren<any>) {
  return <>{children}</>;
}
