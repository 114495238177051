import React from "react";

import { COLOR } from "../../styling/colors";

export function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86983 0.135181C8.04339 0.315423 8.04339 0.607653 7.86983 0.787895L2.98094 5.86482C2.80737 6.04506 2.52596 6.04506 2.3524 5.86482L0.130175 3.55713C-0.0433916 3.37688 -0.0433916 3.08465 0.130175 2.90441C0.303741 2.72417 0.585148 2.72417 0.758714 2.90441L2.66667 4.88575L7.24129 0.135181C7.41485 -0.0450605 7.69626 -0.0450605 7.86983 0.135181Z"
        fill={props.color || COLOR.SUCCESS}
      />
    </svg>
  );
}
