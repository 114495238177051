export enum VENDORS {
  SLACK = "slack",
  MICROSOFT = "microsoft_teams",
  TRELLO = "trello",
  GDRIVE = "gdrive",
  ZENDESK = "zendesk",
  JIRA = "jira",
  ONEDRIVE = "onedrive",
}

/** See above re. enum. This probably can't be typed predictably */
export type VENDOR_ARRAY = keyof Record<VENDORS, any>;
