import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SuspendUserMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type SuspendUserMutation = {
  __typename?: "RootMutationType";
  suspendUser?:
    | {
        __typename?: "AccountMembership";
        id?: string | null | undefined;
        role?: string | null | undefined;
        user?:
          | {
              __typename?: "User";
              id?: string | null | undefined;
              nickname?: string | null | undefined;
              primaryEmail?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const SuspendUserDocument = gql`
  mutation SuspendUser($id: ID!) {
    suspendUser(id: $id) {
      id
      user {
        id
        nickname
        primaryEmail
      }
      role
    }
  }
`;

export function useSuspendUserMutation() {
  return Urql.useMutation<SuspendUserMutation, SuspendUserMutationVariables>(
    SuspendUserDocument
  );
}
