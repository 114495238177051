import React, { HTMLAttributes } from "react";

import { X } from "react-feather";
import styled from "styled-components";

import { ButtonSmall } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { IModalBase, ModalWrapper } from "./BaseModal";
import { StyledWideModalBox } from "./shared";

export interface IViolationsHistoryModal {
  violationId?: string;
}

export interface IViolationsHistoryModalComponent
  extends IModalBase,
    HTMLAttributes<HTMLDivElement> {
  data?: IViolationsHistoryModal;
}

const StyledButtonSmall = styled(ButtonSmall)`
  font-size: ${theme.fontSizes.xsm};
  position: absolute;
  top: ${APPLICATION_SPACING(3)};
  right: ${APPLICATION_SPACING(3)};
  color: ${COLOR.PLACEHOLDER};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
`;

const ViolationsHistoryModal = ({
  showModal,
  toggleModal,
  data,
  children,
}: IViolationsHistoryModalComponent) => {
  return (
    <ModalWrapper showModal={showModal} toggleModal={toggleModal}>
      <StyledWideModalBox>
        <StyledButtonSmall
          onClick={() => {
            toggleModal();
          }}
        >
          <X size={22} />
        </StyledButtonSmall>
        <StyledHeading type="text-md" Element="h1" isBodyColor>
          Violation Action History
        </StyledHeading>
        {children}
      </StyledWideModalBox>
    </ModalWrapper>
  );
};

export default ViolationsHistoryModal;
