import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAtomBreadcrumbDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type GetAtomBreadcrumbDetailsQuery = {
  __typename?: "RootQueryType";
  getLabelledAtomById?:
    | {
        __typename?: "LabelledAtom";
        iconType?: string | null | undefined;
        location?:
          | {
              __typename?: "AtomLocation";
              parentId?: string | null | undefined;
              label?: string | null | undefined;
              deeplink?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetAtomBreadcrumbDetailsDocument = gql`
  query GetAtomBreadcrumbDetails($id: ID!) {
    getLabelledAtomById(id: $id) {
      iconType
      location {
        parentId
        label
        deeplink
      }
    }
  }
`;

export function useGetAtomBreadcrumbDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetAtomBreadcrumbDetailsQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetAtomBreadcrumbDetailsQuery>({
    query: GetAtomBreadcrumbDetailsDocument,
    ...options,
  });
}
