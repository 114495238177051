import React, { FunctionComponent } from "react";

import * as HoverCard from "@radix-ui/react-hover-card";
import styled, { keyframes } from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

interface IPopover {
  content: React.ReactNode | string;
  disabled?: boolean;
}

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
`;

const StyledContent = styled(HoverCard.Content)`
  @media (prefers-reduced-motion: no-preference) {
    will-change: transform, opacity;
    animation-duration: 250ms;
    animation-timing-function: ease-out;

    &[data-state="open"] {
      &[data-side="top"] {
        animation-name: ${slideDownAndFade};
      }
    }
  }
`;

const DefaultTooltipContent = styled(StyledContent)`
  max-width: 175px;
  padding: ${APPLICATION_SPACING(1)};
  color: ${COLOR.OFF_WHITE};
  background: ${COLOR.BODY};
  text-align: center;
  font-size: ${theme.fontSizes.sm};
  border-radius: 0.125rem;
`;

const StyledTrigger = styled(HoverCard.Trigger)`
  display: flex;
  overflow: hidden;
`;

export const Popover: FunctionComponent<IPopover> = ({
  content,
  disabled,
  children,
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <HoverCard.Root openDelay={0} closeDelay={0}>
      {typeof content === "string" ? (
        <HoverCard.Trigger asChild>
          <span>{children}</span>
        </HoverCard.Trigger>
      ) : (
        <StyledTrigger asChild>
          <span>{children}</span>
        </StyledTrigger>
      )}
      {typeof content === "string" ? (
        <DefaultTooltipContent side="top" alignOffset={20}>
          {content}
        </DefaultTooltipContent>
      ) : (
        <StyledContent side="top" alignOffset={20}>
          {content}
        </StyledContent>
      )}
    </HoverCard.Root>
  );
