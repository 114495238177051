import { Column } from "react-table";
import styled from "styled-components";

import { DictionaryColorLabel } from "../../components/ColorLabel";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IDetectionWithContextRow } from "../DetectionSearch/columns";

const StyledDictionaryColorLabel = styled(DictionaryColorLabel)`
  margin-left: auto;
`;

export const atomDetectionCardColumns: Column<IDetectionWithContextRow>[] = [
  {
    Header: () => null,
    accessor: "obscuredDetection",
    Cell: (data) => (
      <LayoutContainer as="span" fontSize="xsm">
        {data.cell.value}
      </LayoutContainer>
    ),
  },
  {
    Header: () => null,
    accessor: "classifier",

    Cell: (data) => (
      <StyledDictionaryColorLabel type="CLASSIFIER" id={data.cell.value.id}>
        {data.cell.value.name}
      </StyledDictionaryColorLabel>
    ),
  },
];
