import { gql } from "urql";

export const MANAGE_USERS_LIST_QUERY = gql`
  query ManageUsersList($afterCursor: String) {
    listAccountMemberships(pagination: { limit: 30, cursor: $afterCursor }) {
      edges {
        node {
          id
          user {
            id
            nickname
            primaryEmail
          }
          status
          role
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
