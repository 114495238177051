import { MinusCircle, PlusCircle } from "react-feather";
import { Column } from "react-table";
import { css } from "styled-components";

import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { Risk } from "../../generated/graphql.generated";
import { columns as riskColumns } from "../RiskList/columns";

export const columns: Column<Risk>[] = [
  {
    Header: () => null,
    id: "expander",
    columnStyles: css`
      padding-left: 1.75rem;
    `,
    width: 80,
    minWidth: 80,
    maxWidth: 80,
    disableResizing: true,
    Cell: ({ row }: { row: any }) => (
      <LayoutContainer as="span" color="LABEL">
        {row.isExpanded ? <MinusCircle /> : <PlusCircle />}
      </LayoutContainer>
    ),
  },
  ...riskColumns,
];
