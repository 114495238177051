import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";
import { FullRiskFieldsFragmentDoc } from "../fragments/fullRiskFields.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ListRiskActionsByBulkActionIdQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars["String"]>;
  limit: Types.Scalars["Int"];
  bulkActionId: Types.Scalars["ID"];
}>;

export type ListRiskActionsByBulkActionIdQuery = {
  __typename?: "RootQueryType";
  listRiskActionsByBulkActionId?:
    | {
        __typename?: "RiskActionConnection";
        edges?:
          | Array<
              | {
                  __typename?: "RiskActionEdge";
                  node?:
                    | {
                        __typename?: "RiskAction";
                        id?: string | null | undefined;
                        command?:
                          | Types.RiskActionCommandEnum
                          | null
                          | undefined;
                        status?: string | null | undefined;
                        risk?:
                          | {
                              __typename?: "Risk";
                              id?: string | null | undefined;
                              atomId?: string | null | undefined;
                              sdf?: number | null | undefined;
                              sdfLevel?:
                                | Types.RiskSdfLevelEnum
                                | null
                                | undefined;
                              createdAt?: any | null | undefined;
                              sharedAt?: any | null | undefined;
                              shortCode?: string | null | undefined;
                              isDirty?: boolean | null | undefined;
                              atom?:
                                | {
                                    __typename?: "LabelledAtom";
                                    appType?: string | null | undefined;
                                    entityType?: string | null | undefined;
                                    entityId?: string | null | undefined;
                                    location?:
                                      | {
                                          __typename?: "AtomLocation";
                                          label?: string | null | undefined;
                                          deeplink?: string | null | undefined;
                                          entityType?:
                                            | string
                                            | null
                                            | undefined;
                                          appType?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | {
              __typename?: "PageInfo";
              endCursor?: string | null | undefined;
              hasNextPage?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ListRiskActionsByBulkActionIdDocument = gql`
  query ListRiskActionsByBulkActionId(
    $cursor: String
    $limit: Int!
    $bulkActionId: ID!
  ) {
    listRiskActionsByBulkActionId(
      bulkActionId: $bulkActionId
      pagination: { limit: $limit, cursor: $cursor }
    ) {
      edges {
        node {
          id
          command
          status
          risk {
            ...FullRiskFields
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${FullRiskFieldsFragmentDoc}
`;

export function useListRiskActionsByBulkActionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<ListRiskActionsByBulkActionIdQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<ListRiskActionsByBulkActionIdQuery>({
    query: ListRiskActionsByBulkActionIdDocument,
    ...options,
  });
}
