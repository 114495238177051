import { useCallback } from "react";

import { HARD_CODED_DATA_TYPE_COLORS } from "../../definitions/types/data-types";

interface IColorDictionary {
  [key: string]: string;
}

const getDictionaryFromStorage = () => {
  try {
    return localStorage.getItem("metomic-color-dictionary");
  } catch (error) {
    return undefined;
  }
};

export type DictionaryType = "CLASSIFIER" | "AUTHORGROUP";

const dictionaryStorage = getDictionaryFromStorage();

let availableColors = HARD_CODED_DATA_TYPE_COLORS;

let COLOR_DICTIONARY: IColorDictionary = dictionaryStorage
  ? JSON.parse(dictionaryStorage)
  : {};

const usePickAvailableColor = () => {
  const pickAvailableColor = useCallback((type: DictionaryType) => {
    const colorsOfType = Object.keys(COLOR_DICTIONARY).filter(
      (key) => key.split(":")[0] === type
    ).length;

    if (colorsOfType >= availableColors.length) {
      return availableColors[
        colorsOfType -
          Math.floor(colorsOfType / availableColors.length) *
            availableColors.length
      ];
    } else {
      return availableColors[
        Math.max(0, Math.min(colorsOfType, availableColors.length - 1))
      ];
    }
  }, []);

  return {
    pickAvailableColor,
  };
};

export const useColorDictionary = () => {
  const { pickAvailableColor } = usePickAvailableColor();

  const getColorById = useCallback(
    (key: string, type: DictionaryType) => {
      try {
        const id = `${type}:${key}`;

        if (COLOR_DICTIONARY[id]) return COLOR_DICTIONARY[id];

        const colorForId = pickAvailableColor(type);

        COLOR_DICTIONARY = {
          ...COLOR_DICTIONARY,
          [id]: colorForId,
        };
        localStorage.setItem(
          "metomic-color-dictionary",
          JSON.stringify(COLOR_DICTIONARY)
        );

        return colorForId;
      } catch (error) {
        return "";
      }
    },
    [pickAvailableColor]
  );

  return {
    getColorById,
  };
};
