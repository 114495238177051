import React, { FunctionComponent, useMemo } from "react";

import { Filt } from "@metomic/darwin-shared-lib";
import styled from "styled-components";

import { Box } from "../../components/Box";
import { DonutChartWithList } from "../../components/DonutChartWithList";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { AtomLocation, Maybe } from "../../generated/graphql.generated";
import { useColorDictionary } from "../../operations/colors/hooks";
import { useGetRiskDatapointSummaryQuery } from "../../operations/risks/queries/getRiskDatapointSummary.generated";
import { COLOR } from "../../styling/colors";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { AtomDetectionList } from "../AtomDetectionList";

interface IRiskDatapointCard {
  id: string;
  location?: Partial<Maybe<AtomLocation>>;
  showDetectionList?: boolean;
  entityId?: string;
  installationId?: string;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  border-bottom-color: ${COLOR.LINE_2};
  border-bottom-width: 1px;
`;

const getFilters = ({
  entityType,
  entityId,
  installationId,
  appType,
}: {
  entityType: string;
  entityId: string;
  installationId: string;
  appType: string;
}) =>
  Filt.Create.and([
    Filt.Create.field("entityType").op("equals").value(entityType),
    Filt.Create.field("entityId").op("equals").value(entityId),
    Filt.Create.field("installationId").op("equals").value(installationId),
    Filt.Create.field("appType").op("equals").value(appType),
  ]);

export const RiskDatapointCard: FunctionComponent<IRiskDatapointCard> = ({
  id,
  location,
  showDetectionList,
}) => {
  const { getColorById } = useColorDictionary();
  const [result] = useGetRiskDatapointSummaryQuery({
    variables: { id },
  });

  const installationId = result.data?.getRisk?.atom?.installationId;
  const entityId = result.data?.getRisk?.atom?.entityId;

  const graphData = useMemo(() => {
    if (result.error || !result.data) return undefined;
    const data = result.data?.getDataPointSummaryForRisk;

    return (
      data?.map((item, i) => {
        const classifier = item?.classifier;
        return {
          id: defaultTo(classifier?.id, ""),
          count: defaultTo(item?.count, 0),
          color: getColorById(defaultTo(classifier?.id, ""), "CLASSIFIER"),
          name: defaultTo(classifier?.name, ""),
          level: defaultTo(item?.level, 0),
        };
      }) || []
    );
  }, [result.data, result.error, getColorById]);

  const total = useMemo(() => {
    return graphData?.reduce((acc, { count }) => acc + count, 0);
  }, [graphData]);

  const filters = getFilters({
    appType: defaultTo(location?.appType, ""),
    entityType: defaultTo(location?.entityType, ""),
    entityId: defaultTo(entityId, ""),
    installationId: defaultTo(installationId, ""),
  });

  return (
    <Box minWidth="400px" minHeight="169px">
      {result.fetching ? (
        <LayoutContainer
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          minHeight="169px"
        >
          <LoadingSpinner />
        </LayoutContainer>
      ) : (
        <LayoutContainer>
          <StyledLayoutContainer
            px={1}
            py={1}
            fontSize="xsm"
            display="flex"
            alignItems="center"
            color="LABEL"
          >
            {!result.error && (
              <DonutChartWithList
                dataType=""
                number={total}
                graphData={graphData}
              />
            )}
          </StyledLayoutContainer>
          {showDetectionList && (
            <StyledLayoutContainer
              flexDirection="column"
              display="flex"
              maxHeight="300px"
            >
              <AtomDetectionList filters={filters} />
            </StyledLayoutContainer>
          )}
        </LayoutContainer>
      )}
    </Box>
  );
};
