import React, { useState, useCallback } from "react";

import styled from "styled-components";

import { Heading } from "../../atoms/Heading";
import { Dropdown } from "../../components/Dropdown";
import { LocationContextPill } from "../../components/LocationContextPill";
import { VendorIcon } from "../../components/VendorIcons";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { VENDORS } from "../../definitions/types/vendors";
import { Risk } from "../../generated/graphql.generated";
import {
  getAppTypeByKey,
  getAvailableActions,
  getAvailableActionsByNormalisedId,
  getLabelForAction,
  getRiskLabelByKey,
} from "../../operations/risks/helpers";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import {
  OnGroupItemChangeFunction,
  OnIndividualItemChangeFunction,
  PotentialUnsetRiskCommand,
  RiskActionState,
} from "./definitions";

const StyledVendorIcon = styled(VendorIcon)`
  margin-right: ${APPLICATION_SPACING(2)};
`;

const StyledDropdownContainer = styled(LayoutContainer)`
  cursor: pointer;

  &:hover {
    background-color: ${COLOR.INSET_BACKGROUND_DARK};
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 260px;
`;

const StyledErrorContainer = styled(LayoutContainer)`
  color: ${COLOR.OFF_WHITE};
  background-color: ${COLOR.ERROR};
`;

export const DropdownBulkRiskAction = ({
  id,
  risks,
  state,
  onIndividualItemChange,
  onGroupedItemChange,
  error,
}: {
  id: string;
  risks: Risk[];
  state?: RiskActionState;
  onIndividualItemChange: OnIndividualItemChangeFunction;
  onGroupedItemChange: OnGroupItemChangeFunction;
  error?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const referenceId = id as `${string}:${string}`;
  const firstRiskActionCommandEnum = state?.[defaultTo(risks[0].id, "")];

  const isAllEqualValue = risks.every((risk) => {
    const riskId = defaultTo(risk.id, "");
    const riskActionCommandEnum = state?.[riskId];

    return riskActionCommandEnum === firstRiskActionCommandEnum;
  });

  const allSelectOptions = [
    {
      key: "UNSELECTED",
      value: "UNSELECTED",
      label: "Bulk actions...",
      isUnselected: true,
    },
    ...getAvailableActionsByNormalisedId(referenceId).map((action) => ({
      key: action,
      value: action,
      label: getLabelForAction(action),
    })),
  ];

  const getRiskOptions = useCallback(
    (risk: Risk) => [
      {
        key: "UNSELECTED",
        value: "UNSELECTED",
        label: "Select an action...",
        isUnselected: true,
      },
      ...getAvailableActions(risk).map((action) => ({
        key: action,
        value: action,
        label: getLabelForAction(action),
      })),
    ],
    []
  );

  return (
    <>
      <LayoutContainer
        borderRadius={"2px" as any}
        borderWidth={"1px" as any}
        borderColor={error ? "ERROR" : "LINE_2"}
        mt={3}
      >
        {error && (
          <StyledErrorContainer
            fontSize="xsm"
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="ERROR"
            borderColor="ERROR"
          >
            {error}
          </StyledErrorContainer>
        )}
        <StyledDropdownContainer
          p={2}
          display="flex"
          alignItems="center"
          onClick={(event) => {
            event.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <Heading Element="h2" type="text-md" isBodyColor>
            <LayoutContainer
              display="flex"
              alignItems="center"
              color={error ? "ERROR" : "BODY"}
            >
              <StyledVendorIcon
                vendor={defaultTo(getAppTypeByKey(referenceId), "")}
              />{" "}
              {getRiskLabelByKey(referenceId)} ({risks.length})
            </LayoutContainer>
          </Heading>
          <LayoutContainer ml="auto">
            <StyledDropdown
              selected={
                isAllEqualValue && firstRiskActionCommandEnum
                  ? firstRiskActionCommandEnum
                  : "UNSELECTED"
              }
              options={allSelectOptions}
              onSelect={(value: PotentialUnsetRiskCommand) => {
                onGroupedItemChange({
                  value,
                  referenceId,
                });
              }}
            />
          </LayoutContainer>
        </StyledDropdownContainer>
        {isOpen && (
          <LayoutContainer backgroundColor="GRAYSCALE_BACKGROUND">
            {risks.map((risk, i) => (
              <LayoutContainer
                alignItems="center"
                pt={2}
                px={2}
                pb={i === risks.length - 1 ? 2 : 0}
                key={risk.id}
                display="flex"
                flexWrap="nowrap"
                flex={1}
              >
                <LayoutContainer
                  mr={2}
                  fontSize="xsm"
                  color="PLACEHOLDER"
                  minWidth="60px"
                >
                  {risk.shortCode}
                </LayoutContainer>

                <LayoutContainer
                  fontSize="xsm"
                  color="PLACEHOLDER"
                  mr="auto"
                  pr={4}
                  display="flex"
                  overflow="hidden"
                >
                  <LocationContextPill
                    atomId={defaultTo(risk.atomId, "")}
                    location={{
                      appType: risk.atom?.appType as VENDORS,
                      temp_canonicalLabel: defaultTo(
                        risk.atom?.location?.label,
                        ""
                      ),
                      temp_entityDeeplink: defaultTo(
                        risk.atom?.location?.deeplink,
                        ""
                      ),
                      entityType: defaultTo(risk.atom?.entityType, ""),
                    }}
                  />
                </LayoutContainer>

                <StyledDropdown
                  selected={state?.[defaultTo(risk.id, "")] || "UNSELECTED"}
                  options={getRiskOptions(risk)}
                  onSelect={(value: PotentialUnsetRiskCommand) => {
                    onIndividualItemChange({
                      value: value as PotentialUnsetRiskCommand,
                      riskId: defaultTo(risk.id, ""),
                      referenceId,
                    });
                  }}
                />
              </LayoutContainer>
            ))}
          </LayoutContainer>
        )}
      </LayoutContainer>
    </>
  );
};
