import React, { useState } from "react";

import MultiSelect from "react-select";
import styled from "styled-components";

import { ButtonPillPrimary } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { customStyles } from "../../atoms/form/MultiSelectFilter/theme";
import { useInviteUserMutation } from "../../operations/user/hooks/useInviteUser";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { Box } from "../Box";
import { LayoutContainer } from "../layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "./BaseModal";
import { StyledErrorContainer, StyledTextInput } from "./shared";

interface IInviteUserModal extends IModalBase {}

//TODO: These should come from the server

const userRoleOptions = [
  { value: "READONLY", label: "Read Only" },
  { value: "MEMBER", label: "Member" },
  { value: "OWNER", label: "Owner" },
];

const StyledModalBox = styled(Box)`
  padding: ${APPLICATION_SPACING(4)};
  width: 24rem;
  max-width: 100%;
`;

const StyledMultiSelect = styled(MultiSelect)`
  border-radius: 0px;
  margin-bottom: 1.75rem;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 1.75rem;
`;

const InviteUserModal = ({ showModal, toggleModal }: IInviteUserModal) => {
  const [inviteModalFormState, setInviteModalFormState] = useState({
    email: "",
    role: userRoleOptions[0],
    error: "",
  });

  const { loading, error, inviteUser } = useInviteUserMutation({
    updateErrorState: setInviteModalFormState,
    onFinishedUpdate: () => toggleModal(),
  });

  return (
    <ModalWrapper showModal={showModal} toggleModal={toggleModal}>
      <StyledModalBox>
        {(inviteModalFormState.error || error) && (
          <StyledErrorContainer
            p={4}
            fontSize="md"
            color="ERROR"
            mb={3}
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {inviteModalFormState.error || `${error}`}
          </StyledErrorContainer>
        )}
        <StyledHeading type="text-md" Element="h1" isBodyColor>
          Invite Team Member
        </StyledHeading>
        <StyledTextInput
          heading="Email"
          placeholder="Email"
          value={inviteModalFormState.email}
          onChange={(value) =>
            setInviteModalFormState((s) => ({
              ...s,
              email: value as string,
            }))
          }
        />
        <LayoutContainer
          as="label"
          fontSize="xsm"
          color="LABEL"
          mb={1}
          display="block"
        >
          Role
        </LayoutContainer>
        <StyledMultiSelect
          styles={customStyles}
          onChange={(selectValue: any) => {
            setInviteModalFormState((s) => ({
              ...s,
              role: selectValue || userRoleOptions[0],
            }));
          }}
          options={userRoleOptions}
          value={inviteModalFormState.role}
          isSearchable={false}
        />
        <ButtonPillPrimary
          smallLoader
          loading={loading}
          onClick={() =>
            inviteUser({
              email: inviteModalFormState.email,
              role: inviteModalFormState.role.value,
            })
          }
        >
          Send Invite
        </ButtonPillPrimary>
      </StyledModalBox>
    </ModalWrapper>
  );
};

export default InviteUserModal;
