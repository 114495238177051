import React, { useState } from "react";

import { useQuery } from "urql";

import { ConnectionShowMoreWrapper } from "../../components/ShowMoreWrapper";
import { LIST_RULE_ACTION_RESULTS_FOR_VIOLATION } from "../../operations/violations/queries/violationSummaryQuery";
import { TIncomingRelay } from "../../urql/pagination/definitions/definitions";
import { useConnectionData } from "../../utils/pagination/hooks/useConnectionData";
import { RuleActionResultsTable } from "./table";

export interface IViolationActionHistory {
  id: string;
  actionType: string;
  result: {
    id: string;
    type: string;
    meta: {
      reason?: string;
    };
  };
  createdAt: string;
}

export interface IViolationActionHistoryConnection {
  listRuleActionResultsForViolation: TIncomingRelay<
    IViolationActionHistory,
    {}
  >;
}

export interface ViolationActionHistoryConnectionTable {
  violationId: string;
}

export const ViolationActionHistoryConnection = ({
  violationId,
}: ViolationActionHistoryConnectionTable) => {
  const [afterCursor, setAfterCursor] = useState("");
  const [result] = useQuery<IViolationActionHistoryConnection>({
    query: LIST_RULE_ACTION_RESULTS_FOR_VIOLATION,
    variables: {
      violationId,
      afterCursor,
    },
  });

  const { hasData, edges, endCursor, isFirstLoad } = useConnectionData(
    result.data?.listRuleActionResultsForViolation
  );

  return (
    <ConnectionShowMoreWrapper
      hideButton={true}
      showLoadingIndicator={!isFirstLoad}
      loading={result.fetching}
      showMore={() => {
        setAfterCursor(endCursor);
      }}
    >
      <RuleActionResultsTable
        error={result.error}
        loading={result.fetching || !hasData}
        results={edges}
        isFirstLoad={isFirstLoad}
      />
    </ConnectionShowMoreWrapper>
  );
};
