import { gql } from "urql";

import { FULL_DETECTION_FIELDS_FRAGMENT } from "../../operations/detections/fragments";

export const ATOM_DETECTION_SEARCH_QUERY = gql`
  ${FULL_DETECTION_FIELDS_FRAGMENT}
  query AtomDetectionSearchQuery(
    $afterCursor: String
    $filters: DatapointFilters
    $limit: Int
    $canReadDeepLink: Boolean!
    $canUseDetectionActions: Boolean!
  ) {
    detectionSearch(
      pagination: { limit: $limit, cursor: $afterCursor }
      filters: $filters
    ) {
      edges {
        node {
          ...FullDetectionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
