import React, { ComponentType } from "react";

import { Row, TableRowProps } from "react-table";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import { COLOR } from "../../../../../styling/colors";
import { APPLICATION_SPACING } from "../../../../../styling/spacing";
import { LayoutContainer } from "../../../../layout/LayoutContainer";
import { DefaultRowType, IGenericTable } from "../../definitions";
import { MobileRow } from "./mobile";

type PickedRowFields =
  | "onRowClick"
  | "clickWholeRowToExpand"
  | "expanded"
  | "theme"
  | "themeOverrides";

export type RowInterface<T extends DefaultRowType> = Pick<
  IGenericTable<T>,
  PickedRowFields
> & {
  row: Row<T>;
  rowProps: TableRowProps;
  isMobileLayout: boolean;
  RowContainer: ComponentType<T>;
};

const MaybeMobileContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) =>
    isMobile &&
    css`
      background: ${COLOR.INSET_BACKGROUND};
      box-shadow: inset 0px 5px 15px -9px rgba(0, 0, 0, 0.2);
    `}
`;

export const StyledCell = styled(LayoutContainer)<{
  theme?: FlattenSimpleInterpolation;
  themeOverrides?: FlattenSimpleInterpolation;
  columnOverrides?: FlattenSimpleInterpolation;
}>`
  ${({ theme }) => theme};

  padding-right: ${APPLICATION_SPACING(3)};

  &:last-child {
    padding-right: 0;
  }

  ${({ themeOverrides }) => themeOverrides};
  ${({ columnOverrides }) => columnOverrides};
`;

const DesktopRow = <T extends DefaultRowType = {}>({
  onRowClick,
  row,
  clickWholeRowToExpand,
  rowProps,
  theme,
  themeOverrides,
}: RowInterface<T>) => {
  const Component = (onRowClick && "button") || "div";
  return (
    <Component
      tabIndex={onRowClick ? 1 : undefined}
      onClick={() => {
        onRowClick && onRowClick(row.original);
      }}
      {...(clickWholeRowToExpand ? row.getToggleRowExpandedProps() : {})}
      {...rowProps}
      style={{
        width: "100%",
        ...(rowProps.style || {}),
      }}
    >
      {row.cells.map((cell, idx) => (
        <StyledCell
          theme={theme.cell}
          themeOverrides={themeOverrides?.cell}
          columnOverrides={cell.column.columnStyles}
          {...cell.getCellProps()}
          key={cell.getCellProps().key ?? `RowContainerCell-${idx}`}
        >
          {cell.render("Cell")}
        </StyledCell>
      ))}
    </Component>
  );
};

export const TableRow = <T extends DefaultRowType = {}>(
  props: RowInterface<T>
) => {
  const { expanded, row, rowProps, isMobileLayout, RowContainer } = props;
  return (
    <RowContainer
      key={rowProps.key}
      isExpanded={row.isExpanded}
      {...row.original}
    >
      {isMobileLayout ? <MobileRow {...props} /> : <DesktopRow {...props} />}
      {row.isExpanded && expanded ? (
        <MaybeMobileContainer isMobile={isMobileLayout}>
          <LayoutContainer width="100%">{expanded(row)}</LayoutContainer>
        </MaybeMobileContainer>
      ) : null}
    </RowContainer>
  );
};
