import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { useIntegrationMeta } from "../../operations/integrations/hooks/useIntegrationMeta";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

// Colors need to be more strictly defined once we have an idea what the range will be

export interface IVendorPill {
  id: string;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
}

const StyledImage = styled.img`
  height: 100%;
  margin-right: ${APPLICATION_SPACING(1)};
  padding: ${SPECIFIC_SPACING["4px"]};
`;

const StyledVendorPill = styled.span`
  font-size: ${theme.fontSizes.xsm};
  height: 1.25rem;
  padding: 0 ${APPLICATION_SPACING(2)} 0 0;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
`;

export const VendorPill: FunctionComponent<IVendorPill> = ({
  className,
  backgroundColor = COLOR.PAGE_BACKGROUND,
  textColor,
  id,
}) => {
  const { getIntegrationMeta } = useIntegrationMeta();
  return (
    <StyledVendorPill
      style={{ backgroundColor, color: textColor }}
      className={className}
    >
      <StyledImage
        alt={getIntegrationMeta(id)?.name}
        src={getIntegrationMeta(id)?.iconUrl}
      />
      {id ? getIntegrationMeta(id)?.name : ""}
    </StyledVendorPill>
  );
};
