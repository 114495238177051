import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAccountOnboardingStatusMutationVariables = Types.Exact<{
  status: Types.Scalars["String"];
  currentStep?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type UpdateAccountOnboardingStatusMutation = {
  __typename?: "RootMutationType";
  updateAccountOnboardingStatus?:
    | {
        __typename?: "Account";
        id?: string | null | undefined;
        name?: string | null | undefined;
        onboarding?:
          | {
              __typename?: "AccountOnboarding";
              id?: string | null | undefined;
              status?: Types.AccountOnboardingStatus | null | undefined;
              currentStep?:
                | Types.AccountOnboardingCurrentStep
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const UpdateAccountOnboardingStatusDocument = gql`
  mutation UpdateAccountOnboardingStatus(
    $status: String!
    $currentStep: String
  ) {
    updateAccountOnboardingStatus(status: $status, currentStep: $currentStep) {
      id
      name
      onboarding {
        id
        status
        currentStep
      }
    }
  }
`;

export function useUpdateAccountOnboardingStatusMutation() {
  return Urql.useMutation<
    UpdateAccountOnboardingStatusMutation,
    UpdateAccountOnboardingStatusMutationVariables
  >(UpdateAccountOnboardingStatusDocument);
}
