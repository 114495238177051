import { useTransition, animated } from "react-spring";
import styled from "styled-components";

const AnimatedWrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
`;

export const useAnimatedListFromBottom = ({
  listOfElements,
  keyFunc,
  containerClass,
  trail = 50,
}: {
  listOfElements: JSX.Element[];
  keyFunc?: any;
  containerClass?: string;
  trail?: number;
}) => {
  const listTransition = useTransition(listOfElements, {
    trail,
    from: { opacity: 0, transform: "translateY(20px)" },
    enter: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    keys: keyFunc ? keyFunc : (item: JSX.Element) => item.key,
  });

  return listTransition(
    (style, item, t, i) =>
      item && (
        <AnimatedWrapper style={{ ...style }} className={containerClass}>
          {item}
        </AnimatedWrapper>
      )
  );
};
