import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { generatePath } from "react-router";
import { Redirect } from "react-router-dom";

import { UserCacheUpdates } from "../../operations/user/cache-updates";

// import styles from "./styles.module.css";
// import { Box } from "../../components/Box";
// import { Heading } from "../../components/Heading";
// import { Button } from "../../components/Button";

// TODO: This is super MVP, needs tidying and integrating with the application proper
// TODO: Move queries/mutations to a smart container

export const Login = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoggedIn) {
      loginWithRedirect({
        redirectUri: window.location.origin,
      });
      UserCacheUpdates.logUserOut();
    }
  }, [isLoggedIn, loginWithRedirect]);

  if (isLoggedIn) {
    return <Redirect to={generatePath("/overview")} />;
  } else {
    return null;
  }
};
