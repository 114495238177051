export const requiresRedirect = ({
  isLoggedIn,
  requiresAuthentication,
}: {
  isLoggedIn?: boolean;
  requiresAuthentication: boolean;
}) => !isLoggedIn && requiresAuthentication;

export const shouldRenderComponent = ({
  isLoggedIn,
  requiresAuthentication,
}: {
  isLoggedIn?: boolean;
  requiresAuthentication: boolean;
}) => (requiresAuthentication && isLoggedIn) || !requiresAuthentication;
