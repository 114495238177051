import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import { Check } from "react-feather";
import { useLocation } from "react-router";
import { LinkProps, NavLink, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";

import { IUser } from "../../definitions/types/users";
import { getCurrentRouteIndex } from "../../page/Onboarding/components/OnboardingNext";
import { onboardingRoutes } from "../../routing/child/onboarding/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { defaultTo } from "../../utils/maybe/defaultTo";
import {
  MOBILE_BREAKPOINT,
  useMediaQuery,
} from "../../utils/responsive/useMediaQuery";
import { Logo } from "../Logo";
import { ProgressBar } from "../ProgressBar";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface IOnboardingNavigation {}

interface INavLinkContainer extends LinkProps {
  className?: string;
  text?: string;
  enableTooltip?: boolean;
  disabled?: boolean;
}

export interface IMeData {
  me: IUser;
}

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(1)};
  border-color: rgba(255, 255, 255, 0.1);
  color: ${COLOR.PLACEHOLDER};
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: 45px;
  height: 60px;
`;

const StyledNavLinkHover = styled(StyledNavLink)`
  position: relative;
`;

const NavLinkContainer: FunctionComponent<INavLinkContainer> = ({
  children,
  className,
  to,
  text,
  disabled,
}) => (
  <StyledNavLinkHover
    to={to}
    className={className}
    activeStyle={{
      color: COLOR.PRIMARY,
    }}
    style={
      disabled
        ? {
            pointerEvents: "none",
            cursor: "default",
            color: COLOR.SUCCESS,
          }
        : {}
    }
  >
    {children}
    {text}
  </StyledNavLinkHover>
);

const StyledNavLinkWithBorder = styled(NavLinkContainer)`
  border-left-width: 1px;
`;

const StyledNav = styled.nav`
  background: ${COLOR.CONTAINER_BACKGROUND};
  flex: 0 1 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: ${theme.fontSizes.mdlg};
  position: fixed;
  left: 0;
  border-right-width: 1px;
  transition: all 500ms ease-in-out;
  width: 250px;
  height: 100%;

  @media screen and (min-width: 768px) {
    z-index: 10;
  }
`;

const StyledInnerNavContainer = styled.div`
  min-width: min-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 500ms ease-in-out;
`;

const StyledLogoLink = styled(NavLink)`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(1)};
  color: rgba(250, 250, 250, 0.5);
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: 20px;
  height: 50px;
  align-content: center;
  justify-content: center;
  height: 65px;
  padding-left: 12px;
  padding-right: 12px;
  width: 250px;
  overflow: hidden;
  pointer-events: none;
  cursor: default;
`;

const NavChildrenWrapper = styled.div<{
  menuIsExpanded?: boolean;
  isMobile?: boolean;
}>`
  background: ${COLOR.PAGE_BACKGROUND};
  width: 100%;
  z-index: 10;
  position: relative;
  transition: 0.4s cubic-bezier(0.42, 0, 0.58, 1);

  ${({ menuIsExpanded, isMobile }) =>
    menuIsExpanded &&
    isMobile &&
    css`
      transform: translateX(250px);
    `}

  @media screen and (min-width: 768px) {
    z-index: 0;
    padding-left: 250px;
    background: transparent;
    width: auto;
    transition: none;
  }
`;

const StyledCheck = styled(Check)`
  color: ${COLOR.SUCCESS};
  margin-right: ${APPLICATION_SPACING(1)};
`;

export const OnboardingNavigation: FunctionComponent<IOnboardingNavigation> = ({
  children,
}) => {
  const navRef = useRef<HTMLElement>(null);
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const location = useLocation();

  const isLargeLayout = useMediaQuery(MOBILE_BREAKPOINT);

  const isMobile = !isLargeLayout;

  useEffect(() => {
    setMenuIsExpanded(false);
  }, [isMobile, location]);

  const matchingRoute = useRouteMatch(
    onboardingRoutes.map((route) => route.path) as string[]
  );

  const currentRouteIndex = getCurrentRouteIndex(
    onboardingRoutes.find((route) => route.path === matchingRoute?.path)
      ?.id as string
  );

  const currentPercentage =
    ((currentRouteIndex + 1) / onboardingRoutes.length) * 100;

  return (
    <div>
      <StyledNav ref={navRef}>
        <StyledInnerNavContainer>
          <StyledLogoLink
            to={{
              pathname: "/",
              search: window.location.search,
            }}
          >
            <Logo primary />
          </StyledLogoLink>
          {onboardingRoutes.map((route, index) => (
            <StyledNavLinkWithBorder
              enableTooltip={!menuIsExpanded}
              text={`${index + 1}.  ${route.navTitle}`}
              to={defaultTo(route.path, "") as string}
              disabled={index >= currentRouteIndex}
            >
              {index < currentRouteIndex && <StyledCheck size={16} />}
            </StyledNavLinkWithBorder>
          ))}
          <LayoutContainer
            color="PRIMARY"
            fontSize="xsm"
            mt="auto"
            px={3}
            py={3}
          >
            <LayoutContainer mb={1}>Progress</LayoutContainer>
            <ProgressBar percentage={currentPercentage} />
          </LayoutContainer>
        </StyledInnerNavContainer>
      </StyledNav>
      <NavChildrenWrapper isMobile={isMobile} menuIsExpanded={menuIsExpanded}>
        {children}
      </NavChildrenWrapper>
    </div>
  );
};
