import { gql } from "urql";

import { CAN_READ_DEEPLINK_LOCATION_FRAGMENT } from "../../operations/detections/fragments";

export const MONITOR_VIOLATIONS_LIST_QUERY = gql`
  ${CAN_READ_DEEPLINK_LOCATION_FRAGMENT}
  query MonitorViolationsListQuery(
    $id: String!
    $afterCursor: String
    $limit: Int
    $canReadDeepLink: Boolean!
    $canUseDetectionActions: Boolean!
    $violationStatus: ViolationStatus
  ) {
    getMonitor(id: $id) {
      id
      violations(
        pagination: { limit: $limit, cursor: $afterCursor }
        status: $violationStatus
      ) {
        edges {
          node {
            id
            status
            detection {
              id
              pattern
              atomId
              actionState @include(if: $canUseDetectionActions)
              classifierType
              classifier {
                id
                name
                slug
              }
              detectionHash
              obscuredDetection
              location {
                appType
                entityType
                installationId
                fieldIdentifier
                entityId
                ...AuthenticatedLocationFields @include(if: $canReadDeepLink)
              }
              lastDetected
              firstDetectedBackdate
              firstDetected
              actor {
                id
                displayName
                imageUrl
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
