import React from "react";

import { useMutation } from "urql";

import { useToast } from "../../../recoil/toasts/provider";
import { sendAnalytics } from "../analytics";
import { INVITE_USER_MUTATION } from "../mutations/inviteUser";

interface IUseInviteUserMutation {
  updateErrorState?: React.Dispatch<React.SetStateAction<any>>;
  onFinishedUpdate?: Function;
}

export const useInviteUserMutation = ({
  updateErrorState,
  onFinishedUpdate,
}: IUseInviteUserMutation) => {
  const addToast = useToast();

  const [inviteUserResult, inviteUser] = useMutation(INVITE_USER_MUTATION);

  const inviteUserOp = ({ email, role }: { email: string; role: string }) => {
    if (!email) {
      if (updateErrorState) {
        updateErrorState((s: any) => ({
          ...s,
          error: "Please provide an email address",
        }));
      }

      return;
    }

    inviteUser({
      email,
      role,
    }).then(() => {
      if (onFinishedUpdate) {
        onFinishedUpdate();
      }

      addToast({
        type: "success",
        message: `Successfully invited user: ${email}`,
      });

      sendAnalytics("USERS: Add New", undefined);
    });
  };

  return {
    inviteUser: inviteUserOp,
    error: inviteUserResult.error,
    loading: inviteUserResult.fetching,
  };
};
