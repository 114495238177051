import React, { FunctionComponent, HTMLAttributes } from "react";

import { Loader } from "react-feather";
import styled from "styled-components";

interface IAnimatedFeatherLoader extends HTMLAttributes<HTMLSpanElement> {}

const StyledFeatherLoader = styled(Loader)`
  animation: 0.3s weightedSpin infinite ease-in-out;

  @keyframes weightedSpin {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const AnimatedFeatherLoader: FunctionComponent<
  IAnimatedFeatherLoader
> = ({ className }) => <StyledFeatherLoader className={className} size={16} />;
