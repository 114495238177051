import React, { FunctionComponent } from "react";

import styled, { css } from "styled-components";

import { COLOR } from "../../styling/colors";
import theme from "../../styling/theme";
import { EnumMap, HeadingTypes, HtmlHeading } from "./config";

export interface IHeading {
  Element: keyof EnumMap<typeof HtmlHeading>;
  type: keyof EnumMap<typeof HeadingTypes>;
  flipColor?: boolean;
  className?: string;
  isBodyColor?: boolean;
  isPrimary?: boolean;
  isLabel?: boolean;
}

// You're thinking, wtf! Every heading is an h1? It's not, we're using a polymorphic as prop

const StyledHeading = styled.h1<Omit<IHeading, "Element">>`
  color: ${(props) => {
    if (props.isBodyColor) {
      return COLOR.BODY;
    } else if (props.isPrimary) {
      return COLOR.PRIMARY;
    } else if (props.isLabel) {
      return COLOR.LABEL;
    } else if (props.flipColor) {
      return COLOR.TITLE_FLIP;
    } else {
      return COLOR.TITLE_ACTIVE;
    }
  }};
  ${(props) => {
    switch (props.type) {
      case "text-sm":
        return css`
          font-size: ${theme.fontSizes.sm};
          font-weight: 300;
        `;
      case "text-md":
        return css`
          font-size: ${theme.fontSizes.md};
          font-weight: 300;
        `;
      case "text-md-bold":
        return css`
          font-size: ${theme.fontSizes.md};
          font-weight: 400;
        `;
      case "text-mdlg":
        return css`
          font-size: 1.125rem;
          font-weight: 300;
        `;
      case "text-large":
        return css`
          font-size: ${theme.fontSizes.lg};
          font-weight: 300;
        `;

      case "display-extra-small":
        return css`
          font-size: ${theme.fontSizes.lg};
          font-weight: 100;
        `;
      case "display-small":
        return css`
          font-size: ${theme.fontSizes.lg};
          font-weight: 100;
        `;
      case "display-medium-extralight":
        return css`
          font-size: ${theme.fontSizes.displaymd};
          font-weight: 100;
        `;
      case "display-extra-large":
        return css`
          font-size: ${theme.fontSizes.displayxlg};
          font-weight: 300;
        `;
      case "display-large":
        return css`
          font-size: ${theme.fontSizes.displaylg};
          font-weight: 300;
        `;
      case "display-medium":
        return css`
          font-size: ${theme.fontSizes.displaymd};
          font-weight: 300;
        `;
      case "display-large-bold":
        return css`
          font-size: ${theme.fontSizes.displaylg};
          font-weight: 700;
        `;
      case "display-medium-bold":
        return css`
          font-size: ${theme.fontSizes.displaymd};
          font-weight: 700;
        `;
      case "display-small-bold":
        return css`
          font-size: ${theme.fontSizes.lg};
          font-weight: 700;
        `;
    }
  }};
`;

export const Heading: FunctionComponent<IHeading> = ({
  Element,
  children,
  className,
  ...props
}) => (
  <StyledHeading className={className} as={Element} {...props}>
    {children}
  </StyledHeading>
);
