import { gql } from "urql";

export interface IIntegration {
  id: string;
  appType: string;
  name: string;
  logoUrl: string;
  comingSoon: boolean;
  iconUrl: string;
  largeIconUrl: string;
}

export interface IListIntegrations {
  listIntegrations: IIntegration[];
}

export const LIST_INTEGRATIONS_QUERY = gql`
  query ListIntegrations($isAccountRestricted: Boolean!) {
    listIntegrations(isAccountRestricted: $isAccountRestricted) {
      id
      appType
      name
      logoUrl
      comingSoon
      iconUrl
      largeIconUrl
    }
  }
`;
