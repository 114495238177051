import { GetTokenSilentlyOptions, LogoutOptions } from "@auth0/auth0-spa-js";
import { makeOperation } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import { Operation } from "urql";

import { AUTH0_AUDIENCE } from "../../config.auth0";
import { UserCacheUpdates } from "../../operations/user/cache-updates";

interface IAuthState {
  token?: string;
}

export const auth = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions | undefined
  ) => Promise<string>,
  logout: (options?: LogoutOptions | undefined) => void
) =>
  authExchange({
    willAuthError: () => true,
    didAuthError: ({ error }) =>
      error.response && error.response.status === 401,
    getAuth: async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: AUTH0_AUDIENCE,
        });

        return { token };
      } catch (error) {
        UserCacheUpdates.logUserOut();
        logout();
        return null;
      }
    },
    addAuthToOperation: ({
      authState,
      operation,
    }: {
      authState: IAuthState;
      operation: Operation<any>;
    }) => {
      if (!authState || !authState.token) {
        return operation;
      }

      const fetchOptions =
        typeof operation.context.fetchOptions === "function"
          ? operation.context.fetchOptions()
          : operation.context.fetchOptions || {};

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${authState.token}`,
          },
        },
      });
    },
  });
