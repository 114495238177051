import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import queryString from "query-string";
import {
  CheckCircle,
  MoreHorizontal,
  RefreshCw as RefreshCWIcon,
} from "react-feather";
import { Layers as LayersIcon } from "react-feather";
import { useLocation } from "react-router";
import styled from "styled-components";

import { Box } from "../../../components/Box";
import { LayoutContainer } from "../../../components/layout/LayoutContainer";
import { LoadingSpinner } from "../../../components/loading/LoadingSpinner";
import { IIntegrationConfigModalData } from "../../../components/modals/Integration/IntegrationConfigModal";
import { IIntegrationModal } from "../../../components/modals/Integration/IntegrationModal";
import { IntegrationSetup } from "../../../definitions/types/integration";
import { INTEGRATION_API } from "../../../operations/integrations/api";
import { IIntegration } from "../../../operations/integrations/queries/listIntegrations";
import { COLOR } from "../../../styling/colors";
import {
  APPLICATION_SPACING,
  SPECIFIC_SPACING,
} from "../../../styling/spacing";

export interface IIntegrationWithInstallationId extends IIntegration {
  installationId?: string;
  status?: string;
}

const StyledIntegrationIconBox = styled(LayoutContainer)``;

const StyledLayersIcon = styled(LayersIcon)`
  position: absolute;
  top: ${SPECIFIC_SPACING["12px"]};
  left: ${APPLICATION_SPACING(1)};
  color: ${COLOR.PLACEHOLDER};
`;

const StyledButton = styled.button<{ $largeOffset: boolean }>`
  position: absolute;
  top: ${SPECIFIC_SPACING["12px"]};
  right: ${({ $largeOffset }) =>
    $largeOffset ? APPLICATION_SPACING(6) : APPLICATION_SPACING(1)};
`;

const StyledRefreshIcon = styled(RefreshCWIcon)`
  color: ${COLOR.PLACEHOLDER};
`;

const StyledMoreHorizontal = styled(MoreHorizontal)`
  color: ${COLOR.PLACEHOLDER};
`;

const StyledShowMoreButton = styled.button`
  position: absolute;
  top: ${SPECIFIC_SPACING["12px"]};
  right: ${APPLICATION_SPACING(1)};
`;

const StyledIntegrationIcon = styled.img`
  width: 60%;
  max-width: 200px;
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const Installed = ({
  integration,
  toggleModal,
  toggleConfigModal,
}: {
  integration: IIntegrationWithInstallationId;
  toggleModal: (data?: IIntegrationModal) => void;
  toggleConfigModal: (data?: IIntegrationConfigModalData) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const location = useLocation();

  const queryStringParameters = queryString.parse(location.search);

  const { error, appType } = queryStringParameters;

  const { getAccessTokenSilently } = useAuth0();

  const reauthenticate = async () => {
    try {
      const integrationSetup: IntegrationSetup =
        await INTEGRATION_API.reauthenticateInstallation({
          getAccessToken: getAccessTokenSilently,
          setIsLoading,
          setIsError,
          installationId: integration.installationId || "",
        });

      toggleModal(integrationSetup);
    } catch (error) {}
  };

  const getInstallationConfigModal = async () => {
    try {
      const integrationConfigDetails: IIntegrationConfigModalData =
        await INTEGRATION_API.getInstallationConfig({
          getAccessToken: getAccessTokenSilently,
          setIsLoading: setIsConfigLoading,
          setIsError,
          installationId: integration.installationId || "",
        });

      toggleConfigModal({
        ...integrationConfigDetails,
        installationId: integration.installationId,
      });
    } catch (error) {}
  };

  return (
    <LayoutContainer height="100%" display="flex" pb={[3, 0]}>
      <Box
        key={integration.id}
        flex={1}
        display="flex"
        flexDirection="column"
        borderTopWidth={"4px" as any}
        borderColor="SUCCESS_DARK_MODE"
      >
        {(isError || (error && appType === integration.appType)) && (
          <LayoutContainer mx={2}>
            <LayoutContainer
              p={4}
              fontSize="md"
              borderColor="ERROR"
              borderWidth={"1px" as any}
              color="ERROR"
              mt={3}
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {`${
                isError || error || "Something went wrong, please try again."
              }`}
            </LayoutContainer>
          </LayoutContainer>
        )}
        <StyledIntegrationIconBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          position="relative"
          flex={1}
        >
          <StyledLayersIcon size={15} />
          {integration.appType === "slack" && (
            <StyledButton
              $largeOffset={integration.appType === "slack"}
              onClick={() => {
                if (isLoading) return false;
                reauthenticate();
              }}
            >
              {isLoading ? <LoadingSpinner /> : <StyledRefreshIcon size={15} />}
            </StyledButton>
          )}

          <StyledShowMoreButton
            onClick={() => {
              if (isConfigLoading) return false;
              getInstallationConfigModal();
            }}
          >
            {isConfigLoading ? (
              <LoadingSpinner isSmall />
            ) : (
              <StyledMoreHorizontal size={15} />
            )}
          </StyledShowMoreButton>

          <StyledIntegrationIcon
            alt={integration.name}
            src={integration.logoUrl}
          />
        </StyledIntegrationIconBox>
        <StyledLayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="GRAYSCALE_BACKGROUND"
          borderTopWidth={"1px" as any}
          borderColor="LINE"
          p={3}
          height="84px"
          color="SUCCESS"
          fontSize="xsm"
        >
          <LayoutContainer mr={1}>
            <CheckCircle size={16} />
          </LayoutContainer>
          Connected
        </StyledLayoutContainer>
      </Box>
    </LayoutContainer>
  );
};
