import React from "react";

import styled from "styled-components";

import { Heading } from "../../../../atoms/Heading";
import { SectionHeaderNode } from "../../../../definitions/types/integration";

const StyledHeading = styled(Heading)`
  margin-bottom: 1.75rem;
  width: 100%;
  text-align: center;
`;

export const IntegrationNodeHeader = (node: SectionHeaderNode) => (
  <StyledHeading type="display-small" Element="h2" isBodyColor>
    {node.config.text}
  </StyledHeading>
);
