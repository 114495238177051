import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Route, Router, Switch } from "react-router-dom";

import { LoadingMetomicIcon } from "../components/loading/LoadingIcon";
import { useInitialUserState } from "../operations/user/hooks/initialUserState";
import {
  hasFinishedOnboarding,
  routePermittedByPermission,
} from "../operations/user/permissions";
import { Login } from "../page/Login";
import { Logout } from "../page/Logout";
import { AuthorizedUrqlProvider } from "../urql/provider";
import { useRoutePermittedByFeatureFlag } from "../utils/feature-flags/hooks/useRoutePermittedByFeatureFlag";
import { getMaybeArrayStringValue } from "../utils/string/getValueOrEmpty";
import { JSONDefinedRouter } from "./RouteWithAuthentication/JSONDefinedRouter";
import { OnboardingRouter } from "./child/onboarding";
import { history } from "./history";
import { APPLICATION_ROUTES } from "./routes";

//TODO: A way to effectively manage permissions
//TODO: A way to effectively manage feature flagged routes

export const AuthGate = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <LoadingMetomicIcon isFullScreen />;
  }

  if (!isAuthenticated) {
    return <Login isLoggedIn={isAuthenticated} />;
  }

  return (
    <AuthorizedUrqlProvider>
      <Router history={history}>
        <Switch>
          <Route path="/logout" exact render={() => <Logout />} />

          <AppRouter></AppRouter>
        </Switch>
      </Router>
    </AuthorizedUrqlProvider>
  );
};

export const AppRouter = () => {
  const featureFlagRouteFilter = useRoutePermittedByFeatureFlag();
  const routeDictToArray = Object.values(APPLICATION_ROUTES);

  const { loading, user, error } = useInitialUserState();

  if (loading) {
    return <LoadingMetomicIcon isFullScreen />;
  }

  const permittedRoutes = routeDictToArray
    .filter(featureFlagRouteFilter)
    .map((route) => ({
      ...route,
      permissionDenied: !routePermittedByPermission(
        getMaybeArrayStringValue(user?.permissions)
      )(route),
    }));

  if (error) {
    throw new Error("Failed to define user");
  }

  const isOnboarded = hasFinishedOnboarding(user?.account?.onboarding?.status);

  if (!isOnboarded) {
    return <OnboardingRouter />;
  } else {
    return <JSONDefinedRouter permittedRoutes={permittedRoutes} />;
  }
};
