import { GraphCacheOptimisticUpdaters } from "../../generated/graphql.generated";

export const optimistic: GraphCacheOptimisticUpdaters = {
  updateClassifier: (variables) => {
    return {
      __typename: "Classifier",
      ...variables,
    };
  },
  updateRuleActionRedact(variables) {
    const { ruleId, input } = variables;

    const delayedExecutionConfig = input?.delayedExecutionConfig
      ? {
          ...input?.delayedExecutionConfig,
          __typename: "DelayedExecutionConfig",
        }
      : null;

    return {
      __typename: "ActionRedact",
      id: ruleId,
      actionType: "redact",
      ...input,
      config: {
        messageTemplate: input?.messageTemplate,
        __typename: "ActionRedactConfig",
      },
      delayedExecutionConfig: delayedExecutionConfig,
    };
  },
  updateRuleActionRevokeFilePermissions(variables) {
    const { ruleId, input } = variables;

    const delayedExecutionConfig = input?.delayedExecutionConfig
      ? {
          ...input?.delayedExecutionConfig,
          __typename: "DelayedExecutionConfig",
        }
      : null;

    return {
      __typename: "ActionRevokeFilePermissions",
      id: ruleId,
      actionType: "revoke-file-permissions",
      ...input,
      config: {
        revokeInternalSharing: Boolean(input?.revokeInternalSharing),
        revokePublicSharing: Boolean(input?.revokePublicSharing),
        resolveViolation: Boolean(input?.resolveViolation),
        __typename: "ActionRevokeFilePermissionsConfig",
      },
      delayedExecutionConfig: delayedExecutionConfig,
    };
  },
} as GraphCacheOptimisticUpdaters;
