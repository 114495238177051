import { errorExchange } from "urql";

import { IToast } from "../../recoil/toasts/atom";

const noToastForMessage = (message: string) => {
  const messages = {
    "Authorization token expired": true,
  };

  return Object.keys(messages).find((key) => message.startsWith(key)) ?? false;
};

export const graphqlErrorHandler = (addToast: (toast: IToast) => void) =>
  errorExchange({
    onError: ({ graphQLErrors }, operation) => {
      if (graphQLErrors) {
        // if we've got an error, run the local context option

        graphQLErrors.forEach((error) => {
          if (operation.context.onError) {
            return operation.context.onError(error);
          }
        });

        // Now run all unique errors through the generic handler, if they've not already handled it lower in the stack

        [...new Set(graphQLErrors.map((err) => err.message))].forEach(
          (message) => {
            if (message === "Authorization token expired") return;
            if (operation.context.onError) return;

            if (!noToastForMessage(message)) {
              addToast({
                type: "error",
                message: `${message}`,
              });
            }
          }
        );
      }
    },
  });
