import { Exchange, OperationType } from "urql";
import { pipe, tap } from "wonka";

import { IToast } from "../../recoil/toasts/atom";

const isValidOp = (operationType: OperationType) =>
  operationType === "mutation" || operationType === "query";

export const toastExchange =
  (addToast: (toast: IToast) => void): Exchange =>
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap(({ error, operation }) => {
        if (isValidOp(operation.kind) && !error) {
          if (operation.context.toastOnSuccess) {
            addToast(operation.context.toastOnSuccess);
          }
        }
      })
    );
  };
