import React, { FunctionComponent, HTMLAttributes } from "react";

import styled from "styled-components";

import {
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
  SAFE_TEMPLATE,
} from "../../definitions/types/risk-level";
import { EnumMap } from "../../definitions/types/utils";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

interface IThreatBlock extends HTMLAttributes<HTMLDivElement> {
  riskLevel: keyof EnumMap<typeof RiskLevel>;
  violationCount: number;
  showUnknown: boolean;
}

export const getBlockBackgroundColor = (
  violationCount: number,
  showUnknown: boolean,
  riskLevel: keyof EnumMap<typeof RiskLevel>
) => {
  if (showUnknown) {
    return COLOR.PRIMARY;
  } else if (violationCount) {
    return RISK_LEVEL_TEMPLATES[riskLevel].backgroundColor;
  } else {
    return SAFE_TEMPLATE.backgroundColor;
  }
};

const getTextContent = (
  violationCount: number,
  showUnknown: boolean,
  riskLevel: keyof EnumMap<typeof RiskLevel>
) => {
  if (showUnknown) {
    return "Unknown";
  } else if (violationCount) {
    return RISK_LEVEL_TEMPLATES[riskLevel].bodyText;
  } else {
    return SAFE_TEMPLATE.bodyText;
  }
};

const StyledHeading = styled.h3`
  font-size: ${theme.fontSizes.displaymd};
`;

const StyledSubHeading = styled.h2`
  font-size: ${theme.fontSizes.sm};
  margin-bottom: ${SPECIFIC_SPACING["12px"]};
`;

const StyledThreatBlock = styled.div`
  @media screen and (min-width: 768px) {
    max-width: 280px;
  }

  color: ${COLOR.OFF_WHITE};
  height: ${APPLICATION_SPACING(24)};
  padding: 0 1.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ThreatBlock: FunctionComponent<IThreatBlock> = ({
  violationCount,
  riskLevel,
  className,
  showUnknown,
}) => (
  <StyledThreatBlock
    className={className}
    style={{
      backgroundColor: getBlockBackgroundColor(
        violationCount,
        showUnknown,
        riskLevel
      ),
    }}
  >
    <StyledSubHeading>Threat Level</StyledSubHeading>
    <StyledHeading>
      {getTextContent(violationCount, showUnknown, riskLevel)}
    </StyledHeading>
  </StyledThreatBlock>
);
