import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RiskActionsSummaryQueryVariables = Types.Exact<{
  from?: Types.InputMaybe<Types.Scalars["Date"]>;
  to?: Types.InputMaybe<Types.Scalars["Date"]>;
}>;

export type RiskActionsSummaryQuery = {
  __typename?: "RootQueryType";
  bulkActionsSummary?:
    | {
        __typename?: "BulkActionsSummary";
        slackRedactMessage?: number | null | undefined;
        gdriveRevokeFilePermissions?: number | null | undefined;
        resolveRisk?: number | null | undefined;
      }
    | null
    | undefined;
};

export const RiskActionsSummaryDocument = gql`
  query RiskActionsSummary($from: Date, $to: Date) {
    bulkActionsSummary(from: $from, to: $to) {
      slackRedactMessage
      gdriveRevokeFilePermissions
      resolveRisk
    }
  }
`;

export function useRiskActionsSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<RiskActionsSummaryQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<RiskActionsSummaryQuery>({
    query: RiskActionsSummaryDocument,
    ...options,
  });
}
