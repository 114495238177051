import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetExposureSummaryForAtomQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"];
  riskId: Types.Scalars["ID"];
}>;

export type GetExposureSummaryForAtomQuery = {
  __typename?: "RootQueryType";
  getLabelledAtomById?:
    | {
        __typename?: "LabelledAtom";
        id?: string | null | undefined;
        createdAt?: any | null | undefined;
        exposure?:
          | {
              __typename?: "AtomExposure";
              id?: string | null | undefined;
              isPublic?: boolean | null | undefined;
              internalViewerCount?: number | null | undefined;
              externalViewerCount?: number | null | undefined;
              score?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  getDataPointSummaryForRisk?:
    | Array<
        | {
            __typename?: "RiskDataPointSummary";
            level?: number | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export const GetExposureSummaryForAtomDocument = gql`
  query GetExposureSummaryForAtom($id: ID!, $riskId: ID!) {
    getLabelledAtomById(id: $id) {
      id
      exposure {
        id
        isPublic
        internalViewerCount
        externalViewerCount
        score
      }
      createdAt
    }
    getDataPointSummaryForRisk(id: $riskId) {
      level
    }
  }
`;

export function useGetExposureSummaryForAtomQuery(
  options: Omit<
    Urql.UseQueryArgs<GetExposureSummaryForAtomQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetExposureSummaryForAtomQuery>({
    query: GetExposureSummaryForAtomDocument,
    ...options,
  });
}
