export function RevokeAccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.66688 12.2224C9.73513 12.2224 12.2224 9.73513 12.2224 6.66688C12.2224 3.59863 9.73513 1.11133 6.66688 1.11133C3.59863 1.11133 1.11133 3.59863 1.11133 6.66688C1.11133 9.73513 3.59863 12.2224 6.66688 12.2224Z"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.11133 6.6665H12.2224"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66656 1.11133C8.05616 2.63264 8.84587 4.6069 8.88878 6.66688C8.84587 8.72686 8.05616 10.7011 6.66656 12.2224C5.27696 10.7011 4.48725 8.72686 4.44434 6.66688C4.48725 4.6069 5.27696 2.63264 6.66656 1.11133V1.11133Z"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_533_2688)">
        <path
          d="M8.44482 10.2222V8.44439C8.44482 7.85502 8.67895 7.28979 9.0957 6.87304C9.51245 6.45629 10.0777 6.22217 10.667 6.22217C11.2564 6.22217 11.8216 6.45629 12.2384 6.87304C12.6551 7.28979 12.8893 7.85502 12.8893 8.44439V10.2222"
          fill="#EDEEF5"
        />
        <path
          d="M8.44482 10.2222V8.44439C8.44482 7.85502 8.67895 7.28979 9.0957 6.87304C9.51245 6.45629 10.0777 6.22217 10.667 6.22217C11.2564 6.22217 11.8216 6.45629 12.2384 6.87304C12.6551 7.28979 12.8893 7.85502 12.8893 8.44439V10.2222"
          stroke="#58469B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7781 10.2222H7.55588C7.06496 10.2222 6.66699 10.6201 6.66699 11.1111V14.2222C6.66699 14.7131 7.06496 15.1111 7.55588 15.1111H13.7781C14.269 15.1111 14.667 14.7131 14.667 14.2222V11.1111C14.667 10.6201 14.269 10.2222 13.7781 10.2222Z"
          fill="#EDEEF5"
          stroke="#58469B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <line
        x1="10.8335"
        y1="12.1665"
        x2="10.8335"
        y2="13.1665"
        stroke="#58469B"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_533_2688">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(5.3335 5.3335)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
