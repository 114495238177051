import React from "react";

import { groupBy } from "ramda";

import { RedactIcon } from "../../atoms/icons/RedactIcon";
import { ResolveRiskIcon } from "../../atoms/icons/ResolveRiskIcon";
import { RevokeAccessIcon } from "../../atoms/icons/RevokeAccessIcon";
import { Popover } from "../../components/Popover";
import {
  PotentialUnsetRiskCommand,
  RiskActionCommandEnumExtended,
} from "../../features/BulkActions/definitions";
import {
  LabelledAtom,
  Maybe,
  Risk,
  RiskActionCommandEnum,
} from "../../generated/graphql.generated";
import { capitalize } from "../../utils/string/capitalize";

const RiskActionMap: {
  [key: `${string}:${string}`]: {
    label: string;
    actions: (RiskActionCommandEnum | RiskActionCommandEnumExtended)[];
  };
} = {
  "gdrive:File": {
    actions: [
      RiskActionCommandEnum.GdriveRevokeFilePermissions,
      RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsInternal,
      RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsPublic,
      RiskActionCommandEnum.ResolveRisk,
    ],
    label: "Google Drive Files",
  },
  "slack:Message": {
    actions: [
      RiskActionCommandEnum.SlackRedactMessage,
      RiskActionCommandEnum.ResolveRisk,
    ],
    label: "Slack Messages",
  },
};

export const RiskActionDescriptionLabelMap = {
  [RiskActionCommandEnum.GdriveRevokeFilePermissions]: "files",
  [RiskActionCommandEnum.SlackRedactMessage]: "messages",
  [RiskActionCommandEnum.ResolveRisk]: "risks",
};

type RiskActionIconMap = {
  [key in RiskActionCommandEnum]: React.ReactNode;
};

export const RiskActionCommandIconMap: RiskActionIconMap = {
  [RiskActionCommandEnum.GdriveRevokeFilePermissions]: (
    <Popover content="Revoke Sharing">
      <RevokeAccessIcon />
    </Popover>
  ),
  [RiskActionCommandEnum.ResolveRisk]: (
    <Popover content="Resolve Risk">
      <ResolveRiskIcon />
    </Popover>
  ),
  [RiskActionCommandEnum.SlackRedactMessage]: (
    <Popover content="Redact Message">
      <RedactIcon />
    </Popover>
  ),
};

type ActionLabelMapType = {
  [key in PotentialUnsetRiskCommand]: string;
};

type ActionMetaMapType = {
  [key in PotentialUnsetRiskCommand]?: {
    [key: string]: any;
  };
};

const ActionLabelMap: ActionLabelMapType = {
  [RiskActionCommandEnum.GdriveRevokeFilePermissions]: "Revoke all sharing",
  [RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsInternal]:
    "Revoke internal sharing",
  [RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsPublic]:
    "Revoke public sharing",
  [RiskActionCommandEnum.SlackRedactMessage]: "Redact message",
  [RiskActionCommandEnum.ResolveRisk]: "Resolve risk",
  UNSELECTED: "",
};

export const ActionMetaMap: ActionMetaMapType = {
  [RiskActionCommandEnum.GdriveRevokeFilePermissions]: {
    revokeInternalSharing: true,
    revokePublicSharing: true,
  },
  [RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsInternal]: {
    revokeInternalSharing: true,
    revokePublicSharing: false,
  },
  [RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsPublic]: {
    revokeInternalSharing: false,
    revokePublicSharing: true,
  },
};

export const getTypeKeyFromAtom = (
  atom?: Maybe<LabelledAtom>
): `${string}:${string}` => {
  if (atom) {
    const { appType, entityType } = atom;
    return `${appType}:${entityType}`;
  } else {
    return ":";
  }
};

export const getAvailableActions = (risk: Risk) =>
  RiskActionMap[getTypeKeyFromAtom(risk.atom)]?.actions ?? [
    RiskActionCommandEnum.ResolveRisk,
  ];

export const getAvailableActionsByNormalisedId = (key: `${string}:${string}`) =>
  RiskActionMap[key]?.actions ?? [RiskActionCommandEnum.ResolveRisk];

export const getLabelForAction = (action: PotentialUnsetRiskCommand) =>
  ActionLabelMap[action] ?? action;

const groupByAppTypeAndEntityTypeKey = groupBy((risk: Risk) =>
  getTypeKeyFromAtom(risk.atom)
);

export const bucketRiskByType = (risks: Risk[]) =>
  groupByAppTypeAndEntityTypeKey(risks);

export const getRiskLabelByKey = (key: `${string}:${string}`) =>
  RiskActionMap[key]?.label ??
  `${capitalize(key.split(":")[0])} ${capitalize(key.split(":")[1])}`;

export const getAppTypeByKey = (key: `${string}:${string}`) =>
  key.split(":")[0];
