import React, { PropsWithChildren, useMemo } from "react";
import { createContext } from "react";

import {
  HeaderGroup,
  Row,
  TablePropGetter,
  TableProps,
  TableToggleAllRowsSelectedProps,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useTable,
} from "react-table";
import { ListItem } from "react-virtuoso";

import { Checkbox } from "../../../atoms/form/Checkbox";
import { LayoutContainer } from "../../layout/LayoutContainer";
import { DefaultRowType, IGenericTable } from "./definitions";

type ITableContext<T extends DefaultRowType> = {
  getTableProps: (propGetter?: TablePropGetter<T> | undefined) => TableProps;
  headerGroups: HeaderGroup<T>[];
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  selectedFlatRows: Row<T>[];
  getToggleAllRowsSelectedProps?: (
    props?: Partial<TableToggleAllRowsSelectedProps> | undefined
  ) => TableToggleAllRowsSelectedProps;
  toggleAllRowsSelected?: (value?: boolean | undefined) => void;
  onVirtualisedItemsChange?: (items: ListItem<Row<T>>[]) => void;
};

export const TableContext = createContext<ITableContext<DefaultRowType>>(
  undefined!
);

export const useTableContext = <T extends DefaultRowType>() => {
  const context = React.useContext<ITableContext<T>>(
    TableContext as unknown as React.Context<ITableContext<T>>
  );
  if (!context) {
    throw new Error(
      "useTableContext must be used within a TableContextProvider"
    );
  }
  return context;
};

export const TableProvider = <T extends DefaultRowType>({
  children,
  columns,
  autoResetExpanded,
  data,
  SelectableContextMenu,
  selectable,
  canSelectAllRows,
  onVirtualisedItemsChange,
  stopCheckboxPropagation,
}: PropsWithChildren<
  Pick<
    IGenericTable<T>,
    | "autoResetExpanded"
    | "SelectableContextMenu"
    | "selectable"
    | "canSelectAllRows"
    | "data"
    | "onVirtualisedItemsChange"
    | "stopCheckboxPropagation"
  > &
    Required<Pick<IGenericTable<T>, "columns" | "data">>
>) => {
  const memodColumns = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    toggleAllRowsSelected,
  } = useTable<T>(
    {
      columns: memodColumns,
      data,
      autoResetExpanded: autoResetExpanded,
      autoResetSelectedRows: false,
    },
    useExpanded,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      selectable &&
        hooks.columns.push((columns) => [
          {
            id: "selection",
            width: 40,
            disableResizing: true,
            ...(SelectableContextMenu && !canSelectAllRows
              ? {
                  Header: (props) => {
                    return (
                      props.selectedFlatRows.length > 0 && (
                        <SelectableContextMenu />
                      )
                    );
                  },
                }
              : {}),

            ...(!SelectableContextMenu && canSelectAllRows
              ? {
                  Header: (props) => {
                    const { onChange, checked } =
                      props.getToggleAllRowsSelectedProps();

                    return (
                      <Checkbox
                        stopPropagation={stopCheckboxPropagation}
                        checked={!!checked}
                        onChange={
                          (checked) =>
                            onChange &&
                            onChange({
                              target: { checked },
                            } as any) // TODO: Relevant issue: https://github.com/radix-ui/primitives/issues/734
                        }
                      />
                    );
                  },
                }
              : {}),

            Cell: ({ row }: { row: any }) => {
              const { checked, onChange } = row.getToggleRowSelectedProps();

              return (
                <LayoutContainer
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Checkbox
                    stopPropagation={stopCheckboxPropagation}
                    checked={checked}
                    onChange={(checked) => onChange({ target: { checked } })} // TODO: Relevant issue: https://github.com/radix-ui/primitives/issues/734
                  />
                </LayoutContainer>
              );
            },
          },
          ...columns,
        ]);
    }
  );

  const value: ITableContext<T> = {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    toggleAllRowsSelected,
    onVirtualisedItemsChange,
  };

  return (
    <TableContext.Provider value={value as any}>
      {children}
    </TableContext.Provider>
  );
};
