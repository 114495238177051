export function RedactIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9998 2.6665H5.33317L0.666504 7.99984L5.33317 13.3332H13.9998C14.3535 13.3332 14.6926 13.1927 14.9426 12.9426C15.1927 12.6926 15.3332 12.3535 15.3332 11.9998V3.99984C15.3332 3.64622 15.1927 3.30708 14.9426 3.05703C14.6926 2.80698 14.3535 2.6665 13.9998 2.6665V2.6665Z"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6L8 10"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6L12 10"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
