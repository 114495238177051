import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";

import { curveMonotoneX } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { scalePoint, scaleLinear } from "@visx/scale";
import { AreaClosed } from "@visx/shape";
import { LinePath } from "@visx/shape";
import * as d3 from "d3";
import { format } from "date-fns";
import { animated, useSpring, useTrail } from "react-spring";
import styled from "styled-components";

import { Heading } from "../../atoms/Heading";
import { ViolationHistory } from "../../definitions/types/violations";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { debounce } from "../../utils/functions/debounce";
import { Box } from "../Box";
import { LayoutContainer } from "../layout/LayoutContainer";

interface ILineData {
  total: number;
  date: string;
}

const StyledLineChartContainer = styled(LayoutContainer)`
  &:before {
    content: "";
    width: 200px;
    height: 1px;
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 50%;
    margin-left: -100px;
    background-color: ${COLOR.LINE};
  }

  @media screen and (min-width: 768px) {
    &:before {
      content: "";
      height: 138px;
      width: 1px;
      position: absolute;
      z-index: 1;
      left: -1px;
      top: 25px;
      margin-left: 0;
      background-color: ${COLOR.LINE};
    }
  }
`;

const StyledLineChartSVG = styled.svg`
  max-width: 100%;
`;

const StyledToolTipContainer = styled.div`
  position: absolute;
  pointer-events: none;
  transform: translateY(-100%);
  z-index: 50;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(1)};
`;

export const SimplifiedLineChart = ({
  graphData,
  height = 192,
  count,
}: {
  count?: number;
  graphData?: ViolationHistory[];
  height?: number;
}) => {
  const [lineNode, setLineNode] = useState<SVGPathElement>();
  const [lineLength, setLineLength] = useState<number>(0);

  const graphRootNode = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = React.useState({
    width: 0,
  });

  const [tooltip, setTooltip] = useState({
    visible: false,
    data: {} as ILineData,
  });

  const lineRef = useCallback((node) => {
    if (node !== null) {
      setLineNode(node);
    }
  }, []);

  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const { offsetChartHeight, offsetChartWidth, stackableChartLayout } =
    useMemo(() => {
      const stackableChartLayout = { top: 20, right: 0, bottom: 0, left: 0 };

      const offsetChartWidth =
        dimensions.width -
        stackableChartLayout.left -
        stackableChartLayout.right;

      const offsetChartHeight =
        height - stackableChartLayout.top - stackableChartLayout.bottom;

      return {
        offsetChartHeight,
        offsetChartWidth,
        stackableChartLayout,
      };
    }, [dimensions.width, height]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: graphRootNode.current?.clientWidth || 0,
      });
    }, 500);

    setDimensions({
      width: graphRootNode.current?.clientWidth || 0,
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [graphRootNode]);

  const [lineSpring, lineSpringApi] = useSpring(() => ({
    config: { duration: 1500, easing: d3.easeQuadInOut },
    value: lineLength,
    opacity: 0,
  }));

  const [areaSpring, areaSpringApi] = useSpring(() => ({
    config: { duration: 300, easing: d3.easeQuadInOut },
    opacity: 0,
  }));

  const [circleTrail, circleTrailApi] = useTrail(10, () => ({
    radius: 0,
    config: { duration: 150, easing: d3.easeQuadInOut },
  }));

  useEffect(() => {
    areaSpringApi.stop();
    lineSpringApi.stop();
    circleTrailApi.stop();

    areaSpringApi.start({
      opacity: 0,
      from: { opacity: 1 },
      config: { duration: 0 },
    });

    lineSpringApi.start({
      value: 0,
      opacity: 1,
      from: { value: lineLength, opacity: 0 },
    });

    circleTrailApi.start({
      radius: 3,
      from: { radius: 0 },
    });

    areaSpringApi.start({
      opacity: 1,
      from: { opacity: 0 },
      delay: 1300,
      config: { duration: 300 },
    });
  }, [lineLength, lineSpringApi, areaSpringApi, circleTrailApi]);

  useLayoutEffect(() => {
    if (lineNode) {
      setLineLength(lineNode?.getTotalLength());
    }
  }, [lineNode, dimensions.width, graphData]);

  const lineData: ILineData[] = graphData
    ? graphData.map((d) => {
        return {
          total: Object.values(d.violations)
            .filter((value) => typeof value !== "string")
            .reduce((total, current) => current + total, 0),
          date: d.date,
        };
      })
    : [];

  const max = graphData
    ? Math.max(
        ...graphData.map((d) =>
          Object.values(d.violations)
            .filter((value) => typeof value !== "string")
            .reduce((total, current) => current + total, 0)
        )
      )
    : 0;

  const xAxis = scalePoint({
    range: [0, offsetChartWidth],
    domain: lineData.map((d) => format(new Date(d.date), "do MMM")),
  });

  const yAxis = scaleLinear({
    range: [
      offsetChartHeight + stackableChartLayout.top,
      stackableChartLayout.top,
    ],
    domain: [0, max],
  });

  return (
    <StyledLineChartContainer
      onMouseMove={(e) => {
        const clientRect = graphRootNode.current?.getBoundingClientRect() || {
          right: 0,
        };
        const windowOffset = clientRect.right - (e.nativeEvent.clientX + 144);
        const exceedsWindow = windowOffset < 0;
        setTooltipPosition({
          x: exceedsWindow
            ? e.nativeEvent.offsetX + windowOffset
            : e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
        });
      }}
      ref={graphRootNode}
      width="100%"
      position="relative"
    >
      {count !== undefined && (
        <LayoutContainer color="BODY" fontSize="lg" mt={1}>
          {count}
        </LayoutContainer>
      )}
      <StyledLineChartSVG
        overflow="visible"
        width={dimensions.width}
        height={height}
      >
        {graphData && (
          <>
            <LinearGradient
              id="area-gradient"
              from="#F3F5F9"
              to="#E3E2EF"
              x1={0}
              y1={1}
              y2={0.1}
              x2={0}
            />
            <AreaClosed<ILineData>
              data={lineData}
              x={(d) => xAxis(format(new Date(d.date), "do MMM")) ?? 0}
              y={(d) => yAxis(d.total)}
              yScale={yAxis}
              curve={curveMonotoneX}
            >
              {({ path }) => {
                const d = path(lineData) || "";

                return (
                  <animated.path
                    style={areaSpring}
                    d={d}
                    fill="url(#area-gradient)"
                    strokeOpacity={lineSpring.opacity}
                    shapeRendering="geometricPrecision"
                  />
                );
              }}
            </AreaClosed>
            <Group>
              <LinePath<ILineData>
                curve={curveMonotoneX}
                data={lineData}
                x={(d) => xAxis(format(new Date(d.date), "do MMM")) ?? 0}
                y={(d) => yAxis(d.total) ?? 0}
              >
                {({ path }) => {
                  const d = path(lineData) || "";

                  return (
                    <animated.path
                      d={d}
                      ref={lineRef}
                      fill="none"
                      stroke="#58469B"
                      strokeWidth={1.5}
                      strokeOpacity={lineSpring.opacity}
                      shapeRendering="geometricPrecision"
                      strokeDasharray={lineLength}
                      strokeDashoffset={lineSpring.value}
                    />
                  );
                }}
              </LinePath>
              {lineData.map((d, i) => (
                <animated.circle
                  key={i}
                  r={circleTrail[i].radius}
                  cx={xAxis(format(new Date(d.date), "do MMM")) ?? 0}
                  cy={yAxis(d.total) ?? 0}
                  stroke="rgba(33,33,33,0.5)"
                  strokeOpacity={0}
                  strokeWidth={10}
                  fill="#9B90C3"
                  onMouseOver={() => {
                    setTooltip({
                      visible: true,
                      data: d,
                    });
                  }}
                  onMouseOut={() => {
                    setTooltip({
                      visible: false,
                      data: {} as ILineData,
                    });
                  }}
                />
              ))}
            </Group>
          </>
        )}
      </StyledLineChartSVG>

      {tooltip.visible && (
        <StyledToolTipContainer
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
          }}
        >
          <Box p={1} width={APPLICATION_SPACING(18)}>
            <StyledHeading Element="h4" type="text-sm">
              {format(new Date(tooltip.data.date), "do MMM")}
            </StyledHeading>{" "}
            <LayoutContainer as="span" fontSize="sm">
              {tooltip.data.total} Violations
            </LayoutContainer>
          </Box>
        </StyledToolTipContainer>
      )}
    </StyledLineChartContainer>
  );
};
