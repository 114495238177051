import React, { useEffect } from "react";

import { Row } from "react-table";
import styled from "styled-components";
import { useQuery } from "urql";

import { ButtonOutlinePrimary, ReactRouterLink } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { GenericBoxRowTable } from "../../components/tables/themes/GenericBoxRowTable";
import { IMonitor } from "../../definitions/types/monitors";
import { sendAnalytics } from "../../operations/user/analytics";
import { UIFilterState } from "../../recoil/filters/atom";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { MonitorViolationsList } from "../MonitorViolationsList";
import { columns } from "./columns";
import { RULE_OVERVIEW_LIST_QUERY } from "./query";

interface ISearchVariables {
  filters?: UIFilterState;
}

interface IOverviewRuleListQuery {
  activeRules: IMonitor[];
  allRules: string[];
}

const StyledCreateRuleButton = styled(ButtonOutlinePrimary)`
  display: inline-flex;
  margin-top: ${APPLICATION_SPACING(1)};
  text-transform: uppercase;
  align-items: center;
`;

const ExpanderWithAnalytics = ({ row }: { row: Row<IMonitor> }) => {
  useEffect(() => {
    if (row.isExpanded) {
      sendAnalytics("VIOLATION: Expanded Detections", undefined);
    }
  }, [row.isExpanded]);

  return <MonitorViolationsList id={row.original.id} limit={8} />;
};

const StyledNoResultsContainer = styled.div`
  min-height: calc(100vh - 23rem);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${APPLICATION_SPACING(4)};
`;

const StyledInnerNoResultsContainer = styled.div`
  max-width: 750px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${({ theme }) => theme.colors.LABEL};
`;

const StyledHeadingNoColor = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${({ theme }) => theme.colors.BODY};
`;

export const RuleOverviewList = ({ filters }: ISearchVariables) => {
  const [result, executeQuery] = useQuery<IOverviewRuleListQuery>({
    query: RULE_OVERVIEW_LIST_QUERY,
    requestPolicy: "network-only",
  });

  useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(
        () => executeQuery({ requestPolicy: "network-only" }),
        30000
      );
      return () => clearTimeout(id);
    }
  }, [result.fetching, executeQuery]);

  const { data, fetching, error } = result;

  const ruleArray = data?.allRules;

  const results = data?.activeRules || [];

  const hasNoRules = Boolean(
    ruleArray && ruleArray.length === 0 && (!fetching || data)
  );

  return hasNoRules ? (
    <StyledNoResultsContainer>
      <StyledInnerNoResultsContainer>
        <StyledHeadingNoColor Element="h1" type="display-small">
          You currently have no active rules.
          <br />
          To see where you could be exposed, please create your first rule.
        </StyledHeadingNoColor>
        <StyledCreateRuleButton
          as={ReactRouterLink}
          to={APPLICATION_ROUTES.RULES.NESTED_ROUTES?.CREATE.url || ""}
        >
          Create Rule
        </StyledCreateRuleButton>
      </StyledInnerNoResultsContainer>
    </StyledNoResultsContainer>
  ) : (
    <GenericBoxRowTable
      noResults={
        <StyledNoResultsContainer>
          <StyledInnerNoResultsContainer>
            <StyledHeading Element="h1" type="display-small">
              You have no active rules or violations at the moment
            </StyledHeading>
          </StyledInnerNoResultsContainer>
        </StyledNoResultsContainer>
      }
      loadingSpinner={
        <StyledNoResultsContainer>
          <StyledInnerNoResultsContainer>
            <LoadingSpinner />
          </StyledInnerNoResultsContainer>
        </StyledNoResultsContainer>
      }
      error={error}
      loading={fetching && !data}
      clickWholeRowToExpand
      data={results}
      columns={columns}
      expanded={(row) => <ExpanderWithAnalytics row={row} />}
      theme={{}}
    />
  );
};
