import { API_URL } from "../../config";
import { AUTH0_AUDIENCE } from "../../config.auth0";
import { checkStatus } from "../../utils/fetch/checkStatus";
import { isEmptyObject } from "../../utils/object/isEmptyObject";

export const INTEGRATION_SETUP_URL = `${API_URL}/integrations/`;
export const INSTALLATION_SETUP_URL = `${API_URL}/integrations/installations/`;
export const AUTHENTICATION_URL = `${API_URL}/authenticate`;

interface IIntegrationAuthFlow {
  getAccessToken: Function;
  appType: string;
  setIsLoading: (state: boolean) => void;
  setIsError: (error: string) => void;
}

interface IInstallationReauthenticate {
  getAccessToken: Function;
  installationId: string;
  setIsLoading: (state: boolean) => void;
  setIsError: (error: string) => void;
}

interface ISubmitIntegrationStep {
  getAccessToken: Function;
  installationId: string;
  setIsError: (error: string) => void;
  stepNumber: number;
  stepPayload?: Record<string, any>;
}

interface IGetCurrentIntegrationStep {
  getAccessToken: Function;
  installationId: string;
  setIsError: (error: string) => void;
}

interface IGetCurrentIntegrationConfig {
  getAccessToken: Function;
  installationId: string;
  setIsError: (error: string) => void;
  setIsLoading: (state: boolean) => void;
}

interface ISetCurrentIntegrationConfig {
  getAccessToken: Function;
  installationId: string;
  setIsError: (error: string) => void;
  setIsLoading: (state: boolean) => void;
  stepPayload?: Record<string, any>;
}

const startInstallation = async ({
  getAccessToken,
  setIsError,
  setIsLoading,
  appType,
}: IIntegrationAuthFlow) => {
  try {
    setIsLoading(true);

    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(`${INTEGRATION_SETUP_URL}${appType}/setup`, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    checkStatus(response);

    const json = await response.json();

    setIsLoading(false);

    return json;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      setIsLoading(false);
      throw errorMessage;
    }
  }
};

const reauthenticateInstallation = async ({
  getAccessToken,
  setIsError,
  setIsLoading,
  installationId,
}: IInstallationReauthenticate) => {
  try {
    setIsLoading(true);

    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/re-authenticate`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    checkStatus(response);

    const json = await response.json();

    setIsLoading(false);

    return json;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      setIsLoading(false);
      throw errorMessage;
    }
  }
};

const submitInstallationStep = async ({
  getAccessToken,
  setIsError,
  installationId,
  stepNumber,
  stepPayload,
}: ISubmitIntegrationStep) => {
  try {
    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/step/${stepNumber}`,
      stepPayload && !isEmptyObject(stepPayload)
        ? {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(stepPayload),
          }
        : {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
    );

    checkStatus(response);

    const json = await response.json();

    return json;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      throw errorMessage;
    }
  }
};

const getInstallationStepDetails = async ({
  getAccessToken,
  setIsError,
  installationId,
  stepNumber,
}: ISubmitIntegrationStep) => {
  try {
    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/step/${stepNumber}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    checkStatus(response);

    const json = await response.json();

    return json;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      throw errorMessage;
    }
  }
};

const getCurrentInstallationStep = async ({
  getAccessToken,
  setIsError,
  installationId,
}: IGetCurrentIntegrationStep) => {
  try {
    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/current-step`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    checkStatus(response);

    const json = await response.json();

    return json;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      throw errorMessage;
    }
  }
};

const getInstallationConfig = async ({
  getAccessToken,
  setIsLoading,
  setIsError,
  installationId,
}: IGetCurrentIntegrationConfig) => {
  try {
    setIsLoading(true);

    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/config`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    checkStatus(response);

    const json = await response.json();

    setIsLoading(false);

    return json.data;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      setIsLoading(false);
      throw errorMessage;
    }
  }
};

const setInstallationConfig = async ({
  getAccessToken,
  setIsLoading,
  setIsError,
  installationId,
  stepPayload,
}: ISetCurrentIntegrationConfig) => {
  try {
    setIsLoading(true);

    const authToken = await getAccessToken({ audience: AUTH0_AUDIENCE });

    const response = await fetch(
      `${INSTALLATION_SETUP_URL}${installationId}/config`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stepPayload),
      }
    );

    checkStatus(response);

    const json = await response.json();

    setIsLoading(false);

    return json.data;
  } catch (error) {
    let errorMessage: string = "Sorry, something went wrong";
    try {
      // TODO: Typescript 4.4 patch
      const errorWithJson = error as any;
      const { message } = await errorWithJson.json();

      errorMessage = message;
    } catch (error) {
    } finally {
      setIsError(errorMessage);
      setIsLoading(false);
      throw errorMessage;
    }
  }
};

export const INTEGRATION_API = {
  getCurrentInstallationStep,
  getInstallationStepDetails,
  submitInstallationStep,
  startInstallation,
  reauthenticateInstallation,
  getInstallationConfig,
  setInstallationConfig,
};
