import React, { FunctionComponent, HTMLAttributes } from "react";

import { ErrorBoundary } from "react-error-boundary";
import { AlertTriangle } from "react-feather";
import styled from "styled-components";
import { useQuery } from "urql";

import { ButtonOutlinePrimary } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { IUser } from "../../definitions/types/users";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { GET_ME_QUERY } from "../Navigation/queries";
import { UserProfileBlock } from "../UserProfileBlock";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IOnboardingPageHeaderWrapper extends HTMLAttributes<HTMLDivElement> {}

export interface IMeData {
  me: IUser;
}

const StyledHeader = styled.header`
  height: 65px;
  display: flex;
  flex: 1;
  color: ${COLOR.PRIMARY_DARK_MODE};
  position: relative;
  width: 100%;
  top: 0;
  z-index: 40;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(3)};
`;

const StyledAlertIcon = styled(AlertTriangle)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${COLOR.ERROR};
`;

const StyledErrorBoundaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - var(--logo-height));
`;

const StyledUserProfileBlockContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(3)};
  border-color: rgba(250, 250, 250, 0.1);
  color: rgba(160, 163, 189, 0.5);
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: calc((150px - 20px) / 2);
  height: 50px;
  height: 65px;
  position: relative;
  text-align: left;
  margin-left: auto;
`;

export const OnboardingPageHeaderWrapper: FunctionComponent<
  IOnboardingPageHeaderWrapper
> = ({ className, children }) => {
  const [response] = useQuery({ query: GET_ME_QUERY });

  const { data } = response;
  const user = data?.me;

  return (
    <LayoutContainer position="relative">
      <StyledHeader>
        <LayoutContainer display="flex" ml="auto" alignItems="center">
          <StyledUserProfileBlockContainer>
            <UserProfileBlock {...user} />
          </StyledUserProfileBlockContainer>
        </LayoutContainer>
      </StyledHeader>
      <LayoutContainer
        minHeight="calc(100vh - 65px)"
        display="flex"
        flexDirection="column"
      >
        {" "}
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <StyledErrorBoundaryContainer role="alert">
              <StyledAlertIcon size={60} />
              <StyledHeading Element="h1" type="display-medium" isBodyColor>
                Something went wrong
              </StyledHeading>
              <ButtonOutlinePrimary
                onClick={() => {
                  resetErrorBoundary();
                }}
              >
                Try Again
              </ButtonOutlinePrimary>
            </StyledErrorBoundaryContainer>
          )}
        >
          {children}
        </ErrorBoundary>
      </LayoutContainer>
    </LayoutContainer>
  );
};
