import { useState } from "react";

export interface IPaginatedTableState {
  cursor: string;
  limit: number;
  shouldShowFullLoader: boolean;
  suppressLoader?: boolean;
}

export const usePaginatedTableState = (initialState: IPaginatedTableState) =>
  useState<IPaginatedTableState>(initialState);
