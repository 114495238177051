import React from "react";

import styled from "styled-components";

import { Heading } from "../../../atoms/Heading";
import { OnboardingPageHeaderWrapper } from "../../../components/OnboardingPageHeaderWrapper/OnboardingPageHeaderWrapper";
import { LayoutContainer, P } from "../../../components/layout/LayoutContainer";
import { Integrations } from "../../../features/Integrations";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { OnboardingBack } from "../components/OnboardingBack";
import { OnboardingNext } from "../components/OnboardingNext";

const StyledHeading = styled(Heading)`
  padding-bottom: ${APPLICATION_SPACING(2)};
`;

export const OnboardingIntegrating = () => {
  const routeId = "integrating";

  return (
    <OnboardingPageHeaderWrapper>
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LayoutContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="75%"
          maxWidth="850px"
        >
          <StyledHeading Element="h1" type="display-extra-small" isBodyColor>
            Integrating with your cloud stack
          </StyledHeading>
          <P pb={8} fontSize="sm" textAlign="center" fontWeight={100 as any}>
            Before we can start scanning and discovering in your cloud stack, we
            need you to follow a few steps and integrate with your chosen
            integrations
          </P>
        </LayoutContainer>
      </LayoutContainer>
      <Integrations isOnboarding>
        {({ installations }) => (
          <LayoutContainer display="flex" pt={3} pb={4} px={4} mt="auto">
            {installations && installations?.length > 0 && (
              <LayoutContainer display="flex" alignItems="center" ml="auto">
                <LayoutContainer mr={2}>
                  <OnboardingBack currentId={routeId} />
                </LayoutContainer>
                <OnboardingNext currentId={routeId} />
              </LayoutContainer>
            )}
          </LayoutContainer>
        )}
      </Integrations>
    </OnboardingPageHeaderWrapper>
  );
};
