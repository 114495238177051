import { FunctionComponent, useMemo } from "react";

import { generatePath, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useQuery, CombinedError } from "urql";

import { Heading } from "../../atoms/Heading";
import { GenericTable } from "../../components/tables/GenericTable";
import { ITableTheme } from "../../components/tables/GenericTable/definitions";
import { TABLE_THEMES } from "../../components/tables/themes";
import { IMonitor } from "../../definitions/types/monitors";
import { RULE_LIST_BY_DATE_QUERY } from "../../operations/reports/queries/ruleListByDate";
import { ICriticalityDropdownState } from "../../page/Overview";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { columns } from "./columns";

interface IRuleWithViolationsList {
  loading: boolean;
  error?: CombinedError;
  filters: {
    dateRange: {
      from: Date;
      to: Date;
    };
  };
  filterByCriticality: ICriticalityDropdownState;
}

const StyledNoResultsContainer = styled.div`
  min-height: 150px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${APPLICATION_SPACING(4)};
`;

const StyledInnerNoResultsContainer = styled.div`
  max-width: 750px;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${({ theme }) => theme.colors.LABEL};
`;

const tableTheme: ITableTheme = {
  table: css`
    height: 100%;
  `,
  row: css`
    border-width: 1px;
    border-color: ${COLOR.TABLE_BORDER};
    margin-bottom: ${APPLICATION_SPACING(2)};
    &:hover {
      background-color: #edeef5;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `,
  cell: css`
    height: 55px;
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  `,
  selectedRowStyles: css`
    background-color: #edeef5;
  `,
};

export const RowWrapper: FunctionComponent<IMonitor> = ({ id, children }) => {
  return (
    <Link
      to={generatePath(
        APPLICATION_ROUTES.RULES.NESTED_ROUTES?.DETAIL.url || "",
        {
          monitorId: id,
        }
      )}
    >
      {children}
    </Link>
  );
};

export const RulesViolatedOverTime = ({
  loading,
  error,
  filters,
  filterByCriticality,
}: IRuleWithViolationsList) => {
  const { dateRange } = filters;

  const [violationsResult] = useQuery({
    query: RULE_LIST_BY_DATE_QUERY,
    variables: dateRange,
  });

  const results = useMemo(() => {
    if (filterByCriticality === "ALL") {
      return violationsResult?.data?.listMonitorsOverTime;
    } else {
      return violationsResult?.data?.listMonitorsOverTime?.filter(
        (rule: IMonitor) => rule.riskLevel === filterByCriticality
      );
    }
  }, [filterByCriticality, violationsResult.data]);

  return (
    <GenericTable
      clickWholeRowToExpand
      noResults={
        <StyledNoResultsContainer>
          <StyledInnerNoResultsContainer>
            <StyledHeading Element="h1" type="text-large">
              No Violated Rules In Period
            </StyledHeading>
          </StyledInnerNoResultsContainer>
        </StyledNoResultsContainer>
      }
      error={error}
      loading={(loading && !results) || violationsResult.fetching}
      data={results}
      columns={columns}
      theme={TABLE_THEMES.NO_HEADER_NO_GAP_TABLE}
      themeOverrides={tableTheme}
      RowContainer={RowWrapper}
    />
  );
};
