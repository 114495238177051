import { Column } from "react-table";
import styled, { css } from "styled-components";

import { GIClassifier } from "../../features/ConnectedTitledDoughnutGraph/queries/overview";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { IDoughnutGraphData } from "../charts/DoughnutChart";

export interface IGIClassifierLegend extends GIClassifier {
  color: string;
  count: number;
  onLegendItemClick?: (data: IDoughnutGraphData) => void;
}

const StyledContainer = styled.div`
  border-radius: 9999px;
  width: ${APPLICATION_SPACING(1)};
  height: ${APPLICATION_SPACING(1)};
  margin-right: ${APPLICATION_SPACING(1)};
`;

export const columns: Column<IGIClassifierLegend>[] = [
  {
    Header: "Data-type",
    accessor: "name",
    width: 150,
    minWidth: 50,
    maxWidth: 50,
    Cell: (data) => (
      <>
        <StyledContainer
          style={{ backgroundColor: data.row.original.color }}
        ></StyledContainer>
        {data.cell.value}
      </>
    ),
  },
  {
    Header: "#",
    accessor: "count",
    headerStyles: css`
      text-align: center;
    `,
    columnStyles: css`
      justify-content: center;
    `,
    width: 20,
    minWidth: 20,
    maxWidth: 20,
  },
];
