import React, { FunctionComponent } from "react";

import { X } from "react-feather";
import styled from "styled-components";

import { ButtonSmall, IButton } from "../../atoms/Button";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { Box } from "../Box";
import { HeadedTextArea } from "../filters/HeadedTextArea";
import { HeadedTextInput } from "../filters/HeadedTextInput";
import { LayoutContainer } from "../layout/LayoutContainer";

export const StyledCloseButton = styled(ButtonSmall)`
  font-size: ${theme.fontSizes.xsm};
  position: absolute;
  top: ${APPLICATION_SPACING(3)};
  right: ${APPLICATION_SPACING(3)};
  color: ${COLOR.PLACEHOLDER};
`;

export const StyledModalBox = styled(Box)`
  padding: ${APPLICATION_SPACING(4)};
  width: 24rem;
  max-width: 100%;
`;

export const StyledWideModalBox = styled(Box)`
  padding: ${APPLICATION_SPACING(4)};
  max-width: 100%;
  width: 560px;
`;

export const StyledErrorContainer = styled(LayoutContainer)`
  border-width: 1px;
  border-color: ${COLOR.ERROR};
`;

export const StyledTextInput = styled(HeadedTextInput)`
  width: 100%;
  padding: ${SPECIFIC_SPACING["12px"]};
  font-size: ${theme.fontSizes.xsm};
  background: ${COLOR.INPUT_BACKGROUND};
`;

export const StyledTextArea = styled(HeadedTextArea)`
  width: 100%;
  font-size: ${theme.fontSizes.xsm};
  background: ${COLOR.INPUT_BACKGROUND};
  margin-bottom: ${APPLICATION_SPACING(2)};
`;

export const LargeStyledTextArea = styled(StyledTextArea)`
  height: 90px;
`;

export const CloseButton: FunctionComponent<Omit<IButton, "$isWhiteLoader">> = (
  props
) => (
  <StyledCloseButton {...props}>
    <X size={22} />
  </StyledCloseButton>
);
