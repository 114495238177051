import mixpanel from "mixpanel-browser";

import { CLASSIFIER_ANALYTICS } from "./definitions/classifier";
import { DATAPOINT_ANALYTICS } from "./definitions/datapoint";
import { DETECTION_ANALYTICS } from "./definitions/detection";
import { INSPECTOR_ANALYTICS } from "./definitions/inspector";
import { REPORT_ANALYTICS } from "./definitions/report";
import { RULE_ANALYTICS } from "./definitions/rule";
import { SAVED_QUERY_ANALYTICS } from "./definitions/saved-query";
import { USER_ANALYTICS } from "./definitions/users";
import { VIOLATION_ANALYTICS } from "./definitions/violation";
import { WEBHOOK_ANALYTICS } from "./definitions/webhook";

// TODO: MVP. Obviously this object definition is a little verbose

export const ANALYTICS_PAYLOAD = {
  ...VIOLATION_ANALYTICS,
  ...RULE_ANALYTICS,
  ...CLASSIFIER_ANALYTICS,
  ...REPORT_ANALYTICS,
  ...WEBHOOK_ANALYTICS,
  ...USER_ANALYTICS,
  ...INSPECTOR_ANALYTICS,
  ...DATAPOINT_ANALYTICS,
  ...DETECTION_ANALYTICS,
  ...SAVED_QUERY_ANALYTICS,
};

export const sendAnalytics = <T extends keyof typeof ANALYTICS_PAYLOAD>(
  key: T,
  payload: Parameters<typeof ANALYTICS_PAYLOAD[T]>[0]
) => {
  try {
    mixpanel.track(key, ANALYTICS_PAYLOAD[key](payload as any));
  } catch (error) {
    console.log("Mixpanel failed to track");
  }
};
