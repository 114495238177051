import { useState } from "react";

import {
  PlusCircle as PlusCircleIcon,
  MinusCircle as MinusCircleIcon,
  ChevronRight,
} from "react-feather";
import { Column } from "react-table";
import styled, { css } from "styled-components";

import { PrimarySquareButton } from "../../atoms/Button";
import { Checkbox } from "../../atoms/form/Checkbox";
import {
  calculateRiskLevelForPercentage,
  RiskDot,
} from "../../atoms/icons/RiskDot";
import { Popover } from "../../components/Popover";
import { VendorIcon } from "../../components/VendorIcons";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { useTableContext } from "../../components/tables/GenericTable/TableProvider";
import { Risk, RiskGroup } from "../../generated/graphql.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { defaultTo } from "../../utils/maybe/defaultTo";
import BulkActionsModal from "../BulkActions/BulkActionsModal";

const ExpandedTableRowCheckbox = () => {
  const { getToggleAllRowsSelectedProps } = useTableContext<Risk>();

  const toggleAllRows =
    getToggleAllRowsSelectedProps && getToggleAllRowsSelectedProps();

  return (
    <Checkbox
      stopPropagation
      checked={!!toggleAllRows?.checked}
      onChange={
        (checked) =>
          toggleAllRows?.onChange &&
          toggleAllRows?.onChange({
            target: { checked },
          } as any) // TODO: Relevant issue: https://github.com/radix-ui/primitives/issues/734
      }
    />
  );
};

const ExpandedBulkActionsButtonWithModal = () => {
  const [showBulkActions, setShowBulkActions] = useState(false);
  const { selectedFlatRows, toggleAllRowsSelected } = useTableContext<Risk>();

  return (
    <>
      <Popover
        disabled={!!selectedFlatRows.length}
        content="Please select some risks to action"
      >
        <PrimarySquareButton
          disabled={!selectedFlatRows.length}
          onClick={(event) => {
            if (selectedFlatRows.length) {
              event.stopPropagation();
              setShowBulkActions(true);
            }
          }}
        >
          Bulk Actions
        </PrimarySquareButton>
      </Popover>

      <BulkActionsModal
        showModal={showBulkActions}
        toggleModal={() => setShowBulkActions((s) => !s)}
        selectedRisks={selectedFlatRows.map(({ original }) => original)}
        onSubmit={() => {
          toggleAllRowsSelected && toggleAllRowsSelected(false);
        }}
      />
    </>
  );
};

const StyledSpan = styled.span`
  color: ${COLOR.PRIMARY};
  font-size: ${theme.fontSizes.xsm};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
`;

export const columns: Column<RiskGroup>[] = [
  {
    Header: () => null,
    accessor: "appType",
    width: 430,
    columnStyles: css`
      padding-left: 1.5rem;
      padding-right: ${APPLICATION_SPACING(3)};
    `,
    Cell: (data) => (
      <LayoutContainer display="flex" alignItems="center">
        <LayoutContainer as="span" color="LABEL" mr={4}>
          {data.row.isExpanded ? (
            <LayoutContainer display="flex" alignItems="center">
              <LayoutContainer mr="1.25rem">
                <MinusCircleIcon />
              </LayoutContainer>
              <ExpandedTableRowCheckbox />
            </LayoutContainer>
          ) : (
            <PlusCircleIcon />
          )}
        </LayoutContainer>
        <VendorIcon vendor={defaultTo(data.cell.value, "")} />
        <LayoutContainer ml={3}>
          {data.row.original.description}
        </LayoutContainer>
      </LayoutContainer>
    ),
  },
  {
    Header: () => null,
    accessor: "cumulativeSdf",
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
    `,
    Cell: (data) => {
      return (
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="sm"
        >
          <RiskDot
            sdf={{
              level: calculateRiskLevelForPercentage(
                defaultTo(data.cell.value, 0)
              ),
            }}
          />
          {data.cell.value}
        </LayoutContainer>
      );
    },
  },
  {
    Header: () => null,
    id: "bulk actions",
    accessor: "cumulativeSdf",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    headerStyles: css`
      text-align: center;
    `,
    columnStyles: css`
      justify-content: center;
      align-items: center;
      padding-right: 0;
    `,
    disableResizing: true,
    Cell: ({ row }: { row: any }) => {
      return !row.isExpanded ? (
        <StyledSpan>
          Bulk Actions
          <LayoutContainer ml={"0.1rem"}>
            <ChevronRight size={16} />
          </LayoutContainer>
        </StyledSpan>
      ) : (
        <ExpandedBulkActionsButtonWithModal />
      );
    },
  },
];
