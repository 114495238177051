import React from "react";

import styled from "styled-components";

import { ImageNode } from "../../../../definitions/types/integration";
import { APPLICATION_SPACING } from "../../../../styling/spacing";

const StyledImage = styled.img`
  margin-bottom: ${APPLICATION_SPACING(3)};
`;

export const IntegrationNodeImage = (node: ImageNode) => (
  <StyledImage src={node.config.src} alt="" />
);
