import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

import { ButtonPillPrimary } from "../../../atoms/Button";
import { ConfigNodes } from "../../../definitions/types/integration";
import { INTEGRATION_API } from "../../../operations/integrations/api";
import { useToast } from "../../../recoil/toasts/provider";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { isEmptyObject } from "../../../utils/object/isEmptyObject";
import { Box } from "../../Box";
import { LayoutContainer } from "../../layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "../BaseModal";
import { CloseButton } from "../shared";
import { IntegrationNodeFactory } from "./factories/IntegrationNodeFactory";

export interface IIntegrationConfigModalData {
  nodes: ConfigNodes[];
  installationId?: string;
}

export interface IIntegrationConfigModalComponent extends IModalBase {
  data?: IIntegrationConfigModalData;
  setModalData: (data: IIntegrationConfigModalData) => void;
  refetchInstallations: () => void;
}

interface IIntegrationStepAction {
  installationId?: string;
  getAccessToken: Function;
  setIsLoading: (state: boolean) => void;
  setIsError: (error: string) => void;
  setModalData: (data: IIntegrationConfigModalData) => void;
  setStepPayload: Dispatch<SetStateAction<any>>;
  toggleModal: (item: any) => void;
  stepPayload: Record<string, any>;
  refetchInstallations: () => void;
}

const setInstallationConfig = async (
  integrationArgs: IIntegrationStepAction
) => {
  const {
    setIsLoading,
    setIsError,
    setStepPayload,
    toggleModal,
    refetchInstallations,
    getAccessToken,
    stepPayload,
    installationId,
  } = integrationArgs;

  if (!installationId) return;

  setIsLoading(true);

  try {
    await INTEGRATION_API.setInstallationConfig({
      setIsLoading,
      setIsError,
      getAccessToken,
      stepPayload,
      installationId,
    });

    setStepPayload({});
    toggleModal({});
    refetchInstallations();
  } catch (error) {}

  setIsLoading(false);

  return;
};

const setInitialValues = (data?: IIntegrationConfigModalData) => {
  if (data && data.nodes) {
    const values: Record<string, any> = {};

    data?.nodes.forEach((node) => {
      if (
        node.type === "input" &&
        node.config.type === "multi-select" &&
        node.config.currentValue
      ) {
        values[node.id] = node.config.currentValue.map((item) => item.value);
      } else if (node.type === "input" && node.config.currentValue) {
        values[node.id] = node.config.currentValue;
      }
    });

    return values;
  } else {
    return {};
  }
};

const StyledConfigBox = styled(Box)`
  width: 960px;
  height: 100%;
  padding: ${APPLICATION_SPACING(4)} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

const StyledInnerModalContainer = styled.div`
  flex: 1;
  padding-top: 1.75rem;
  width: 100%;
  height: 100%;
`;

const IntegrationConfigModal = ({
  refetchInstallations,
  showModal,
  toggleModal,
  setModalData,
  data,
}: IIntegrationConfigModalComponent) => {
  const addToast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const setError = (message: string) => {
    addToast({
      type: "error",
      message,
    });
  };

  const [stepPayload, setStepPayload] = useState<Record<string, any>>(
    setInitialValues(data)
  );

  useEffect(() => {
    setStepPayload(setInitialValues(data));
  }, [data]);

  return (
    <ModalWrapper fullHeight showModal={showModal} toggleModal={toggleModal}>
      {!isEmptyObject(data) && (
        <StyledConfigBox>
          <CloseButton onClick={() => toggleModal()} />
          <StyledInnerModalContainer>
            <LayoutContainer
              width="100%"
              height="100%"
              px={4}
              overflow="auto"
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="center"
            >
              {data?.nodes ? (
                data?.nodes.map((node) =>
                  IntegrationNodeFactory(node, setStepPayload, stepPayload)
                )
              ) : (
                <LayoutContainer fontSize="sm">
                  <p>
                    Sorry, we're having trouble fetching configuration details
                    for this integration right now
                  </p>
                </LayoutContainer>
              )}
              {data?.nodes && data?.nodes.length > 0 && (
                <LayoutContainer mt={data?.nodes && data?.nodes.length ? 1 : 0}>
                  <ButtonPillPrimary
                    smallLoader
                    loading={loading}
                    onClick={() => {
                      if (data?.installationId) {
                        setInstallationConfig({
                          refetchInstallations,
                          installationId: data.installationId,
                          getAccessToken: getAccessTokenSilently,
                          setIsLoading: setLoading,
                          setIsError: setError,
                          stepPayload,
                          setStepPayload,
                          setModalData,
                          toggleModal,
                        });
                      }
                    }}
                  >
                    Save Changes
                  </ButtonPillPrimary>
                </LayoutContainer>
              )}
            </LayoutContainer>
          </StyledInnerModalContainer>
        </StyledConfigBox>
      )}
    </ModalWrapper>
  );
};

export default IntegrationConfigModal;
