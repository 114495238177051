import { gql } from "urql";

import {
  DefinitionType,
  RunExtendedMutationPayload,
} from "../../../hooks/useExtendedMutation";
import { LeaveSdfFeedbackForRiskMutationVariables } from "./leaveSdfFeedback.generated";
import { success, reject } from "./tasks";

type PayloadType =
  RunExtendedMutationPayload<LeaveSdfFeedbackForRiskMutationVariables>;

export interface LeaveSDFFeedbackDefinition extends DefinitionType {
  tasks: {
    success: ({ riskId }: { riskId: string }) => PayloadType;
    reject: ({
      riskId,
      comments,
    }: {
      riskId: string;
      comments: string;
    }) => PayloadType;
  };
}

export const LEAVE_SDF_FEEDBACK: LeaveSDFFeedbackDefinition = {
  definition: gql`
    mutation LeaveSDFFeedbackForRisk(
      $approved: Boolean!
      $comments: String
      $riskId: String
    ) {
      leaveSDFFeedback(
        approved: $approved
        comments: $comments
        riskId: $riskId
      ) {
        success
      }
    }
  `,
  tasks: {
    success,
    reject,
  },
};
