import { useMemo, useCallback } from "react";

import {
  UseMutationResponse,
  TypedDocumentNode,
  CombinedError,
  OperationResult,
} from "urql";

import { IToast } from "../../recoil/toasts/atom";
import { merge } from "../../utils/object/merge";
import { ANALYTICS_PAYLOAD } from "../user/analytics";

type MutationData<G> = G extends UseMutationResponse<infer S, any> ? S : never;

type MutationVariables<G> = G extends UseMutationResponse<any, infer S>
  ? S
  : never;

export type AnalyticsPayload<T extends keyof typeof ANALYTICS_PAYLOAD> = [
  T,
  Parameters<typeof ANALYTICS_PAYLOAD[T]>[0]
];

export const getAnalyticsPayload = <T extends keyof typeof ANALYTICS_PAYLOAD>(
  payload: AnalyticsPayload<T>
): AnalyticsPayload<T> => payload;

export type RunExtendedMutationPayload<V> = {
  update: Partial<V>;
  successToast?: IToast;
  analytics?: AnalyticsPayload<keyof typeof ANALYTICS_PAYLOAD>;
};

export type DefinitionType = Readonly<{
  definition: TypedDocumentNode;
  tasks: Tasks;
}>;

export type Tasks = Readonly<{
  [key: string]: (args: any) => RunExtendedMutationPayload<any>;
}>;

interface EmptyDefinition extends DefinitionType {
  tasks: {};
}

type EmptyDefTasks = {
  [Key in keyof EmptyDefinition["tasks"]]: (
    ...args: Parameters<EmptyDefinition["tasks"][Key]>
  ) => Promise<any>;
};

interface UseExtendedMutation<
  K extends UseMutationResponse<any, any>,
  D extends DefinitionType
> {
  error?: CombinedError;
  fetching: boolean;
  run(): [D] extends EmptyDefinition
    ? EmptyDefTasks
    : D extends DefinitionType
    ? {
        [Key in keyof D["tasks"]]: (
          ...args: Parameters<D["tasks"][Key]>
        ) => Promise<OperationResult<MutationData<K>, MutationVariables<K>>>;
      }
    : EmptyDefTasks;
  run(
    args?: RunExtendedMutationPayload<MutationVariables<K>>
  ): Promise<OperationResult<MutationData<K>, MutationVariables<K>>>;
}

const emptyDefinition: EmptyDefinition = {
  definition: "" as any,
  tasks: {},
};

export function useExtendedMutation<
  K extends UseMutationResponse<any, any>,
  A extends keyof typeof ANALYTICS_PAYLOAD,
  D extends DefinitionType = EmptyDefinition
>(
  hook: () => K,
  {
    DEFAULT_SUCCESS_TOAST,
    initialState,
    defaultAnalytics,
    definition = emptyDefinition as any,
  }: {
    DEFAULT_SUCCESS_TOAST?: IToast;
    initialState: Partial<MutationVariables<K>>;
    defaultAnalytics?: [A, Parameters<typeof ANALYTICS_PAYLOAD[A]>[0]];
    definition?: D;
  }
): UseExtendedMutation<K, typeof definition> {
  const [mutationState, runMutation] = hook();

  const extendedTasks = useMemo(() => {
    const wrappedTasks = Object.entries(definition.tasks).map((task) => {
      const [taskName, taskFunc] = task;

      return {
        [taskName]: (args: Parameters<typeof taskFunc>) => {
          const { update, successToast, analytics } = taskFunc(args);

          return runMutation(merge(initialState, update), {
            toastOnSuccess: successToast || DEFAULT_SUCCESS_TOAST,
            ananlyticsOnSuccess: analytics || defaultAnalytics,
          }).then((data) => data);
        },
      };
    });

    return Object.assign({}, ...wrappedTasks);
  }, [
    DEFAULT_SUCCESS_TOAST,
    defaultAnalytics,
    definition.tasks,
    initialState,
    runMutation,
  ]);

  const run = useCallback(
    (args?: RunExtendedMutationPayload<MutationVariables<K>>): any => {
      if (args) {
        const { update, successToast, analytics } = args;

        return runMutation(merge(initialState, update), {
          toastOnSuccess: successToast || DEFAULT_SUCCESS_TOAST,
          ananlyticsOnSuccess: analytics || defaultAnalytics,
        }).then((data) => data);
      } else {
        if (definition) {
          return extendedTasks;
        }
      }
    },
    [
      DEFAULT_SUCCESS_TOAST,
      defaultAnalytics,
      initialState,
      runMutation,
      extendedTasks,
      definition,
    ]
  );

  return {
    error: mutationState.error,
    fetching: mutationState.fetching,
    run,
  };
}
