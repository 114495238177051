import { FunctionComponent } from "react";

import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IManageUsersTableRow } from "./columns";

export const UserRowContainer: FunctionComponent<IManageUsersTableRow> = ({
  children,
  status,
}) => {
  const isPendingInvitation = status === "PENDING_INVITE_ACCEPTANCE";
  return (
    <LayoutContainer color={isPendingInvitation ? "PLACEHOLDER" : undefined}>
      {children}
    </LayoutContainer>
  );
};
