import React, { FunctionComponent } from "react";

import { Redirect, useLocation } from "react-router";

interface IMonitorRuleRedirect {}

export const MonitorRuleRedirect: FunctionComponent<
  IMonitorRuleRedirect
> = () => {
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: location.pathname.replace("/monitor", "/rules"),
        search: location.search,
      }}
    />
  );
};
