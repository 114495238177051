import React, {
  useState,
  createContext,
  FunctionComponent,
  useCallback,
} from "react";

import { IConfirmModal } from "../../components/modals/ConfirmModal";
import { IIntegrationConfigModalData } from "../../components/modals/Integration/IntegrationConfigModal";
import { IIntegrationModal } from "../../components/modals/Integration/IntegrationModal";
import { ISecretKeyModal } from "../../components/modals/SecretKeyModal";
import { IMonitor } from "../../definitions/types/monitors";

interface IModalProvider {
  editMonitorModal: {
    visible: boolean;
    toggleVisible: (data?: IMonitor) => void;
    data?: IMonitor;
  };
  inviteUserModal: {
    visible: boolean;
    toggleVisible: () => void;
  };

  saveQueryModal: {
    visible: boolean;
    toggleVisible: () => void;
  };
  confirmModal: {
    visible: boolean;
    data?: IConfirmModal;
    toggleConfirm: (data?: IConfirmModal) => void;
  };
  secretKeyModal: {
    visible: boolean;
    data?: ISecretKeyModal;
    toggle: (data?: ISecretKeyModal) => void;
  };
  integrationModal: {
    visible: boolean;
    data?: IIntegrationModal;
    toggle: (data?: IIntegrationModal) => void;
    setVisible: (data?: IIntegrationModal) => void;
    setModalData: (data: IIntegrationModal) => void;
  };
  integrationConfigModal: {
    visible: boolean;
    data?: IIntegrationConfigModalData;
    toggle: (data?: IIntegrationConfigModalData) => void;
    setVisible: (data?: IIntegrationConfigModalData) => void;
    setModalData: (data: IIntegrationConfigModalData) => void;
  };
}

const ModalContext = createContext({} as IModalProvider);

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

const ModalProvider: FunctionComponent = ({ children }) => {
  const [editMonitorModalState, setEditMonitorModal] = useState({
    visible: false,
    data: {} as IMonitor,
  });

  const [editInviteUserModalState, setInviteUserModal] = useState({
    visible: false,
  });

  const [saveQueryModalState, setSaveQueryModal] = useState({
    visible: false,
  });

  const [confirmModalState, setConfirmModal] = useState({
    visible: false,
    data: {} as IConfirmModal,
  });

  const [secretKeyModalState, setSecretKeyModalState] = useState({
    visible: false,
    data: {} as ISecretKeyModal,
  });

  const [integrationModalState, setIntegrationModal] = useState({
    visible: false,
    data: {} as IIntegrationModal,
  });

  const [integrationConfigModalState, setIntegrationConfigModal] = useState({
    visible: false,
    data: {} as IIntegrationConfigModalData,
  });

  const toggleShowMonitorModal = useCallback(
    (data?: IMonitor) => {
      setEditMonitorModal((s) => ({
        ...s,
        visible: !s.visible,
        data: data ? data : s.data,
      }));
    },
    [setEditMonitorModal]
  );

  const toggleInviteUserModal = useCallback(() => {
    setInviteUserModal((s) => ({
      ...s,
      visible: !s.visible,
    }));
  }, [setInviteUserModal]);

  const toggleSaveQueryModal = useCallback(() => {
    setSaveQueryModal((s) => ({
      ...s,
      visible: !s.visible,
    }));
  }, [setSaveQueryModal]);

  const toggleConfirmModal = useCallback(
    (data?: IConfirmModal) => {
      setConfirmModal((s) => ({
        ...s,
        visible: s.visible ? false : true,
        data: data ? data : s.data,
      }));
    },
    [setConfirmModal]
  );

  const toggleSecretKeyModal = useCallback(
    (data?: ISecretKeyModal) => {
      setSecretKeyModalState((s) => ({
        ...s,
        visible: s.visible ? false : true,
        data: data ? data : ({} as ISecretKeyModal),
      }));
    },
    [setSecretKeyModalState]
  );

  const toggleIntegrationModal = useCallback(
    (data?: IIntegrationModal) => {
      setIntegrationModal((s) => ({
        ...s,
        visible: s.visible ? false : true,
        data: data ? data : s.data,
      }));
    },
    [setIntegrationModal]
  );

  const integrationModalVisible = useCallback(
    (data?: IIntegrationModal) => {
      setIntegrationModal((s) =>
        s.visible
          ? s
          : {
              ...s,
              visible: true,
              data: data ? data : s.data,
            }
      );
    },
    [setIntegrationModal]
  );

  const setIntegrationModalData = useCallback(
    (data: IIntegrationModal) => {
      setIntegrationModal((s) => ({
        ...s,
        data,
      }));
    },
    [setIntegrationModal]
  );

  const toggleIntegrationConfigModal = useCallback(
    (data?: IIntegrationConfigModalData) => {
      setIntegrationConfigModal((s) => ({
        ...s,
        visible: s.visible ? false : true,
        data: data ? data : s.data,
      }));
    },
    [setIntegrationConfigModal]
  );

  const integrationConfigModalVisible = useCallback(
    (data?: IIntegrationConfigModalData) => {
      setIntegrationConfigModal((s) =>
        s.visible
          ? s
          : {
              ...s,
              visible: true,
              data: data ? data : s.data,
            }
      );
    },
    [setIntegrationConfigModal]
  );

  const setIntegrationConfigModalData = useCallback(
    (data: IIntegrationConfigModalData) => {
      setIntegrationConfigModal((s) => ({
        ...s,
        data,
      }));
    },
    [setIntegrationConfigModal]
  );

  const modals = {
    editMonitorModal: {
      visible: editMonitorModalState.visible,
      toggleVisible: toggleShowMonitorModal,
      data: editMonitorModalState.data,
    },
    inviteUserModal: {
      visible: editInviteUserModalState.visible,
      toggleVisible: toggleInviteUserModal,
    },
    saveQueryModal: {
      visible: saveQueryModalState.visible,
      toggleVisible: toggleSaveQueryModal,
    },
    confirmModal: {
      visible: confirmModalState.visible,
      toggleConfirm: toggleConfirmModal,
      data: confirmModalState.data,
    },
    secretKeyModal: {
      visible: secretKeyModalState.visible,
      toggle: toggleSecretKeyModal,
      data: secretKeyModalState.data,
    },
    integrationModal: {
      visible: integrationModalState.visible,
      toggle: toggleIntegrationModal,
      setModalData: setIntegrationModalData,
      setVisible: integrationModalVisible,
      data: integrationModalState.data,
    },

    integrationConfigModal: {
      visible: integrationConfigModalState.visible,
      toggle: toggleIntegrationConfigModal,
      setModalData: setIntegrationConfigModalData,
      setVisible: integrationConfigModalVisible,
      data: integrationConfigModalState.data,
    },
  };

  return (
    <ModalContext.Provider value={modals}>{children}</ModalContext.Provider>
  );
};

export { ModalProvider, useModal };
