import {
  MonitorRiskLevel,
  RiskSdfLevelEnum,
} from "../../generated/graphql.generated";
import { COLOR } from "../../styling/colors";

export enum RiskLevel {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}

type MonitorRiskLevelToRiskSdfLevelMap = {
  [key in MonitorRiskLevel]: RiskSdfLevelEnum;
};
type MonitorRiskLevelToRiskLevelMap = { [key in MonitorRiskLevel]: RiskLevel };

export const monitorRiskLevelToRiskSdfLevel: MonitorRiskLevelToRiskSdfLevelMap =
  {
    [MonitorRiskLevel.Low]: RiskSdfLevelEnum.Low,
    [MonitorRiskLevel.Medium]: RiskSdfLevelEnum.Medium,
    [MonitorRiskLevel.High]: RiskSdfLevelEnum.High,
    [MonitorRiskLevel.Critical]: RiskSdfLevelEnum.Critical,
  };

export const monitorRiskLevelToRiskLevel: MonitorRiskLevelToRiskLevelMap = {
  [MonitorRiskLevel.Low]: RiskLevel.LOW,
  [MonitorRiskLevel.Medium]: RiskLevel.MEDIUM,
  [MonitorRiskLevel.High]: RiskLevel.HIGH,
  [MonitorRiskLevel.Critical]: RiskLevel.CRITICAL,
};

export const SAFE_TEMPLATE = {
  backgroundColor: "#22A6B3",
  bodyText: "Safe",
};

export const RISK_LEVEL_TEMPLATES = {
  [RiskLevel.CRITICAL]: {
    backgroundColor: COLOR.SDF_CRITICAL,
    bodyText: "Critical",
  },
  [RiskLevel.MEDIUM]: {
    backgroundColor: COLOR.WARNING,
    bodyText: "Medium",
  },
  [RiskLevel.HIGH]: {
    backgroundColor: "#F28C2D",
    bodyText: "High",
  },
  [RiskLevel.LOW]: {
    backgroundColor: "#3F809B",
    bodyText: "Low",
  },
};
