import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";
import { createContext } from "react";

export const ContextMenuContext = createContext<{
  visible: boolean;
  setVisibility: Dispatch<SetStateAction<boolean>>;
}>(undefined!);

export const useContextMenuControl = () => {
  const context = React.useContext(ContextMenuContext);
  if (!context) {
    throw new Error(
      "useContextControl must be used within a ContextMenuProvider"
    );
  }
  return context;
};

export const ContextMenuProvider: FunctionComponent = ({ children }) => {
  const [visible, setVisibility] = useState(false);

  const value = { visible, setVisibility };

  return (
    <ContextMenuContext.Provider value={value}>
      {children}
    </ContextMenuContext.Provider>
  );
};
