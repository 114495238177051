import React from "react";

import { Link } from "react-router-dom";

import { TextButtonSimple } from "../../../atoms/Button";
import { onboardingRoutes } from "../../../routing/child/onboarding/routes";
import { defaultTo } from "../../../utils/maybe/defaultTo";
import { getCurrentRouteIndex } from "./OnboardingNext";

interface IOnboardingBack {
  currentId: string;
  text?: string;
}

export const canShowBackOnboardingButton = (currentId: string) =>
  getCurrentRouteIndex(currentId) > 0;

export const getPrevRouteIndex = (currentId: string) =>
  getCurrentRouteIndex(currentId) - 1;

export const getPrevRoute = (currentId: string) =>
  onboardingRoutes[getPrevRouteIndex(currentId)];

export const OnboardingBack = ({ currentId, text }: IOnboardingBack) => {
  const prevRoute = getPrevRoute(currentId);

  if (canShowBackOnboardingButton(currentId)) {
    return (
      <TextButtonSimple as={Link} to={defaultTo(prevRoute.path, "") as string}>
        {text ? text : "Back"}
      </TextButtonSimple>
    );
  } else {
    return null;
  }
};
