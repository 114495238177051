import React, { FunctionComponent } from "react";

import primaryLogoSVG from "../../assets/logo/logo-primary.svg";
import logoSVG from "../../assets/logo/logo-white.svg";

const LOGO_WIDTH = "100";
const LOGO_ALT = "Metomic";

interface ILogo {
  primary?: boolean;
  className?: string;
  width?: number;
}

export const Logo: FunctionComponent<ILogo> = ({
  primary,
  className,
  width,
}) => (
  <img
    className={className}
    src={primary ? primaryLogoSVG : logoSVG}
    style={{ width: `${width ? width : LOGO_WIDTH}px` }}
    alt={LOGO_ALT}
  />
);
