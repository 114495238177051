import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BulkCreateRiskActionMutationVariables = Types.Exact<{
  actions?: Types.InputMaybe<
    Array<Types.RiskActionCommandInput> | Types.RiskActionCommandInput
  >;
}>;

export type BulkCreateRiskActionMutation = {
  __typename?: "RootMutationType";
  bulkCreateRiskAction?:
    | {
        __typename?: "RiskActionCommandResult";
        success?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const BulkCreateRiskActionDocument = gql`
  mutation BulkCreateRiskAction($actions: [RiskActionCommandInput!]) {
    bulkCreateRiskAction(actions: $actions) {
      success
    }
  }
`;

export function useBulkCreateRiskActionMutation() {
  return Urql.useMutation<
    BulkCreateRiskActionMutation,
    BulkCreateRiskActionMutationVariables
  >(BulkCreateRiskActionDocument);
}
