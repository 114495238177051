const fontSizes = [
  "0.625rem",
  "0.75rem",
  "0.775rem",
  "0.875rem",
  "1rem",
  "1.125rem",
  "1.5rem",
  "2.2rem",
  "2.5rem",
];

export default fontSizes;
