import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { useIntegrationMeta } from "../../operations/integrations/hooks/useIntegrationMeta";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { Popover } from "../Popover";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IMiniVendorIcon {
  className?: string;
  name?: string;
  iconUrl?: string;
  width?: string;
}

interface IVendorIcons {
  vendors: string[];
  width?: string;
}

interface IVendorIcon {
  vendor: string;
  width?: string;
  className?: string;
}

const StyledVendorIcon = styled(LayoutContainer)`
  border-radius: 9999px;
`;

const StyledImage = styled.img`
  padding: ${SPECIFIC_SPACING["4px"]};
  width: 100%;
`;

const MiniVendorIcon = (props: IMiniVendorIcon) => (
  <StyledVendorIcon
    width={props.width || "1.75rem"}
    height={props.width || "1.75rem"}
    display="flex"
    justifyContent="center"
    alignItems="center"
    className={props.className}
  >
    <Popover content={props.name}>
      <StyledImage alt={props.name} src={props.iconUrl} />
    </Popover>
  </StyledVendorIcon>
);

const StyledMiniVendorIcon = styled(MiniVendorIcon)`
  margin-right: ${SPECIFIC_SPACING["4px"]};
`;

export const VendorIcons: FunctionComponent<IVendorIcons> = ({
  vendors,
  width,
}) => {
  const { getIntegrationMeta } = useIntegrationMeta();
  return (
    <LayoutContainer display="flex">
      {vendors.map((vendor, i, arr) => (
        <StyledMiniVendorIcon
          key={vendor}
          {...getIntegrationMeta(vendor)}
          width={width}
        />
      ))}
    </LayoutContainer>
  );
};

export const VendorIcon: FunctionComponent<IVendorIcon> = ({
  vendor,
  width,
  className,
}) => {
  const { getIntegrationMeta } = useIntegrationMeta();
  return (
    <LayoutContainer className={className} display="flex">
      <MiniVendorIcon
        key={vendor}
        {...getIntegrationMeta(vendor)}
        width={width}
      />
    </LayoutContainer>
  );
};
