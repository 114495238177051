import { Maybe } from "../../generated/graphql.generated";

export const getValueOrEmpty = (item?: Maybe<string>) => item || "";

const isString = (item: Maybe<string>): item is string => {
  return !!item;
};

export const getMaybeArrayStringValue = (items?: Maybe<Maybe<string>[]>) =>
  items ? items.filter(isString) : [];
