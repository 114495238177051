import React, { FunctionComponent } from "react";

import { Globe, Lock, Users } from "react-feather";

import { AtomExposure } from "../../generated/graphql.generated";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IAtomSharingFigure extends AtomExposure {
  column?: boolean;
}

const getSizedIcon = (isLarge?: boolean) => (isLarge ? 26 : 18);

function getIcon(exposure: AtomExposure, column?: boolean) {
  switch (true) {
    case exposure.isPublic:
      return <Globe size={getSizedIcon(column)} />;
    case defaultTo(exposure.externalViewerCount, 0) > 0 ||
      defaultTo(exposure.internalViewerCount, 0) > 0:
      return <Users size={getSizedIcon(column)} />;
    default:
      return <Lock size={getSizedIcon(column)} />;
  }
}

function getCopy(exposure: AtomExposure, column?: boolean) {
  switch (true) {
    case exposure.isPublic:
      return (
        <LayoutContainer
          fontSize="lg"
          width="100%"
          display="flex"
          justifyContent={column ? "center" : "auto"}
        >
          <LayoutContainer
            fontSize={column ? "sm" : "xsm"}
            mr={SPECIFIC_SPACING["4px"]}
          >
            Public
          </LayoutContainer>
        </LayoutContainer>
      );
    case defaultTo(exposure.externalViewerCount, 0) > 0:
      return (
        <LayoutContainer display="flex">
          <LayoutContainer mr={1}>
            {defaultTo(exposure.externalViewerCount, 0) +
              defaultTo(exposure.internalViewerCount, 0)}
          </LayoutContainer>
          <LayoutContainer color="LABEL">(Shared externally)</LayoutContainer>
        </LayoutContainer>
      );
    case defaultTo(exposure.internalViewerCount, 0) > 0:
      return (
        <LayoutContainer display="flex" alignItems="center">
          <LayoutContainer mr={1}>
            {defaultTo(exposure.internalViewerCount, 0)}
          </LayoutContainer>{" "}
          <LayoutContainer fontSize="xsm" color="LABEL">
            (Shared internally)
          </LayoutContainer>
        </LayoutContainer>
      );
    default:
      return <>private</>;
  }
}

export const AtomSharingFigure: FunctionComponent<IAtomSharingFigure> = ({
  isPublic,
  internalViewerCount,
  externalViewerCount,
  column,
}) => (
  <LayoutContainer
    display="flex"
    alignItems="center"
    justifyContent={column ? "center" : "normal"}
    flexDirection={column ? "column" : "row"}
    width="100%"
  >
    <LayoutContainer mr={column ? 0 : 1} color="PRIMARY">
      {getIcon({ isPublic, internalViewerCount, externalViewerCount }, column)}
    </LayoutContainer>
    <LayoutContainer
      fontSize={column ? "sm" : "xsm"}
      display="flex"
      alignItems="center"
      flex={1}
    >
      {getCopy({ isPublic, internalViewerCount, externalViewerCount })}
    </LayoutContainer>
  </LayoutContainer>
);
