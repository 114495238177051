import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";
import { FullRiskFieldsFragmentDoc } from "../fragments/fullRiskFields.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetRisksQueryVariables = Types.Exact<{
  afterCursor?: Types.InputMaybe<Types.Scalars["String"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  appTypes?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.Scalars["String"]>>
    | Types.InputMaybe<Types.Scalars["String"]>
  >;
  term?: Types.InputMaybe<Types.Scalars["String"]>;
  riskGroupId?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type GetRisksQuery = {
  __typename?: "RootQueryType";
  listRisks?:
    | {
        __typename?: "RiskConnection";
        edges?:
          | Array<
              | {
                  __typename?: "RiskEdge";
                  node?:
                    | {
                        __typename?: "Risk";
                        id?: string | null | undefined;
                        atomId?: string | null | undefined;
                        sdf?: number | null | undefined;
                        sdfLevel?: Types.RiskSdfLevelEnum | null | undefined;
                        createdAt?: any | null | undefined;
                        sharedAt?: any | null | undefined;
                        shortCode?: string | null | undefined;
                        isDirty?: boolean | null | undefined;
                        atom?:
                          | {
                              __typename?: "LabelledAtom";
                              appType?: string | null | undefined;
                              entityType?: string | null | undefined;
                              entityId?: string | null | undefined;
                              location?:
                                | {
                                    __typename?: "AtomLocation";
                                    label?: string | null | undefined;
                                    deeplink?: string | null | undefined;
                                    entityType?: string | null | undefined;
                                    appType?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | {
              __typename?: "PageInfo";
              hasNextPage?: boolean | null | undefined;
              endCursor?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetRisksDocument = gql`
  query GetRisks(
    $afterCursor: String
    $limit: Int
    $appTypes: [String]
    $term: String
    $riskGroupId: ID
  ) {
    listRisks(
      pagination: { limit: $limit, cursor: $afterCursor }
      filters: {
        appTypes: $appTypes
        shortCode: $term
        riskGroupId: $riskGroupId
      }
    ) {
      edges {
        node {
          ...FullRiskFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${FullRiskFieldsFragmentDoc}
`;

export function useGetRisksQuery(
  options: Omit<Urql.UseQueryArgs<GetRisksQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<GetRisksQuery>({ query: GetRisksDocument, ...options });
}
