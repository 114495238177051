import React, { FunctionComponent } from "react";

import { ThumbsDown, ThumbsUp } from "react-feather";
import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { LayoutContainer } from "../layout/LayoutContainer";
import { LoadingSpinner } from "../loading/LoadingSpinner";

interface IFeedbackBar {
  text?: string;
  onSuccessClick?: () => void;
  onRejectClick?: () => void;
  className?: string;
  successLoading?: boolean;
  rejectLoading?: boolean;
}

const SuccessButton = styled.button`
  color: ${COLOR.SUCCESS};
  border: 1px solid ${COLOR.SUCCESS};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RejectButton = styled.button`
  margin-right: ${APPLICATION_SPACING(1)};
  color: ${COLOR.ERROR};
  border: 1px solid ${COLOR.ERROR};
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeedbackBar: FunctionComponent<IFeedbackBar> = ({
  text,
  onSuccessClick,
  onRejectClick,
  className,
  successLoading,
  rejectLoading,
}) => {
  return (
    <LayoutContainer
      alignItems="center"
      display="flex"
      backgroundColor="INPUT_BACKGROUND"
      px={2}
      py={2}
      className={className}
      fontSize="sm"
    >
      {text}
      <LayoutContainer ml="auto" display="flex">
        {onRejectClick && (
          <RejectButton onClick={onRejectClick}>
            {rejectLoading ? (
              <LoadingSpinner isSmall />
            ) : (
              <ThumbsDown size={16} />
            )}
          </RejectButton>
        )}
        {onSuccessClick && (
          <SuccessButton onClick={onSuccessClick}>
            {successLoading ? (
              <LoadingSpinner isSmall />
            ) : (
              <ThumbsUp size={16} />
            )}
          </SuccessButton>
        )}
      </LayoutContainer>
    </LayoutContainer>
  );
};
