import React, { FunctionComponent } from "react";

import pluralize from "pluralize";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { OutlineWhiteButton } from "../../atoms/Button";
import {
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
  SAFE_TEMPLATE,
} from "../../definitions/types/risk-level";
import { EnumMap } from "../../definitions/types/utils";
import { Maybe, RiskSummary } from "../../generated/graphql.generated";
import { useRiskSummaryQuery } from "../../operations/risks/queries/riskSummary.generated";
import {
  InlineDateDropdown,
  ITimeboundDropdownState,
} from "../../page/Overview";
import { COLOR } from "../../styling/colors";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { LayoutContainer } from "../layout/LayoutContainer";
import { LoadingSpinner } from "../loading/LoadingSpinner";

const getBlockBackgroundColor = (
  totalCount: number,
  showUnknown: boolean,
  riskLevel: keyof EnumMap<typeof RiskLevel>
) => {
  if (showUnknown) {
    return COLOR.PRIMARY;
  } else if (totalCount) {
    return RISK_LEVEL_TEMPLATES[riskLevel].backgroundColor;
  } else {
    return SAFE_TEMPLATE.backgroundColor;
  }
};

const getTextContent = (
  totalCount: number,
  showUnknown: boolean,
  riskLevel: keyof EnumMap<typeof RiskLevel>
) => {
  if (showUnknown) {
    return "Unknown";
  } else if (totalCount) {
    return RISK_LEVEL_TEMPLATES[riskLevel].bodyText;
  } else {
    return SAFE_TEMPLATE.bodyText;
  }
};

const StyledSubHeading = styled.h2`
  font-size: ${theme.fontSizes.lg};
  margin-bottom: ${SPECIFIC_SPACING["12px"]};
  font-weight: 100;
  margin-top: auto;
  text-transform: lowercase;
`;

const StyledRecentRiskBlock = styled.div`
  height: 100%;
  color: ${COLOR.OFF_WHITE};
  padding: 1.75rem 1.75rem 1.75rem 1.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
`;

const getHighestCriticalityCountAndType = (
  riskSummary?: Maybe<RiskSummary>
): { count: number; type: RiskLevel } => {
  if (riskSummary?.critical) {
    return {
      count: riskSummary.critical,
      type: RiskLevel.CRITICAL,
    };
  } else if (riskSummary?.high) {
    return {
      count: riskSummary.high,
      type: RiskLevel.HIGH,
    };
  } else if (riskSummary?.medium) {
    return {
      count: riskSummary.medium,
      type: RiskLevel.MEDIUM,
    };
  } else if (riskSummary?.low) {
    return {
      count: riskSummary.low,
      type: RiskLevel.LOW,
    };
  } else {
    return {
      count: 0,
      type: RiskLevel.LOW,
    };
  }
};

interface IRecentRiskBlock {
  time: ITimeboundDropdownState;
  setTime: (time: ITimeboundDropdownState) => void;
  fromTo: {
    from: Date;
    to: Date;
  };
}

export const RecentRiskBlock: FunctionComponent<IRecentRiskBlock> = ({
  time,
  setTime,
  fromTo,
}) => {
  const [result] = useRiskSummaryQuery({
    variables: fromTo,
  });

  const riskSummary = result.data?.riskSummary;

  const { count, type } = getHighestCriticalityCountAndType(riskSummary);

  return result.fetching && !result.data ? (
    <LayoutContainer
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <LoadingSpinner />
    </LayoutContainer>
  ) : (
    <StyledRecentRiskBlock
      style={{
        backgroundColor: getBlockBackgroundColor(count, false, type),
      }}
    >
      <StyledSubHeading>
        <LayoutContainer display="inline-flex" as="span">
          {result.fetching ? <LoadingSpinner isSmall isWhite /> : count}
        </LayoutContainer>{" "}
        {count === 0 ? (
          <>new risks in the last </>
        ) : (
          <>
            new {getTextContent(count, false, type)} {pluralize("risk", count)}
            <br />
            in the last{" "}
          </>
        )}
        <LayoutContainer as="span" fontWeight={100 as any}>
          <InlineDateDropdown value={time} onSelect={setTime} />
        </LayoutContainer>
      </StyledSubHeading>
      <LayoutContainer justifySelf="flex-end" mt="auto">
        <OutlineWhiteButton as={Link} to="/risks">
          Take Action
        </OutlineWhiteButton>
      </LayoutContainer>
    </StyledRecentRiskBlock>
  );
};
