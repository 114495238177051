import React from "react";

import { useQuery } from "urql";

import { ContextMenuButton } from "../../atoms/Button";
import { ContextMenuContainer } from "../../components/ContextMenuContainer";
import { ContextMenuContext } from "../../components/ContextMenuContainer/context";
import { GET_ME_QUERY } from "../../components/Navigation/queries";
import { useModal } from "../../contexts/modals";
import { useExtendedMutation } from "../../operations/hooks/useExtendedMutation";
import { sendAnalytics } from "../../operations/user/analytics";
import { useInviteUserMutation } from "../../operations/user/hooks/useInviteUser";
import { SUSPEND_USER } from "../../operations/user/mutations/suspendUser";
import { useSuspendUserMutation } from "../../operations/user/mutations/suspendUser.generated";
import { IManageUsersTableRow } from "./columns";

export const ContextMenu = ({
  id,
  status,
  user,
  role,
}: IManageUsersTableRow) => {
  const { confirmModal } = useModal();

  const [response] = useQuery({ query: GET_ME_QUERY });
  const { data: { me } = {} } = response;

  const { loading: inviteUserLoading, inviteUser } = useInviteUserMutation({});

  const suspendUserMutation = useExtendedMutation(useSuspendUserMutation, {
    initialState: { id: user.id },
    definition: SUSPEND_USER,
  });
  const { suspendUser } = suspendUserMutation.run();

  const isEnabledUser = status === "ACTIVE";
  const isMe = me.id === id;

  return !isMe ? (
    <ContextMenuContainer>
      <ContextMenuContext.Consumer>
        {({ setVisibility }) => (
          <>
            <ContextMenuButton
              loading={suspendUserMutation.fetching}
              onClick={() => {
                confirmModal.toggleConfirm({
                  heading: `Are you sure you want to remove access for ${user.nickname}?`,
                  onConfirm: async ({ setModalLoadingState }) => {
                    setModalLoadingState(true);
                    if (!suspendUserMutation.fetching) {
                      await suspendUser({
                        userId: user.id,
                        nickname: user.nickname,
                      });
                      setModalLoadingState(false);
                    }
                  },
                });
                setVisibility(false);
              }}
            >
              Remove user
            </ContextMenuButton>

            {!isEnabledUser ? (
              <ContextMenuButton
                loading={inviteUserLoading}
                onClick={async () => {
                  if (!inviteUserLoading) {
                    await inviteUser({
                      email: user.primaryEmail,
                      role: role.toUpperCase(),
                    });

                    setVisibility(false);
                    sendAnalytics("USERS: Resend Invite", undefined);
                  }
                }}
              >
                Resend invite
              </ContextMenuButton>
            ) : null}
          </>
        )}
      </ContextMenuContext.Consumer>
    </ContextMenuContainer>
  ) : null;
};
