import { gql } from "urql";

import {
  DefinitionType,
  RunExtendedMutationPayload,
} from "../../../hooks/useExtendedMutation";
import { MarkRiskAsResolvedMutationVariables } from "./markRiskAsResolved.generated";
import { markRiskAsResolved } from "./tasks";

type PayloadType =
  RunExtendedMutationPayload<MarkRiskAsResolvedMutationVariables>;

export interface MarkRiskAsResolvedDefinition extends DefinitionType {
  tasks: {
    markRiskAsResolved: ({ id }: { id: string }) => PayloadType;
  };
}

export const MARK_RISK_AS_RESOLVED: MarkRiskAsResolvedDefinition = {
  definition: gql`
    mutation MarkRiskAsResolved($id: ID!) {
      markRiskAsResolved(id: $id) {
        id
      }
    }
  `,
  tasks: {
    markRiskAsResolved,
  },
};
