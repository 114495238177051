import { gql } from "urql";

export const INVITE_USER_MUTATION = gql`
  mutation InviteUser($email: String!, $role: Role!) {
    inviteUser(email: $email, role: $role) {
      id
      user {
        id
        nickname
        primaryEmail
      }
      status
      role
    }
  }
`;
