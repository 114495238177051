import { gql } from "urql";

export const BULK_CREATE_CLASSIFIER_HASH_EXCLUSION_MUTATION = gql`
  mutation BulkCreateClassifierHashExclusions(
    $inputs: [ClassifierExclusionHashBulkCreateInput]
  ) {
    bulkCreateClassifierHashExclusions(inputs: $inputs) {
      id
    }
  }
`;
