import { gql } from "urql";

import { MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT } from "../../operations/monitors/fragments/monitorViolationSummaryFields";

export const MONITOR_LIST_QUERY = gql`
  ${MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT}
  query RuleListQuery {
    listMonitors(currentlyTriggered: true) {
      ...MonitorViolationSummaryFields
    }
  }
`;

export const RULE_OVERVIEW_LIST_QUERY = gql`
  ${MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT}
  query OverviewRuleListQuery {
    activeRules: listMonitors(currentlyTriggered: true) {
      ...MonitorViolationSummaryFields
    }

    allRules: listMonitors {
      id
    }
  }
`;

export const RULE_LIST_QUERY_SLIM = gql`
  query RuleListQuerySlim {
    listMonitors {
      id
    }
  }
`;
