import React, { useCallback } from "react";

import { css } from "styled-components";

import { GenericTable } from "../../../components/tables/GenericTable";
import { ITableTheme } from "../../../components/tables/GenericTable/definitions";
import { TABLE_THEMES } from "../../../components/tables/themes";
import { useListRiskActionsByBulkActionIdQuery } from "../../../operations/risks/queries/listRiskActionsByBulkActionId.generated";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { defaultTo } from "../../../utils/maybe/defaultTo";
import { usePollPaginatedTableList } from "../../../utils/table/pollPaginatedTableList";
import { usePaginatedTableData } from "../../../utils/table/usePaginatedTableData";
import { usePaginatedTableState } from "../../../utils/table/useTableState";
import {
  riskColumnsWithStatus,
  RiskWithActionStatus,
} from "../../RiskList/columns";

const theme: ITableTheme = {
  table: css`
    overflow: auto;
    flex: 1;
    height: 100%;
  `,
  row: css`
    padding-left: ${APPLICATION_SPACING(3)};
    &:first-child {
      border-top-width: 1px;
    }
  `,
  cell: css`
    border-width: 0px;

    &:last-child {
      font-size: 60px;
      padding-right: 0;
      border-right: 0;
      border-left-width: 1px;
      border-color: ${COLOR.TABLE_BORDER};
    }
  `,
};

export const BulkActionHistoryDetailPanel = ({
  bulkActionId,
  pollForUpdates,
}: {
  bulkActionId: string;
  pollForUpdates?: boolean;
}) => {
  const [tableState, setTableState] = usePaginatedTableState({
    cursor: "",
    shouldShowFullLoader: true,
    limit: 5,
    suppressLoader: false,
  });

  const [result, executeQuery] = useListRiskActionsByBulkActionIdQuery({
    variables: {
      cursor: tableState.cursor,
      limit: tableState.limit,
      bulkActionId: bulkActionId,
    },
    pause: !bulkActionId,
    requestPolicy: "network-only",
  });

  const { rows, hasNextPage, endCursor } = usePaginatedTableData({
    data: result.data?.listRiskActionsByBulkActionId,
    transformer: (data) =>
      data?.edges?.map((edge) => {
        const node = edge?.node?.risk;

        return {
          ...node,
          id: defaultTo(edge?.node?.id, ""),
          status: edge?.node?.status,
          command: edge?.node?.command,
        } as RiskWithActionStatus;
      }) || [],
  });

  const showLoader =
    tableState.shouldShowFullLoader &&
    result.fetching &&
    !tableState.suppressLoader;

  usePollPaginatedTableList({
    tableStateGetterSetter: [tableState, setTableState],
    initialPageSize: 5,
    numberOfResults: rows.length,
    frequency: 1000,
    runQuery: executeQuery,
    pause: !bulkActionId || !pollForUpdates,
  });

  const onScrollEnd = useCallback(() => {
    if (!result.fetching && hasNextPage) {
      setTableState(() => ({
        cursor: endCursor,
        limit: 5,
        shouldShowFullLoader: false,
        suppressLoader: false,
      }));
    }
  }, [endCursor, result.fetching, hasNextPage, setTableState]);

  return (
    <GenericTable
      theme={TABLE_THEMES.NO_HEADER_NO_GAP_TABLE}
      loading={showLoader}
      onScrollEnd={onScrollEnd}
      canInfiniteScroll={hasNextPage && !showLoader}
      themeOverrides={theme}
      data={rows}
      columns={riskColumnsWithStatus}
    />
  );
};
