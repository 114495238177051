import React, { useState } from "react";

import { css } from "styled-components";
import { useQuery } from "urql";

import { ConnectionShowMoreWrapper } from "../../components/ShowMoreWrapper";
import { GenericTable } from "../../components/tables/GenericTable";
import { TABLE_THEMES } from "../../components/tables/themes";
import {
  USER_MEMBERSHIP_STATUS,
  USER_ROLE,
} from "../../definitions/types/users";
import { APPLICATION_SPACING } from "../../styling/spacing";
import {
  TIncomingRelay,
  TRelayEdge,
} from "../../urql/pagination/definitions/definitions";
import { useConnectionData } from "../../utils/pagination/hooks/useConnectionData";
import { UserRowContainer } from "./UserRowContainer";
import { columns } from "./columns";
import { MANAGE_USERS_LIST_QUERY } from "./query";

// TODO: Sort location and naming of interfaces
// TODO: Sort the installationId query repetition

export interface IInviteUsers {
  listAccountMemberships: TIncomingRelay<IAccountMembership, {}>;
}

export interface IAccountMembership {
  id: string;
  accountId: string;
  user: {
    id: string;
    customerId: string;
    nickname: string;
    avatarUrl: string;
    primaryEmail: string;
  };
  status: USER_MEMBERSHIP_STATUS;
  role: USER_ROLE;
}

const membershipIsNotSuspended = (edge: TRelayEdge<IAccountMembership, {}>) =>
  edge.node.status !== "SUSPENDED";

const tableStylingOverrides = {
  table: css`
    margin-top: ${APPLICATION_SPACING(3)};
  `,
};

export interface IInviteNewUser {
  email?: string;
  role?: USER_ROLE;
}

export const InviteUsers = () => {
  const [afterCursor, setAfterCursor] = useState("");

  const [result] = useQuery<IInviteUsers>({
    query: MANAGE_USERS_LIST_QUERY,
    requestPolicy: "network-only",
    variables: {
      afterCursor,
    },
  });

  const { hasData, edges, hasNextPage, endCursor, isFirstLoad } =
    useConnectionData(result.data?.listAccountMemberships);

  const results =
    edges?.filter(membershipIsNotSuspended).map((edge, i) => ({
      ...edge.node,
    })) || [];

  return (
    <ConnectionShowMoreWrapper
      hideButton={!hasNextPage}
      showLoadingIndicator={!isFirstLoad}
      loading={result.fetching}
      withButtonSpacing
      showMore={() => {
        setAfterCursor(endCursor);
      }}
    >
      <GenericTable
        noResults="No users found"
        error={result.error}
        loading={(result.fetching || !hasData) && isFirstLoad}
        columns={columns}
        data={results}
        RowContainer={UserRowContainer}
        theme={TABLE_THEMES.BORDER_ROW_TABLE}
        themeOverrides={tableStylingOverrides}
      />
    </ConnectionShowMoreWrapper>
  );
};
