export const COLOR = {
  PRIMARY: "#58469b",
  PRIMARY_DARK_MODE: "#9b90c3",
  PRIMARY_DARK_MODE_HOVER: "#e1e2ea",
  PRIMARY_HOVER: "#352a5d",
  PRIMARY_NAV_HOVER: "#5E4F9B",
  BG_PRIMARY: "#eeecf5",
  BG_DARK_MODE: "#eaebf3",
  CONTAINER_BACKGROUND: "#F5F6FA",
  SECONDARY: "#ff8c47",
  BG_SECONDARY: "#fff3ed",
  ERROR: "#ff5757",
  ERROR_DARK_MODE: "#ffabab",
  BG_ERROR: "#ffeeee",
  SUCCESS: "#22a6b3",
  SUCCESS_DARK_MODE: "#91D2D9",
  BG_SUCCESS: "#e9f6f7",
  WARNING: "#f4b740",
  BG_WARNING: "#fdf1d9",
  WARNING_DARK: "#a26b00",
  GRADIENT_PRIMARY: "linear-gradient(114.44deg, #58469b 0%, #ff72e8 100%)",
  GRADIENT_SECONDARY: "linear-gradient(114.44deg,#ff8c47 0%,#ff7070 100%)",
  OFF_WHITE: "#FAFAFA",
  OFF_WHITE_DARKER: "rgba(250, 250, 250, 0.5)",
  TITLE_ACTIVE: "#181718",
  TITLE_FLIP: "#ffffff",
  BACKGROUND: "#f5f6fa",
  BODY: "#4e4b66",
  LABEL: "#6e7191",
  PLACEHOLDER: "#a0a3bd",
  PAGE_BACKGROUND: "#edeff4",
  GRAYSCALE_BACKGROUND: "#eff0f6",
  GRAYSCALE_UNEDITABLE: "#edeef5",
  GRAYSCALE_READONLY: "#808080",
  PRIMARY_LIGHT: "#9b90c3",
  LINE: "#d6d8e7",
  LINE_2: "#e6e8f0",
  LINE_SUCCESS: "#91d2d9",
  INPUT_BACKGROUND: "#f5f6fa",
  INSET_BACKGROUND: "#f1f2f8",
  INSET_BACKGROUND_DARK: "#f2f3f8",
  INSET_SPLIT_BACKGROUND: "#EEEFF3",
  CHART_LABEL: "#a3a3a3",
  SDF_CRITICAL: "#D45555",
  SDF_HIGH: "#E4842A",
  SDF_MEDIUM: "#EEBF48",
  SDF_LOW: "#3F6F9B",
  TABLE_BORDER: "#E6E8EF",
  LIGHT_BORDER: "#D8CDFF",
};
