import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { PrimarySquareButton } from "../../../atoms/Button";
import { Box } from "../../../components/Box";
import { Popover } from "../../../components/Popover";
import { LayoutContainer } from "../../../components/layout/LayoutContainer";
import { IIntegrationModal } from "../../../components/modals/Integration/IntegrationModal";
import { IntegrationSetup } from "../../../definitions/types/integration";
// TODO: Why is the integration defined on the page, move integration types to somewhere useful
import { INTEGRATION_API } from "../../../operations/integrations/api";
import { useToast } from "../../../recoil/toasts/provider";
import { IIntegrationWithInstallationId } from "../Installed";

const StyledBox = styled(Box)<{ $comingSoon: boolean }>`
  width: 100%;
  height: 100%;

  ${({ $comingSoon }) =>
    $comingSoon &&
    css`
      background: #f2f3f8;
    `};
`;

const StyledIntegrationIconBox = styled(LayoutContainer)`
  flex: 1;
`;

const StyledIntegrationIcon = styled.img`
  width: 60%;
  max-width: 200px;
`;

export const NotInstalled = ({
  integration,
  toggleModal,
}: {
  integration: IIntegrationWithInstallationId;
  toggleModal: (data?: IIntegrationModal) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useToast();
  const location = useLocation();

  const setIsError = (message: string) =>
    addToast({ type: "error", message: `${message}` });

  const queryStringParameters = queryString.parse(location.search);

  const { error, appType } = queryStringParameters;

  const { getAccessTokenSilently } = useAuth0();

  const startInstall = async () => {
    try {
      const integrationSetup: IntegrationSetup =
        await INTEGRATION_API.startInstallation({
          getAccessToken: getAccessTokenSilently,
          setIsLoading,
          setIsError,
          appType: integration.appType,
        });

      toggleModal(integrationSetup);
    } catch (error) {}
  };

  const continueInstall = async () => {
    setIsLoading(true);

    try {
      const loadInstallationDetails = async (installationId: string) => {
        const data = await INTEGRATION_API.getCurrentInstallationStep({
          getAccessToken: getAccessTokenSilently,
          setIsError: (error: string) => {},
          installationId,
        });

        const currentStepData =
          await INTEGRATION_API.getInstallationStepDetails({
            getAccessToken: getAccessTokenSilently,
            setIsError: (error: string) => {},
            installationId,
            stepNumber: data.currentStepNumber,
          });

        return currentStepData.data;
      };

      if (integration.installationId) {
        loadInstallationDetails(integration.installationId).then(
          (setup: IIntegrationModal) => {
            setIsLoading(false);
            toggleModal(setup);
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <LayoutContainer height="100%" display="flex" flex={1} pb={[3, 0]}>
      <StyledBox
        $comingSoon={integration.comingSoon}
        key={integration.id}
        flex={1}
        display="flex"
        flexDirection="column"
        borderTopWidth={"4px" as any}
        borderColor="LIGHT_BORDER"
      >
        {error && appType === integration.appType && (
          <LayoutContainer ml={2} mr={2}>
            <LayoutContainer
              p={4}
              fontSize="md"
              borderColor="ERROR"
              borderWidth={"1px" as any}
              color="ERROR"
              mt={3}
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              {`${error || "Something went wrong, please try again."}`}
            </LayoutContainer>
          </LayoutContainer>
        )}
        <StyledIntegrationIconBox
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="center"
          width="100%"
          position="relative"
        >
          <StyledIntegrationIcon
            alt={integration.name}
            src={integration.logoUrl}
          />
        </StyledIntegrationIconBox>
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="GRAYSCALE_BACKGROUND"
          borderTopWidth={"1px" as any}
          borderColor="LINE"
          p={3}
          style={{
            opacity: integration.comingSoon ? 0.5 : 1,
            height: "84px",
          }}
        >
          {integration.comingSoon ? (
            <Popover
              content={`Please reach out to your customer success manager to discuss ${integration.name}`}
            >
              <div>
                <PrimarySquareButton disabled>Connect now</PrimarySquareButton>
              </div>
            </Popover>
          ) : (
            <PrimarySquareButton
              smallLoader
              loading={isLoading}
              onClick={() => {
                integration.status === "SETUP_IN_PROGRESS"
                  ? continueInstall()
                  : startInstall();
              }}
            >
              {" "}
              Connect now
            </PrimarySquareButton>
          )}
        </LayoutContainer>
      </StyledBox>
    </LayoutContainer>
  );
};
