import { format } from "date-fns";
import { enUS, enGB } from "date-fns/locale";

/* Before you go, Mike! Just use Intl.DateTimeFormat, safari only just supports dateStyle
and it's still not type in TS for this reason. Date-fns is still more flexible for now */

const getCurrentLocale = () => {
  const lang = window.navigator.language || "en-GB";

  switch (lang) {
    case "en-US":
      return enUS;
    default:
      return enGB;
  }
};

export const localeBasedTime = (date: Date) =>
  format(date, "d/M - HH:mm", { locale: getCurrentLocale() });

export const localeBasedShortDateWithTimeFirst = (date: Date) =>
  format(date, "P - HH:mm", { locale: getCurrentLocale() });

export const localeBasedShortDateTime = (date: Date) =>
  format(new Date(date), "PPP HH:mm:ss", { locale: getCurrentLocale() });

export const localeBasedShortDate = (date: Date) =>
  format(new Date(date), "PP", { locale: getCurrentLocale() });

export const localeBasedDayDateMonthFormat = (date: Date) =>
  format(new Date(date), "P", { locale: getCurrentLocale() }).slice(0, -5);

export const toVerboseDateFormat = (timestamp: string) =>
  format(new Date(parseInt(timestamp)), "eeee dd MMMM yyyy, HH:mm:ss ");
