import { RiskActionCommandEnum } from "../../generated/graphql.generated";

export type BulkActionState = {
  [normalisedKey: `${string}:${string}`]: RiskActionState;
};

export const PERMANENT_RISK_ACTION_COMMANDS = [
  RiskActionCommandEnum.SlackRedactMessage,
];

export enum RiskActionCommandEnumExtended {
  GdriveRevokeFilePermissionsPublic = "GDRIVE_REVOKE_FILE_PERMISSIONS_PUBLIC",
  GdriveRevokeFilePermissionsInternal = "GDRIVE_REVOKE_FILE_PERMISSIONS_INTERNAL",
}

export type PotentialUnsetRiskCommand =
  | RiskActionCommandEnum
  | RiskActionCommandEnumExtended
  | "UNSELECTED";

export type RiskActionState = {
  [riskId: string]: PotentialUnsetRiskCommand;
};

export type OnIndividualItemChangeFunction = ({
  value,
  riskId,
  referenceId,
}: {
  value: PotentialUnsetRiskCommand;
  riskId: string;
  referenceId: string;
}) => void;

export type OnGroupItemChangeFunction = ({
  value,
  referenceId,
}: {
  referenceId: string;
  value: PotentialUnsetRiskCommand;
}) => void;

export type InterfaceValidationState = {
  [key: string]: string;
};
