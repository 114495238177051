import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ModifyGdrivePermissionMutationVariables = Types.Exact<{
  installationId: Types.Scalars["String"];
  entityId: Types.Scalars["String"];
  revokeInternalSharing?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  revokePublicSharing?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  violationId?: Types.InputMaybe<Types.Scalars["String"]>;
  resolveAllViolationsInFile?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type ModifyGdrivePermissionMutation = {
  __typename?: "RootMutationType";
  modifyGDrivePermissions?:
    | {
        __typename?: "FilePermissionRevoked";
        entityId?: string | null | undefined;
        idsToInvalidate?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type ModifyGdrivePermissionForRiskMutationVariables = Types.Exact<{
  revokeInternalSharing?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  revokePublicSharing?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  riskId?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ModifyGdrivePermissionForRiskMutation = {
  __typename?: "RootMutationType";
  modifyGDrivePermissionsForRisk?:
    | {
        __typename?: "FilePermissionByRiskRevoked";
        atomId?: string | null | undefined;
      }
    | null
    | undefined;
};

export const ModifyGdrivePermissionDocument = gql`
  mutation ModifyGdrivePermission(
    $installationId: String!
    $entityId: String!
    $revokeInternalSharing: Boolean
    $revokePublicSharing: Boolean
    $violationId: String
    $resolveAllViolationsInFile: Boolean
  ) {
    modifyGDrivePermissions(
      installationId: $installationId
      entityId: $entityId
      revokeInternalSharing: $revokeInternalSharing
      revokePublicSharing: $revokePublicSharing
      violationId: $violationId
      resolveAllViolationsInFile: $resolveAllViolationsInFile
    ) {
      entityId
      idsToInvalidate
    }
  }
`;

export function useModifyGdrivePermissionMutation() {
  return Urql.useMutation<
    ModifyGdrivePermissionMutation,
    ModifyGdrivePermissionMutationVariables
  >(ModifyGdrivePermissionDocument);
}
export const ModifyGdrivePermissionForRiskDocument = gql`
  mutation ModifyGdrivePermissionForRisk(
    $revokeInternalSharing: Boolean
    $revokePublicSharing: Boolean
    $riskId: String
  ) {
    modifyGDrivePermissionsForRisk(
      riskId: $riskId
      revokeInternalSharing: $revokeInternalSharing
      revokePublicSharing: $revokePublicSharing
    ) {
      atomId
    }
  }
`;

export function useModifyGdrivePermissionForRiskMutation() {
  return Urql.useMutation<
    ModifyGdrivePermissionForRiskMutation,
    ModifyGdrivePermissionForRiskMutationVariables
  >(ModifyGdrivePermissionForRiskDocument);
}
