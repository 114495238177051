import { USER_PERMISSIONS } from "../../definitions/types/users";
import {
  AccountOnboardingStatus,
  Maybe,
} from "../../generated/graphql.generated";
import { ApplicationRoute } from "../../routing/routes";

export const canManageMemberships = (permissions?: string[]) =>
  permissions?.includes(USER_PERMISSIONS.UPDATE_MEMBERSHIP) ?? false;

export const canReadDeepLink = (permissions?: string[]) =>
  permissions?.includes(USER_PERMISSIONS.READ_DEEP_LINK) ?? false;

export const routePermittedByPermission =
  (permissions: string[]) => (route: ApplicationRoute) =>
    permissions.some(
      (def) => route.permissions?.some((routeDef) => routeDef === def) ?? true
    );

export const hasFinishedOnboarding = (
  status?: Maybe<AccountOnboardingStatus>
) => status && status === AccountOnboardingStatus.Completed;
