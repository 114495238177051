import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { ITextArea } from "../../../atoms/form/TextArea";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { LayoutContainer } from "../../layout/LayoutContainer";

interface IHeadedTextArea extends ITextArea {
  heading?: string;
  hideLabel?: boolean;
  className?: string;
}

const StyledTextArea = styled.textarea<{ hasValue?: boolean }>`
  padding: ${APPLICATION_SPACING(1)};
  border: 1px solid;
  border-color: ${({ hasValue }) => (hasValue ? COLOR.PRIMARY : "#d6d8e7")};
`;

export const HeadedTextArea: FunctionComponent<IHeadedTextArea> = (props) => {
  const { heading, hideLabel, className, ...extraProps } = props;
  return (
    <LayoutContainer className={className} width="100%">
      {!hideLabel && (
        <LayoutContainer
          as="label"
          fontSize="xsm"
          color="LABEL"
          mb={1}
          display="block"
        >
          {props.heading}
        </LayoutContainer>
      )}
      <StyledTextArea
        {...extraProps}
        className={props.className}
        hasValue={Boolean(props.value)}
      />
    </LayoutContainer>
  );
};
