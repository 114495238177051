import React, { FunctionComponent, HTMLAttributes } from "react";

import styled from "styled-components";

import { COLOR } from "../../../styling/colors";

interface IHorizontalLoader extends HTMLAttributes<HTMLSpanElement> {
  isWhite?: boolean;
}

const StyledHorizontalLoader = styled.div<{ $isWhite?: boolean }>`
  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -16px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) =>
      props.$isWhite ? COLOR.OFF_WHITE : COLOR.PRIMARY_HOVER};
    animation: dotFlashing 1s infinite ease-in-out alternate;
    animation-delay: 0.2s;
    opacity: 1;
  }

  &:after {
    left: 16px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) =>
      props.$isWhite ? COLOR.OFF_WHITE : COLOR.PRIMARY_HOVER};
    animation: dotFlashing 1s infinite ease-in-out alternate;
    animation-delay: 0.6s;
    opacity: 1;
  }
`;

const StyledCenterDot = styled.div<{ $isWhite?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.$isWhite ? COLOR.OFF_WHITE : COLOR.PRIMARY};
  animation: dotFlashing 1s infinite ease-in-out alternate;
  animation-delay: 0.4s;
  opacity: 1;

  @keyframes dotFlashing {
    0% {
      background-color: ${(props) =>
        props.$isWhite ? COLOR.OFF_WHITE : COLOR.PRIMARY};
      transform: scale(0.8);
      opacity: 1;
    }
    10% {
      transform: scale(0.8);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      background-color: ${(props) =>
        props.$isWhite ? COLOR.OFF_WHITE : COLOR.PRIMARY_LIGHT};
      opacity: 0.1;
      transform: scale(1);
    }
  }
`;

export const HorizontalLoader: FunctionComponent<IHorizontalLoader> = ({
  className,
  isWhite,
}) => {
  return (
    <div className={className}>
      <StyledHorizontalLoader $isWhite={isWhite}>
        <StyledCenterDot $isWhite={isWhite} />
      </StyledHorizontalLoader>
    </div>
  );
};
