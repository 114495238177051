import React, { Dispatch, SetStateAction, useState } from "react";

import styled from "styled-components";

import { ButtonPillPrimary, ButtonSmall } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { LayoutContainer } from "../layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "./BaseModal";
import { StyledModalBox } from "./shared";

export interface IConfirmModal {
  loading?: boolean;
  heading: string;
  description?: string;
  body?: JSX.Element;
  confirmText?: string;
  onConfirm: ({
    setModalLoadingState,
  }: {
    setModalLoadingState: Dispatch<SetStateAction<boolean>>;
  }) => void;
  onReject?: ({
    setModalLoadingState,
  }: {
    setModalLoadingState: Dispatch<SetStateAction<boolean>>;
  }) => void;
}

export interface IConfirmModalComponent extends IModalBase {
  data?: IConfirmModal;
}

const CancelButton = styled(ButtonSmall)`
  font-size: ${theme.fontSizes.xsm};
  margin-right: ${APPLICATION_SPACING(3)};
`;

const ConfirmModal = ({
  showModal,
  toggleModal,
  data,
}: IConfirmModalComponent) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isNegativeLoading, setIsLoadingNegative] = useState(false);
  return (
    <ModalWrapper showModal={showModal} toggleModal={toggleModal}>
      <StyledModalBox>
        <Heading type="text-md" Element="h1" isBodyColor>
          {data?.heading}
        </Heading>
        {data?.description && (
          <LayoutContainer as="p" mt={2} fontSize="xsm">
            {data.description}
          </LayoutContainer>
        )}
        {data?.body && data.body}
        <LayoutContainer mt={3} display="flex" justifyContent="flex-end">
          <CancelButton
            loading={isNegativeLoading}
            onClick={async () => {
              if (data) {
                if (data.onReject) {
                  await data.onReject({
                    setModalLoadingState: setIsLoadingNegative,
                  });
                }
              }
              toggleModal();
            }}
          >
            {data && data.onReject ? "No" : "Cancel"}
          </CancelButton>
          <ButtonPillPrimary
            smallLoader
            loading={data?.loading || isLoading}
            onClick={async () => {
              if (data) {
                await data.onConfirm({ setModalLoadingState: setIsLoading });
              }
              toggleModal();
            }}
          >
            {data?.confirmText
              ? data?.confirmText
              : data && data.onReject
              ? "Yes"
              : "Confirm"}
          </ButtonPillPrimary>
        </LayoutContainer>
      </StyledModalBox>
    </ModalWrapper>
  );
};

export default ConfirmModal;
