import React, { useMemo } from "react";

import { sub } from "date-fns";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { PrimarySquareButton } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { Box } from "../../components/Box";
import { Content } from "../../components/Content";
import { InlineDropdown } from "../../components/InlineDropdown";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper/PageHeaderWrapper";
import { RecentRiskBlock } from "../../components/RecentRiskBlock";
import { RiskSummaryBlock } from "../../components/RiskSummaryBlock";
import { SDFCounter } from "../../components/SDFCounter";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { Column, Grid } from "../../components/layout/grid";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { getActionIcon } from "../../features/BulkActions/columns";
import { useStateLocalStorage } from "../../features/ClassifierDefinitionForm/useStateLocalStorage";
import { RecentRiskGroupList } from "../../features/RecentRiskGroupsList";
import { RecentRiskList } from "../../features/RecentRiskList";
import { RulesViolatedOverTime } from "../../features/RulesViolatedOverTime";
import {
  MonitorRiskLevel,
  RiskActionCommandEnum,
} from "../../generated/graphql.generated";
import { useRiskActionsSummaryQuery } from "../../operations/risks/queries/riskActionsSummary.generated";
import { useRiskSummaryQuery } from "../../operations/risks/queries/riskSummary.generated";
import { TOTAL_SDF_HEALTH } from "../../operations/sdf/constants";
import { useGetSdfTotalQuery } from "../../operations/sdf/queries/getSdfTotal.generated";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import theme from "../../styling/theme";
import { useFeatureFlag } from "../../utils/feature-flags/hooks/useFeatureFlag";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { sumObject } from "../../utils/object/sumObject";

// TODO: THIS IS AN ABSOLUTE BLITZ LFG.

const StyledBoxHeading = styled.h3`
  font-size: ${theme.fontSizes.md};
`;

const StyledHeading = styled.h3`
  font-size: ${theme.fontSizes.md};
  color: ${COLOR.LABEL};
`;

interface IRiskSummaryComponent {
  time: ITimeboundDropdownState;
  setTime: (time: ITimeboundDropdownState) => void;
  fromTo: {
    from: Date;
    to: Date;
  };
}

const RiskSummaryComponent = (props: IRiskSummaryComponent) => {
  const [result] = useRiskSummaryQuery({
    variables: props.fromTo,
  });

  const riskSummary = result.data?.riskSummary;

  return result.fetching ? (
    <LoadingSpinner />
  ) : (
    <RiskSummaryBlock
      CRITICAL={riskSummary?.critical || 0}
      HIGH={riskSummary?.high || 0}
      MEDIUM={riskSummary?.medium || 0}
      LOW={riskSummary?.low || 0}
      activeRules={0}
    />
  );
};

interface ISDFSummaryComponent {
  time: ITimeboundDropdownState;
  setTime: (time: ITimeboundDropdownState) => void;
  fromTo: {
    from: Date;
    to: Date;
  };
}

const SDFSummaryComponent = (props: ISDFSummaryComponent) => {
  const [sdfCountResult] = useGetSdfTotalQuery({
    requestPolicy: "network-only",
  });

  const loading = sdfCountResult.fetching;
  const totalSDFCount = sdfCountResult.data?.getSDFTotal?.sdf;

  return (
    <LayoutContainer flexDirection="column" display="flex" height="100%">
      <LayoutContainer>
        <StyledBoxHeading>Sensitive Data Footprint</StyledBoxHeading>
      </LayoutContainer>
      {/* the below width and height is a little temporary hack to get the graph to layout correctly */}
      <LayoutContainer display="flex" flex={1} alignItems="center">
        <LayoutContainer width="50%" maxWidth="250px" pr={2}>
          {totalSDFCount !== undefined &&
            totalSDFCount !== null &&
            !loading && (
              <SDFCounter
                displayCount={totalSDFCount}
                number={totalSDFCount}
                total={TOTAL_SDF_HEALTH}
              />
            )}
        </LayoutContainer>
        <LayoutContainer
          display="flex"
          justifyContent="center"
          flex={1}
          minWidth="50%"
        >
          <RiskSummaryComponent {...props} />
        </LayoutContainer>
      </LayoutContainer>
    </LayoutContainer>
  );
};

interface IActionsPerformedBlock {
  time: ITimeboundDropdownState;
  setTime: (time: ITimeboundDropdownState) => void;
  fromTo: {
    from: Date;
    to: Date;
  };
}

const ActionsPerformedBlock = (props: IActionsPerformedBlock) => {
  const [result] = useRiskActionsSummaryQuery({
    variables: props.fromTo,
  });

  const riskActionsSummary = result.data?.bulkActionsSummary;

  const totalCount = sumObject(defaultTo(riskActionsSummary, {}));

  return result.fetching && !riskActionsSummary ? (
    <LayoutContainer
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <LoadingSpinner />
    </LayoutContainer>
  ) : (
    <LayoutContainer flexDirection="column" display="flex" height="100%">
      <LayoutContainer>
        <StyledBoxHeading>Actions Performed</StyledBoxHeading>
      </LayoutContainer>
      <LayoutContainer mt={2} mb={2}>
        <Heading Element="h2" type="display-extra-small" isBodyColor>
          {result.fetching ? (
            <LayoutContainer mr={1} display="inline-flex">
              <LoadingSpinner isSmall />
            </LayoutContainer>
          ) : (
            totalCount
          )}{" "}
          Actions
        </Heading>
      </LayoutContainer>
      <LayoutContainer display="flex" flexDirection="column">
        <LayoutContainer
          fontSize="xsm"
          display="flex"
          alignItems="center"
          mb={1}
        >
          <LayoutContainer
            display="inline-flex"
            alignItems="center"
            alignSelf="flex-start"
            borderRadius={"2px" as any}
            backgroundColor="GRAYSCALE_UNEDITABLE"
            px={1}
            height="28px"
            flexWrap="nowrap"
            fontSize="xsm"
          >
            <LayoutContainer as="span" mr={1}>
              {getActionIcon([
                { command: RiskActionCommandEnum.GdriveRevokeFilePermissions },
              ])}
            </LayoutContainer>
            Gdrive Permissions Revoked
          </LayoutContainer>
          <LayoutContainer ml="auto">
            {riskActionsSummary?.gdriveRevokeFilePermissions || 0}
          </LayoutContainer>
        </LayoutContainer>
        <LayoutContainer
          fontSize="xsm"
          display="flex"
          alignItems="center"
          mb={1}
        >
          <LayoutContainer
            display="inline-flex"
            alignItems="center"
            alignSelf="flex-start"
            borderRadius={"2px" as any}
            backgroundColor="GRAYSCALE_UNEDITABLE"
            px={1}
            height="28px"
            flexWrap="nowrap"
            fontSize="xsm"
          >
            <LayoutContainer as="span" mr={1}>
              {getActionIcon([
                { command: RiskActionCommandEnum.SlackRedactMessage },
              ])}
            </LayoutContainer>
            Redact
          </LayoutContainer>
          <LayoutContainer ml="auto">
            {riskActionsSummary?.slackRedactMessage || 0}
          </LayoutContainer>
        </LayoutContainer>
        <LayoutContainer
          fontSize="xsm"
          display="flex"
          alignItems="center"
          mb={1}
        >
          <LayoutContainer
            display="inline-flex"
            alignItems="center"
            alignSelf="flex-start"
            borderRadius={"2px" as any}
            backgroundColor="GRAYSCALE_UNEDITABLE"
            px={1}
            height="28px"
            flexWrap="nowrap"
            fontSize="xsm"
          >
            <LayoutContainer as="span" mr={1}>
              {getActionIcon([{ command: RiskActionCommandEnum.ResolveRisk }])}
            </LayoutContainer>
            Risks Marked Resolved
          </LayoutContainer>
          <LayoutContainer ml="auto">
            {riskActionsSummary?.resolveRisk || 0}
          </LayoutContainer>
        </LayoutContainer>
      </LayoutContainer>
      {/* the below width and height is a little temporary hack to get the graph to layout correctly */}
    </LayoutContainer>
  );
};

export type ICriticalityDropdownState = MonitorRiskLevel | "ALL";
export type ITimeboundDropdownState =
  | "4HOURS"
  | "12HOURS"
  | "24HOURS"
  | "7DAYS"
  | "30DAYS";

export const TimeBoundDropdownStateMap: {
  [key in ITimeboundDropdownState]: {
    from: Date;
    to: Date;
  };
} = {
  "4HOURS": {
    from: sub(new Date(), {
      hours: 4,
    }),
    to: new Date(),
  },
  "12HOURS": {
    from: sub(new Date(), {
      hours: 12,
    }),
    to: new Date(),
  },
  "24HOURS": {
    from: sub(new Date(), {
      days: 1,
    }),
    to: new Date(),
  },
  "7DAYS": {
    from: sub(new Date(), {
      days: 7,
    }),
    to: new Date(),
  },
  "30DAYS": {
    from: sub(new Date(), {
      days: 30,
    }),
    to: new Date(),
  },
};

export const InlineDateDropdown = ({
  onSelect,
  value,
}: {
  value: ITimeboundDropdownState;
  onSelect: (value: ITimeboundDropdownState) => void;
}) => {
  return (
    <InlineDropdown
      label=""
      onSelect={onSelect}
      options={[
        {
          key: "4 Hours",
          value: "4HOURS",
          selected: "4HOURS" === value,
        },
        {
          key: "12 Hours",
          value: "12HOURS",
          selected: "12HOURS" === value,
        },
        {
          key: "day",
          value: "24HOURS",
          selected: "24HOURS" === value,
        },
        {
          key: "7 Days",
          value: "7DAYS",
          selected: "7DAYS" === value,
        },
        {
          key: "30 Days",
          value: "30DAYS",
          selected: "30DAYS" === value,
        },
      ]}
    />
  );
};

export const Overview = () => {
  const FF_RISK_GROUPS = useFeatureFlag("risk_groups");
  const [dropdownState, setDropdownState] =
    useStateLocalStorage<ICriticalityDropdownState>(
      "METOMIC:OVERVIEW:RULEDROPDOWNCRITICALITY",
      MonitorRiskLevel.Critical
    );
  const [dropdownStateTime, setDropdownStateTime] =
    useStateLocalStorage<ITimeboundDropdownState>(
      "METOMIC:OVERVIEW:RULEDROPDOWNTIME",
      "24HOURS"
    );

  const [globalOverviewDateTime, setGlobalOverviewStateTime] =
    useStateLocalStorage<ITimeboundDropdownState>(
      "METOMIC:OVERVIEW:GLOBALDROPDOWNTIME",
      "24HOURS"
    );

  const filterDate = useMemo(() => {
    return {
      dateRange: TimeBoundDropdownStateMap[dropdownStateTime],
    };
  }, [dropdownStateTime]);

  const globalDate = useMemo(() => {
    return TimeBoundDropdownStateMap[globalOverviewDateTime];
  }, [globalOverviewDateTime]);

  return (
    <PageHeaderWrapper
      showTimeFilter
      time={globalOverviewDateTime}
      setTime={setGlobalOverviewStateTime}
      breadcrumb={["Overview"]}
    >
      <Content as="main">
        <Grid responsive gridAutoRows={"1fr"} mb={2}>
          <Column mb={[2, 0]} span={4}>
            <Box height="100%">
              <RecentRiskBlock
                time={globalOverviewDateTime}
                setTime={setGlobalOverviewStateTime}
                fromTo={globalDate}
              />
            </Box>
          </Column>
          <Column mb={[2, 0]} span={4}>
            <Box height="100%" p={2} pb={1}>
              <SDFSummaryComponent
                time={globalOverviewDateTime}
                setTime={setGlobalOverviewStateTime}
                fromTo={globalDate}
              />
            </Box>
          </Column>
          <Column mb={[2, 0]} span={4}>
            <Box height="100%" p={2}>
              <ActionsPerformedBlock
                time={globalOverviewDateTime}
                setTime={setGlobalOverviewStateTime}
                fromTo={globalDate}
              />
            </Box>
          </Column>
        </Grid>
        <Box p={3} mb={2}>
          <LayoutContainer mb={2}>
            <LayoutContainer mb={2}>
              <StyledHeading>Top 3 Risks</StyledHeading>
            </LayoutContainer>
            <RecentRiskList />
          </LayoutContainer>
          <PrimarySquareButton as={Link} to={APPLICATION_ROUTES.RISKS.url}>
            Fix Now
          </PrimarySquareButton>
        </Box>

        {FF_RISK_GROUPS.enabled && (
          <Box p={3} mb={2}>
            <LayoutContainer mb={2}>
              <LayoutContainer mb={2}>
                <StyledHeading>Risk Patterns</StyledHeading>
              </LayoutContainer>
              <RecentRiskGroupList />
            </LayoutContainer>
            <PrimarySquareButton
              as={Link}
              to={defaultTo(
                APPLICATION_ROUTES.RISKS.NESTED_ROUTES?.RISK_GROUPS.url,
                ""
              )}
            >
              View More
            </PrimarySquareButton>
          </Box>
        )}
        <Box p={3}>
          <LayoutContainer mb={2}>
            <LayoutContainer mb={2}>
              <StyledHeading>
                <LayoutContainer as="span" mr={1}>
                  <InlineDropdown
                    label=""
                    onSelect={setDropdownState}
                    options={[
                      {
                        key: "Critical",
                        value: MonitorRiskLevel.Critical,
                        selected: MonitorRiskLevel.Critical === dropdownState,
                      },
                      {
                        key: "High",
                        value: MonitorRiskLevel.High,
                        selected: MonitorRiskLevel.High === dropdownState,
                      },
                      {
                        key: "Medium",
                        value: MonitorRiskLevel.Medium,
                        selected: MonitorRiskLevel.Medium === dropdownState,
                      },
                      {
                        key: "Low",
                        value: MonitorRiskLevel.Low,
                        selected: MonitorRiskLevel.Low === dropdownState,
                      },
                      {
                        key: "All",
                        value: "ALL",
                        selected: "ALL" === dropdownState,
                      },
                    ]}
                  />
                </LayoutContainer>
                risk rules violated in the last
                <LayoutContainer as="span" ml={1}>
                  <InlineDateDropdown
                    value={dropdownStateTime}
                    onSelect={setDropdownStateTime}
                  />
                </LayoutContainer>
              </StyledHeading>
            </LayoutContainer>
            <RulesViolatedOverTime
              filterByCriticality={dropdownState}
              loading={false}
              filters={filterDate}
            />
          </LayoutContainer>
          <PrimarySquareButton as={Link} to={APPLICATION_ROUTES.VIOLATIONS.url}>
            Fix Now
          </PrimarySquareButton>
        </Box>
      </Content>
    </PageHeaderWrapper>
  );
};
