export const CLASSIFIER_ANALYTICS = {
  "CLASSIFIER: Create Custom": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
  "CLASSIFIER: Update Custom": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
  "CLASSIFIER: Override": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
  "CLASSIFIER: View Details": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
  "CLASSIFIER: Enable": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
  "CLASSIFIER: Disable": ({ name }: { name: string }) => ({
    custom_classifier_name: name,
  }),
};
