import React, { FunctionComponent } from "react";

import { ErrorBoundary } from "react-error-boundary";
import { AlertTriangle } from "react-feather";
import styled from "styled-components";

import { ButtonOutlinePrimary } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { Navigation } from "../../components/Navigation";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { useModal } from "../../contexts/modals";
import { ApplicationRoute } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";

export interface IDashboard {
  nestedRoutes?: {
    [key: string]: ApplicationRoute;
  };
  isAlpha?: boolean;
  currentRouteDefinition?: ApplicationRoute;
}

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(3)};
`;

const StyledAlertIcon = styled(AlertTriangle)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${COLOR.ERROR};
`;

const StyledErrorBoundaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - var(--logo-height));
`;

export const Dashboard: FunctionComponent<IDashboard> = ({ children }) => {
  const { confirmModal } = useModal();
  return (
    <Navigation isLoggedIn={true}>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <StyledErrorBoundaryContainer role="alert">
            <StyledAlertIcon size={60} />
            <StyledHeading Element="h1" type="display-medium" isBodyColor>
              Something went wrong
            </StyledHeading>
            <ButtonOutlinePrimary
              onClick={() => {
                resetErrorBoundary();
              }}
            >
              Try Again
            </ButtonOutlinePrimary>
          </StyledErrorBoundaryContainer>
        )}
      >
        {children}

        <ConfirmModal
          data={confirmModal.data}
          showModal={confirmModal.visible}
          toggleModal={confirmModal.toggleConfirm}
        />
      </ErrorBoundary>
    </Navigation>
  );
};
