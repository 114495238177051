import { Column } from "react-table";

import { IAccountMembership } from ".";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { USER_ROLE } from "../../definitions/types/users";
import { ContextMenu } from "./ContextMenu";

export interface IManageUsersTableRow extends IAccountMembership {}

// TODO: This is copied from `src/components/modals/InviteUserModal.tsx` but it already has a todo to move it out
const userRoleOptions = [
  { value: "READONLY", label: "Read Only" },
  { value: "MEMBER", label: "Member" },
  { value: "OWNER", label: "Owner" },
];
const roleValueToLabel = (value: USER_ROLE): string | undefined =>
  userRoleOptions.find((role) => role.value === value)?.label;

export const columns: Column<IManageUsersTableRow>[] = [
  {
    Header: "Email",
    accessor: "user",
    id: "email",
    width: 200,
    minWidth: 50,
    maxWidth: 500,
    Cell: (data) =>
      `${data.cell.value.primaryEmail}${
        data.row.original.status === "PENDING_INVITE_ACCEPTANCE"
          ? " (pending)"
          : ""
      }`,
  },
  {
    Header: "Permission Level",
    accessor: "role",
    id: "role",
    width: 200,
    minWidth: 50,
    maxWidth: 500,
    Cell: (data) =>
      roleValueToLabel(data.cell.value.toUpperCase() as USER_ROLE) || null,
  },
  {
    Header: () => null,
    id: "context-menu-dots",
    accessor: "user",
    width: 50,
    minWidth: 50,
    maxWidth: 50,
    disableResizing: true,
    isContextMenu: true,
    Cell: (data) => (
      <LayoutContainer ml="auto">
        <ContextMenu {...data.row.original} />
      </LayoutContainer>
    ),
  },
];
