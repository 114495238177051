import { lazy } from "react";

import { USER_PERMISSIONS } from "../definitions/types/users";
import { IntegrationRedirect } from "../page/Integrations/redirect";
import { Login } from "../page/Login";
import { Overview } from "../page/Overview";
import { ActionHistory } from "../page/Risks/ActionHistory";
import { RiskGroups } from "../page/Risks/RiskGroups";
import { MonitorRuleRedirect } from "../page/Rules/redirect";
import { IFeatureFlag } from "../utils/feature-flags/constants";

const Violations = lazy(() => import("../page/Violations/Violations"));
const SDFOverviewRoot = lazy(() => import("../page/Risks/Root"));
const Risks = lazy(() => import("../page/Risks/Risks"));
const ReportsRoot = lazy(() => import("../page/Reports/ReportsRoot"));

const ClassifierCreate = lazy(
  () => import("../page/Classifiers/ClassifierCreate")
);

const ClassifierDetail = lazy(
  () => import("../page/Classifiers/ClassifierDetail")
);

const ClassifierListPage = lazy(
  () => import("../page/Classifiers/ClassifierList")
);

const ClassifierRoot = lazy(() => import("../page/Classifiers/ClassifierRoot"));

const AnalyticsOverview = lazy(
  () => import("../page/Discovery/AnalyticsOverview")
);

const AnalyticsRoot = lazy(() => import("../page/Discovery/AnalyticsRoot"));

const InspectorPage = lazy(() => import("../page/Discovery/Inspector"));

const ReportsViolations = lazy(
  () => import("../page/Reports/ReportsViolations")
);

const UserAuditLogs = lazy(() => import("../page/Reports/UserAuditLogs"));

const RuleCreate = lazy(() => import("../page/Rules/RuleCreate"));

const RuleDetail = lazy(() => import("../page/Rules/RuleDetail"));

const RuleListPage = lazy(() => import("../page/Rules/RuleList"));

const RuleRoot = lazy(() => import("../page/Rules/RuleRoot"));

const SettingsAlerts = lazy(() => import("../page/Settings/SettingsAlerts"));

const SettingsIntegrations = lazy(
  () => import("../page/Settings/SettingsIntegrations")
);

const SettingsManageUsers = lazy(
  () => import("../page/Settings/SettingsManageUsers")
);

const SettingsRoot = lazy(() => import("../page/Settings/SettingsRoot"));

const SettingsWebhooks = lazy(
  () => import("../page/Settings/SettingsWebhooks")
);

const Authors = lazy(() => import("../page/Authors/Authors"));

// This is defo 'MVP' but it allows me to make quick config changes and tweaks

interface ApplicationRoutes {
  [key: string]: ApplicationRoute;
}

export interface ApplicationRoute {
  displayName?: string;
  url: string;
  NESTED_ROUTES?: {
    [key: string]: ApplicationRoute;
  };
  requiresAuthentication: boolean;
  Component: React.ElementType;
  breadcrumb?: string[];
  exact?: boolean;
  hideTab?: boolean;
  featureFlag?: IFeatureFlag;
  permissions?: string[];
  permissionDenied?: boolean;
}

export const APPLICATION_ROUTES: ApplicationRoutes = {
  ROOT: {
    url: "/",
    requiresAuthentication: false,
    Component: Login,
    exact: true,
  },
  VIOLATIONS: {
    url: "/violations",
    requiresAuthentication: true,
    Component: Violations,
    breadcrumb: ["Violations"],
    // featureFlag: "sdf_overview_page",
  },
  OVERVIEW: {
    breadcrumb: ["Overview"],
    url: "/overview",
    Component: Overview,
    requiresAuthentication: true,
  },
  RISKS: {
    breadcrumb: ["Risks"],
    url: "/risks",
    Component: SDFOverviewRoot,
    requiresAuthentication: true,
    NESTED_ROUTES: {
      SDF: {
        displayName: "Risks",
        url: "/risks/list",
        requiresAuthentication: true,
        Component: Risks,
        breadcrumb: [],
        exact: true,
      },
      RISK_GROUPS: {
        displayName: "Risk Groups",
        url: "/risks/risk-groups",
        requiresAuthentication: true,
        Component: RiskGroups,
        breadcrumb: [],
        exact: true,
        featureFlag: "risk_groups",
      },
      ACTION_HISTORY: {
        displayName: "Action History",
        url: "/risks/actions",
        requiresAuthentication: true,
        Component: ActionHistory,
        breadcrumb: [],
        exact: true,
        featureFlag: "bulk_actions",
      },
    },
  },
  DISCOVERY: {
    url: "/discovery",
    requiresAuthentication: true,
    Component: AnalyticsRoot,
    breadcrumb: ["Discovery"],
    NESTED_ROUTES: {
      OVERVIEW: {
        displayName: "Analytics",
        url: "/discovery/overview/:installationId?",
        requiresAuthentication: true,
        Component: AnalyticsOverview,
        breadcrumb: ["Discovery"],
        exact: true,
      },
      INSPECTOR: {
        displayName: "Inspector",
        url: "/discovery/inspector",
        requiresAuthentication: true,
        Component: InspectorPage,
        breadcrumb: ["Inspector"],
        exact: true,
      },
    },
  },
  REPORTS: {
    url: "/reports",
    requiresAuthentication: true,
    Component: ReportsRoot,
    breadcrumb: ["Reports"],
    NESTED_ROUTES: {
      OVERVIEW: {
        displayName: "Violations",
        url: "/reports/violations",
        requiresAuthentication: true,
        Component: ReportsViolations,
        breadcrumb: ["Violations"],
        exact: true,
      },
      AUDITS: {
        displayName: "Audit Logs",
        url: "/reports/audit-logs",
        requiresAuthentication: true,
        Component: UserAuditLogs,
        breadcrumb: ["Audit Logs"],
        exact: true,
      },
    },
  },
  RULES: {
    url: "/rules",
    requiresAuthentication: true,
    Component: RuleRoot,
    breadcrumb: ["Rules"],
    NESTED_ROUTES: {
      LIST: {
        displayName: "Rules",
        url: "/rules/list",
        requiresAuthentication: true,
        Component: RuleListPage,
        breadcrumb: [],
        exact: true,
      },
      DETAIL: {
        hideTab: true,
        url: "/rules/detail/:monitorId?",
        requiresAuthentication: true,
        Component: RuleDetail,
        breadcrumb: [],
        exact: true,
      },
      CREATE: {
        displayName: "Create",
        url: "/rules/create",
        requiresAuthentication: true,
        Component: RuleCreate,
        breadcrumb: ["Create"],
        exact: true,
      },
    },
  },
  MONITOR: {
    url: "/monitor",
    requiresAuthentication: true,
    Component: MonitorRuleRedirect,
    breadcrumb: [""],
  },
  INTEGRATIONS: {
    url: "/integrations",
    requiresAuthentication: true,
    Component: IntegrationRedirect,
    breadcrumb: ["Integrations"],
  },
  CLASSIFIERS: {
    url: "/classifiers",
    requiresAuthentication: true,
    Component: ClassifierRoot,
    breadcrumb: ["Classifiers"],
    NESTED_ROUTES: {
      LIST: {
        displayName: "Classifiers",
        url: "/classifiers/list",
        requiresAuthentication: true,
        Component: ClassifierListPage,
        breadcrumb: ["Classifiers"],
        exact: true,
      },
      DETAIL: {
        hideTab: true,
        url: "/classifiers/detail/:classifierId?",
        requiresAuthentication: true,
        Component: ClassifierDetail,
        breadcrumb: ["Classifiers"],
        exact: true,
      },
      CREATE: {
        displayName: "Create",
        url: "/classifiers/create",
        requiresAuthentication: true,
        Component: ClassifierCreate,
        breadcrumb: ["Classifiers"],
        exact: true,
      },
    },
  },
  SETTINGS: {
    url: "/settings",
    requiresAuthentication: true,
    Component: SettingsRoot,
    breadcrumb: ["Settings"],
    NESTED_ROUTES: {
      INTEGRATIONS: {
        displayName: "Integrations",
        url: "/settings/integrations",
        requiresAuthentication: true,
        Component: SettingsIntegrations,
        breadcrumb: ["Settings"],
        exact: true,
      },
      MANAGE_USERS: {
        displayName: "Manage Users",
        url: "/settings/manage-users",
        requiresAuthentication: true,
        Component: SettingsManageUsers,
        breadcrumb: ["Settings"],
        permissions: [USER_PERMISSIONS.UPDATE_MEMBERSHIP],
        exact: true,
      },
      WEBHOOKS: {
        displayName: "Webhooks",
        url: "/settings/webhooks",
        requiresAuthentication: true,
        Component: SettingsWebhooks,
        breadcrumb: ["Settings"],
        exact: true,
      },
      ALERTS: {
        displayName: "Alerts",
        url: "/settings/alerts",
        requiresAuthentication: true,
        Component: SettingsAlerts,
        breadcrumb: ["Settings", "Alerts"],
        exact: true,
      },
    },
  },
  AUTHORS: {
    breadcrumb: ["Authors"],
    url: "/authors",
    requiresAuthentication: true,
    Component: Authors,
    exact: true,
  },
  LOGIN: {
    url: "/login",
    requiresAuthentication: false,
    Component: Login,
    exact: true,
  },
};

export const AUTHENTICATED_APPLICATION_ROUTES: ApplicationRoutes = {};
