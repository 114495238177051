import React from "react";

import styled from "styled-components";

import { ContextMenuButton, ReactRouterLink } from "../../atoms/Button";
import { ContextMenuContainer } from "../../components/ContextMenuContainer";
import { sendAnalytics } from "../../operations/user/analytics";

const StyledMenuLink = styled(ContextMenuButton)`
  display: flex;
  align-items: center;
`;

export const ContextMenu = ({ id }: { id: string }) => (
  <ContextMenuContainer>
    <StyledMenuLink
      as={ReactRouterLink}
      onClick={() => {
        sendAnalytics("VIOLATION: Expanded Detections", undefined);
      }}
      to={`/rules/detail/${id}`}
    >
      View More
    </StyledMenuLink>
  </ContextMenuContainer>
);
