import { gql } from "urql";

import { MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT } from "../../monitors/fragments/monitorViolationSummaryFields";

export const RULE_LIST_BY_DATE_QUERY = gql`
  ${MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT}
  query RuleListOverTimeQuery($from: Date, $to: Date) {
    listMonitorsOverTime(from: $from, to: $to) {
      ...MonitorViolationSummaryFields
    }
  }
`;
