import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  Activity as ActivityIcon,
  Settings as SettingsIcon,
  Book,
  Tag,
  FileText,
  Compass,
  Menu,
  X,
  Users,
  Zap,
  Target,
} from "react-feather";
import { generatePath, useLocation } from "react-router";
import { LinkProps, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { IUser } from "../../definitions/types/users";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { useFeatureFlag } from "../../utils/feature-flags/hooks/useFeatureFlag";
import {
  MOBILE_BREAKPOINT,
  useMediaQuery,
} from "../../utils/responsive/useMediaQuery";
import { Logo } from "../Logo";

export interface INavigation {
  isLoggedIn: boolean;
}

interface INavLinkContainer extends LinkProps {
  className?: string;
  text?: string;
  enableTooltip?: boolean;
}

export interface IMeData {
  me: IUser;
}

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(1)};
  border-color: rgba(255, 255, 255, 0.1);
  color: ${COLOR.OFF_WHITE_DARKER};
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: 20px;
  height: 50px;
`;

const StyledNavLinkHover = styled(StyledNavLink)`
  position: relative;
  &:hover {
    background: ${COLOR.PRIMARY_NAV_HOVER};
    color: ${COLOR.OFF_WHITE};
  }
`;

const NavLinkContainer: FunctionComponent<INavLinkContainer> = ({
  children,
  className,
  to,
  text,
}) => (
  <StyledNavLinkHover
    to={to}
    className={className}
    activeStyle={{
      backgroundColor: COLOR.PRIMARY_NAV_HOVER,
      color: COLOR.OFF_WHITE,
    }}
  >
    {children}
    {text}
  </StyledNavLinkHover>
);

const StyledNavLinkWithBorder = styled(NavLinkContainer)`
  border-left-width: 1px;
`;

const StyledNavLinkWithYBorders = styled(NavLinkContainer)`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${COLOR.PRIMARY_NAV_HOVER};
`;

const StyledNav = styled.nav`
  background: ${COLOR.PRIMARY};
  flex: 0 1 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-color: ${COLOR.PRIMARY_NAV_HOVER};
  font-size: ${theme.fontSizes.mdlg};
  position: fixed;
  left: 0;
  border-right-width: 1px;
  transition: all 500ms ease-in-out;
  width: 150px;
  height: 100%;

  @media screen and (min-width: 768px) {
    z-index: 10;
  }
`;

const StyledInnerNavContainer = styled.div`
  min-width: min-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 500ms ease-in-out;
`;

const StyledLogoLink = styled(NavLink)`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(1)};
  border-color: ${COLOR.PRIMARY_NAV_HOVER};
  color: rgba(250, 250, 250, 0.5);
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: 20px;
  height: 50px;
  border-right-width: 1px;
  align-content: center;
  justify-content: center;
  border-bottom-width: 1px;
  height: 65px;
  padding-left: 12px;
  padding-right: 12px;
  width: 150px;
  overflow: hidden;
`;

const StyledIcon = styled.span`
  width: 25px;
  margin-right: ${SPECIFIC_SPACING["12px"]};
`;

const StyledMobileMenuIcon = styled.span`
  color: ${COLOR.PRIMARY};
`;

const StyledMobileMenuButton = styled.button<{
  menuIsExpanded?: boolean;
  isMobile?: boolean;
}>`
  position: fixed;
  margin-left: ${APPLICATION_SPACING(2)};
  z-index: 50;
  transition: 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  top: 21px;

  ${({ menuIsExpanded, isMobile }) =>
    menuIsExpanded &&
    isMobile &&
    css`
      transform: translateX(150px);
    `}
`;

const NavChildrenWrapper = styled.div<{
  menuIsExpanded?: boolean;
  isMobile?: boolean;
}>`
  background: ${COLOR.PAGE_BACKGROUND};
  width: 100%;
  z-index: 10;
  position: relative;
  transition: 0.4s cubic-bezier(0.42, 0, 0.58, 1);

  ${({ menuIsExpanded, isMobile }) =>
    menuIsExpanded &&
    isMobile &&
    css`
      transform: translateX(150px);
    `}

  @media screen and (min-width: 768px) {
    z-index: 0;
    padding-left: 150px;
    background: transparent;
    width: auto;
    transition: none;
  }
`;

export const Navigation: FunctionComponent<INavigation> = ({ children }) => {
  const navRef = useRef<HTMLElement>(null);
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const location = useLocation();

  const FF_SDF_PAGE = useFeatureFlag("sdf_overview_page");

  const isLargeLayout = useMediaQuery(MOBILE_BREAKPOINT);

  const isMobile = !isLargeLayout;

  useEffect(() => {
    setMenuIsExpanded(false);
  }, [isMobile, location]);

  return (
    <div>
      <StyledNav ref={navRef}>
        <StyledInnerNavContainer>
          <StyledLogoLink
            to={{
              pathname: generatePath(APPLICATION_ROUTES.OVERVIEW.url || ""),
              search: window.location.search,
            }}
          >
            <Logo />
          </StyledLogoLink>
          <StyledNavLinkWithBorder
            enableTooltip={!menuIsExpanded}
            text="Overview"
            to={{
              pathname: APPLICATION_ROUTES.OVERVIEW.url,
            }}
          >
            <StyledIcon as={ActivityIcon} size={20} />
          </StyledNavLinkWithBorder>
          <StyledNavLinkWithBorder
            enableTooltip={!menuIsExpanded}
            text="Risks"
            to={{
              pathname: APPLICATION_ROUTES.RISKS.url,
            }}
          >
            <StyledIcon as={Target} size={20} />
          </StyledNavLinkWithBorder>
          {FF_SDF_PAGE.enabled && (
            <StyledNavLinkWithBorder
              enableTooltip={!menuIsExpanded}
              text="Violations"
              to={{
                pathname: APPLICATION_ROUTES.VIOLATIONS.url,
              }}
            >
              <StyledIcon as={Zap} size={20} />
            </StyledNavLinkWithBorder>
          )}

          <NavLinkContainer
            enableTooltip={!menuIsExpanded}
            text="Discovery"
            to={{
              pathname: APPLICATION_ROUTES.DISCOVERY.url,
              search: window.location.search,
            }}
          >
            <StyledIcon as={Compass} size={20} />
          </NavLinkContainer>
          <NavLinkContainer
            enableTooltip={!menuIsExpanded}
            text="Rules"
            to={{
              pathname: APPLICATION_ROUTES.RULES.url,
              search: window.location.search,
            }}
          >
            <StyledIcon as={Book} size={20} />
          </NavLinkContainer>
          <NavLinkContainer
            enableTooltip={!menuIsExpanded}
            text="Classifiers"
            to={{
              pathname: APPLICATION_ROUTES.CLASSIFIERS.url,
              search: window.location.search,
            }}
          >
            <StyledIcon as={Tag} size={20} />
          </NavLinkContainer>
          <NavLinkContainer
            enableTooltip={!menuIsExpanded}
            text="Authors"
            to={{
              pathname: APPLICATION_ROUTES.AUTHORS.url,
              search: window.location.search,
            }}
          >
            <StyledIcon as={Users} />
          </NavLinkContainer>
          <NavLinkContainer
            enableTooltip={!menuIsExpanded}
            text="Reports"
            to={{
              pathname: APPLICATION_ROUTES.REPORTS.url,
              search: window.location.search,
            }}
          >
            <StyledIcon as={FileText} size={20} />
          </NavLinkContainer>

          <StyledNavLinkWithYBorders
            enableTooltip={!menuIsExpanded}
            text="Settings"
            to={APPLICATION_ROUTES.SETTINGS.url}
          >
            <StyledIcon as={SettingsIcon} size={18} />
          </StyledNavLinkWithYBorders>
        </StyledInnerNavContainer>
      </StyledNav>
      {isMobile && (
        <StyledMobileMenuButton
          isMobile={isMobile}
          menuIsExpanded={menuIsExpanded}
          onClick={() => setMenuIsExpanded(!menuIsExpanded)}
        >
          {menuIsExpanded ? (
            <StyledMobileMenuIcon as={X} size={24} />
          ) : (
            <StyledMobileMenuIcon as={Menu} size={24} />
          )}
        </StyledMobileMenuButton>
      )}
      <NavChildrenWrapper isMobile={isMobile} menuIsExpanded={menuIsExpanded}>
        {children}
      </NavChildrenWrapper>
    </div>
  );
};
