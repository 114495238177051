import { formatDistanceToNow, parseISO } from "date-fns";
import pluralize from "pluralize";
import { Column } from "react-table";
import styled, { css } from "styled-components";

import { OutlineErrorButton } from "../../atoms/Button";
import { ActorIcon } from "../../components/ActorIcon";
import { StatusIndicator } from "../../components/StatusIndicator";
import { VendorIcons } from "../../components/VendorIcons";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { VENDORS } from "../../definitions/types/vendors";
import {
  BulkAction,
  BulkActionSummaryCommandCount,
  RiskActionCommandEnum,
} from "../../generated/graphql.generated";
import {
  RiskActionCommandIconMap,
  RiskActionDescriptionLabelMap,
} from "../../operations/risks/helpers";
import { useCancelBulkActionMutation } from "../../operations/risks/mutations/cancelBulkAction.generated";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import { defaultTo, getMaybeArrayValue } from "../../utils/maybe/defaultTo";
import { capitalize } from "../../utils/string/capitalize";

const NoWrapLayoutContainer = styled(LayoutContainer)`
  white-space: nowrap;
`;

export const getActionIcon = (commands: BulkActionSummaryCommandCount[]) => {
  const onlyCommands = commands.map(({ command }) => command);
  const uniqueActions = [...new Set(onlyCommands)];
  return uniqueActions.map((command, i) => {
    if (i === 2) {
      return (
        <NoWrapLayoutContainer key={i}>{`+ ${
          uniqueActions.length - 2
        }`}</NoWrapLayoutContainer>
      );
    } else {
      return i > 2 ? null : (
        <NoWrapLayoutContainer
          key={i}
          mr={uniqueActions.length - 1 === i ? 0 : 1}
        >
          {RiskActionCommandIconMap[command as RiskActionCommandEnum] ?? ""}
        </NoWrapLayoutContainer>
      );
    }
  });
};

export const getActionSummaryAppTypes = (
  commands: BulkActionSummaryCommandCount[]
) => {
  const onlyAppTypes = commands.map(({ appType }) => appType);
  const uniqueAppTypes = [...new Set(onlyAppTypes)];
  return <VendorIcons width="1.5rem" vendors={uniqueAppTypes as VENDORS[]} />;
};

export const getActionSummaryDescription = (
  commands: BulkActionSummaryCommandCount[]
) => {
  return commands.map((command, i) => {
    return `${command.count} ${capitalize(
      defaultTo(command.appType, "")
    )} ${pluralize(
      RiskActionDescriptionLabelMap[
        defaultTo(command.command, "") as RiskActionCommandEnum
      ],
      defaultTo(command.count, 0)
    )}${
      i === commands.length - 1
        ? ""
        : i === commands.length - 2
        ? " and "
        : ", "
    }`;
  });
};

export const columns: Column<BulkAction>[] = [
  {
    Header: "Status",
    accessor: "status",
    width: 140,
    disableResizing: true,
    Cell: (data) => (
      <StatusIndicator
        inProgress={data.cell.value === "active"}
        error={data.cell.value === "errored"}
        completed={formatDistanceToNow(parseISO(data.row.original.startedAt))} // This will be the date when the query is supported
      />
    ),
  },
  {
    Header: "Action",
    accessor: "summary",
    width: 120,
    disableResizing: true,
    Cell: (data) => {
      return (
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={"2px" as any}
          backgroundColor="GRAYSCALE_UNEDITABLE"
          px={1}
          height="28px"
          flexWrap="nowrap"
        >
          {getActionIcon(getMaybeArrayValue(data.cell.value?.commands))}
        </LayoutContainer>
      );
    },
  },
  {
    Header: "Summary",
    id: "Summary",
    accessor: "summary",
    minWidth: 300,
    maxWidth: 900,
    Cell: (data) => {
      return (
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={"2px" as any}
          flexWrap="nowrap"
        >
          {getActionSummaryAppTypes(
            getMaybeArrayValue(data.cell.value?.commands)
          )}

          <LayoutContainer ml={1}>
            {getActionSummaryDescription(
              getMaybeArrayValue(data.cell.value?.commands)
            )}
          </LayoutContainer>
        </LayoutContainer>
      );
    },
  },
  {
    Header: "Author",
    accessor: "createdByUser",
    width: 130,
    columnStyles: css`
      justify-content: center;
      padding-right: ${APPLICATION_SPACING(2)};
    `,
    headerStyles: css`
      text-align: center;
    `,
    Cell: (data) => (
      <LayoutContainer display="flex" alignItems="center" pr={4}>
        <LayoutContainer mr={SPECIFIC_SPACING["12px"]}>
          <ActorIcon
            imageUrl={defaultTo(data.cell.value?.avatarUrl, "")}
            displayName={defaultTo(data.cell.value?.nickname, "")}
          />
        </LayoutContainer>
        {data.cell.value?.nickname && data.cell.value?.nickname?.length > 30
          ? `${data.cell.value?.nickname?.slice(0, 30)}...`
          : data.cell.value?.nickname}
      </LayoutContainer>
    ),
  },
];

const CancelBulkActionButton = ({ id }: { id: string }) => {
  const [cancelBulkActionState, cancelBulkAction] =
    useCancelBulkActionMutation();

  return (
    <OutlineErrorButton
      onClick={() => {
        cancelBulkAction({
          id,
        });
      }}
      loading={cancelBulkActionState.fetching}
    >
      Cancel Job
    </OutlineErrorButton>
  );
};

export const columnsWithCancel: Column<BulkAction>[] = [
  ...columns,
  {
    Header: () => null,
    accessor: "cancelledAt",
    width: 150,
    disableResizing: true,
    Cell: (data) => {
      return (
        <CancelBulkActionButton id={defaultTo(data.row.original.id, "")} />
      );
    },
  },
];
