import React, { FunctionComponent } from "react";

import { Redirect, useLocation } from "react-router";

interface IRedirect {}

export const IntegrationRedirect: FunctionComponent<IRedirect> = () => {
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: location.pathname.replace(
          "/integrations",
          "/settings/integrations"
        ),
        search: location.search,
      }}
    />
  );
};
