import { Filt } from "@metomic/darwin-shared-lib";
import { NodeTypes } from "@metomic/darwin-shared-lib/dist/shared-lib/filters/lensing/filter.types";

import { GraphCacheResolvers } from "../../generated/graphql.generated";
import { relayPagination } from "../pagination/urqlRelayPagination";

const parseFilters = ({ filters }: { filters: NodeTypes.all }) =>
  Filt.Lens.on(filters);

export const resolvers: GraphCacheResolvers = {
  Monitor: {
    violations: relayPagination(),
    filters: ({ filters }) =>
      filters ? parseFilters({ filters: filters }) : null,
  },
  SavedQuery: {
    filters: ({ filters }) =>
      filters ? parseFilters({ filters: filters }) : null,
  },
  RootQueryType: {
    retrieveUserAuditLogs: relayPagination(),
    datapointSearch: relayPagination(),
    detectionSearch: relayPagination(),
    detectionBundleConnection: relayPagination(),
    findDetectionsForDatapoint: relayPagination(),
    listAccountMemberships: relayPagination(),
    listQueries: relayPagination(),
    listCustomClassifiers: relayPagination(),
    listActors: relayPagination(),
    listRisks: relayPagination(),
    atomSearch: relayPagination(),
    listBulkActions: relayPagination(),
    listRiskActionsByBulkActionId: relayPagination(),
    listRiskGroups: relayPagination(),
  } as GraphCacheResolvers["RootQueryType"],
};
