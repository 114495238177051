import { Column } from "react-table";
import { css } from "styled-components";

import { RiskDot } from "../../atoms/icons/RiskDot";
import { LocationContextPill } from "../../components/LocationContextPill";
import { StatusIndicator } from "../../components/StatusIndicator";
import { VendorIcon } from "../../components/VendorIcons";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { AnimatedFeatherLoader } from "../../components/loading/AnimatedFeatherLoader";
import { VENDORS } from "../../definitions/types/vendors";
import {
  Risk,
  RiskActionCommandEnum,
  RiskSdfLevelEnum,
} from "../../generated/graphql.generated";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { localeBasedShortDate } from "../../utils/date";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { getActionIcon } from "../BulkActions/columns";

export const columns: Column<Risk>[] = [
  {
    Header: () => null,
    accessor: "id",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    `,
    Cell: (data) => (
      <VendorIcon vendor={defaultTo(data.row.original.atom?.appType, "")} />
    ),
  },
  {
    Header: () => null,
    accessor: "atom",
    columnStyles: css`
      padding-right: ${APPLICATION_SPACING(3)};
      padding-left: ${APPLICATION_SPACING(3)};
    `,
    Cell: (data) => {
      return (
        <LayoutContainer display="flex" alignItems="center" overflow="hidden">
          <LayoutContainer
            minWidth={60}
            mr={2}
            color="PLACEHOLDER"
            fontSize="xsm"
          >
            {data.row.original.shortCode}
          </LayoutContainer>
          {data.row.original.isDirty && (
            <LayoutContainer mr={2} color="SECONDARY" fontSize="xsm">
              <AnimatedFeatherLoader />
            </LayoutContainer>
          )}
          <LocationContextPill
            atomId={defaultTo(data.row.original?.atomId, "")}
            location={{
              appType: data.row.original.atom?.appType as VENDORS,
              temp_canonicalLabel: defaultTo(
                data.cell.value?.location?.label,
                ""
              ),
              temp_entityDeeplink: defaultTo(
                data.cell.value?.location?.deeplink,
                ""
              ),
              entityType: defaultTo(data.cell.value?.entityType, ""),
            }}
          />
        </LayoutContainer>
      );
    },
  },
  {
    Header: () => null,
    accessor: "sharedAt",
    width: 120,
    minWidth: 120,
    maxWidth: 120,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${theme.fontSizes.xsm};
      padding-right: 0;
    `,
    Cell: (data) => localeBasedShortDate(new Date(data.cell.value)),
  },
  {
    Header: () => null,
    accessor: "sdf",
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
    `,
    Cell: (data) => (
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
      >
        <RiskDot
          sdf={{
            level: defaultTo(data.row.original.sdfLevel, RiskSdfLevelEnum.Low),
          }}
        />
        {data.cell.value}
      </LayoutContainer>
    ),
  },
];

export interface RiskWithActionStatus extends Risk {
  status?: string;
  command?: RiskActionCommandEnum;
}

export const riskColumnsWithStatus: Column<RiskWithActionStatus>[] = [
  {
    Header: () => null,
    accessor: "status",
    id: "status",
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    disableResizing: true,
    columnStyles: css`
      padding-right: 0;
    `,
    Cell: (data) => (
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
      >
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="sm"
        >
          <LayoutContainer fontSize="xsm">
            <StatusIndicator
              hideLabel
              inProgress={!!data.row.original.isDirty}
              error={data.cell.value === "unrecoverable"}
              isSuccess={data.cell.value === "succeeded"}
            />
          </LayoutContainer>
        </LayoutContainer>
      </LayoutContainer>
    ),
  },
  {
    Header: "Action",
    accessor: "command",
    width: 50,
    disableResizing: true,
    Cell: (data) => {
      return (
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={"2px" as any}
          backgroundColor="GRAYSCALE_UNEDITABLE"
          px={1}
          height="28px"
          flexWrap="nowrap"
        >
          {getActionIcon([{ command: data.cell.value }])}
        </LayoutContainer>
      );
    },
  },
  {
    Header: () => null,
    accessor: "id",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    `,
    Cell: (data) => (
      <VendorIcon vendor={defaultTo(data.row.original.atom?.appType, "")} />
    ),
  },
  {
    Header: () => null,
    accessor: "atom",
    columnStyles: css`
      padding-right: ${APPLICATION_SPACING(3)};
      padding-left: ${APPLICATION_SPACING(3)};
    `,
    Cell: (data) => {
      return (
        <LayoutContainer display="flex" alignItems="center" overflow="hidden">
          <LayoutContainer
            minWidth={60}
            mr={2}
            color="PLACEHOLDER"
            fontSize="xsm"
          >
            {data.row.original.shortCode}
          </LayoutContainer>
          <LocationContextPill
            atomId={defaultTo(data.row.original?.atomId, "")}
            location={{
              appType: data.row.original.atom?.appType as VENDORS,
              temp_canonicalLabel: defaultTo(
                data.cell.value?.location?.label,
                ""
              ),
              temp_entityDeeplink: defaultTo(
                data.cell.value?.location?.deeplink,
                ""
              ),
              entityType: defaultTo(data.cell.value?.entityType, ""),
            }}
          />
        </LayoutContainer>
      );
    },
  },
  {
    Header: () => null,
    accessor: "sdf",
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    disableResizing: true,
    columnStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
    `,
    Cell: (data) => (
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="sm"
      >
        <RiskDot
          sdf={{
            level: defaultTo(data.row.original.sdfLevel, RiskSdfLevelEnum.Low),
          }}
        />
        {data.cell.value}
      </LayoutContainer>
    ),
  },
];
