import { FieldTypes } from "@metomic/darwin-shared-lib/dist/shared-lib/filters/lensing/filter.types";

import { MonitorRiskLevel } from "../../../../generated/graphql.generated";
import { UIFilterState } from "../../../../recoil/filters/atom";

const didUseAdvancedFilter = (filterState: UIFilterState) =>
  !!filterState.filters.collect<FieldTypes.EntityProp>((cur) => {
    if (cur.node.type === "must" && cur.node.field === "entityProp") {
      return cur.node;
    }
    return undefined;
  }).length;

type RuleActionType =
  | "Redact"
  | "Notify Data Author"
  | "Alert Admin"
  | "Reduce Exposure";

export const RULE_ANALYTICS = {
  "RULE: Create": ({
    name,
    riskLevel,
    filters,
  }: {
    name: string;
    riskLevel: MonitorRiskLevel;
    filters: UIFilterState;
  }) => ({
    rule_name: name,
    rule_risk_level: riskLevel,
    rule_didUseAdvancedFilter: didUseAdvancedFilter(filters),
  }),
  "RULE: Updated": ({
    name,
    riskLevel,
    filters,
  }: {
    name: string;
    riskLevel: MonitorRiskLevel;
    filters: UIFilterState;
  }) => ({
    rule_name: name,
    rule_risk_level: riskLevel,
    rule_didUseAdvancedFilter: didUseAdvancedFilter(filters),
  }),
  "RULE: Enable": ({ name }: { name: string }) => ({ name }),
  "RULE: Disable": ({ name }: { name: string }) => ({ name }),
  "RULE: Deleted": ({ name }: { name: string }) => ({
    rule_name: name,
  }),
  "RULE: Edit Modal Opened": () => ({}),
  "RULE: View Details": () => ({}),
  "RULE: Enable Control": ({
    type,
    name,
  }: {
    type: RuleActionType;
    name: string;
  }) => ({
    rule_name: name,
    rule_control_type: type,
  }),
  "RULE: Disable Control": ({
    type,
    name,
  }: {
    type: RuleActionType;
    name: string;
  }) => ({
    rule_name: name,
    rule_control_type: type,
  }),
  "RULE: Set Custom Notify Data Author Message": ({
    message,
  }: {
    message: string;
  }) => ({
    custom_notify_message: message,
  }),
  "RULE: Set Alert Admin Alert Channel": ({
    channels,
    name,
  }: {
    channels: string;
    name: string;
  }) => ({
    alert_admin_channels: channels,
    rule_name: name,
  }),
  "RULE: Enable Retention Policy": ({
    control,
    delay,
    name,
  }: {
    control: RuleActionType;
    delay: `${string}:${string}`;
    name: string;
  }) => ({
    rule_control_type: control,
    rule_control_retention_delay: delay,
    rule_name: name,
  }),
  "RULE: Viewed Resolved Violations": () => ({}),
  "RULE: Viewed Unresolved Violations": () => ({}),
  "RULE: Viewed Resolved Violations Details": () => ({}),
  "RULE: Viewed Pending Actions": () => ({}),
};
