import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: "RootQueryType";
  me?:
    | {
        __typename?: "Me";
        id?: string | null | undefined;
        customerId?: string | null | undefined;
        nickname?: string | null | undefined;
        avatarUrl?: string | null | undefined;
        permissions?: Array<string | null | undefined> | null | undefined;
        featureFlags?: any | null | undefined;
        membershipStatus?: Types.AccountMembershipStatus | null | undefined;
        account?:
          | {
              __typename?: "Account";
              name?: string | null | undefined;
              id?: string | null | undefined;
              onboarding?:
                | {
                    __typename?: "AccountOnboarding";
                    id?: string | null | undefined;
                    status?: Types.AccountOnboardingStatus | null | undefined;
                    currentStep?:
                      | Types.AccountOnboardingCurrentStep
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      customerId
      nickname
      avatarUrl
      permissions
      featureFlags
      membershipStatus
      account {
        name
        onboarding {
          id
          status
          currentStep
        }
        id
      }
    }
  }
`;

export function useGetMeQuery(
  options: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<GetMeQuery>({ query: GetMeDocument, ...options });
}
