import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { SidePanel } from "../../components/SidePanel";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { RiskDetailPanel } from "../../features/RiskDetailPanel";
import { RiskGroupsTable } from "../../features/RiskGroupsTable";
import { Maybe, Risk } from "../../generated/graphql.generated";

export const SelectedGroupRiskRowContext = createContext<Maybe<string>>("");

export const RiskGroups = () => {
  const ref = useRef(null);
  const [sidePanel, setSidePanel] = useState<{
    isOpen: boolean;
    risk?: Risk;
  }>({
    isOpen: false,
  });

  const onRowClick = useCallback((data: Risk) => {
    setSidePanel((s) => ({
      ...s,
      isOpen: true,
      risk: data,
    }));
  }, []);

  const SidePanelInner = useMemo(
    () => (
      <LayoutContainer height="100%" width="100%" overflow="hidden auto">
        <RiskDetailPanel
          selectedRisk={sidePanel.risk}
          clearSelectedRow={() => {}}
        />
      </LayoutContainer>
    ),
    [sidePanel.risk]
  );

  return (
    <LayoutContainer ref={ref}>
      <SidePanel
        onClickOutside={() => {
          setSidePanel((s) => ({ ...s, isOpen: false }));
        }}
        isOpen={sidePanel.isOpen}
        InnerComponent={SidePanelInner}
      >
        <LayoutContainer as="main">
          <LayoutContainer m={3}>
            <SelectedGroupRiskRowContext.Provider
              value={sidePanel.isOpen ? sidePanel.risk?.id : ""}
            >
              <RiskGroupsTable onRiskRowClick={onRowClick} />
            </SelectedGroupRiskRowContext.Provider>
          </LayoutContainer>
        </LayoutContainer>
      </SidePanel>
    </LayoutContainer>
  );
};
