import styled from "styled-components";

import { getGrid, span } from "../../../styling/theme";
import { LayoutContainer } from "../LayoutContainer";

interface IGrid {
  columns?: number;
  gap?: number;
  responsive?: boolean;
}

interface IColumn {
  span?: number;
}

export const Grid = styled(LayoutContainer).attrs<IGrid>((props) => ({
  display: props.responsive ? ["block", "grid"] : "grid",
  gridTemplateColumns: getGrid(props.columns || 12),
  gridGap: props.gap || 2,
}))<IGrid>``;

export const Column = styled(LayoutContainer).attrs<IColumn>((props) => ({
  gridColumn: span(props.span || 1),
}))<IColumn>``;
