import React from "react";

import styled from "styled-components";

import { ButtonOutlinePrimary, ReactRouterLink } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper/PageHeaderWrapper";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { APPLICATION_SPACING } from "../../styling/spacing";

interface IFourOhFour {
  isLoggedIn: boolean;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  height: calc(100vh - 155px);
`;

const StyledButtonOutlinePrimary = styled(ButtonOutlinePrimary)`
  margin-top: ${APPLICATION_SPACING(4)};
  align-items: center;
  display: flex;
`;

export const FourOhFour = ({ isLoggedIn }: IFourOhFour) => {
  return (
    <PageHeaderWrapper breadcrumb={["Metomic"]}>
      <StyledLayoutContainer
        display="flex"
        alignSelf="stretch"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        flex="1"
        as="div"
      >
        <Heading Element="h1" type="display-extra-large" isBodyColor>
          404
        </Heading>
        <p>You've stumbled upon a broken link</p>
        <StyledButtonOutlinePrimary as={ReactRouterLink} to="/">
          Return Home
        </StyledButtonOutlinePrimary>
      </StyledLayoutContainer>
    </PageHeaderWrapper>
  );
};
