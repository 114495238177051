import React from "react";

import styled from "styled-components";

import onboardingWelcomeImage from "../../../assets/images/onboarding-welcome-image.png";
import { Heading } from "../../../atoms/Heading";
import { OnboardingPageHeaderWrapper } from "../../../components/OnboardingPageHeaderWrapper/OnboardingPageHeaderWrapper";
import { LayoutContainer, P } from "../../../components/layout/LayoutContainer";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { useAnimatedListFromBottom } from "../../../utils/animation/hooks/list/useAnimatedListFromBottom";
import { OnboardingNext } from "../components/OnboardingNext";

const ONBOARDING_HEADER_HEIGHT = "65px";

const StyledImage = styled.img`
  width: 75%;
  max-width: 850px;
`;

const StyledHeading = styled(Heading)`
  padding-bottom: ${APPLICATION_SPACING(2)};
`;

export const OnboardingWelcome = () => {
  const routeId = "welcome";

  const animatedElements = useAnimatedListFromBottom({
    listOfElements: [
      <LayoutContainer display="flex" justifyContent="center">
        <StyledHeading Element="h1" type="display-extra-small" isBodyColor>
          Welcome to Metomic!
        </StyledHeading>
      </LayoutContainer>,
      <P pb={2} fontSize="sm" textAlign="center" fontWeight={100 as any}>
        Metomic gives you the ability to discover, understand and manage the
        sensitive data in your cloud stack
      </P>,
      <P pb={8} fontSize="sm" textAlign="center" fontWeight={100 as any}>
        Before you can fully get started, we need you to do a few things for
        us...
      </P>,
      <LayoutContainer display="flex" justifyContent="center" pb={8}>
        <StyledImage src={onboardingWelcomeImage} />
      </LayoutContainer>,
      <LayoutContainer display="flex" justifyContent="center">
        <LayoutContainer pb="100px">
          <OnboardingNext currentId={routeId} text="Get Started" />
        </LayoutContainer>
      </LayoutContainer>,
    ],
    trail: 120,
  });

  return (
    <OnboardingPageHeaderWrapper>
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={`calc(100vh - ${ONBOARDING_HEADER_HEIGHT})}`}
      >
        <LayoutContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="75%"
          maxWidth="850px"
        >
          {animatedElements}
        </LayoutContainer>
      </LayoutContainer>
    </OnboardingPageHeaderWrapper>
  );
};
