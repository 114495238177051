import React, { FunctionComponent } from "react";

import {
  Redirect,
  RouteProps,
  RouteChildrenProps,
  Route,
} from "react-router-dom";

import { ApplicationRoute, APPLICATION_ROUTES } from "../routes";
import { requiresRedirect, shouldRenderComponent } from "../utils";

export interface IPageDefinition extends RouteChildrenProps, IRouteDefinition {}

export interface IRouteDefinition {
  parentRouteDefinition?: ApplicationRoute;
  routeDefinition?: ApplicationRoute;
}

export interface IRouteWithAuthentication extends RouteProps, IRouteDefinition {
  requiresAuthentication: boolean;
  setRouteDefinition?: Function;
  path: string;
  Component: React.ElementType;
  nestedRoutes?: {
    [key: string]: ApplicationRoute;
  };
  isLoggedIn?: boolean;
}

export const RouteWithAuthentication: FunctionComponent<
  IRouteWithAuthentication
> = ({
  Component,
  path,
  routeDefinition,
  requiresAuthentication,
  isLoggedIn,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (
          requiresRedirect({
            isLoggedIn,
            requiresAuthentication,
          })
        ) {
          return (
            <Redirect
              to={{
                pathname: APPLICATION_ROUTES.LOGIN.url,
                state: { from: props.location.pathname },
              }}
            />
          );
        }

        return shouldRenderComponent({
          requiresAuthentication,
          isLoggedIn,
        }) ? (
          <Component
            {...props}
            isLoggedIn={isLoggedIn}
            routeDefinition={routeDefinition}
            location={location}
          />
        ) : null;
      }}
    />
  );
};
