import React from "react";

import styled from "styled-components";

import { Heading } from "../../../../atoms/Heading";
import { COLOR } from "../../../../styling/colors";
import { APPLICATION_SPACING } from "../../../../styling/spacing";

const StyledNoResultsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${APPLICATION_SPACING(4)};
  height: 100%;
`;

const StyledNoResultsHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${COLOR.LABEL};
`;

export const DefaultNoResults = ({ message }: { message?: string }) => (
  <StyledNoResultsContainer>
    <div>
      <StyledNoResultsHeading Element="h1" type="display-small">
        {message ? message : "No Results"}
      </StyledNoResultsHeading>
    </div>
  </StyledNoResultsContainer>
);
