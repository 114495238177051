import React, { FunctionComponent, useEffect, useState } from "react";

import { useSpring, animated } from "react-spring";
import styled, { css } from "styled-components";

import { Heading } from "../../atoms/Heading";
import { calculateRiskLevelForSensitivity } from "../../atoms/icons/RiskDot";
import { FlameIcon } from "../../atoms/icons/flameIcon";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { DoughnutChart, IDoughnutGraphData } from "../charts/DoughnutChart";
import { LayoutContainer, Span } from "../layout/LayoutContainer";
import { Grid, Column } from "../layout/grid";
import { LoadingSpinner } from "../loading/LoadingSpinner";

interface graphDataWithRiskLevel extends IDoughnutGraphData {
  level?: number;
}

export interface IDonutChartWithList {
  dataType: string;
  number?: number;
  total?: number;
  graphData?: graphDataWithRiskLevel[];
  comingSoon?: boolean;
  loading?: boolean;
  blockClickLegend?: boolean;
  onLegendItemClick?: (data: graphDataWithRiskLevel) => void;
}

const StyledLoadingIndicator = styled(LoadingSpinner)`
  position: absolute;
  top: ${SPECIFIC_SPACING["12px"]};
  right: ${SPECIFIC_SPACING["12px"]};
`;

const StyledTransitionColumn = styled(Column)<{ $loading?: boolean }>`
  transition: 0.3s;

  ${(props) =>
    props.$loading &&
    css`
      opacity: 0.5;
    `}
`;

const StyledHeading = styled(Heading)`
  overflow-wrap: break-word;
  font-weight: 100;
  position: absolute;
  width: 100%;
  text-align: center;
`;

const StyledLabelInner = styled.span`
  width: ${APPLICATION_SPACING(1)};
  height: ${APPLICATION_SPACING(1)};
  margin-right: ${APPLICATION_SPACING(1)};
  display: block;
  border-radius: 9999px;
`;

export const DonutChartWithList: FunctionComponent<IDonutChartWithList> = ({
  dataType,
  number,
  total,
  graphData,
  loading,
  blockClickLegend,
  onLegendItemClick,
}) => {
  const [localTotal, setLocalTotal] = useState(0);
  const [animatedNumber, animate] = useSpring(() => ({
    val: number,
    from: { val: localTotal },
    config: { duration: 800 },
  }));

  useEffect(() => {
    animate.update({ val: number });
    animate.start();
    setLocalTotal(number || 0);
  }, [number, animate]);

  return (
    <LayoutContainer
      py="2"
      pr={1}
      as="figure"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {loading && <StyledLoadingIndicator />}
      <Grid gap={3} width="100%">
        <Column position="relative" justifyContent="center" span={4}>
          <LayoutContainer
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {graphData && (
              <DoughnutChart
                onSegmentClick={
                  !blockClickLegend ? onLegendItemClick : undefined
                }
                graphData={graphData}
              />
            )}
            <StyledHeading Element="h3" type="display-small">
              <Span>
                <animated.span>
                  {animatedNumber.val &&
                    animatedNumber.val.to((val: any) =>
                      Number(Math.floor(val)).toLocaleString()
                    )}
                </animated.span>
              </Span>
            </StyledHeading>
          </LayoutContainer>
        </Column>
        <StyledTransitionColumn
          overflowY="auto"
          maxHeight={120}
          span={8}
          $loading={loading}
          color="LABEL"
          fontSize="xsm"
        >
          {graphData?.map((data) => {
            return (
              <LayoutContainer
                flexWrap="nowrap"
                display="flex"
                alignItems="center"
                pb={1}
                key={data.id}
              >
                <StyledLabelInner style={{ backgroundColor: data.color }} />
                {data.name}{" "}
                <LayoutContainer display="flex" ml="auto">
                  <Span mr={1}>{data.count}</Span>
                  <FlameIcon
                    riskLevel={calculateRiskLevelForSensitivity(
                      defaultTo(data.level, 0)
                    )}
                  />
                </LayoutContainer>
              </LayoutContainer>
            );
          }) || []}
        </StyledTransitionColumn>
      </Grid>
    </LayoutContainer>
  );
};
