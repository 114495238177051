import React, { FunctionComponent, HTMLAttributes } from "react";

import pluralize from "pluralize";
import styled from "styled-components";

import {
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
} from "../../definitions/types/risk-level";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { kFormatter } from "../../utils/number/kformatter";
import { LayoutContainer, P, Span } from "../layout/LayoutContainer";

interface IThreatSummaryBlock
  extends HTMLAttributes<HTMLDivElement>,
    Record<RiskLevel, number> {
  activeRules?: number;
  showUnknown: boolean;
}

const StyledHeading = styled.h1`
  font-size: 2.2rem /* 35.2px */;
  line-height: 2.95rem /* 47.2px */;
`;

export const ThreatSummaryBlock: FunctionComponent<IThreatSummaryBlock> = ({
  CRITICAL,
  MEDIUM,
  HIGH,
  LOW,
  activeRules,
  className,
}) => (
  <LayoutContainer
    px={["1.75rem", 8]}
    color="BODY"
    display="flex"
    justifyContent="center"
    flexDirection="column"
    className={className}
    height="12rem"
  >
    <LayoutContainer
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <LayoutContainer
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <StyledHeading>{CRITICAL || HIGH || MEDIUM || LOW || 0}</StyledHeading>
        <P mb={1} color="LABEL" fontSize="sm">
          {(CRITICAL && `Critical risk ${pluralize("violation", CRITICAL)}`) ||
            (HIGH && `High risk ${pluralize("violation", HIGH)}`) ||
            (MEDIUM && `Medium risk ${pluralize("violation", MEDIUM)}`) ||
            (LOW && `Low risk ${pluralize("violation", LOW)}`) ||
            "Violations"}
        </P>
        <P mb={3} fontSize="sm">
          {(CRITICAL && (
            <span>
              {HIGH + MEDIUM + LOW}
              <Span color="LABEL" ml={SPECIFIC_SPACING["4px"]}>
                other {pluralize("violation", HIGH + MEDIUM + LOW)}
              </Span>
            </span>
          )) ||
            (HIGH && (
              <span>
                {MEDIUM + LOW}
                <Span color="LABEL" ml={SPECIFIC_SPACING["4px"]}>
                  other {pluralize("violation", MEDIUM + LOW)}
                </Span>
              </span>
            )) ||
            (MEDIUM && (
              <span>
                {LOW}{" "}
                <Span color="LABEL" ml={SPECIFIC_SPACING["4px"]}>
                  other {pluralize("violation", LOW)}
                </Span>
              </span>
            )) || <Span color="LABEL">0 other violations</Span>}
        </P>
        <P color="LABEL" fontSize="sm">
          {activeRules} {pluralize("rule", activeRules)} triggered
        </P>
      </LayoutContainer>
      <LayoutContainer
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <LayoutContainer display="flex" mb={1} alignItems="center">
          <Span
            mr={1}
            height="1.5rem"
            px={SPECIFIC_SPACING["4px"]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="OFF_WHITE"
            style={{
              minWidth: "1.5rem",
              backgroundColor: RISK_LEVEL_TEMPLATES["CRITICAL"].backgroundColor,
            }}
          >
            {kFormatter(CRITICAL)}
          </Span>
          Critical {pluralize("violation", CRITICAL)}
        </LayoutContainer>
        <LayoutContainer display="flex" mb={1} alignItems="center">
          <Span
            mr={1}
            height="1.5rem"
            px={SPECIFIC_SPACING["4px"]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="OFF_WHITE"
            style={{
              minWidth: "1.5rem",
              backgroundColor: RISK_LEVEL_TEMPLATES["HIGH"].backgroundColor,
            }}
          >
            {kFormatter(HIGH)}
          </Span>
          High-risk {pluralize("violation", HIGH)}
        </LayoutContainer>
        <LayoutContainer display="flex" mb={1} alignItems="center">
          <Span
            mr={1}
            height="1.5rem"
            px={SPECIFIC_SPACING["4px"]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="OFF_WHITE"
            style={{
              minWidth: "1.5rem",
              backgroundColor: RISK_LEVEL_TEMPLATES["MEDIUM"].backgroundColor,
            }}
          >
            {kFormatter(MEDIUM)}
          </Span>
          Medium-risk {pluralize("violation", MEDIUM)}
        </LayoutContainer>
        <LayoutContainer display="flex" alignItems="center">
          <Span
            mr={1}
            height="1.5rem"
            px={SPECIFIC_SPACING["4px"]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="OFF_WHITE"
            style={{
              minWidth: "1.5rem",
              backgroundColor: RISK_LEVEL_TEMPLATES["LOW"].backgroundColor,
            }}
          >
            {kFormatter(LOW)}
          </Span>
          Low-risk {pluralize("violation", LOW)}
        </LayoutContainer>
      </LayoutContainer>
    </LayoutContainer>
  </LayoutContainer>
);
