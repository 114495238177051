import React from "react";

import styled from "styled-components";

import { TextButtonSimple } from "../../../atoms/Button";
import { Heading } from "../../../atoms/Heading";
import { Content } from "../../../components/Content";
import { OnboardingPageHeaderWrapper } from "../../../components/OnboardingPageHeaderWrapper/OnboardingPageHeaderWrapper";
import { LayoutContainer, P } from "../../../components/layout/LayoutContainer";
import { Column, Grid } from "../../../components/layout/grid";
import InviteUserModal from "../../../components/modals/InviteUserModal";
import { useModal } from "../../../contexts/modals";
import { InviteUsers } from "../../../features/InviteUsers";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { OnboardingBack } from "../components/OnboardingBack";
import { OnboardingNext } from "../components/OnboardingNext";

const StyledHeading = styled(Heading)`
  padding-bottom: ${APPLICATION_SPACING(2)};
`;

const StyledAddNewUserButton = styled(TextButtonSimple)`
  margin-left: ${APPLICATION_SPACING(2)};
  margin-top: ${APPLICATION_SPACING(2)};
  display: block;
`;

const GridContainer = styled(Grid)`
  max-width: 1200px;
`;

export const OnboardingInviteTeam = () => {
  const routeId = "invite-team";

  const { inviteUserModal } = useModal();

  return (
    <OnboardingPageHeaderWrapper>
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LayoutContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="75%"
          maxWidth="850px"
        >
          <StyledHeading Element="h1" type="display-extra-small" isBodyColor>
            Invite your team members
          </StyledHeading>
          <P pb={3} fontSize="sm" textAlign="center" fontWeight={100 as any}>
            Send an invite link to your team members so they can get started and
            onboarded (you can always do this later)
          </P>
        </LayoutContainer>
      </LayoutContainer>
      <Content
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        as="main"
      >
        <GridContainer gap={4} width="100%" mb={2}>
          <Column span={12}>
            <LayoutContainer>
              <InviteUsers />
              <StyledAddNewUserButton
                onClick={() => inviteUserModal.toggleVisible()}
              >
                + Add New User
              </StyledAddNewUserButton>
            </LayoutContainer>
          </Column>
        </GridContainer>
        <InviteUserModal
          showModal={inviteUserModal.visible}
          toggleModal={inviteUserModal.toggleVisible}
        />
      </Content>
      <LayoutContainer display="flex" pt={3} pb={4} px={4} mt="auto">
        <LayoutContainer display="flex" alignItems="center" ml="auto">
          <LayoutContainer mr={2}>
            <OnboardingBack currentId={routeId} />
          </LayoutContainer>
          <OnboardingNext currentId={routeId} />
        </LayoutContainer>
      </LayoutContainer>
    </OnboardingPageHeaderWrapper>
  );
};
