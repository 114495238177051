import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RiskSummaryQueryVariables = Types.Exact<{
  from?: Types.InputMaybe<Types.Scalars["Date"]>;
  to?: Types.InputMaybe<Types.Scalars["Date"]>;
}>;

export type RiskSummaryQuery = {
  __typename?: "RootQueryType";
  riskSummary?:
    | {
        __typename?: "RiskSummary";
        low?: number | null | undefined;
        medium?: number | null | undefined;
        high?: number | null | undefined;
        critical?: number | null | undefined;
      }
    | null
    | undefined;
};

export const RiskSummaryDocument = gql`
  query RiskSummary($from: Date, $to: Date) {
    riskSummary(from: $from, to: $to) {
      low
      medium
      high
      critical
    }
  }
`;

export function useRiskSummaryQuery(
  options: Omit<Urql.UseQueryArgs<RiskSummaryQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<RiskSummaryQuery>({
    query: RiskSummaryDocument,
    ...options,
  });
}
