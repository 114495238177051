import { gql } from "urql";

export const VIOLATION_SUMMARY_QUERY = gql`
  query ViolationSummaryQuery {
    violationSummary {
      currentViolations {
        low
        medium
        high
        critical
      }
      triggeredRules
      currentThreatLevel
      history {
        date
        violations {
          low
          medium
          high
          critical
        }
      }
    }
  }
`;

export const OVERVIEW_PAGE_QUERY = gql`
  query OverviewPageQuery {
    violationSummary {
      currentViolations {
        low
        medium
        high
        critical
      }
      triggeredRules
      currentThreatLevel
      history {
        date
        violations {
          low
          medium
          high
          critical
        }
      }
    }
    listMonitorIds: listMonitors {
      id
    }
  }
`;

export const LIST_RULE_ACTION_RESULTS_FOR_VIOLATION = gql`
  query listRuleActionResultsForViolation(
    $violationId: ID!
    $afterCursor: String!
  ) {
    listRuleActionResultsForViolation(
      violationId: $violationId
      pagination: { limit: 10, cursor: $afterCursor }
    ) {
      edges {
        node {
          id
          actionType
          result {
            id
            type
            meta
          }
          createdAt
        }
      }
    }
  }
`;
