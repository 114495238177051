import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetRiskDatapointSummaryQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type GetRiskDatapointSummaryQuery = {
  __typename?: "RootQueryType";
  getDataPointSummaryForRisk?:
    | Array<
        | {
            __typename?: "RiskDataPointSummary";
            count?: number | null | undefined;
            level?: number | null | undefined;
            classifier?:
              | {
                  __typename?: "Classifier";
                  name?: string | null | undefined;
                  id?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  getRisk?:
    | {
        __typename?: "Risk";
        atom?:
          | {
              __typename?: "LabelledAtom";
              entityId?: string | null | undefined;
              installationId?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetRiskDatapointSummaryDocument = gql`
  query GetRiskDatapointSummary($id: ID) {
    getDataPointSummaryForRisk(id: $id) {
      classifier {
        name
        id
      }
      count
      level
    }
    getRisk(id: $id) {
      atom {
        entityId
        installationId
      }
    }
  }
`;

export function useGetRiskDatapointSummaryQuery(
  options: Omit<
    Urql.UseQueryArgs<GetRiskDatapointSummaryQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetRiskDatapointSummaryQuery>({
    query: GetRiskDatapointSummaryDocument,
    ...options,
  });
}
