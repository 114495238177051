import { useMemo } from "react";

type ChartMargin = { top: number; right: number; bottom: number; left: number };

export function usePieLayout({
  width,
  height,
  margin,
  thickness,
}: {
  width: number;
  height: number;
  margin: ChartMargin;
  thickness: number; // Configure this to 1 for a full pie
}) {
  const { centerX, centerY, radius, top, left, donutOffsetRadius } =
    useMemo(() => {
      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      const radius = Math.min(innerWidth, innerHeight) / 2;
      const donutOffsetRadius = radius - thickness;
      const centerY = innerHeight / 2;
      const centerX = innerWidth / 2;
      const top = centerY + margin.top;
      const left = centerX + margin.left;

      return {
        donutOffsetRadius,
        centerX,
        centerY,
        innerWidth,
        innerHeight,
        radius,
        top,
        left,
      };
    }, [width, height, margin, thickness]);

  return {
    donutOffsetRadius,
    centerX,
    centerY,
    radius,
    top,
    left,
  };
}
