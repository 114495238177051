import { gql } from "urql";

export const REDACT_DETECTION_MUTATION = gql`
  mutation RedactDetection(
    $id: ID
    $action: DetectionActionInput
    $permissionGiven: Boolean
  ) {
    demoRedactDetection(
      id: $id
      action: $action
      permissionGiven: $permissionGiven
    ) {
      id
      actionState
    }
  }
`;
