export enum DATA_TYPES {
  DOCUMENT = "DOCUMENT",
  ATTACHMENT = "ATTACHMENT",
  TEXT = "TEXT",
}

export type DATA_TYPES_ARRAY = keyof typeof DATA_TYPES;

export interface IDataPointDetail {
  type: DATA_TYPES;
  name: string;
}

export interface IDataTypeMeta {
  displayName: string;
  iconUrl: string;
}

type IDataTypes = {
  [key in DATA_TYPES]: IDataTypeMeta;
};

export const HARD_CODED_DATA_TYPES: IDataTypes = {
  [DATA_TYPES.DOCUMENT]: {
    displayName: "Document",
    iconUrl: "",
  },
  [DATA_TYPES.ATTACHMENT]: {
    displayName: "Attachment",
    iconUrl: "",
  },
  [DATA_TYPES.TEXT]: {
    displayName: "Text",
    iconUrl: "",
  },
};

export const HARD_CODED_DATA_TYPE_COLORS = [
  "#B5D6B2",
  "#D7ECD9",
  "#ECC9C7",
  "#D5D6EA",
  "#DDBEA9",
  "#EDDCD2",
  "#FFF1E6",
  "#B7B7A4",
  "#AED9E0",
  "#E5BDDD",
  "#E5E0AF",
  "#CFC0BD",
  "#D0D1AC",
  "#B4CBD9",
  "#D9C3E7",
  "#B5D6D6",
  "#FCD5CE",
  "#FFD7BA",
  "#D8E2DC",
];

export const HARD_CODED_OTHER_TYPE_COLOR = "#E1E1E6";
