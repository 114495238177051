import React, { useCallback } from "react";

import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import { LoadingSpinner } from "../../../loading/LoadingSpinner";
import { DefaultRowWrapper, RowWrapper } from "../defaults";
import { DefaultRowType, TableInner } from "../definitions";
import { TableRow } from "./Row";

const StyledFullHeightLoadingContainer = styled(LoadingSpinner)`
  padding: 1.75rem;
  height: 80px;
`;

export const VirtualisedTableInner = <T extends DefaultRowType = {}>(
  props: TableInner<T>
) => {
  const {
    canInfiniteScroll,
    prepareRow,
    rows,
    themeOverrides,
    theme,
    expanded,
    RowContainer = DefaultRowWrapper,
    isMobileLayout,
    onScrollEnd,
    onRowClick,
    clickWholeRowToExpand,
    onTableScrolled,
    onVirtualisedItemsChange,
  } = props;

  const onLoadMore = useCallback(() => {
    if (onScrollEnd) {
      onScrollEnd();
    }
  }, [onScrollEnd]);

  return (
    <Virtuoso
      onScroll={onTableScrolled}
      increaseViewportBy={{ bottom: 400, top: 0 }}
      data={rows}
      endReached={onLoadMore}
      style={{ height: "100%" }}
      itemsRendered={(items) => {
        onVirtualisedItemsChange && onVirtualisedItemsChange(items);
      }}
      itemContent={(index, row) => {
        prepareRow(row);

        const rowProps = row.getRowProps();

        return (
          <RowWrapper
            theme={theme}
            themeOverrides={themeOverrides}
            key={rowProps.key}
            id={row.original.id}
          >
            <TableRow<T>
              theme={theme}
              rowProps={rowProps}
              row={row}
              expanded={expanded}
              RowContainer={RowContainer}
              isMobileLayout={isMobileLayout}
              onRowClick={onRowClick}
              clickWholeRowToExpand={clickWholeRowToExpand}
              themeOverrides={themeOverrides}
            />
          </RowWrapper>
        );
      }}
      components={{
        Footer: () => {
          return canInfiniteScroll ? (
            <div>
              <StyledFullHeightLoadingContainer />
            </div>
          ) : null;
        },
      }}
    />
  );
};
