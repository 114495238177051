import React, { FunctionComponent } from "react";

// I Think we should have a Searchbar module but I think this could be any generic string input so will refactor to that.

export interface ITextInput {
  onChange: (value: string | number) => void;
  value: string | number;
  placeholder: string;
  loading?: boolean;
  name?: string;
  className?: string;
  type?: string;
  heading?: string;
}

export const TextInput: FunctionComponent<ITextInput> = ({
  placeholder,
  className,
  onChange,
  value,
  name,
  type = "text",
}) => (
  <input
    type={type}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className={className}
    placeholder={placeholder}
    name={name}
  />
);
