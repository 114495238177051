import { useLocation } from "react-router";

import { IDetectionLocationDetail } from "../../definitions/types/detection";
import { VENDORS } from "../../definitions/types/vendors";
import { VIOLATION_STATUS } from "../../features/MonitorViolationsList";
import { useQueryParams } from "../filters/helpers";

export const canActionViolation = ({
  entityType,
  appType,
}: IDetectionLocationDetail) =>
  entityType === "Message" && appType === VENDORS.SLACK;

export const canEditPermission = ({
  entityType,
  appType,
}: IDetectionLocationDetail) =>
  entityType === "File" && appType === VENDORS.GDRIVE;

export const useViolationQueryParams = () => {
  const location = useLocation();
  const queryParams = useQueryParams(location.search);

  return {
    c: queryParams.get("c"),
    id: queryParams.get("id"),
  };
};

export const isResolvedViolation = (status?: VIOLATION_STATUS) =>
  status && (status === "RESOLVED" || status === "FALSE_POSITIVE");
