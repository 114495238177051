import React from "react";

import styled, { css } from "styled-components";

import { COLOR } from "../../../styling/colors";

const AnimatedPath = styled.path`
  paint-order: stroke;
  stroke-width: 1.2px;
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  animation: dash 1.3s ease-in-out infinite;
  stroke: #58469b;

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const StyledLoadingContainer = styled.div<{
  spinner?: boolean;
  isWhite?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isFullScreen?: boolean;
}>`
  ${(props) =>
    props.spinner &&
    css`
      border-radius: 70%;
      border: 10px solid #333;
      border-color: ${COLOR.PRIMARY};
      border-width: 3px;
      box-sizing: border-box;
      animation: sweep 0.8s linear alternate infinite,
        rotate 0.6s linear infinite;
      width: 30px;
      height: 30px;

      @keyframes sweep {
        0% {
          clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
        }
        50% {
          clip-path: polygon(
            0% 0%,
            0% 100%,
            0% 100%,
            50% 50%,
            100% 0%,
            100% 0%,
            0% 0%
          );
        }
        100% {
          clip-path: polygon(
            0% 0%,
            0% 100%,
            100% 100%,
            50% 50%,
            100% 100%,
            100% 0%,
            0% 0%
          );
        }
      }
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `}

  ${(props) =>
    props.isWhite &&
    css`
      border-color: white;
      stroke: white;
    `}

  ${(props) =>
    props.isLarge &&
    css`
      width: 60px;
      height: 60px;
      border-width: 4px;
    `}

  ${(props) =>
    props.isSmall &&
    css`
      width: 18px;
      height: 18px;
    `}

  ${(props) =>
    props.isFullScreen &&
    css`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

export function MetomicIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
    >
      <AnimatedPath
        strokeWidth="1"
        d="M9.16911 2.95386L0.122959 21.0462H18.2153L9.16911 2.95386ZM9.16911 2.95386"
      />
      <AnimatedPath
        fill="none"
        strokeWidth="1"
        d="M23.8153 21.0462 L14.7692 2.95386 L5.723 21.0462ZM23.8153 21.0462"
      />
    </svg>
  );
}

export const LoadingMetomicIcon = ({
  className,
  ...rest
}: {
  className?: string;
  isWhite?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isFullScreen?: boolean;
}) => (
  <StyledLoadingContainer className={className} {...rest}>
    <MetomicIcon />
  </StyledLoadingContainer>
);
