import React, { PropsWithChildren, useRef } from "react";

import styled, { css } from "styled-components";

import { COLOR } from "../../styling/colors";
import { useOnClickOutside } from "../../utils/layout/useOnClickOutside";
import { Box } from "../Box";
import { LayoutContainer } from "../layout/LayoutContainer";

interface ISidePanel {
  isOpen: boolean;
  InnerComponent: React.ReactNode;
  onClickOutside?: () => void;
  className?: string;
}

const StyledSidePanelContainer = styled(Box).attrs({
  boxShadow: true,
})<{ isOpen: boolean }>`
  background-color: ${COLOR.GRAYSCALE_UNEDITABLE};
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 40vw;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.15s;

  ${(props) =>
    props.isOpen &&
    css`
      transform: translateX(0);
    `}
`;

export const SidePanel = (props: PropsWithChildren<ISidePanel>) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    props.onClickOutside && props.onClickOutside();
  });

  return (
    <>
      <LayoutContainer className={props.className}>
        {props.children}
      </LayoutContainer>
      <StyledSidePanelContainer isOpen={props.isOpen} ref={ref}>
        {props.InnerComponent}
      </StyledSidePanelContainer>
    </>
  );
};
