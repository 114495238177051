import React, {
  useState,
  useRef,
  useMemo,
  CSSProperties,
  useCallback,
  FunctionComponent,
  MouseEvent,
} from "react";

import {
  Check as CheckIcon,
  ChevronDown as ChevronDownIcon,
} from "react-feather";
import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { useOnClickOutside } from "../../utils/layout/useOnClickOutside";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface IDropdown {
  options: {
    key: string | number;
    value: string | number;
    selected?: boolean;
    label?: string;
    isUnselected?: boolean;
  }[];
  onSelect?: Function;
  label?: string;
  className?: string;
  style?: CSSProperties;
  showLabel?: string;
  selected?: any;
}

export interface IDropdownWithPlaceholder extends IDropdown {
  placeholder: string;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  cursor: pointer;
  border-radius: 2px;
`;

const StyledOptionsContainer = styled(LayoutContainer)`
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 10;
  background-color: ${COLOR.INSET_BACKGROUND};
  border: 1px solid ${COLOR.LINE};
`;

const StyledDropdownButton = styled.button<{
  selected?: boolean;
  isPlaceholder?: boolean;
}>`
  display: flex;
  padding: ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(1)}
    ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(2)};
  font-weight: 300;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: ${COLOR.BODY};
  ${(props) =>
    props.selected &&
    `
    color: #49b5c0;
  `}

  &:hover {
    color: #49b5c0;
  }

  ${(props) =>
    props.isPlaceholder &&
    `
    color: #A0A3BD;

    &:hover {
      color: #A0A3BD;
    }
  `}
`;

const SpacedCheckIcon = styled(CheckIcon)`
  margin-right: 0.5em;
  width: 24px;
`;

const SpacedContainer = styled.div`
  margin-right: 0.5em;
  width: 24px;
`;

const StyledButton = styled.button<{ isPlaceholder: boolean }>`
  line-height: 1rem;
  font-weight: inherit;
  font-size: inherit;
  width: 100%;
  border-bottom: 1px dashed ${COLOR.PLACEHOLDER};
  &:hover {
    color: ${COLOR.PLACEHOLDER};
  }

  ${(props) =>
    props.isPlaceholder &&
    `
    color: #A0A3BD;

    &:hover {
      color: #A0A3BD;
    }
  `}
`;

export const InlineDropdown: FunctionComponent<IDropdown> = ({
  options,
  onSelect,
  label,
  className,
  style,
  showLabel,
  selected,
}) => {
  const [isShown, hideOrShow] = useState<boolean>(false);
  const ref = useRef(null);

  const toggleHideOrShow = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      hideOrShow((prev) => !prev);
    },
    [hideOrShow]
  );

  const activeDescendant = useMemo(
    () => options.findIndex(({ selected }) => selected) ?? 0,
    [options]
  );

  const selectedOption = useMemo(() => {
    if (selected) {
      return options.find((option) => option.value === selected) ?? options[0];
    } else {
      return options[activeDescendant] ?? {};
    }
  }, [options, activeDescendant, selected]);

  useOnClickOutside(ref, () => {
    hideOrShow(false);
  });

  return (
    <StyledLayoutContainer
      position="relative"
      ref={ref}
      className={className}
      style={style}
      role="listbox"
      tabIndex={0}
      aria-activedescendant={`listbox1-${activeDescendant}`}
      display="inline-flex"
    >
      <StyledButton
        isPlaceholder={!!selectedOption.isUnselected}
        onClick={toggleHideOrShow}
      >
        <LayoutContainer display="flex" flexWrap="nowrap" alignItems="center">
          {showLabel ? (
            <div>
              {label}: {selectedOption.key}
            </div>
          ) : (
            <div>
              {selectedOption.label ? selectedOption.label : selectedOption.key}
            </div>
          )}

          <LayoutContainer as="span" mt="2px" color={"inherit" as any}>
            <ChevronDownIcon size={16} />
          </LayoutContainer>
        </LayoutContainer>
      </StyledButton>
      {isShown && (
        <StyledOptionsContainer
          backgroundColor="BACKGROUND"
          py={1}
          position="absolute"
          fontSize="xsm"
        >
          {options.map(({ key, value, selected, ...option }, i: number) => (
            <StyledDropdownButton
              isPlaceholder={option.isUnselected}
              selected={selected || selectedOption.value === value}
              key={key}
              onClick={(event) => {
                onSelect?.(value);
                toggleHideOrShow(event);
              }}
              id={`listbox1-${i + 1}`}
            >
              <LayoutContainer pr={2} display="flex" flexWrap="nowrap">
                {option.label ? option.label : key}
              </LayoutContainer>
              <LayoutContainer ml="auto">
                {selected || selectedOption.value === value ? (
                  <SpacedCheckIcon size={16} />
                ) : (
                  <SpacedContainer />
                )}
              </LayoutContainer>
            </StyledDropdownButton>
          ))}
        </StyledOptionsContainer>
      )}
    </StyledLayoutContainer>
  );
};
