import { useState, useCallback } from "react";

import { Row } from "react-table";
import { useMutation } from "urql";

import { IDetectionWithContextRow } from "../../../features/DetectionSearch/columns";
import { useToast } from "../../../recoil/toasts/provider";
import { DETECTION_ACTIONS } from "../../detections/definitions";
import { useViolationAction } from "../../detections/hooks";
import { sendAnalytics } from "../../user/analytics";
import { BULK_CHANGE_VIOLATION_STATUS } from "../../violations/mutations/bulkChangeViolationStatus";
import { BULK_CREATE_CLASSIFIER_HASH_EXCLUSION_MUTATION } from "../mutations/bulkCreateClassifierHashExclusions";
import { CREATE_CLASSIFIER_EXCLUSION_MUTATION } from "../mutations/createClassifierExclusion";

export interface ISetExclusionsOrFalsePositives {
  selectedOption: string;
  selectedRows: Row<IDetectionWithContextRow>[];
}

export function useFalsePositive() {
  const [loading, setIsLoading] = useState(false);

  const markAsFalsePositive = useViolationAction({
    isViolation: true,
  });

  const addToast = useToast();

  const [, bulkCreateClassifierHashExclusions] = useMutation(
    BULK_CREATE_CLASSIFIER_HASH_EXCLUSION_MUTATION
  );

  const [, createClassifierExclusion] = useMutation(
    CREATE_CLASSIFIER_EXCLUSION_MUTATION
  );

  const [, bulkChangeViolationStatus] = useMutation(
    BULK_CHANGE_VIOLATION_STATUS
  );

  const setExclusionsOrFalsePositives = useCallback(
    async ({
      selectedRows,
      selectedOption,
    }: ISetExclusionsOrFalsePositives) => {
      const isBulk = selectedRows.length > 1;

      const isOneOff = selectedOption === "one_off";
      const isDetectionHash = selectedOption === "detection_hash";
      const isPattern = selectedOption === "pattern";
      const isPatternEntity = selectedOption === "pattern_entity";

      setIsLoading(true);

      if (isBulk) {
        if (isOneOff) {
          await bulkChangeViolationStatus({
            status: "false-positive",
            violationIds: selectedRows.map(
              ({ original }) => original.violationId as string
            ),
          });
        } else {
          await bulkCreateClassifierHashExclusions({
            inputs: selectedRows.map(({ original }) => ({
              classifierId: original.classifier.id,
              detectionHash: original.detectionHash,
            })),
          });
        }
      } else {
        const { original } = selectedRows[0];

        if (isOneOff) {
          await markAsFalsePositive.violationAction({
            id: original.violationId as string,
            action: DETECTION_ACTIONS.MARK_FALSE_POSITIVE,
            permissionGiven: true,
          });
        } else {
          await createClassifierExclusion({
            input: {
              type: selectedOption,
              classifierId: original.classifier.id,
              ...(isDetectionHash && {
                detectionHash: original.detectionHash,
              }),
              ...(isPatternEntity && {
                appType: original.location.appType,
              }),
              ...(isPatternEntity && {
                entityType: original.location.entityType,
              }),
              ...((isPattern || isPatternEntity) && {
                pattern: original.pattern,
              }),
            },
          });
        }
      }

      setIsLoading(false);

      sendAnalytics("VIOLATION: Resolved", {
        how: "Mark As False Positive",
      });

      addToast({
        type: "success",
        message:
          selectedOption !== "one_off"
            ? "Successfully created classifier exclusion and marked as false positive"
            : "Successfully marked as false positive",
      });
    },
    [
      addToast,
      bulkCreateClassifierHashExclusions,
      bulkChangeViolationStatus,
      createClassifierExclusion,
      markAsFalsePositive,
    ]
  );

  return {
    loading,
    setExclusionsOrFalsePositives,
  };
}
