import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";

import { Row } from "react-table";
import { css } from "styled-components";

import { ContextMenuButton } from "../../atoms/Button";
import { CheckboxWithLabel } from "../../atoms/form/Checkbox";
import { ContextMenuContainer } from "../../components/ContextMenuContainer";
import { ContextMenuContext } from "../../components/ContextMenuContainer/context";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { useModal } from "../../contexts/modals";
import { DETECTION_ACTIONS } from "../../operations/detections/definitions";
import { useViolationAction } from "../../operations/detections/hooks";
import { useModifyGdrivePermissionMutation } from "../../operations/detections/mutations/modifyGdrivePermission.generated";
import { sendAnalytics } from "../../operations/user/analytics";
import {
  canActionViolation,
  canEditPermission,
} from "../../operations/violations/helpers";
import { useToast } from "../../recoil/toasts/provider";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import { IDetectionWithContextRow } from "./columns";
import { FalsePositiveModal } from "./components/FalsePositiveModal";
import { ViolationHistoryDetailsButton } from "./components/ViolationHistoryDetailsButton";

// TODO: REPLACE REF HACK BELOW THIS IS JUST A CHEAP FIX UNTIL THE CONFIRM DIALOG CAN BE REFACTORED TO ACTIVELY RENDER

export const ClearAllViolationsModal = ({ temp__ref }: { temp__ref: any }) => {
  const [clearAllViolations, setClearAllViolations] = useState(false);

  // TODO: Ask Mike why we need to do this.MR
  useEffect(() => {
    if (temp__ref) {
      temp__ref.current = clearAllViolations;
    }
    //eslint-disable-next-line
  }, [clearAllViolations]);

  return (
    <div className="flex flex-col">
      <div className="text-xsm w-full mt-xlg mb-sm items-center p-lg bg-inset-background border-line border">
        <LayoutContainer pb={2} pt={2}>
          <CheckboxWithLabel
            onChange={() => setClearAllViolations((t) => !t)}
            checked={clearAllViolations}
            label="Clear all violations?"
          />
        </LayoutContainer>
        <div className="flex flex-col">
          <p>
            Selecting this option will perform the requested action and clear
            all violations of the same type
          </p>
        </div>
      </div>
    </div>
  );
};

export interface IContextMenu extends IDetectionWithContextRow {
  selectedRows?: Row<IDetectionWithContextRow>[];
}

const ButtonStyles = css`
  display: flex;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  padding: ${SPECIFIC_SPACING["4px"]} ${APPLICATION_SPACING(1)};
  border-color: ${COLOR.ERROR};
  color: ${COLOR.ERROR};
`;

export const ContextMenu = ({ selectedRows, ...currentRow }: IContextMenu) => {
  const addToast = useToast();
  const [showFPModal, toggleFPModal] = useState<boolean>(false);

  const tempClearViolations = useRef(false);

  const showOrHideFPModal = useCallback(
    () => toggleFPModal((prev) => !prev),
    [toggleFPModal]
  );

  const { id, location, violationId, status } = currentRow;

  const violationOrDetectionid = violationId || id;

  const { loading, violationAction } = useViolationAction({
    isViolation: Boolean(violationId),
  });
  const notifyDetection = useViolationAction({
    isViolation: Boolean(violationId),
  });
  const markAsResolved = useViolationAction({
    isViolation: Boolean(violationId),
  });
  const markAsFalsePositive = useViolationAction({
    isViolation: Boolean(violationId),
  });
  const cancel = useViolationAction({
    isViolation: Boolean(violationId),
  });

  const { confirmModal } = useModal();

  const [modifyGdrivePermissionInternalState, modifyGdrivePermissionInternal] =
    useModifyGdrivePermissionMutation();

  const [modifyGdrivePermissionPublicState, modifyGdrivePermissionPublic] =
    useModifyGdrivePermissionMutation();

  const [modifyGdrivePermissionBothState, modifyGdrivePermissionBoth] =
    useModifyGdrivePermissionMutation();

  const violationRows = useMemo(
    () =>
      selectedRows && selectedRows.length
        ? selectedRows
        : ([{ original: currentRow }] as Row<IDetectionWithContextRow>[]),
    [selectedRows, currentRow]
  );

  const multipleViolationsSelected = useMemo(
    () => violationRows.length > 1,
    [violationRows]
  );

  const violationIds = useMemo(
    () => violationRows.map(({ original }) => original.violationId as string),
    [violationRows]
  );

  const checkResolveAll = useCallback(
    ({
      onConfirm,
      loading,
    }: {
      onConfirm: (shouldClearViolations: boolean) => void;
      loading: boolean;
    }) => {
      confirmModal.toggleConfirm({
        confirmText: "continue",
        heading: "Options",
        body: <ClearAllViolationsModal temp__ref={tempClearViolations} />,
        onConfirm: async ({ setModalLoadingState }) => {
          setModalLoadingState(true);
          await onConfirm(tempClearViolations.current);
          setModalLoadingState(false);
        },
      });
    },
    [confirmModal]
  );

  return (
    <>
      <ContextMenuContainer buttonText="Actions" buttonStyles={ButtonStyles}>
        <ContextMenuContext.Consumer>
          {({ setVisibility }) => (
            <>
              {!multipleViolationsSelected && (
                <ViolationHistoryDetailsButton
                  asContextMenuItem
                  {...currentRow}
                />
              )}
              {status === "PENDING" && (
                <ContextMenuButton
                  loading={markAsResolved.loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    cancel
                      .violationAction({
                        id: violationOrDetectionid,
                        action: DETECTION_ACTIONS.CANCEL,
                      })
                      .then(() => setVisibility(false))
                      .then(() => {
                        sendAnalytics(
                          "VIOLATION: Cancelled Pending Action",
                          undefined
                        );
                      });
                  }}
                >
                  Cancel
                </ContextMenuButton>
              )}

              {canActionViolation(location) &&
                status !== "RESOLVED" &&
                status !== "FALSE_POSITIVE" && (
                  <>
                    <ContextMenuButton
                      loading={notifyDetection.loading}
                      onClick={(event) => {
                        event.stopPropagation();
                        checkResolveAll({
                          onConfirm: async (shouldClear) => {
                            return notifyDetection
                              .violationAction({
                                id: violationOrDetectionid,
                                action: DETECTION_ACTIONS.NOTIFY,
                                resolveAllViolationsInEntity: shouldClear,
                              })
                              .then(() => setVisibility(false))
                              .then(() => {
                                sendAnalytics("VIOLATION: Resolved", {
                                  how: "Notify",
                                });
                              });
                          },

                          loading: notifyDetection.loading,
                        });
                      }}
                    >
                      Notify
                    </ContextMenuButton>
                    <ContextMenuButton
                      loading={loading}
                      onClick={(event) => {
                        event.stopPropagation();

                        violationAction({
                          id: violationOrDetectionid,
                          action: DETECTION_ACTIONS.REDACT,
                        })
                          .then(() => setVisibility(false))
                          .then(() => {
                            sendAnalytics("VIOLATION: Resolved", {
                              how: "Redact",
                            });
                          });
                      }}
                    >
                      Redact
                    </ContextMenuButton>
                  </>
                )}

              {canEditPermission(location) && (
                <>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionPublicState.fetching}
                    onClick={() => {
                      setVisibility(false);

                      confirmModal.toggleConfirm({
                        confirmText: "continue",
                        heading: "Options",
                        body: (
                          <ClearAllViolationsModal
                            temp__ref={tempClearViolations}
                          />
                        ),
                        onConfirm: ({ setModalLoadingState }) => {
                          setModalLoadingState(true);
                          modifyGdrivePermissionPublic({
                            entityId: location.entityId,
                            installationId: location.installationId,
                            revokePublicSharing: true,
                            violationId,
                            resolveAllViolationsInFile:
                              Boolean(tempClearViolations),
                          }).then(() => {
                            setModalLoadingState(false);
                            tempClearViolations.current = false;
                            addToast({
                              type: "success",
                              message:
                                "Successfully revoked public permissions",
                            });
                          });
                        },
                      });
                    }}
                  >
                    Revoke Public Sharing
                  </ContextMenuButton>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionInternalState.fetching}
                    onClick={() => {
                      setVisibility(false);
                      confirmModal.toggleConfirm({
                        confirmText: "continue",
                        heading: "Options",
                        body: (
                          <ClearAllViolationsModal
                            temp__ref={tempClearViolations}
                          />
                        ),
                        onConfirm: ({ setModalLoadingState }) => {
                          setModalLoadingState(true);
                          modifyGdrivePermissionInternal({
                            entityId: location.entityId,
                            installationId: location.installationId,
                            revokeInternalSharing: true,
                            violationId,
                            resolveAllViolationsInFile:
                              Boolean(tempClearViolations),
                          }).then(() => {
                            tempClearViolations.current = false;
                            addToast({
                              type: "success",
                              message:
                                "Successfully revoked internal permissions",
                            });
                            setModalLoadingState(false);
                          });
                        },
                      });
                    }}
                  >
                    Revoke Internal Sharing
                  </ContextMenuButton>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionBothState.fetching}
                    onClick={() => {
                      setVisibility(false);
                      confirmModal.toggleConfirm({
                        confirmText: "continue",
                        heading: "Options",
                        body: (
                          <ClearAllViolationsModal
                            temp__ref={tempClearViolations}
                          />
                        ),
                        onConfirm: ({ setModalLoadingState }) => {
                          setModalLoadingState(true);
                          modifyGdrivePermissionBoth({
                            entityId: location.entityId,
                            installationId: location.installationId,
                            revokeInternalSharing: true,
                            revokePublicSharing: true,
                            violationId,
                            resolveAllViolationsInFile:
                              Boolean(tempClearViolations),
                          }).then(() => {
                            setModalLoadingState(false);
                            addToast({
                              type: "success",
                              message: "Successfully revoked both permissions",
                            });
                            setModalLoadingState(false);
                          });
                        },
                      });
                    }}
                  >
                    Revoke Both
                  </ContextMenuButton>
                </>
              )}

              <ContextMenuButton
                loading={markAsResolved.loading}
                onClick={(event) => {
                  event.stopPropagation();

                  checkResolveAll({
                    onConfirm: async (shouldClear) => {
                      return markAsResolved
                        .violationAction({
                          action: DETECTION_ACTIONS.MARK_RESOLVED,
                          ...(!multipleViolationsSelected && {
                            id: violationOrDetectionid,
                          }),
                          ...(multipleViolationsSelected && {
                            ids: violationIds,
                          }),
                          resolveAllViolationsInEntity: shouldClear,
                        })
                        .then(() => setVisibility(false))
                        .then(() => {
                          sendAnalytics("VIOLATION: Resolved", {
                            how: "Mark As Resolved",
                          });
                        });
                    },

                    loading: markAsResolved.loading,
                  });
                }}
              >
                {multipleViolationsSelected
                  ? "Mark all as resolved"
                  : "Mark as resolved"}
              </ContextMenuButton>

              {status !== "RESOLVED" && (
                <ContextMenuButton
                  loading={markAsFalsePositive.loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    showOrHideFPModal();
                    setVisibility(false);
                  }}
                >
                  {multipleViolationsSelected
                    ? "Mark all as false positive"
                    : "Mark as false positive"}
                </ContextMenuButton>
              )}
            </>
          )}
        </ContextMenuContext.Consumer>
      </ContextMenuContainer>
      <FalsePositiveModal
        showModal={showFPModal}
        toggleModal={showOrHideFPModal}
        selectedRows={violationRows}
      />
    </>
  );
};
