import React, { useCallback, useMemo, useRef, useState } from "react";

import { Box } from "../../components/Box";
import { SidePanel } from "../../components/SidePanel";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { BulkActionHistoryTableActive } from "../../features/BulkActionHistoryTable/BulkActionHistoryTableActive";
import { BulkActionHistoryTableCompleted } from "../../features/BulkActionHistoryTable/BulkActionHistoryTableCompleted";
import { BulkActionHistorySidePanel } from "../../features/BulkActionHistoryTable/SidePanel";
import { BulkAction } from "../../generated/graphql.generated";
import { defaultTo } from "../../utils/maybe/defaultTo";

interface IActionHistorySidePanelState {
  bulkActionId: string;
  isOpen: boolean;
  pollForUpdates: boolean;
  selectedBulkAction?: BulkAction;
}

export const ActionHistory = () => {
  const ref = useRef(null);
  const [sidePanel, setSidePanel] = useState<IActionHistorySidePanelState>({
    bulkActionId: "",
    isOpen: false,
    pollForUpdates: false,
  });

  const onActiveRowClick = useCallback((data: BulkAction) => {
    setSidePanel((s) => ({
      ...s,
      bulkActionId: defaultTo(data.id, ""),
      isOpen: true,
      pollForUpdates: true,
      selectedBulkAction: data,
    }));
  }, []);

  const onInActiveRowClick = useCallback((data: BulkAction) => {
    setSidePanel((s) => ({
      ...s,
      bulkActionId: defaultTo(data.id, ""),
      isOpen: true,
      pollForUpdates: false,
      selectedBulkAction: data,
    }));
  }, []);

  const SidePanelInner = useMemo(
    () => (
      <BulkActionHistorySidePanel
        pollForUpdates={sidePanel.pollForUpdates}
        selectedBulkAction={sidePanel.selectedBulkAction}
      />
    ),
    [sidePanel.pollForUpdates, sidePanel.selectedBulkAction]
  );

  return (
    <LayoutContainer ref={ref}>
      <SidePanel
        onClickOutside={() => {
          setSidePanel((s) => ({ ...s, isOpen: false }));
        }}
        isOpen={sidePanel.isOpen}
        InnerComponent={SidePanelInner}
      >
        <LayoutContainer as="main">
          <Box m={3}>
            <BulkActionHistoryTableActive
              selectedRowId={sidePanel.bulkActionId}
              onRowClick={onActiveRowClick}
            />
          </Box>
          <Box mx={3} mb={3}>
            <BulkActionHistoryTableCompleted
              selectedRowId={sidePanel.bulkActionId}
              onRowClick={onInActiveRowClick}
            />
          </Box>
        </LayoutContainer>
      </SidePanel>
    </LayoutContainer>
  );
};
