import React, { PropsWithChildren } from "react";

import { TABLE_THEMES } from "..";
import { Box } from "../../../Box";
import { GenericTable } from "../../GenericTable";
import { IGenericTable } from "../../GenericTable/definitions";

const BoxRowWrapper = ({ children }: PropsWithChildren<any>) => (
  <Box>{children}</Box>
);

const BoxRowWrapperWithHover = ({ children }: PropsWithChildren<any>) => (
  <Box hasHoverState>{children}</Box>
);

export function GenericBoxRowTable<T extends object = {}>({
  RowContainer,
  theme,
  clickWholeRowToExpand,
  ...rest
}: PropsWithChildren<IGenericTable<T>>) {
  return (
    <GenericTable
      theme={{
        ...TABLE_THEMES.BOX_ROW_TABLE,
        ...theme,
      }}
      RowContainer={
        RowContainer
          ? RowContainer
          : clickWholeRowToExpand
          ? BoxRowWrapperWithHover
          : BoxRowWrapper
      }
      clickWholeRowToExpand={clickWholeRowToExpand}
      {...rest}
    />
  );
}
