import React, { FunctionComponent } from "react";

import { formatDistanceToNow, parseISO } from "date-fns";
import { Clock as ClockIcon } from "react-feather";
import styled from "styled-components";

import { calculateRiskLevelForSensitivity } from "../../atoms/icons/RiskDot";
import { FlameIcon } from "../../atoms/icons/flameIcon";
import { LabelledAtom } from "../../generated/graphql.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { capitalize } from "../../utils/string/capitalize";
import { AtomSharingFigure } from "../AtomSharingFigure";
import { Box } from "../Box";
import { LayoutContainer } from "../layout/LayoutContainer";
import { LoadingSpinner } from "../loading/LoadingSpinner";

interface ISummaryPanel extends Partial<LabelledAtom> {
  loading?: boolean;
  sensitivityScore?: number;
}

interface IPanelBlock {
  children: React.ReactNode;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  border-right-color: ${COLOR.LINE_2};
  border-right-width: 1px;
  & :last-child {
    border-right-width: 0px;
  }
  & svg {
    margin-bottom: ${APPLICATION_SPACING(1)};
  }
`;

const PanelBlock = ({ children }: IPanelBlock) => (
  <StyledLayoutContainer
    display="flex"
    flexDirection="column"
    flexBasis={0}
    flexGrow={1}
    justifyContent="center"
    alignItems="center"
    fontSize="sm"
    color="BODY"
    padding={2}
  >
    {children}
  </StyledLayoutContainer>
);

export const SummaryPanel: FunctionComponent<ISummaryPanel> = ({
  exposure,
  createdAt,
  loading,
  sensitivityScore,
}) => {
  const riskLevel = calculateRiskLevelForSensitivity(
    defaultTo(sensitivityScore, 0)
  );

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="stretch"
      mb={2}
      minHeight="84px"
    >
      {loading ? (
        <LayoutContainer
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          minHeight="84px"
        >
          <LoadingSpinner />
        </LayoutContainer>
      ) : (
        <>
          <PanelBlock>
            <FlameIcon large riskLevel={riskLevel} />
            {capitalize(riskLevel)}
          </PanelBlock>

          <PanelBlock>
            <AtomSharingFigure column {...exposure} />
          </PanelBlock>

          <PanelBlock>
            <ClockIcon color={COLOR.BODY} size={24} />
            {Boolean(createdAt) && formatDistanceToNow(parseISO(createdAt))}
          </PanelBlock>
        </>
      )}
    </Box>
  );
};
