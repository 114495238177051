import styled from "styled-components";
import {
  layout,
  LayoutProps,
  SpaceProps,
  space,
  GridProps,
  grid,
  ColorProps,
  color,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
  compose,
  border,
  BorderProps,
} from "styled-system";

import theme from "../../styling/theme";

export const LayoutContainer = styled.div<
  LayoutProps<typeof theme> &
    SpaceProps &
    ColorProps<typeof theme> &
    FlexboxProps<typeof theme> &
    GridProps<typeof theme> &
    PositionProps<typeof theme> &
    TypographyProps<typeof theme> &
    BorderProps<typeof theme>
>(compose(position, layout, space, grid, color, flexbox, typography, border));

export const P = styled.p<
  LayoutProps<typeof theme> &
    SpaceProps &
    ColorProps<typeof theme> &
    FlexboxProps<typeof theme> &
    GridProps<typeof theme> &
    PositionProps<typeof theme> &
    TypographyProps<typeof theme>
>(compose(position, layout, space, grid, color, flexbox, typography));

export const Span = styled.span<
  LayoutProps<typeof theme> &
    SpaceProps &
    ColorProps<typeof theme> &
    FlexboxProps<typeof theme> &
    GridProps<typeof theme> &
    PositionProps<typeof theme> &
    TypographyProps<typeof theme>
>(compose(position, layout, space, grid, color, flexbox, typography));
