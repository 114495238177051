import React from "react";

import * as Sentry from "@sentry/react";

import { ContextMenuButton, PrimarySquareButton } from "../../atoms/Button";
import { ContextMenuContainer } from "../../components/ContextMenuContainer";
import { ContextMenuContext } from "../../components/ContextMenuContainer/context";
import { IDetectionLocationDetail } from "../../definitions/types/detection";
import { Risk } from "../../generated/graphql.generated";
import { useModifyGdrivePermissionForRiskMutation } from "../../operations/detections/mutations/modifyGdrivePermission.generated";
import { useExtendedMutation } from "../../operations/hooks/useExtendedMutation";
import { MARK_RISK_AS_RESOLVED } from "../../operations/risks/mutations/markRiskAsResolved/markRiskAsResolved";
import { useMarkRiskAsResolvedMutation } from "../../operations/risks/mutations/markRiskAsResolved/markRiskAsResolved.generated";
import { canEditPermission } from "../../operations/violations/helpers";
import { useToast } from "../../recoil/toasts/provider";
import { defaultTo } from "../../utils/maybe/defaultTo";

export interface IContextMenu {
  selectedRisk: Risk;
  clearSelectedRow: () => void;
}

export const ContextMenu = ({
  selectedRisk,
  clearSelectedRow,
}: IContextMenu) => {
  const addToast = useToast();

  const { id, atomId } = selectedRisk;

  let atomReference: {
    appEntity: IDetectionLocationDetail; // I mean, it's not, but shh
    entityId: string;
    installationId: string;
  };

  try {
    atomReference = JSON.parse(atob(defaultTo(atomId, "")));
  } catch (error) {
    // well, we tried
    Sentry.captureException(error);
  }

  const markRiskAsResolvedMutation = useExtendedMutation(
    useMarkRiskAsResolvedMutation,
    {
      initialState: {},
      definition: MARK_RISK_AS_RESOLVED,
    }
  );

  const { markRiskAsResolved } = markRiskAsResolvedMutation.run();

  const [modifyGdrivePermissionInternalState, modifyGdrivePermissionInternal] =
    useModifyGdrivePermissionForRiskMutation();

  const [modifyGdrivePermissionPublicState, modifyGdrivePermissionPublic] =
    useModifyGdrivePermissionForRiskMutation();

  const [modifyGdrivePermissionBothState, modifyGdrivePermissionBoth] =
    useModifyGdrivePermissionForRiskMutation();

  return (
    <>
      <ContextMenuContainer buttonText="ACTIONS" Button={PrimarySquareButton}>
        <ContextMenuContext.Consumer>
          {({ setVisibility }) => (
            <>
              <ContextMenuButton
                loading={markRiskAsResolvedMutation.fetching}
                onClick={async () => {
                  await markRiskAsResolved({ id: defaultTo(id, "") });
                  clearSelectedRow();
                  setVisibility(false);
                }}
              >
                Mark as resolved
              </ContextMenuButton>

              {canEditPermission(atomReference?.appEntity) && (
                <>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionPublicState.fetching}
                    onClick={async () => {
                      await modifyGdrivePermissionPublic({
                        riskId: id,
                        revokeInternalSharing: false,
                        revokePublicSharing: true,
                      });
                      addToast({
                        type: "success",
                        message: "Successfully revoked public permissions",
                      });
                      setVisibility(false);
                    }}
                  >
                    Revoke Public Sharing
                  </ContextMenuButton>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionInternalState.fetching}
                    onClick={async () => {
                      await modifyGdrivePermissionInternal({
                        riskId: id,
                        revokePublicSharing: false,
                        revokeInternalSharing: true,
                      });
                      addToast({
                        type: "success",
                        message: "Successfully revoked internal permissions",
                      });
                      setVisibility(false);
                    }}
                  >
                    Revoke Internal Sharing
                  </ContextMenuButton>
                  <ContextMenuButton
                    loading={modifyGdrivePermissionBothState.fetching}
                    onClick={async () => {
                      await modifyGdrivePermissionBoth({
                        riskId: id,
                        revokeInternalSharing: true,
                        revokePublicSharing: true,
                      });
                      addToast({
                        type: "success",
                        message: "Successfully revoked both permissions",
                      });
                      setVisibility(false);
                    }}
                  >
                    Revoke Both
                  </ContextMenuButton>
                </>
              )}
            </>
          )}
        </ContextMenuContext.Consumer>
      </ContextMenuContainer>
    </>
  );
};
