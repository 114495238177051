import React, {
  useState,
  useRef,
  useMemo,
  CSSProperties,
  useCallback,
  FunctionComponent,
  MouseEvent,
} from "react";

import {
  Check as CheckIcon,
  ChevronDown as ChevronDownIcon,
  ChevronRight,
  ChevronUp as ChevronUpIcon,
} from "react-feather";
import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { useOnClickOutside } from "../../utils/layout/useOnClickOutside";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface IDropdown {
  options: {
    key: string | number;
    value: string | number;
    selected?: boolean;
    label?: string;
    isUnselected?: boolean;
  }[];
  onSelect?: Function;
  label?: string;
  className?: string;
  style?: CSSProperties;
  showLabel?: string;
  selected?: any;
}

export interface IDropdownWithPlaceholder extends IDropdown {
  placeholder: string;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  cursor: pointer;
  border: 1px solid ${COLOR.LINE_2};
  border-radius: 2px;
  font-weight: 300;
`;

const StyledOptionsContainer = styled(LayoutContainer)`
  border: 1px solid #dbddeb;
  top: 38px;
  right: 0;
  min-width: 100%;
  z-index: 10;
`;

const StyledPlaceholderOptionsContainer = styled(LayoutContainer)`
  border: 1px solid #dbddeb;
  border-top: 0;
  top: 100%;
  left: -1px;
  right: -1px;
  z-index: 20;
`;

const StyledDropdownButton = styled.button<{
  selected?: boolean;
  isPlaceholder?: boolean;
}>`
  display: flex;
  padding: ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(1)}
    ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(2)};
  font-weight: 300;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  ${(props) =>
    props.selected &&
    `
    color: #49b5c0;
  `}

  &:hover {
    color: #49b5c0;
  }

  ${(props) =>
    props.isPlaceholder &&
    `
    color: #A0A3BD;

    &:hover {
      color: #A0A3BD;
    }
  `}
`;

const SpacedCheckIcon = styled(CheckIcon)`
  margin-right: 0.5em;
  width: 24px;
`;

const SpacedContainer = styled.div`
  margin-right: 0.5em;
  width: 24px;
`;

const StyledPlacholder = styled.div<{ selectedOptionKey: string | number }>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: ${APPLICATION_SPACING(2)};
  color: ${(props) =>
    props.selectedOptionKey ? COLOR.BODY : COLOR.PLACEHOLDER};
`;

const StyledPlaceholderDropdownButton = styled.button`
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const StyledChevronContainer = styled(LayoutContainer)`
  margin-left: auto;
  margin-top: -2px;
`;

const StyledButton = styled.button<{ isPlaceholder: boolean }>`
  width: 100%;
  &:hover {
    color: ${COLOR.PLACEHOLDER};
  }

  ${(props) =>
    props.isPlaceholder &&
    `
    color: #A0A3BD;

    &:hover {
      color: #A0A3BD;
    }
  `}
`;

export const Dropdown: FunctionComponent<IDropdown> = ({
  options,
  onSelect,
  label,
  className,
  style,
  showLabel,
  selected,
}) => {
  const [isShown, hideOrShow] = useState<boolean>(false);
  const ref = useRef(null);

  const toggleHideOrShow = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      hideOrShow((prev) => !prev);
    },
    [hideOrShow]
  );

  const activeDescendant = useMemo(
    () => options.findIndex(({ selected }) => selected) ?? 0,
    [options]
  );

  const selectedOption = useMemo(() => {
    if (selected) {
      return options.find((option) => option.value === selected) ?? options[0];
    } else {
      return options[activeDescendant] ?? {};
    }
  }, [options, activeDescendant, selected]);

  useOnClickOutside(ref, () => {
    hideOrShow(false);
  });

  return (
    <StyledLayoutContainer
      p={1}
      backgroundColor="INPUT_BACKGROUND"
      fontSize="xsm"
      position="relative"
      ref={ref}
      className={className}
      style={style}
      role="listbox"
      tabIndex={0}
      aria-activedescendant={`listbox1-${activeDescendant}`}
    >
      <StyledButton
        isPlaceholder={!!selectedOption.isUnselected}
        onClick={toggleHideOrShow}
      >
        <LayoutContainer display="flex" flexWrap="nowrap" alignItems="center">
          {showLabel ? (
            <div>
              {label}: {selectedOption.key}
            </div>
          ) : (
            <div>
              {selectedOption.label ? selectedOption.label : selectedOption.key}
            </div>
          )}

          <StyledChevronContainer color="PLACEHOLDER">
            {isShown ? (
              <ChevronDownIcon size={16} />
            ) : (
              <ChevronRight size={16} />
            )}
          </StyledChevronContainer>
        </LayoutContainer>
      </StyledButton>
      {isShown && (
        <StyledOptionsContainer
          backgroundColor="BACKGROUND"
          py={1}
          position="absolute"
          fontSize="xsm"
        >
          {options.map(({ key, value, selected, ...option }, i: number) => (
            <StyledDropdownButton
              isPlaceholder={option.isUnselected}
              selected={selected || selectedOption.value === value}
              key={key}
              onClick={(event) => {
                onSelect?.(value);
                toggleHideOrShow(event);
              }}
              id={`listbox1-${i + 1}`}
            >
              <LayoutContainer pr={2} display="flex" flexWrap="wrap">
                {option.label ? option.label : key}
              </LayoutContainer>
              <LayoutContainer ml="auto">
                {selected || selectedOption.value === value ? (
                  <SpacedCheckIcon size={16} />
                ) : (
                  <SpacedContainer />
                )}
              </LayoutContainer>
            </StyledDropdownButton>
          ))}
        </StyledOptionsContainer>
      )}
    </StyledLayoutContainer>
  );
};

export const DropdownWithPlaceholder: FunctionComponent<
  IDropdownWithPlaceholder
> = ({ options, onSelect, placeholder, className, style }) => {
  const [isShown, hideOrShow] = useState<boolean>(false);
  const ref = useRef(null);

  const toggleHideOrShow = () => hideOrShow((prev) => !prev);

  const activeDescendant = useMemo(
    () => options.findIndex(({ selected }) => selected) ?? 0,
    [options]
  );

  const selectedOption = useMemo(
    () => options[activeDescendant] ?? {},
    [options, activeDescendant]
  );

  useOnClickOutside(ref, () => {
    hideOrShow(false);
  });

  return (
    <StyledLayoutContainer
      p={1}
      backgroundColor="INPUT_BACKGROUND"
      fontSize="sm"
      position="relative"
      ref={ref}
      className={className}
      style={style}
      role="listbox"
      tabIndex={0}
      aria-activedescendant={`listbox1-${activeDescendant}`}
    >
      <StyledPlaceholderDropdownButton onClick={toggleHideOrShow}>
        <LayoutContainer display="flex">
          <StyledPlacholder selectedOptionKey={selectedOption.key}>
            {selectedOption.key ?? placeholder}
          </StyledPlacholder>
          <LayoutContainer px={1}>
            {isShown ? (
              <ChevronUpIcon height="100%" />
            ) : (
              <ChevronDownIcon height="100%" />
            )}
          </LayoutContainer>
        </LayoutContainer>
      </StyledPlaceholderDropdownButton>
      {isShown && (
        <StyledPlaceholderOptionsContainer
          backgroundColor="BACKGROUND"
          py={1}
          position="absolute"
        >
          {options.map(({ key, value, selected }, i: number) => (
            <StyledDropdownButton
              selected={selected}
              key={key}
              onClick={() => {
                onSelect?.(value);
                toggleHideOrShow();
              }}
              id={`listbox1-${i + 1}`}
            >
              {selected ? <SpacedCheckIcon /> : <SpacedContainer />}
              <div>{key}</div>
            </StyledDropdownButton>
          ))}
        </StyledPlaceholderOptionsContainer>
      )}
    </StyledLayoutContainer>
  );
};
