import { LeaveSDFFeedbackDefinition } from "./leaveSdfFeedback";

type TaskDefs = LeaveSDFFeedbackDefinition["tasks"];

export const success: TaskDefs["success"] = ({ riskId }) => ({
  update: {
    riskId,
    approved: true,
    comments: "",
  },
  successToast: {
    type: "success",
    message: "Thank you letting us know you'd like to see more like this",
  },
});

export const reject: TaskDefs["reject"] = ({ riskId, comments }) => ({
  update: {
    riskId,
    approved: false,
    comments,
  },
  successToast: {
    type: "success",
    message: "Thank you for providing your feedback",
  },
});
