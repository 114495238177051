import { Filt } from "@metomic/darwin-shared-lib";
import { Location } from "history";

import { UIFilterState } from "../../recoil/filters/atom";

export const useQueryParams = (location?: string) => {
  return new URLSearchParams(location || window.location.search);
};

export const parseFilterQueryFromUrl = (location?: Location): UIFilterState => {
  const filterParams = new URLSearchParams(
    location ? location.search : window.location.search
  ).get("filters");

  try {
    return filterParams ? JSON.parse(filterParams) : {};
  } catch (error) {
    return { filters: Filt.empty() };
  }
};
