import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetLocationSummaryForAtomQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type GetLocationSummaryForAtomQuery = {
  __typename?: "RootQueryType";
  getLabelledAtomById?:
    | {
        __typename?: "LabelledAtom";
        id?: string | null | undefined;
        iconType?: string | null | undefined;
        createdAt?: any | null | undefined;
        location?:
          | {
              __typename?: "AtomLocation";
              id?: string | null | undefined;
              label?: string | null | undefined;
              deeplink?: string | null | undefined;
              appType?: string | null | undefined;
              entityType?: string | null | undefined;
              parentId?: string | null | undefined;
              createdBy?:
                | {
                    __typename?: "Actor";
                    id?: string | null | undefined;
                    displayName?: string | null | undefined;
                    imageUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              lastEditedBy?:
                | {
                    __typename?: "Actor";
                    id?: string | null | undefined;
                    displayName?: string | null | undefined;
                    imageUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        exposure?:
          | {
              __typename?: "AtomExposure";
              isPublic?: boolean | null | undefined;
              internalViewerCount?: number | null | undefined;
              externalViewerCount?: number | null | undefined;
              score?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetLocationSummaryForAtomDocument = gql`
  query GetLocationSummaryForAtom($id: ID!) {
    getLabelledAtomById(id: $id) {
      id
      iconType
      location {
        id
        label
        deeplink
        appType
        entityType
        parentId
        createdBy {
          id
          displayName
          imageUrl
        }
        lastEditedBy {
          id
          displayName
          imageUrl
        }
      }
      exposure {
        isPublic
        internalViewerCount
        externalViewerCount
        score
      }
      createdAt
    }
  }
`;

export function useGetLocationSummaryForAtomQuery(
  options: Omit<
    Urql.UseQueryArgs<GetLocationSummaryForAtomQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetLocationSummaryForAtomQuery>({
    query: GetLocationSummaryForAtomDocument,
    ...options,
  });
}
