import React, { FunctionComponent } from "react";

import styled, { css } from "styled-components";

import {
  DictionaryType,
  useColorDictionary,
} from "../../operations/colors/hooks";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

// Colors need to be more strictly defined once we have an idea what the range will be

export interface IColorLabel {
  uppercase?: boolean;
  className?: string;
  backgroundColor?: string;
  textColor?: string;
  noBorder?: boolean;
  dotColor?: string;
}

interface IStyledColorLabel {
  uppercase?: boolean;
  noBorder?: boolean;
}

const StyledColorLabel = styled.span<IStyledColorLabel>`
  font-size: ${theme.fontSizes.xsm};
  padding: ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(1)};
  display: inline-flex;
  align-items: center;
  line-height: 1rem;

  ${(props) =>
    !props.noBorder &&
    css`
      border-width: 1px;
      border-color: ${COLOR.LINE};
    `}
`;

const StyledLabelInner = styled.span`
  width: ${APPLICATION_SPACING(1)};
  height: ${APPLICATION_SPACING(1)};
  margin-right: ${APPLICATION_SPACING(1)};
  display: block;
  border-radius: 9999px;
`;

export const ColorLabel: FunctionComponent<IColorLabel> = ({
  children,
  uppercase,
  className,
  backgroundColor = COLOR.INPUT_BACKGROUND,
  textColor,
  noBorder,
  dotColor,
}) => (
  <StyledColorLabel
    noBorder={noBorder}
    uppercase={uppercase}
    style={{ backgroundColor, color: textColor }}
    className={className}
  >
    {dotColor && (
      <StyledLabelInner
        style={{ backgroundColor: dotColor }}
      ></StyledLabelInner>
    )}
    {children}
  </StyledColorLabel>
);

export const DictionaryColorLabel: FunctionComponent<{
  id: string;
  className?: string;
  type: DictionaryType;
}> = ({ id, className, children, type }) => {
  const { getColorById } = useColorDictionary();

  const color = getColorById(id, type);

  return (
    <ColorLabel className={className} dotColor={color}>
      {children}
    </ColorLabel>
  );
};
