import React, { Dispatch, SetStateAction, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

import { ButtonPillPrimary } from "../../../atoms/Button";
import { Heading } from "../../../atoms/Heading";
import {
  IntegrationSetup,
  IntegrationSetupStepType,
} from "../../../definitions/types/integration";
import { INTEGRATION_API } from "../../../operations/integrations/api";
import { useToast } from "../../../recoil/toasts/provider";
import { history } from "../../../routing/history";
import { APPLICATION_ROUTES } from "../../../routing/routes";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { isEmptyObject } from "../../../utils/object/isEmptyObject";
import { Box } from "../../Box";
import { LayoutContainer } from "../../layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "../BaseModal";
import { CloseButton } from "../shared";
import { IntegrationProgressBar } from "./components/IntegrationProgressBar";
import { IntegrationNodeFactory } from "./factories/IntegrationNodeFactory";

export interface IIntegrationModal extends IntegrationSetup {}

export interface IIntegrationModalComponent extends IModalBase {
  data?: IIntegrationModal;
  setModalData: (data: IntegrationSetup) => void;
  refetchInstallations: () => void;
}

interface IIntegrationStepAction {
  installationId: string;
  type: IntegrationSetupStepType;
  totalSteps: number;
  stepNumber: number;
  getAccessToken: Function;
  setIsLoading: (state: boolean) => void;
  setIsError: (error: string) => void;
  setModalData: (data: IntegrationSetup) => void;
  setStepPayload: Dispatch<SetStateAction<any>>;
  toggleModal: (item: any) => void;
  stepPayload: Record<string, any>;
  refetchInstallations: () => void;
}

const nextIntegrationStepAction = async (
  integrationArgs: IIntegrationStepAction
) => {
  const {
    setModalData,
    setIsLoading,
    setStepPayload,
    toggleModal,
    refetchInstallations,
    stepPayload,
    ...rest
  } = integrationArgs;

  setIsLoading(true);

  try {
    const { data, finished } = await INTEGRATION_API.submitInstallationStep({
      ...rest,
      stepPayload,
    });

    const { nextStep } = data;

    if (finished || !nextStep) {
      setStepPayload({});
      toggleModal({});
      setIsLoading(false);
      history.push(APPLICATION_ROUTES.INTEGRATIONS.url);
      refetchInstallations();

      return;
    }

    const nextStepData = await INTEGRATION_API.getInstallationStepDetails({
      ...rest,
      stepNumber: nextStep,
    });

    setModalData(nextStepData.data);
    setStepPayload({});
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    return;
  }
};

const handleRedirect = (data: IntegrationSetup) => {
  window.location.href = data.currentStep.redirectUrl || "";
};

const StyledConfigBox = styled(Box)`
  width: 960px;
  height: 100%;
  padding-bottom: ${APPLICATION_SPACING(4)};
  padding-top: ${APPLICATION_SPACING(10)};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(3)};
  color: ${COLOR.LABEL};
  position: absolute;
  top: 1.75rem;
`;

const IntegrationModal = ({
  refetchInstallations,
  showModal,
  toggleModal,
  setModalData,
  data,
}: IIntegrationModalComponent) => {
  const addToast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [stepPayload, setStepPayload] = useState<Record<string, any>>({});

  const setError = (message: string) => {
    addToast({
      type: "error",
      message,
    });
  };

  return (
    <ModalWrapper fullHeight showModal={showModal} toggleModal={toggleModal}>
      {!isEmptyObject(data) && (
        <StyledConfigBox>
          <CloseButton onClick={() => toggleModal()} />
          <StyledHeading type="text-md" Element="h1" isBodyColor>
            {data?.currentStep.stepTitle}
          </StyledHeading>
          <LayoutContainer
            flex={1}
            width="100%"
            height="100%"
            display="flex"
            overflow="auto"
          >
            <LayoutContainer
              width="100%"
              px={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              m="auto"
            >
              {data?.currentStep.nodes &&
                data?.currentStep.nodes.map((node) =>
                  IntegrationNodeFactory(node, setStepPayload, stepPayload)
                )}
              <LayoutContainer
                mt={
                  data?.currentStep.nodes && data?.currentStep.nodes.length
                    ? 1
                    : 0
                }
              >
                <ButtonPillPrimary
                  smallLoader
                  loading={loading}
                  onClick={() => {
                    if (!data) {
                      return;
                    }

                    if (data?.currentStep.type === "redirect") {
                      handleRedirect(data);
                      return;
                    }

                    nextIntegrationStepAction({
                      refetchInstallations,
                      installationId: data?.installationId,
                      type: data?.currentStep.type,
                      totalSteps: data?.totalSteps,
                      stepNumber: data?.currentStep.stepNumber,
                      getAccessToken: getAccessTokenSilently,
                      setIsLoading: setLoading,
                      setIsError: setError,
                      stepPayload,
                      setStepPayload,
                      setModalData,
                      toggleModal,
                    });
                  }}
                >
                  {data?.currentStep.buttonText}
                </ButtonPillPrimary>
              </LayoutContainer>
            </LayoutContainer>
          </LayoutContainer>
          <IntegrationProgressBar
            currentStepNumber={data?.currentStep.stepNumber}
            totalSteps={data?.totalSteps}
          />
        </StyledConfigBox>
      )}
    </ModalWrapper>
  );
};

export default IntegrationModal;
