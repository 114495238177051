import {
  PlusCircle as PlusCircleIcon,
  MinusCircle as MinusCircleIcon,
} from "react-feather";
import { Column } from "react-table";
import { css } from "styled-components";

import { RiskPill } from "../../components/RiskPill";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IMonitor } from "../../definitions/types/monitors";
import { MonitorRiskLevel } from "../../generated/graphql.generated";
import { sumViolations } from "../../page/Violations/Violations";
import { ContextMenu } from "../RuleOverviewList/ContextMenu";

export const columns: Column<IMonitor>[] = [
  {
    Header: () => null,
    id: "expander",
    columnStyles: css`
      padding-left: 1.75rem;
    `,
    width: 80,
    minWidth: 80,
    maxWidth: 80,
    disableResizing: true,
    Cell: ({ row }: { row: any }) => (
      <LayoutContainer as="span" color="LABEL">
        {row.isExpanded ? <MinusCircleIcon /> : <PlusCircleIcon />}
      </LayoutContainer>
    ),
  },
  {
    Header: "Name",
    accessor: "name",
    width: 300,
    minWidth: 300,
    maxWidth: 2000,
  },
  {
    Header: "Risk Level",
    accessor: "riskLevel",
    headerStyles: css`
      text-align: center;
    `,
    columnStyles: css`
      justify-content: center;
      align-items: center;
      padding-right: 0;
    `,
    disableResizing: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    Cell: (data) => {
      return <RiskPill riskLevel={data.cell.value as MonitorRiskLevel} />;
    },
  },
  {
    Header: "Violations",
    accessor: "violationSummary",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    headerStyles: css`
      text-align: center;
    `,
    columnStyles: css`
      justify-content: center;
      align-items: center;
      padding-right: 0;
    `,
    disableResizing: true,
    Cell: (data) => {
      return sumViolations(data.cell.value?.currentViolations);
    },
  },
  {
    Header: () => null,
    id: "context-menu-dots",
    accessor: "id",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    disableResizing: true,
    isContextMenu: true,
    Cell: (data) => {
      return <ContextMenu key="context-menu" id={data.cell.value} />;
    },
  },
];
