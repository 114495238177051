import { useEffect } from "react";

import styled from "styled-components";
import { useQuery } from "urql";

import { ButtonOutlinePrimary, ReactRouterLink } from "../../atoms/Button";
import { Box } from "../../components/Box";
import { Content } from "../../components/Content";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper/PageHeaderWrapper";
import { SimplifiedLineChart } from "../../components/SimplifiedLineChart";
import { ThreatBlock } from "../../components/ThreatBlock";
import { ThreatSummaryBlock } from "../../components/ThreatSummaryBlock";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { Column, Grid } from "../../components/layout/grid";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { RiskLevel } from "../../definitions/types/risk-level";
import {
  IViolationSummary,
  CurrentViolations,
} from "../../definitions/types/violations";
import { RuleOverviewList } from "../../features/RuleOverviewList";
import { OVERVIEW_PAGE_QUERY } from "../../operations/violations/queries/violationSummaryQuery";
import { IRouteDefinition } from "../../routing/RouteWithAuthentication";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { APPLICATION_SPACING } from "../../styling/spacing";

interface IOverviewPageQuery {
  violationSummary: IViolationSummary;
  listMonitorIds: string[];
}

export const sumViolations = (violations?: CurrentViolations) => {
  if (!violations) return 0;

  return Object.values(violations)
    .filter((value) => typeof value !== "string")
    .reduce((total, current) => current + total, 0);
};

const StyledCreateRuleButton = styled(ButtonOutlinePrimary)`
  display: inline-flex;
  margin-top: ${APPLICATION_SPACING(2)};
  text-transform: uppercase;
  align-items: center;
`;

interface IAnimatedSection {
  fadeOut: boolean;
}

const AnimatedSection = styled(LayoutContainer).attrs({
  as: "section",
})<IAnimatedSection>`
  opacity: ${(props) => (props.fadeOut ? "0.2" : "1")};
  transition: 0.3s all;
`;

const WrappedRadiusLayoutContainer = styled(LayoutContainer)`
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const OverviewSummary = () => {
  const [result, executeQuery] = useQuery<IOverviewPageQuery>({
    query: OVERVIEW_PAGE_QUERY,
    requestPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(
        () => executeQuery({ requestPolicy: "network-only" }),
        30000
      );
      return () => clearTimeout(id);
    }
  }, [result.fetching, executeQuery]);

  const { data, fetching } = result;

  const violationSummary = data?.violationSummary;

  const ruleArray = data?.listMonitorIds;

  const violationCount = sumViolations(violationSummary?.currentViolations);

  const shouldShowLoadingIndicator = fetching && !data;

  const hasNoRules = Boolean(
    ruleArray && ruleArray.length === 0 && (!fetching || data)
  );

  return (
    <Box position="relative">
      {shouldShowLoadingIndicator && <StyledLoadingSpinner />}
      <AnimatedSection
        fadeOut={shouldShowLoadingIndicator}
        fontSize="sm"
        display={["block", "flex"]}
      >
        <WrappedRadiusLayoutContainer width={["100%", 2 / 12]}>
          <ThreatBlock
            riskLevel={violationSummary?.currentThreatLevel || RiskLevel.LOW}
            violationCount={violationCount}
            showUnknown={hasNoRules}
          />
        </WrappedRadiusLayoutContainer>
        {!hasNoRules && (
          <Grid responsive flex={1} fontSize="sm">
            <Column span={6}>
              <ThreatSummaryBlock
                CRITICAL={violationSummary?.currentViolations.critical || 0}
                HIGH={violationSummary?.currentViolations.high || 0}
                MEDIUM={violationSummary?.currentViolations.medium || 0}
                LOW={violationSummary?.currentViolations.low || 0}
                activeRules={violationSummary?.triggeredRules || 0}
                showUnknown={hasNoRules}
              />
            </Column>
            <Column span={6}>
              <SimplifiedLineChart graphData={violationSummary?.history} />
            </Column>
          </Grid>
        )}
        {hasNoRules && (
          <LayoutContainer
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p={4}
            textAlign="center"
          >
            <div>
              <LayoutContainer as="p" fontSize="mdlg">
                To see more data and insights please start by creating your
                first rule
              </LayoutContainer>
              <StyledCreateRuleButton
                as={ReactRouterLink}
                to={APPLICATION_ROUTES.RULES.NESTED_ROUTES?.CREATE.url || ""}
              >
                Create Rule
              </StyledCreateRuleButton>
            </div>
          </LayoutContainer>
        )}
      </AnimatedSection>
    </Box>
  );
};

const Violations = ({ routeDefinition }: IRouteDefinition) => {
  const breadcrumb = routeDefinition?.breadcrumb;
  return (
    <PageHeaderWrapper breadcrumb={breadcrumb}>
      <Content as="main">
        <OverviewSummary />
        <RuleOverviewList />
      </Content>
    </PageHeaderWrapper>
  );
};

export default Violations;
