import React, { FunctionComponent } from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import styled from "styled-components";

import { APPLICATION_SPACING } from "../../../styling/spacing";
import theme from "../../../styling/theme";
import { CheckIcon } from "../../icons/checkIcon";

export interface ICheckbox {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  stopPropagation?: boolean;
}

export type CheckedState = boolean;

export interface ICheckboxWithLabel extends ICheckbox {
  label: string;
}

const StyledCheckbox = styled(CheckboxPrimitive.Root)`
  color: ${theme.colors.LINE};
  background-color: ${({ disabled, checked }) =>
    disabled && !checked
      ? theme.colors.GRAYSCALE_BACKGROUND
      : theme.colors.INPUT_BACKGROUND};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ checked, disabled }) =>
      checked && !disabled ? theme.colors.LINE_SUCCESS : theme.colors.LINE};
  border-radius: 1px;
  margin-right: ${APPLICATION_SPACING(1)};
  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : "0px 1px 3px rgba(0, 0, 0, 0.25)"};
  }
  &:focus {
    outline: ${({ disabled }) =>
      `1px solid ${disabled ? "none" : theme.colors.LINE_SUCCESS}`};
  }
`;

export const Checkbox: FunctionComponent<ICheckbox> = ({
  onChange,
  checked,
  disabled = false,
  stopPropagation,
}) => {
  return (
    <StyledCheckbox
      asChild
      onClick={(event) => {
        if (stopPropagation) event.stopPropagation();
      }}
      checked={checked}
      disabled={disabled}
      onCheckedChange={(checked, ...rest) => {
        onChange(checked as boolean);
      }}
    >
      <div>
        <CheckboxPrimitive.Indicator>
          {checked === true && (
            <CheckIcon
              color={disabled ? theme.colors.LINE : theme.colors.SUCCESS}
            />
          )}
        </CheckboxPrimitive.Indicator>
      </div>
    </StyledCheckbox>
  );
};

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  cursor: pointer;
  font-size: ${theme.fontSizes.sm};
`;

export const CheckboxWithLabel: FunctionComponent<ICheckboxWithLabel> = ({
  label,
  ...restCheckbox
}) => (
  <CheckboxLabel
    color={restCheckbox.disabled ? theme.colors.LINE : theme.colors.BODY}
  >
    <Checkbox {...restCheckbox} />
    {label}
  </CheckboxLabel>
);
