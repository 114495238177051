import React, { useCallback, ReactChild } from "react";

import { nanoid } from "nanoid";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

import { RootToast } from "../../../components/toasts/Root";
import {
  APPLICATION_SPACING,
  SPECIFIC_SPACING,
} from "../../../styling/spacing";
import { useAnimatedListFromBottom } from "../../../utils/animation/hooks/list/useAnimatedListFromBottom";
import toastAtom, { IToast, TOAST_TEMPLATES } from "../atom";

export const useToast = () => {
  const setToasts = useSetRecoilState(toastAtom);

  const addToast = useCallback(
    function (toast: IToast) {
      const toastWithId = {
        id: nanoid(),
        ...toast,
      };
      setToasts((toasts) => [...toasts, toastWithId]);
    },
    [setToasts]
  );

  return addToast;
};

const StyledToastContainer = styled.div`
  position: fixed;
  bottom: ${APPLICATION_SPACING(2)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  margin-top: -${SPECIFIC_SPACING["12px"]};
`;

export function ToastContextProvider({ children }: { children: ReactChild }) {
  const [toasts, setToasts] = useRecoilState(toastAtom);

  const removeToast = useCallback(
    function (id: string) {
      setToasts((toasts) => [...toasts.filter((toast) => toast.id !== id)]);
    },
    [setToasts]
  );

  const toastElements = toasts.map((toast) => {
    const El = TOAST_TEMPLATES[toast.type] || RootToast;
    return (
      <El
        key={toast.id}
        toastType={toast.type}
        onCloseClick={() => {
          removeToast(toast.id);
        }}
      >
        {toast.message}
      </El>
    );
  });

  const animatedListItems = useAnimatedListFromBottom({
    listOfElements: toastElements,
    containerClass: "flex justify-center",
  });

  return (
    <>
      {children}
      <StyledToastContainer>{animatedListItems}</StyledToastContainer>
    </>
  );
}
