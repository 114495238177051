import React from "react";

import { LayoutContainer } from "../../layout/LayoutContainer";
import { StyledLoadingContainer } from "../LoadingIcon";

export const LoadingSpinner = ({
  className,
  ...rest
}: {
  className?: string;
  isWhite?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  isFullScreen?: boolean;
}) => (
  <LayoutContainer
    display="flex"
    flex={1}
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    className={className}
  >
    <StyledLoadingContainer {...rest} spinner={true} />
  </LayoutContainer>
);
