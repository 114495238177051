import { useCallback } from "react";

import { OperationContext } from "urql";

import { usePoll } from "../timing/usePoll";
import { IPaginatedTableState } from "./useTableState";

interface IPollPaginatedTableList {
  tableStateGetterSetter: [
    IPaginatedTableState,
    React.Dispatch<React.SetStateAction<IPaginatedTableState>>
  ];
  runQuery: (opts?: Partial<OperationContext> | undefined) => void;
  frequency: number;
  initialPageSize: number;
  numberOfResults?: number;
  pause?: boolean;
}

export const usePollPaginatedTableList = ({
  tableStateGetterSetter,
  runQuery,
  frequency,
  initialPageSize,
  numberOfResults,
  pause,
}: IPollPaginatedTableList) => {
  const [state, setter] = tableStateGetterSetter;
  const onPoll = useCallback(() => {
    if (pause) return;

    if (state.cursor) {
      setter((s) => ({
        ...s,
        cursor: "",
        limit: numberOfResults ?? initialPageSize,
        suppressLoader: true,
      }));
    } else {
      setter((s) => ({
        ...s,
        shouldShowFullLoader: false,
        suppressLoader: true,
      }));
      runQuery({
        requestPolicy: "cache-and-network",
      });
    }
  }, [numberOfResults, runQuery, state, setter, initialPageSize, pause]);

  usePoll(onPoll, frequency);
};
