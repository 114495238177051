import React, { FunctionComponent, HTMLAttributes, Suspense } from "react";

import { matchPath, useLocation } from "react-router";
import { Route } from "react-router";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithAuthentication } from ".";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { Dashboard } from "../../layouts/Dashboard";
import { FourOhFour } from "../../page/404";
import { PermissionDenied } from "../../page/PermissionDenied";
import { ApplicationRoute } from "../routes";

interface IJSONDefinedRouter extends HTMLAttributes<HTMLDivElement> {
  permittedRoutes: ApplicationRoute[];
}

export const RouteSuspendWrapper: FunctionComponent = ({ children }) => (
  <Suspense
    fallback={
      <LayoutContainer
        height="calc(100vh - 65px)"
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
      >
        <LoadingSpinner />
      </LayoutContainer>
    }
  >
    {children}
  </Suspense>
);

const getRouteComponent = (RouteDefinition: ApplicationRoute) => {
  if (RouteDefinition.permissionDenied) {
    return (
      <Route
        key="permission-denied-route"
        path={RouteDefinition.url}
        render={() => <PermissionDenied />}
      />
    );
  } else {
    return (
      <RouteWithAuthentication
        requiresAuthentication={RouteDefinition.requiresAuthentication}
        routeDefinition={RouteDefinition}
        key={RouteDefinition.url}
        path={RouteDefinition.url}
        Component={RouteDefinition.Component}
        nestedRoutes={RouteDefinition.NESTED_ROUTES}
        exact={RouteDefinition.exact}
        isLoggedIn={true}
      />
    );
  }
};

export const JSONDefinedRouter: FunctionComponent<IJSONDefinedRouter> = ({
  className,
  permittedRoutes,
}) => {
  const location = useLocation();
  const pathname = location.pathname;

  const RouteDefinition = permittedRoutes.find((route) =>
    matchPath(pathname, {
      path: route.url,
      exact: route.exact,
    })
  );

  return (
    <Dashboard
      nestedRoutes={RouteDefinition?.NESTED_ROUTES}
      currentRouteDefinition={RouteDefinition}
    >
      <RouteSuspendWrapper>
        <Switch>
          {permittedRoutes.map((RouteDefinition) =>
            getRouteComponent(RouteDefinition)
          )}
          <Route path="/onboarding/finish" render={() => <Redirect to="/" />} />
          <Route path="*" render={() => <FourOhFour isLoggedIn={true} />} />
        </Switch>
      </RouteSuspendWrapper>
    </Dashboard>
  );
};
