import React, { PropsWithChildren, useMemo } from "react";

import { useAnimatedListFromBottom } from "../../../../utils/animation/hooks/list/useAnimatedListFromBottom";
import { DefaultRowWrapper, RowWrapper } from "../defaults";
import { DefaultRowType, TableInner } from "../definitions";
import { TableRow } from "./Row";

export const NonVirtualisedTableInner = <T extends DefaultRowType = {}>(
  props: PropsWithChildren<TableInner<T>>
) => {
  const noComponent = useMemo(() => [<span></span>], []);

  const {
    animateList,
    prepareRow,
    rows,
    themeOverrides,
    theme,
    expanded,
    RowContainer = DefaultRowWrapper,
    isMobileLayout,
    onRowClick,
    clickWholeRowToExpand,
  } = props;

  const rowComponents = rows.map((row, i) => {
    prepareRow(row);

    const rowProps = row.getRowProps();

    return (
      <RowWrapper
        theme={theme}
        themeOverrides={themeOverrides}
        key={rowProps.key}
        id={row.original.id}
      >
        <TableRow<T>
          theme={theme}
          themeOverrides={themeOverrides}
          rowProps={rowProps}
          row={row}
          expanded={expanded}
          RowContainer={RowContainer}
          isMobileLayout={isMobileLayout}
          onRowClick={onRowClick}
          clickWholeRowToExpand={clickWholeRowToExpand}
        />
      </RowWrapper>
    );
  });

  const animatedRowComponents = useAnimatedListFromBottom({
    listOfElements: animateList ? rowComponents : noComponent,
  });

  return (
    <>
      <div>{animateList ? animatedRowComponents : rowComponents}</div>
      {props.children}
    </>
  );
};
