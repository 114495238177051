import { IFeatureFlag } from "../../utils/feature-flags/constants";

export const USER_PERMISSIONS = {
  READ_MONITORS: "read:monitors",
  READ_DEEP_LINK: "read:deeplink",
  UPDATE_MEMBERSHIP: "update:membership",
  UPDATE_MONITOR: "update:monitor",
};

export type USER_MEMBERSHIP_STATUS =
  | "ACTIVE"
  | "SUSPENDED"
  | "PENDING_INVITE_ACCEPTANCE";

export type USER_ROLE = "READONLY" | "MEMBER" | "OWNER";

export interface IUser {
  id?: string;
  customerId?: string;
  nickname?: string;
  avatarUrl?: string;
  email?: string;
  role?: USER_ROLE;
  permissions?: string[];
  featureFlags?: Record<IFeatureFlag, boolean>;
  membershipStatus?: USER_MEMBERSHIP_STATUS;
  accountName?: string;
}

export const USER_OWNER = "owner";
