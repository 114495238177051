import React, { FunctionComponent, useMemo } from "react";

import { SummaryPanel } from "../../components/SummaryPanel";
import { useGetExposureSummaryForAtomQuery } from "../../operations/atom/queries/getExposureSummaryForAtom.generated";
import { defaultTo, getMaybeArrayValue } from "../../utils/maybe/defaultTo";

interface IAtomExposurePanel {
  atomId: string;
  riskId: string;
}

export const AtomExposurePanel: FunctionComponent<IAtomExposurePanel> = ({
  atomId,
  riskId,
}) => {
  const [result] = useGetExposureSummaryForAtomQuery({
    variables: { id: atomId, riskId: riskId },
  });

  const sensitivityScore = useMemo(
    () =>
      Math.max(
        ...getMaybeArrayValue(result.data?.getDataPointSummaryForRisk).map(
          (o) => defaultTo(o.level, 0)
        ),
        0
      ),
    [result.data?.getDataPointSummaryForRisk]
  );

  return (
    <SummaryPanel
      sensitivityScore={sensitivityScore}
      loading={result.fetching}
      {...result.data?.getLabelledAtomById}
    />
  );
};
