import React from "react";

import { Link } from "react-router-dom";

import { PrimarySquareButton } from "../../../atoms/Button";
import { onboardingRoutes } from "../../../routing/child/onboarding/routes";
import { defaultTo } from "../../../utils/maybe/defaultTo";

interface IOnboardingNext {
  currentId: string;
  text?: string;
}

export const canShowNextOnboardingButton = (currentId: string) =>
  getCurrentRouteIndex(currentId) < onboardingRoutes.length - 1;

export const getCurrentRouteIndex = (currentId: string) =>
  onboardingRoutes.findIndex((route) => currentId === route.id);

export const getNextRouteIndex = (currentId: string) =>
  getCurrentRouteIndex(currentId) + 1;

export const getNextRoute = (currentId: string) =>
  onboardingRoutes[getNextRouteIndex(currentId)];

export const OnboardingNext = ({ currentId, text }: IOnboardingNext) => {
  const nextRoute = getNextRoute(currentId);

  if (canShowNextOnboardingButton(currentId)) {
    return (
      <PrimarySquareButton
        as={Link}
        to={defaultTo(nextRoute.path, "") as string}
      >
        {text ? text : "Next"}
      </PrimarySquareButton>
    );
  } else {
    return null;
  }
};
