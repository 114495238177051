import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LeaveSdfFeedbackForRiskMutationVariables = Types.Exact<{
  approved: Types.Scalars["Boolean"];
  comments?: Types.InputMaybe<Types.Scalars["String"]>;
  riskId?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type LeaveSdfFeedbackForRiskMutation = {
  __typename?: "RootMutationType";
  leaveSDFFeedback?:
    | { __typename?: "FeedbackReceived"; success?: boolean | null | undefined }
    | null
    | undefined;
};

export const LeaveSdfFeedbackForRiskDocument = gql`
  mutation LeaveSDFFeedbackForRisk(
    $approved: Boolean!
    $comments: String
    $riskId: String
  ) {
    leaveSDFFeedback(
      approved: $approved
      comments: $comments
      riskId: $riskId
    ) {
      success
    }
  }
`;

export function useLeaveSdfFeedbackForRiskMutation() {
  return Urql.useMutation<
    LeaveSdfFeedbackForRiskMutation,
    LeaveSdfFeedbackForRiskMutationVariables
  >(LeaveSdfFeedbackForRiskDocument);
}
