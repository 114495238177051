import React, {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useState,
} from "react";

import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IProgressBar extends HTMLAttributes<HTMLSpanElement> {
  percentage: number;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  height: 7px;
  width: 100%;
`;

const ProgressBarInner = styled.div<{ percentage: number }>`
  position: absolute;
  left: 0;
  top: 0;
  background: ${COLOR.PRIMARY};
  transform: translateX(-${(props) => 100 - props.percentage}%);
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 7px;
  width: 100%;
`;

export const ProgressBar: FunctionComponent<IProgressBar> = ({
  percentage,
}) => {
  const [currentPercentage, setCurrentPercentage] = useState<number>(0);
  useEffect(() => {
    setCurrentPercentage(percentage);
  }, [percentage]);

  return (
    <StyledLayoutContainer
      backgroundColor="BG_PRIMARY"
      position="relative"
      overflow="hidden"
    >
      <ProgressBarInner percentage={currentPercentage} />
    </StyledLayoutContainer>
  );
};
