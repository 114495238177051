import React, { useCallback } from "react";

import { css } from "styled-components";

import { GenericTable } from "../../components/tables/GenericTable";
import { ITableTheme } from "../../components/tables/GenericTable/definitions";
import { TABLE_THEMES } from "../../components/tables/themes";
import { Risk } from "../../generated/graphql.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";

const theme: ITableTheme = {
  table: css`
    height: 100%;
  `,
  row: css`
    padding-left: ${APPLICATION_SPACING(3)};
    &:hover {
      background-color: #edeef5;
    }
  `,
  cell: css`
    border-width: 0px;

    &:last-child {
      font-size: 60px;
      padding-right: 0;
      border-right: 0;
      border-left-width: 1px;
      border-color: ${COLOR.TABLE_BORDER};
    }
  `,
  selectedRowStyles: css`
    background-color: #edeef5;
  `,
};

interface IRiskList {
  onRowClick?: (risk: Risk) => void;
  selectedRowId?: string;
  onScrollEnd?: () => void;
  loading?: boolean;
  canInfiniteScroll?: boolean;
  onTableScrolled?: () => void;
  virtualised?: boolean;
}

export const RiskList = ({
  onRowClick,
  selectedRowId,
  loading,
  onScrollEnd,
  canInfiniteScroll,
  onTableScrolled,
  virtualised,
}: IRiskList) => {
  const clickTableRow = useCallback(
    (risk) => {
      return onRowClick && onRowClick(risk);
    },
    [onRowClick]
  );

  return (
    <GenericTable
      onRowClick={clickTableRow}
      selectedRowId={selectedRowId}
      theme={TABLE_THEMES.NO_HEADER_NO_GAP_TABLE}
      loading={loading}
      onScrollEnd={onScrollEnd}
      canInfiniteScroll={canInfiniteScroll}
      themeOverrides={theme}
      onTableScrolled={onTableScrolled}
      useExternalContext
      virtualised={virtualised}
    />
  );
};
