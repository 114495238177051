import React, { FunctionComponent, useState } from "react";

import styled from "styled-components";

import { ContextMenuButton } from "../../../atoms/Button";
import { LayoutContainer } from "../../../components/layout/LayoutContainer";
import ViolationsHistoryModal from "../../../components/modals/ViolationsHistoryModal";
import { COLOR } from "../../../styling/colors";
import theme from "../../../styling/theme";
import { ViolationActionHistoryConnection } from "../../ViolationActionHistoryConnection";
import { IDetectionWithContextRow } from "../columns";

interface IViolationHistoryButton extends IDetectionWithContextRow {
  asContextMenuItem?: boolean;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${COLOR.PRIMARY};
  font-size: ${theme.fontSizes.xsm};
`;

export const ViolationHistoryDetailsButton: FunctionComponent<
  IViolationHistoryButton
> = ({ asContextMenuItem, violationId }) => {
  const [showViolationsDetailModal, setShowViolationsDetailModal] =
    useState(false);
  return (
    <LayoutContainer position="relative">
      {asContextMenuItem ? (
        <ContextMenuButton
          onClick={() => {
            setShowViolationsDetailModal(!showViolationsDetailModal);
          }}
        >
          View details
        </ContextMenuButton>
      ) : (
        <StyledButton
          onClick={() => {
            setShowViolationsDetailModal(!showViolationsDetailModal);
          }}
        >
          View details
        </StyledButton>
      )}
      <ViolationsHistoryModal
        showModal={showViolationsDetailModal}
        toggleModal={() => {
          setShowViolationsDetailModal(!showViolationsDetailModal);
        }}
      >
        {violationId && (
          <ViolationActionHistoryConnection violationId={violationId} />
        )}
      </ViolationsHistoryModal>
    </LayoutContainer>
  );
};
