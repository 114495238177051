import React from "react";

import styled from "styled-components";

const AnimatedPath = styled.path`
  paint-order: stroke;
  stroke-width: 1.2px;
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  animation: dash 0.8s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const AnimatedPolyline = styled.polyline`
  transform: scale(0);
  transform-origin: 50% 50%;
  animation: scaleIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-delay: 0.5s;

  @keyframes scaleIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const AnimatedCheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <AnimatedPath d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></AnimatedPath>
      <AnimatedPolyline points="22 4 12 14.01 9 11.01"></AnimatedPolyline>
    </svg>
  );
};
