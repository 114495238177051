import React, { FunctionComponent, HTMLAttributes } from "react";

import styled from "styled-components";

import { COLOR } from "../../../../styling/colors";
import { SPECIFIC_SPACING } from "../../../../styling/spacing";
import { LayoutContainer } from "../../../layout/LayoutContainer";

interface IIntegrationProgressBar extends HTMLAttributes<HTMLDivElement> {
  currentStepNumber?: number;
  totalSteps?: number;
}

const StyledTransitionContainer = styled.div`
  height: ${SPECIFIC_SPACING["12px"]};
  position: absolute;
  left: 0;
  background: ${COLOR.PRIMARY};
  transition: 0.3s all ease-in-out;
`;

export const IntegrationProgressBar: FunctionComponent<
  IIntegrationProgressBar
> = ({ className, currentStepNumber = 0, totalSteps = 0 }) => (
  <LayoutContainer
    position="absolute"
    bottom={0}
    width="100%"
    height={SPECIFIC_SPACING["12px"]}
    backgroundColor="BG_PRIMARY"
    className={className}
  >
    <StyledTransitionContainer
      style={{
        width: `${(currentStepNumber / totalSteps) * 100}%`,
      }}
    ></StyledTransitionContainer>
  </LayoutContainer>
);
