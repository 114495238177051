import React from "react";

import styled from "styled-components";

import { PrimarySquareButton } from "../../../atoms/Button";
import { Heading } from "../../../atoms/Heading";
import { AnimatedCheckIcon } from "../../../atoms/icons/AnimatedCheck";
import { OnboardingPageHeaderWrapper } from "../../../components/OnboardingPageHeaderWrapper/OnboardingPageHeaderWrapper";
import { LayoutContainer, P } from "../../../components/layout/LayoutContainer";
import { useUpdateAccountOnboardingStatusMutation } from "../../../operations/user/mutations/updateAccountOnboardingStatus.generated";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { useAnimatedListFromBottom } from "../../../utils/animation/hooks/list/useAnimatedListFromBottom";

const ONBOARDING_HEADER_HEIGHT = "65px";

const StyledHeading = styled(Heading)`
  padding-bottom: ${APPLICATION_SPACING(3)};
  color: ${COLOR.SUCCESS};
  display: flex;
  align-items: center;
`;

export const OnboardingFinish = () => {
  // const routeId = "finish";

  const [updateOnboardingState, setUpdateAccountOnboardingStatus] =
    useUpdateAccountOnboardingStatusMutation();

  const animatedElements = useAnimatedListFromBottom({
    listOfElements: [
      <LayoutContainer display="flex" justifyContent="center">
        <StyledHeading Element="h1" type="display-extra-small">
          <LayoutContainer mr={2}>
            <AnimatedCheckIcon />
          </LayoutContainer>
          Setup Finished
        </StyledHeading>
      </LayoutContainer>,
      <P pb={4} fontSize="sm" textAlign="center" fontWeight={100 as any}>
        Congratulations, you're all set up. You are free to explore everything
        Metomic has to offer from this point. You can begin to retroactively and
        proactively deal wih any existing or upcoming risks.
      </P>,
      <LayoutContainer display="flex" justifyContent="center" pb="100px">
        <PrimarySquareButton
          loading={updateOnboardingState.fetching}
          onClick={() => {
            setUpdateAccountOnboardingStatus({
              status: "completed",
              currentStep: "finish",
            });
          }}
        >
          Go to Dashboard
        </PrimarySquareButton>
      </LayoutContainer>,
    ],
    trail: 120,
  });

  return (
    <OnboardingPageHeaderWrapper>
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={`calc(100vh - ${ONBOARDING_HEADER_HEIGHT})}`}
      >
        <LayoutContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="75%"
          maxWidth="850px"
        >
          {animatedElements}
        </LayoutContainer>
      </LayoutContainer>
    </OnboardingPageHeaderWrapper>
  );
};
