import React, { Dispatch, SetStateAction } from "react";

import { InputNode } from "../../../../../definitions/types/integration";
import { StyledTextInput } from "../../../shared";

export const IntegrationInputBox = ({
  node,
  setDeploymentData,
  stepPayload,
}: {
  node: InputNode;
  setDeploymentData: Dispatch<SetStateAction<Record<string, any>>>;
  stepPayload: Record<string, any>;
}) => {
  return (
    <StyledTextInput
      containerClass="w-full"
      heading={node.config.label}
      placeholder={node.config.placeholder}
      value={stepPayload[node.id]}
      onChange={(value) =>
        setDeploymentData((s: any) => ({
          ...s,
          [node.id]: value,
        }))
      }
      type={node.config.type}
    />
  );
};
