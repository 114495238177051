import React, { useState, useCallback } from "react";

import { useHistory } from "react-router-dom";

import { PrimarySquareButton, TextButtonSimple } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { Box } from "../../components/Box";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "../../components/modals/BaseModal";
import { Risk } from "../../generated/graphql.generated";
import {
  bucketRiskByType,
  getTypeKeyFromAtom,
} from "../../operations/risks/helpers";
import { useBulkCreateRiskActionMutation } from "../../operations/risks/mutations/bulkCreateRiskAction.generated";
import { IToast } from "../../recoil/toasts/atom";
import { APPLICATION_ROUTES } from "../../routing/routes";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { isEmptyObject } from "../../utils/object/isEmptyObject";
import { DropdownBulkRiskAction } from "./DropdownBulkRiskAction";
import {
  BulkActionState,
  InterfaceValidationState,
  OnGroupItemChangeFunction,
  OnIndividualItemChangeFunction,
} from "./definitions";
import {
  getErrorKeys,
  hasPermanentAction,
  parseToBulkActionCommands,
  validate,
} from "./helpers";

interface IBulkActionsModal extends IModalBase {
  selectedRisks: Risk[];
  onSubmit: () => void;
}

const BulkActionsModal = ({
  showModal,
  toggleModal,
  selectedRisks,
  onSubmit,
}: IBulkActionsModal) => {
  const history = useHistory();
  const [bulkCreateMutationState, bulkCreateMutation] =
    useBulkCreateRiskActionMutation();

  const mutationSuccess = React.useMemo(
    () => ({
      toastOnSuccess: {
        type: "success",
        message: "Successfully created bulk action",
      } as IToast,
    }),
    []
  );

  const [validationErrors, setValidationErrors] =
    useState<InterfaceValidationState>();

  const [bulkActionState, setBulkActionState] = useState<BulkActionState>({});
  const [showPermanentWarning, setShowPermanentWarning] =
    useState<boolean>(false);
  const onGroupedItemChange: OnGroupItemChangeFunction = useCallback(
    ({ referenceId, value }) => {
      setBulkActionState((prevState) => ({
        ...prevState,
        [referenceId]: {
          ...selectedRisks
            .filter((risk) => getTypeKeyFromAtom(risk.atom) === referenceId)
            .reduce(
              (acc, risk) => ({
                ...acc,
                [defaultTo(risk.id, "")]: value,
              }),
              {}
            ),
        },
      }));
    },
    [selectedRisks]
  );

  const onIndividualItemChange: OnIndividualItemChangeFunction = useCallback(
    ({ value, riskId, referenceId }) => {
      setBulkActionState((prevState) => {
        const previousSlice = prevState[referenceId as `${string}:${string}`];

        const newSlice = {
          ...previousSlice,
          [riskId]: value,
        };

        return {
          ...prevState,
          [referenceId]: newSlice,
        };
      });
    },
    []
  );

  const submit = useCallback(async () => {
    await bulkCreateMutation(
      {
        actions: parseToBulkActionCommands(bulkActionState),
      },
      mutationSuccess
    );

    toggleModal();

    setBulkActionState({});

    onSubmit();

    history.push(
      defaultTo(APPLICATION_ROUTES.RISKS.NESTED_ROUTES?.ACTION_HISTORY?.url, "")
    );
  }, [
    toggleModal,
    onSubmit,
    mutationSuccess,
    bulkCreateMutation,
    bulkActionState,
    history,
  ]);

  return (
    <ModalWrapper fullHeight showModal={showModal} toggleModal={toggleModal}>
      <Box
        width="80vw"
        minHeight="80vh"
        maxHeight="90%"
        maxWidth="90%"
        overflow="auto"
        p={4}
        display="flex"
        flexDirection="column"
      >
        <Heading Element="h1" type="text-large" isBodyColor>
          Bulk Actions
        </Heading>
        <Heading Element="h2" type="text-md" isBodyColor>
          Apply bulk actions on all your selected risks
        </Heading>
        <LayoutContainer>
          {Object.entries(bucketRiskByType(selectedRisks)).map(
            ([key, risks]) => {
              return (
                <DropdownBulkRiskAction
                  state={bulkActionState[key as `${string}:${string}`]}
                  onIndividualItemChange={onIndividualItemChange}
                  onGroupedItemChange={onGroupedItemChange}
                  key={key}
                  id={key}
                  risks={risks}
                  error={validationErrors?.[key]}
                />
              );
            }
          )}
        </LayoutContainer>
        <LayoutContainer
          ml="auto"
          mt="auto"
          display="flex"
          flexDirection="column"
          pt={3}
        >
          {validationErrors && !isEmptyObject(validationErrors) && (
            <LayoutContainer color="ERROR" pb={2} fontSize="xsm">
              Please select an action for all risks
            </LayoutContainer>
          )}
          {showPermanentWarning && (
            <LayoutContainer
              backgroundColor="PRIMARY"
              color="OFF_WHITE"
              mb={2}
              p={2}
              fontSize="sm"
              borderRadius={"3px" as any}
            >
              Some of the actions selected are{" "}
              <LayoutContainer as="span" fontWeight={500 as any}>
                non-reversible
              </LayoutContainer>{" "}
              and cannot be undone. You will{" "}
              <LayoutContainer as="span" fontWeight={500 as any}>
                permanently
              </LayoutContainer>{" "}
              lose access to the data and any content. Do you wish to continue?
            </LayoutContainer>
          )}
          <LayoutContainer ml="auto" display="flex" alignItems="center">
            <LayoutContainer pr={2}>
              <TextButtonSimple
                onClick={() => {
                  if (showPermanentWarning) {
                    setShowPermanentWarning(false);
                  } else {
                    toggleModal();
                  }
                }}
              >
                cancel
              </TextButtonSimple>
            </LayoutContainer>
            <PrimarySquareButton
              loading={bulkCreateMutationState.fetching}
              onClick={async () => {
                if (validate(bulkActionState, selectedRisks)) {
                  setValidationErrors(undefined);

                  if (
                    !hasPermanentAction(bulkActionState) ||
                    (hasPermanentAction(bulkActionState) &&
                      showPermanentWarning)
                  ) {
                    submit();
                  } else {
                    setShowPermanentWarning(true);
                  }
                } else {
                  setValidationErrors(
                    getErrorKeys(bulkActionState, selectedRisks)
                  );
                }
              }}
            >
              {showPermanentWarning ? "Continue" : "Run Actions"}
            </PrimarySquareButton>
          </LayoutContainer>
        </LayoutContainer>
      </Box>
    </ModalWrapper>
  );
};

export default BulkActionsModal;
