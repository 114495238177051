import {
  Risk,
  RiskActionCommandEnum,
  RiskActionCommandInput,
} from "../../generated/graphql.generated";
import { ActionMetaMap } from "../../operations/risks/helpers";
import { defaultTo } from "../../utils/maybe/defaultTo";
import {
  BulkActionState,
  PERMANENT_RISK_ACTION_COMMANDS,
  RiskActionCommandEnumExtended,
} from "./definitions";

// Scrappy fix for now, to be refactored
const buildRiskActionCommand = (
  riskAction: RiskActionCommandEnum | RiskActionCommandEnumExtended
) => {
  return riskAction ===
    RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsPublic ||
    riskAction ===
      RiskActionCommandEnumExtended.GdriveRevokeFilePermissionsInternal
    ? RiskActionCommandEnum.GdriveRevokeFilePermissions
    : riskAction;
};

const buildRiskActionCommandMeta = (
  riskAction: RiskActionCommandEnum | RiskActionCommandEnumExtended
) => ActionMetaMap[riskAction];

export const parseToBulkActionCommands = (
  state: BulkActionState
): RiskActionCommandInput[] =>
  Object.entries(state).flatMap(([riskKey, riskState]) =>
    Object.entries(riskState).map(([riskId, riskAction]) => {
      const riskActionCast = riskAction as
        | RiskActionCommandEnum
        | RiskActionCommandEnumExtended;

      const riskActionCommand: RiskActionCommandInput = {
        riskId,
        command: buildRiskActionCommand(riskActionCast),
        meta: buildRiskActionCommandMeta(riskActionCast),
      };

      return riskActionCommand;
    })
  );

export const getErrorKeys = (state: BulkActionState, risks: Risk[]) => {
  const error = "Please select an action for each risk";

  return Object.fromEntries(
    risks
      .map((risk) => {
        const riskId = defaultTo(risk.id, "");
        const riskKey = `${risk.atom?.appType}:${risk.atom?.entityType}`;
        const risksGroup = state[riskKey as `${string}:${string}`];
        const errorTuple = [riskKey, error];

        if (risksGroup) {
          const riskState = risksGroup[defaultTo(riskId, "")];

          if (riskState === "UNSELECTED" || !riskState) {
            return [riskKey, error];
          } else {
            return false;
          }
        } else {
          return errorTuple;
        }
      })
      .filter(Boolean) as [string, string][]
  );
};

export const validate = (state: BulkActionState, risks: Risk[]) =>
  risks.every((risk) => {
    const riskId = risk.id;
    const riskKey = `${risk.atom?.appType}:${risk.atom?.entityType}`;
    const risksGroup = state[riskKey as `${string}:${string}`];

    if (risksGroup) {
      const riskState = risksGroup[defaultTo(riskId, "")];
      return riskState && riskState !== "UNSELECTED";
    } else {
      return false;
    }
  });

export const hasPermanentAction = (state: BulkActionState) => {
  return Object.values(state).some((value) =>
    Object.values(value).some(
      (v) => PERMANENT_RISK_ACTION_COMMANDS.some((command) => v === command) // Only Slack redact is permanent for now
    )
  );
};
