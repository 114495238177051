import { css } from "styled-components";

import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import theme from "../../../styling/theme";

export const TABLE_THEMES = {
  NO_GAP_ROW_TABLE: {
    headerContainer: css`
      padding: ${APPLICATION_SPACING(3)} ${APPLICATION_SPACING(3)}
        ${APPLICATION_SPACING(2)} ${APPLICATION_SPACING(3)};
      border-bottom-width: 1px;
    `,
    table: css`
      width: 100%;
    `,
    header: css`
      font-weight: 300;
      text-align: left;
      font-size: ${theme.fontSizes.xsm};
      color: ${COLOR.LABEL};
    `,
    cell: css`
      font-size: ${theme.fontSizes.xsm};
      display: flex;
      align-items: center;
    `,
  },
  NO_HEADER_NO_GAP_TABLE: {
    headerContainer: css`
      display: none;
    `,
    table: css`
      width: 100%;
    `,
    cell: css`
      display: flex;
      align-items: center;
      height: 60px;
      border-right-width: 1px;
      border-color: ${theme.colors.TABLE_BORDER};
    `,
    row: css`
      background: ${COLOR.INPUT_BACKGROUND};
      border-bottom-width: 1px;
      border-color: ${theme.colors.TABLE_BORDER};
    `,
  },
  BORDER_ROW_TABLE: {
    headerContainer: css`
      padding: ${APPLICATION_SPACING(2)} ${APPLICATION_SPACING(3)};
      border-bottom-width: 1px;
    `,
    table: css`
      width: 100%;
      margin-bottom: ${APPLICATION_SPACING(3)};
    `,
    header: css`
      font-weight: 300;
      text-align: left;
      font-size: ${theme.fontSizes.xsm};
      color: ${COLOR.LABEL};
    `,
    row: css`
      border-bottom-width: 1px;
      padding: ${APPLICATION_SPACING(2)} ${APPLICATION_SPACING(3)};
    `,
    cell: css`
      font-size: ${theme.fontSizes.xsm};
      display: flex;
      align-items: center;
    `,
  },
  BOX_ROW_TABLE: {
    table: css`
      width: 100%;
      margin-top: ${APPLICATION_SPACING(3)};
    `,
    header: css`
      font-weight: 300;
      text-align: left;
      font-size: ${theme.fontSizes.xsm};
      color: ${COLOR.LABEL};
    `,
    row: css`
      margin-top: ${APPLICATION_SPACING(3)};
    `,
    cell: css`
      padding: 1.75rem 0;
      font-size: ${theme.fontSizes.sm};
      display: flex;
      align-items: center;
    `,
  },
  DETECTION_BUNDLE_TABLE: {
    header: css`
      font-weight: 300;
      text-align: left;
      font-size: ${theme.fontSizes.xsm};
      color: ${COLOR.LABEL};
    `,
    cell: css`
      font-size: ${theme.fontSizes.sm};
      display: flex;
      align-items: center;
      min-height: 80px;
    `,
    headerContainer: css`
      height: ${APPLICATION_SPACING(4)};
      display: flex;
      align-items: center;
      border-color: ${COLOR.LINE};
      border-top-width: 1px;
    `,
    row: css`
      align-items: center;
      border-color: ${COLOR.LINE};
      border-top-width: 1px;
      width: 100%;
      font-weight: 300;
    `,
    table: css`
      border-color: ${COLOR.LINE};
      border-left-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
    `,
  },
  SPACED_HEADER_ROW_TABLE: {
    header: css`
      font-weight: 300;
      text-align: left;
      font-size: ${theme.fontSizes.xsm};
      color: ${COLOR.LABEL};
    `,
    cell: css`
      font-size: ${theme.fontSizes.sm};
      display: flex;
      align-items: center;
      min-height: 80px;
    `,
    headerContainer: css`
      height: ${APPLICATION_SPACING(6)};
      display: flex;
      align-items: center;
      border-color: ${COLOR.LINE_2};
      border-top-width: 1px;
      padding: 0 0 0 ${APPLICATION_SPACING(3)};
    `,
    row: css`
      align-items: center;
      border-color: ${COLOR.LINE_2};
      border-top-width: 1px;
      width: 100%;
      font-weight: 300;
      padding: ${APPLICATION_SPACING(2)} 0 ${APPLICATION_SPACING(2)}
        ${APPLICATION_SPACING(3)};

      &:last-child {
        border-bottom-width: 1px;
      }
    `,
  },
};
