import React, { useEffect, useState, useMemo } from "react";

import { useQuery } from "urql";

import { GET_ME_QUERY } from "../../components/Navigation/queries";
import { ConnectionShowMoreWrapper } from "../../components/ShowMoreWrapper";
import { GenericTable } from "../../components/tables/GenericTable";
import { TABLE_THEMES } from "../../components/tables/themes";
import { IDetection } from "../../definitions/types/detection";
import { canReadDeepLink } from "../../operations/user/permissions";
import { Filters } from "../../recoil/filters/atom";
import { TIncomingRelay } from "../../urql/pagination/definitions/definitions";
import { useConnectionData } from "../../utils/pagination/hooks/useConnectionData";
import { violationColumns } from "../DetectionSearch/columns";
import { ViolationHighlightWrapper } from "../DetectionSearch/components/ViolationHighlightWrapper";
import { MONITOR_VIOLATIONS_LIST_QUERY } from "./query";

interface ISearchVariables {
  id: string;
  filters?: Filters;
  isNestedTable?: boolean;
  showResolved?: boolean;
  cursor?: string | null;
  limit?: number;
  status?: VIOLATION_STATUS;
}

export interface IViolationDetection {
  id: string;
  detection: IDetection;
  status: VIOLATION_STATUS;
}

export type VIOLATION_STATUS =
  | "RESOLVED"
  | "UNRESOLVED"
  | "PENDING"
  | "FALSE_POSITIVE";

export const MonitorViolationsList = ({
  id,
  filters,
  isNestedTable,
  status = "UNRESOLVED",
  cursor,
  limit,
}: ISearchVariables) => {
  const [afterCursor, setAfterCursor] = useState({
    cursor: cursor || "",
    shouldShowFullLoader: true,
  });

  const searchVariables = {
    id,
    filters,
    limit: limit || 20,
    violationStatus: status,
  };

  const [response] = useQuery({ query: GET_ME_QUERY });

  const { data, fetching } = response;

  const user = data?.me;
  const permissions = user?.permissions;

  const userCanReadDeepLink = canReadDeepLink(permissions);

  const [result] = useQuery<{
    getMonitor: {
      violations: TIncomingRelay<IViolationDetection, {}>;
    };
  }>({
    requestPolicy: "network-only",
    query: MONITOR_VIOLATIONS_LIST_QUERY,
    variables: {
      ...searchVariables,
      filters: filters?.toJSON(),
      canReadDeepLink: userCanReadDeepLink,
      canUseDetectionActions: true,
      pause: fetching,
      afterCursor: afterCursor.cursor,
    },
  });

  useEffect(() => {
    if (!result.fetching && afterCursor.shouldShowFullLoader === true) {
      setAfterCursor((s) => ({
        ...s,
        shouldShowFullLoader: false,
      }));
    }
  }, [result.fetching, afterCursor.shouldShowFullLoader]);

  const { edges, hasNextPage, endCursor, isFirstLoad } = useConnectionData(
    result.data?.getMonitor.violations
  );

  const results = useMemo(
    () =>
      edges?.map((edge) => ({
        ...edge.node.detection,
        violationId: edge.node.id,
        status: edge.node.status,
        isNestedTable,
      })) || [],
    [edges, isNestedTable]
  );

  if (fetching) {
    return null;
  }

  return (
    <ConnectionShowMoreWrapper
      hideButton={!hasNextPage || afterCursor.shouldShowFullLoader}
      showLoadingIndicator={!isFirstLoad}
      loading={result.fetching}
      withButtonSpacing
      showMore={() => {
        setAfterCursor({
          cursor: endCursor,
          shouldShowFullLoader: false,
        });
      }}
    >
      <GenericTable
        selectable
        canSelectAllRows
        loading={result.fetching && afterCursor.shouldShowFullLoader}
        error={result.error}
        noResults="No violations found"
        data={results}
        columns={violationColumns}
        RowContainer={ViolationHighlightWrapper}
        theme={TABLE_THEMES.BORDER_ROW_TABLE}
      />
    </ConnectionShowMoreWrapper>
  );
};
