import { routePermittedByFeatureFlag } from "../constants";
import { useFeatureFlag } from "./useFeatureFlag";

export const useRoutePermittedByFeatureFlag = () => {
  const FF_SDF_PAGE = useFeatureFlag("sdf_overview_page");
  const FF_BULK_ACTIONS = useFeatureFlag("bulk_actions");
  const FF_RISK_GROUPS = useFeatureFlag("risk_groups");

  return routePermittedByFeatureFlag({
    sdf_overview_page: FF_SDF_PAGE,
    bulk_actions: FF_BULK_ACTIONS,
    risk_groups: FF_RISK_GROUPS,
  });
};
