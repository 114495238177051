import { RouteProps } from "react-router-dom";

// import { OnboardingDataThatMatters } from "../../../page/Onboarding/pages/OnboardingDataThatMatters";
import { OnboardingFinish } from "../../../page/Onboarding/pages/OnboardingFinish";
// import { OnboardingInsights } from "../../../page/Onboarding/pages/OnboardingInsights";
import { OnboardingIntegrating } from "../../../page/Onboarding/pages/OnboardingIntegrating";
import { OnboardingInviteTeam } from "../../../page/Onboarding/pages/OnboardingInviteTeam";
import { OnboardingWelcome } from "../../../page/Onboarding/pages/OnboardingWelcome";

interface OnboardingRoutesProps extends RouteProps {
  id: string;
  navTitle: string;
}

export const onboardingRoutes: OnboardingRoutesProps[] = [
  {
    id: "welcome",
    path: "/onboarding/welcome",
    render: () => <OnboardingWelcome />,
    navTitle: "Welome to Metomic",
  },
  {
    id: "integrating",
    path: "/onboarding/integrate",
    render: () => <OnboardingIntegrating />,
    navTitle: "Integrate",
  },
  // {
  //   id: "data-that-matters",
  //   path: "/onboarding/data-that-matters",
  //   render: () => <OnboardingDataThatMatters />,
  //   navTitle: "Data That Matters",
  // },
  // {
  //   id: "insights",
  //   path: "/onboarding/insights",
  //   render: () => <OnboardingInsights />,
  //   navTitle: "Insights",
  // },
  {
    id: "invite-team",
    path: "/onboarding/invite-team",
    render: () => <OnboardingInviteTeam />,
    navTitle: "Invite Team",
  },
  {
    id: "finish",
    path: "/onboarding/finish",
    render: () => <OnboardingFinish />,
    navTitle: "Finish",
  },
];
