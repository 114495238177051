import { parseISO } from "date-fns";
import { Column } from "react-table";
import styled, { css } from "styled-components";

import { ActorIcon } from "../../components/ActorIcon";
import { InfoLabel } from "../../components/ColorLabel/InfoLabel";
import { LocationContextPill } from "../../components/LocationContextPill";
import { VendorPill } from "../../components/VendorPill";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IDetection } from "../../definitions/types/detection";
import { isResolvedViolation } from "../../operations/violations/helpers";
import { COLOR } from "../../styling/colors";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { localeBasedShortDateWithTimeFirst } from "../../utils/date";
import { VIOLATION_STATUS } from "../MonitorViolationsList";
import { ContextMenu } from "./ContextMenu";
import { ViolationHistoryDetailsButton } from "./components/ViolationHistoryDetailsButton";

export interface IDetectionWithContextRow extends IDetection {
  violationId?: string;
  isNestedTable?: boolean;
  status?: VIOLATION_STATUS;
}

const StyledVendorPill = styled(VendorPill)`
  padding-left: 0;
`;

export const defaultColumns: Column<IDetectionWithContextRow>[] = [
  {
    Header: "Vendor",
    accessor: "location",
    id: "vendor",
    width: 80,
    minWidth: 50,
    maxWidth: 150,
    Cell: (data) => (
      <StyledVendorPill
        backgroundColor="transparent"
        id={data.cell.value.appType}
      />
    ),
  },
  {
    Header: "Classifier",
    accessor: "classifier",
    width: 110,
    minWidth: 50,
    maxWidth: 200,
    Cell: (data) => (
      <InfoLabel
        revealedText={data.row.original.obscuredDetection}
        iconColor={COLOR.LABEL}
      >
        {data.cell.value.name}
      </InfoLabel>
    ),
  },
  {
    Header: "Location",
    accessor: "location",
    width: 110,
    minWidth: 50,
    maxWidth: 200,
    Cell: (data) => (
      <LocationContextPill
        atomId={data.row.original.atomId}
        location={data.cell.value}
      />
    ),
  },
  {
    Header: "Actor",
    accessor: "actor",
    width: 90,
    minWidth: 50,
    maxWidth: 200,
    Cell: (data) => (
      <>
        <LayoutContainer mr={SPECIFIC_SPACING["12px"]}>
          {data.cell.value?.imageUrl && (
            <ActorIcon
              imageUrl={data.cell.value?.imageUrl}
              displayName={data.cell.value?.displayName}
            />
          )}
        </LayoutContainer>
        {data.cell.value?.displayName &&
        data.cell.value?.displayName?.length > 30
          ? `${data.cell.value?.displayName?.slice(0, 30)}...`
          : data.cell.value?.displayName}
      </>
    ),
  },
  {
    Header: "Date Shared",
    accessor: (row) => row["firstDetectedBackdate"] ?? row["firstDetected"],
    id: "date-detected",
    width: 50,
    minWidth: 50,
    maxWidth: 150,
    Cell: (data: { cell: { value: string } }) =>
      localeBasedShortDateWithTimeFirst(parseISO(data.cell.value)),
  },
];

export const violationColumns: Column<IDetectionWithContextRow>[] = [
  ...defaultColumns,
  {
    Header: () => null,
    id: "context-menu",
    accessor: "id",
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    columnStyles: css`
      justify-content: center;
    `,
    headerStyles: css`
      text-align: center;
    `,
    disableResizing: true,
    isContextMenu: true,
    Cell: (data) => {
      if (isResolvedViolation(data.row.original.status)) {
        return <ViolationHistoryDetailsButton {...data.row.original} />;
      } else {
        return (
          <ContextMenu
            {...data.row.original}
            selectedRows={data.selectedFlatRows}
          />
        );
      }
    },
  },
];

export const nestedTableColumns = defaultColumns.filter(
  (column) => column.id !== "vendor"
);
