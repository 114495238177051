import React from "react";

import { Clipboard } from "react-feather";
import styled from "styled-components";

import { CopyBoxNode } from "../../../../definitions/types/integration";
import { useToast } from "../../../../recoil/toasts/provider";
import { COLOR } from "../../../../styling/colors";
import { LayoutContainer } from "../../../layout/LayoutContainer";

const StyledValueContainer = styled(LayoutContainer)`
  border-width: 1px;
  border-color: ${COLOR.LINE};
`;

const StyledButton = styled.button`
  color: ${COLOR.LABEL};
`;

export const IntegrationCopyBox = (node: CopyBoxNode) => {
  const addToast = useToast();
  return (
    <LayoutContainer mb={3} fontSize="sm" width="100%">
      <LayoutContainer
        as="span"
        fontSize="xsm"
        color="LABEL"
        mb={1}
        display="block"
      >
        {node.config.label}
      </LayoutContainer>
      <LayoutContainer display="flex" alignItems="center">
        <StyledValueContainer color="GRAYSCALE_READONLY" p={1} flex={1} mr={2}>
          {node.config.value}
        </StyledValueContainer>
        <StyledButton
          onClick={() => {
            try {
              window.navigator.clipboard.writeText(node.config.value);

              addToast({
                type: "success",
                message: "Successfully copied to clipboard",
              });
            } catch (error) {}
          }}
        >
          <Clipboard size={16} />
        </StyledButton>
      </LayoutContainer>
    </LayoutContainer>
  );
};
