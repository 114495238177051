import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { IViolationActionHistory } from ".";
import { Heading } from "../../atoms/Heading";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { IPaginationResultContainer } from "../../urql/pagination/definitions/definitions";
import { localeBasedShortDateTime } from "../../utils/date";

const StyledLoadingSpinner = styled(LoadingSpinner)`
  padding: 1.75rem;
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${APPLICATION_SPACING(4)};
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.ERROR};
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.ERROR};
  flex: 1;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${theme.colors.LABEL};
`;

export const RuleActionResultsTable: FunctionComponent<
  IPaginationResultContainer<IViolationActionHistory, {}>
> = ({ loading, results, error, isFirstLoad }) => {
  if (loading && isFirstLoad) {
    return (
      <StyledLoadingContainer>
        <StyledLoadingSpinner />
      </StyledLoadingContainer>
    );
  }

  if (error) {
    return <StyledErrorContainer>{`${error}`}</StyledErrorContainer>;
  }

  return (
    <LayoutContainer display="flex" flexDirection="column">
      {!results || !results.length ? (
        <LayoutContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          p={4}
          flex={1}
        >
          <div>
            <StyledHeading Element="h1" type="display-small">
              No actions found
            </StyledHeading>
          </div>
        </LayoutContainer>
      ) : (
        <table>
          <LayoutContainer as="tr" fontSize="sm">
            <LayoutContainer as="th" textAlign="left">
              Action taken
            </LayoutContainer>
            <LayoutContainer as="th" textAlign="left">
              Result
            </LayoutContainer>
            <LayoutContainer as="th" textAlign="center">
              Info
            </LayoutContainer>
            <LayoutContainer as="th" textAlign="right">
              Date/time
            </LayoutContainer>
          </LayoutContainer>
          {results.map((edge, i) => {
            return (
              <LayoutContainer as="tr" fontSize="xsm">
                <LayoutContainer
                  as="td"
                  py={2}
                  borderBottomWidth={"1px" as any}
                  borderColor="LINE"
                >
                  {edge.node.actionType}
                </LayoutContainer>
                <LayoutContainer
                  as="td"
                  py={2}
                  borderBottomWidth={"1px" as any}
                  borderColor="LINE"
                >
                  {edge.node.result.type}
                </LayoutContainer>
                <LayoutContainer
                  as="td"
                  py={2}
                  borderBottomWidth={"1px" as any}
                  borderColor="LINE"
                  textAlign="center"
                >
                  <span>{edge.node.result.meta.reason}</span>
                </LayoutContainer>
                <LayoutContainer
                  as="td"
                  py={2}
                  borderBottomWidth={"1px" as any}
                  borderColor="LINE"
                  textAlign="right"
                >
                  {localeBasedShortDateTime(new Date(edge.node.createdAt))}
                </LayoutContainer>
              </LayoutContainer>
            );
          })}
        </table>
      )}
    </LayoutContainer>
  );
};
