import { FunctionComponent } from "react";

import { LayoutContainer } from "../../../components/layout/LayoutContainer";
import { useViolationQueryParams } from "../../../operations/violations/helpers";
import { IDetectionWithContextRow } from "../columns";

export const ViolationHighlightWrapper: FunctionComponent<
  IDetectionWithContextRow
> = ({ id, children, violationId }) => {
  const params = useViolationQueryParams();
  const violationUrlParam = params.id;
  return (
    <LayoutContainer
      backgroundColor={
        violationId && violationUrlParam && violationId === violationUrlParam
          ? "BG_SECONDARY"
          : undefined
      }
    >
      {children}
    </LayoutContainer>
  );
};
