import { MarkRiskAsResolvedDefinition } from "./markRiskAsResolved";

type TaskDefs = MarkRiskAsResolvedDefinition["tasks"];

export const markRiskAsResolved: TaskDefs["markRiskAsResolved"] = ({ id }) => ({
  update: {
    id,
  },
  successToast: {
    type: "success",
    message: "Successfully marked as resolved",
  },
});
