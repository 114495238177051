import React, { FunctionComponent } from "react";

import { List as ListIcon } from "react-feather";
import styled, { css } from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { Box } from "../Box";
import { IDoughnutGraphData } from "../charts/DoughnutChart";
import { GenericTable } from "../tables/GenericTable";
import { TABLE_THEMES } from "../tables/themes";
import { columns } from "./columns";
import { LegendRowWrapper } from "./components/LegendRowWrapper";

// TODO: Remove the quick fix below to stop the abilty to click 'other' (talk about where we go with 'location')

interface IHoverLegend {
  className?: string;
  legendKeys: IDoughnutGraphData[];
  onLegendItemClick?: (data: IDoughnutGraphData) => void;
}

interface IHoverLegendInner extends IHoverLegend {
  className?: string;
}

const StyledContainer = styled.span`
  position: relative;
  align-self: flex-start;
  padding: 2px;
  border-width: 1px;
  border-color: ${COLOR.LINE};
`;

const StyledBox = styled(Box)`
  width: 250px;
  z-index: 1;
`;

const headerContainerStyles = css`
  padding: ${APPLICATION_SPACING(1)} ${APPLICATION_SPACING(3)};
  border-bottom-width: 1px;
  border-color: ${COLOR.LINE};
  background: ${COLOR.GRAYSCALE_BACKGROUND};
`;

const rowOverrideStyles = css`
  padding: 0;
  border-bottom-width: 0;
`;

const tableOverrideStyles = css`
  margin-bottom: 0;
`;

export const HoverLegendInner: FunctionComponent<IHoverLegendInner> = ({
  legendKeys,
  onLegendItemClick,
  className,
}) => {
  const data = legendKeys.map((item) => ({ ...item, onLegendItemClick }));
  return (
    <StyledBox boxShadow className={className}>
      <GenericTable
        theme={{
          ...TABLE_THEMES.BORDER_ROW_TABLE,
        }}
        themeOverrides={{
          table: tableOverrideStyles,
          headerContainer: headerContainerStyles,
          row: rowOverrideStyles,
        }}
        data={data}
        columns={columns}
        RowContainer={LegendRowWrapper}
      />
    </StyledBox>
  );
};

const StyledHoverLegendInner = styled(HoverLegendInner)`
  position: absolute;
  display: none;

  ${StyledContainer}:hover & {
    display: block;
  }
`;

export const HoverLegend: FunctionComponent<IHoverLegend> = (props) => (
  <StyledContainer className={props.className}>
    <ListIcon size={12} />
    <StyledHoverLegendInner {...props} />
  </StyledContainer>
);
