import { useEffect } from "react";

import { useMutation } from "urql";

import { useGetMeQuery } from "../../../components/Navigation/queries.generated";
import {
  instantiateHotjar,
  instantiateMixpanel,
} from "../analytics/instantiate-analytics";
import { ACCEPT_INVITATION_MUTATION } from "../mutations/acceptInvitation";

const isProd = window.location.host.startsWith("dashboard.metomic.io");

export const useInitialUserState = (isNotAuthenticated?: boolean) => {
  const [result] = useGetMeQuery({
    pause: isNotAuthenticated ?? false,
  });

  const { data, fetching, error } = result;

  const [acceptInvitationMutation, acceptInvitation] = useMutation(
    ACCEPT_INVITATION_MUTATION
  );

  const user = data?.me;

  useEffect(() => {
    if (user?.id && isProd) {
      instantiateHotjar(user);
    }

    if (user?.id) {
      instantiateMixpanel(user, isProd);
    }
  }, [user]);

  useEffect(() => {
    if (
      user?.membershipStatus === "PENDING_INVITE_ACCEPTANCE" &&
      !acceptInvitationMutation.fetching &&
      !acceptInvitationMutation.error
    ) {
      acceptInvitation();
    }
  }, [
    acceptInvitation,
    user?.membershipStatus,
    acceptInvitationMutation.fetching,
    acceptInvitationMutation.error,
  ]);

  return {
    loading:
      fetching || acceptInvitationMutation.fetching || isNotAuthenticated,
    user,
    error,
  };
};
