import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MarkRiskAsResolvedMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type MarkRiskAsResolvedMutation = {
  __typename?: "RootMutationType";
  markRiskAsResolved?:
    | { __typename?: "Risk"; id?: string | null | undefined }
    | null
    | undefined;
};

export const MarkRiskAsResolvedDocument = gql`
  mutation MarkRiskAsResolved($id: ID!) {
    markRiskAsResolved(id: $id) {
      id
    }
  }
`;

export function useMarkRiskAsResolvedMutation() {
  return Urql.useMutation<
    MarkRiskAsResolvedMutation,
    MarkRiskAsResolvedMutationVariables
  >(MarkRiskAsResolvedDocument);
}
