import React, { FunctionComponent } from "react";

import styled from "styled-components";

import { IDetectionLocationDetail } from "../../definitions/types/detection";
import { AtomLocationCard } from "../../features/AtomLocationCard";
import { COLOR } from "../../styling/colors";
import theme from "../../styling/theme";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { Popover } from "../Popover";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface ILocationContextPill {
  className?: string;
  textColor?: string;
  location: Partial<IDetectionLocationDetail>;
  atomId: string;
  disablePopover?: boolean;
}

const LabelledLocation: FunctionComponent<{ name: string }> = ({ name }) => (
  <LayoutContainer as="span" color="PRIMARY" fontSize="sm">
    {name}
  </LayoutContainer>
);

const UnlabelledLocation: FunctionComponent<{ name: string }> = ({ name }) => (
  <LayoutContainer as="span" color="PRIMARY_LIGHT">
    {name}
  </LayoutContainer>
);

const StyledLink = styled.a`
  margin-top: -3px;
  font-size: ${theme.fontSizes.xsm};
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  color: ${COLOR.PRIMARY_LIGHT};

  &:hover {
    color: ${COLOR.PRIMARY};
    border-color: ${COLOR.PRIMARY};
  }
`;

const StyledWrapper = styled.span`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: 2px;
  color: ${COLOR.PRIMARY_LIGHT};
`;

export const LocationContextPill: FunctionComponent<ILocationContextPill> = ({
  className,
  textColor,
  location,
  atomId,
  disablePopover,
}) => (
  <Popover
    disabled={disablePopover}
    content={<AtomLocationCard popover atomId={atomId} />}
  >
    {" "}
    <StyledLink
      href={location.temp_entityDeeplink}
      target="_blank"
      rel="noreferrer"
      style={{ color: textColor }}
      className={className}
    >
      <LayoutContainer
        as="span"
        overflow="hidden"
        width="100%"
        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      >
        <StyledWrapper>
          <UnlabelledLocation name={defaultTo(location.entityType, "")} />
          {location.temp_canonicalLabel && [
            ": ",
            <LabelledLocation key={1} name={location.temp_canonicalLabel} />,
          ]}
        </StyledWrapper>
      </LayoutContainer>
    </StyledLink>
  </Popover>
);
