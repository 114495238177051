import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetSdfTotalQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSdfTotalQuery = {
  __typename?: "RootQueryType";
  getSDFTotal?:
    | {
        __typename?: "SDFTotal";
        sdf?: number | null | undefined;
        updatedAt?: any | null | undefined;
        health?: number | null | undefined;
      }
    | null
    | undefined;
};

export const GetSdfTotalDocument = gql`
  query GetSDFTotal {
    getSDFTotal {
      sdf
      updatedAt
      health
    }
  }
`;

export function useGetSdfTotalQuery(
  options: Omit<Urql.UseQueryArgs<GetSdfTotalQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<GetSdfTotalQuery>({
    query: GetSdfTotalDocument,
    ...options,
  });
}
