import React, { FunctionComponent, useState } from "react";

import { Eye as EyeIcon } from "react-feather";
import styled from "styled-components";

import { ColorLabel, IColorLabel } from ".";
import { sendAnalytics } from "../../operations/user/analytics";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";

export interface IInfoLabel extends IColorLabel {
  iconColor?: string;
  revealedText?: string;
}

const StyledEyeButton = styled.button`
  border-left-width: 1px;
  margin-left: ${APPLICATION_SPACING(1)};
  padding-left: ${APPLICATION_SPACING(1)};
  margin-top: 0;
`;

export const InfoLabel: FunctionComponent<IInfoLabel> = (props) => {
  const { children, textColor, revealedText, iconColor, ...rest } = props;

  const [isRevealed, setRevealed] = useState(false);
  const onToggleReveal = () => setRevealed((r) => !r);

  const buttonColor = iconColor
    ? iconColor
    : textColor
    ? textColor
    : COLOR.PLACEHOLDER;

  return (
    <ColorLabel textColor={textColor} {...rest}>
      {isRevealed ? revealedText : children}
      <StyledEyeButton
        style={{
          borderColor: buttonColor,
          color: buttonColor,
        }}
        disabled={!revealedText}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onToggleReveal();

          if (!isRevealed) {
            sendAnalytics("DETECTION: View Obscured Data", undefined);
          }
        }}
      >
        <EyeIcon size={13} />
      </StyledEyeButton>
    </ColorLabel>
  );
};
