import { gql } from "urql";

export const GET_ME_QUERY = gql`
  query GetMe {
    me {
      id
      customerId
      nickname
      avatarUrl
      permissions
      featureFlags
      membershipStatus
      account {
        name
        onboarding {
          id
          status
          currentStep
        }
        id
      }
    }
  }
`;
