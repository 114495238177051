import React, { ComponentProps, CSSProperties, PropsWithChildren } from "react";

import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import theme from "../../styling/theme";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface IBox {
  Element?: keyof JSX.IntrinsicElements;
  className?: string;
  role?: string;
  style?: CSSProperties;
  boxShadow?: boolean;
  hasHoverState?: boolean;
  hideOverflow?: boolean;
}

export interface LayoutBox {
  boxShadow?: boolean;
  hasHoverState?: boolean;
}

const getBoxShadow = (isHeavy: boolean) =>
  isHeavy
    ? "0px 0px 4px 0px #00000026"
    : " 0px 2px 4px 0px rgba(0, 0, 0, 0.08)";

export const Box = styled(LayoutContainer)<LayoutBox>`
  border-radius: 4px;
  transition: ${(props) => (props.hasHoverState ? "0.3s" : "none")};
  background: ${COLOR.INPUT_BACKGROUND};
  box-shadow: ${(props) => getBoxShadow(!!props.boxShadow)};

  &:hover {
    ${(props) =>
      props.hasHoverState
        ? "box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16)"
        : `box-shadow: ${getBoxShadow(!!props.boxShadow)};`}
  }
`;

type IBoxProps = ComponentProps<typeof Box>;

export type IBoxWithHeader = IBoxProps & {
  mainHeader: string;
  subHeader?: string;
};

const LargeBoxHeading = styled.h2`
  font-size: ${theme.fontSizes.md};
  line-height: 1rem;
`;

const SmallBoxHeading = styled.h3`
  font-size: ${theme.fontSizes.sm};
  line-height: 1rem;
`;

export const BoxWithHeader = ({
  mainHeader,
  subHeader,
  children,
  ...rest
}: PropsWithChildren<IBoxWithHeader>) => (
  <Box {...rest}>
    <LayoutContainer
      as="header"
      display="flex"
      px={2}
      pt={2}
      pb={1}
      color="LABEL"
      alignItems="center"
      justifyContent="space-between"
    >
      <LargeBoxHeading>{mainHeader}</LargeBoxHeading>
      <SmallBoxHeading>{subHeader}</SmallBoxHeading>
    </LayoutContainer>
    {children}
  </Box>
);
