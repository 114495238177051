export function ResolveRiskIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00245 2.25154L1.06162 10.4999C0.959749 10.6763 0.905848 10.8763 0.905278 11.08C0.904708 11.2837 0.957487 11.484 1.05837 11.661C1.15925 11.838 1.30471 11.9855 1.48028 12.0888C1.65585 12.1921 1.85542 12.2476 2.05912 12.2499H11.9408C12.1445 12.2476 12.344 12.1921 12.5196 12.0888C12.6952 11.9855 12.8407 11.838 12.9415 11.661C13.0424 11.484 13.0952 11.2837 13.0946 11.08C13.0941 10.8763 13.0402 10.6763 12.9383 10.4999L7.99745 2.25154C7.89346 2.0801 7.74704 1.93836 7.57232 1.83999C7.39759 1.74162 7.20046 1.68994 6.99995 1.68994C6.79944 1.68994 6.60231 1.74162 6.42759 1.83999C6.25286 1.93836 6.10644 2.0801 6.00245 2.25154V2.25154Z"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5.25V7.58333"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.9165H7.00583"
        stroke="#58469B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2148 9.21742C15.5951 8.82461 15.5951 8.19426 15.2148 7.80144C14.8257 7.39952 14.188 7.39952 13.7989 7.80144L9.68493 12.0512L8.20111 10.5184C7.81203 10.1165 7.17427 10.1165 6.78519 10.5184C6.40494 10.9112 6.40494 11.5416 6.78519 11.9344L8.97697 14.1986C9.36605 14.6005 10.0038 14.6005 10.3929 14.1986L15.2148 9.21742Z"
        fill="#58469B"
        stroke="#E5E5E5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
