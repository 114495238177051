import { Dispatch, SetStateAction } from "react";

import { InputNode } from "../../../../definitions/types/integration";
import { IntegrationInputBox } from "../components/form/IntegrationInputBox";
import { IntegrationMultiSelectBox } from "../components/form/IntegrationMultiSelectBox";
import { IntegrationSelectBox } from "../components/form/IntegrationSelectBox";

export const IntegrationFormFactory = (
  node: InputNode,
  setDeploymentData: Dispatch<SetStateAction<Record<string, any>>>,
  stepPayload: Record<string, any>
) => {
  switch (node.config.type) {
    case "select":
      return (
        <IntegrationSelectBox
          key={node.id}
          node={node}
          setDeploymentData={setDeploymentData}
          stepPayload={stepPayload}
        />
      );
    case "multi-select":
      return (
        <IntegrationMultiSelectBox
          key={node.id}
          node={node}
          setDeploymentData={setDeploymentData}
          stepPayload={stepPayload}
        />
      );
    case "password":
    case "text":
      return (
        <IntegrationInputBox
          key={node.id}
          node={node}
          setDeploymentData={setDeploymentData}
          stepPayload={stepPayload}
        />
      );
    default:
      return null;
  }
};
