import React from "react";

import { ChevronDown, ChevronUp } from "react-feather";
import styled, { css } from "styled-components";

import { RowInterface, StyledCell } from ".";
import { COLOR } from "../../../../../styling/colors";
import { LayoutContainer } from "../../../../layout/LayoutContainer";
import {
  CONTEXT_MENU_ID_DOTS,
  EXPANDED_CELL_INDICATOR_ID,
} from "../../TableRenderer";
import { DefaultRowType } from "../../definitions";

const StyledPointerLayoutContainer = styled(LayoutContainer)<{
  isPointer?: boolean;
}>`
  ${({ isPointer }) =>
    isPointer &&
    css`
      cursor: pointer;
    `}
`;

const StyledMobileBorderCell = styled(LayoutContainer)`
  border-top-width: 1px;
  border-left-width: 1px;
`;

const StyledIcon = styled(ChevronUp)`
  color: ${COLOR.PRIMARY};
`;

export const MobileRow = <T extends DefaultRowType>({
  onRowClick,
  clickWholeRowToExpand,
  row,
  theme,
}: RowInterface<T>) => {
  return (
    <StyledPointerLayoutContainer
      onClick={() => {
        onRowClick && onRowClick(row.original);
      }}
      {...(clickWholeRowToExpand ? row.getToggleRowExpandedProps() : {})}
      position="relative"
      isPointer={clickWholeRowToExpand || Boolean(onRowClick)}
    >
      {row.cells
        .filter(
          (cell) =>
            cell.column.id !== EXPANDED_CELL_INDICATOR_ID &&
            cell.column.id !== CONTEXT_MENU_ID_DOTS &&
            cell.column.id !== "spacer"
        )
        .map((cell, i) => (
          <LayoutContainer display="flex" key={cell.getCellProps().key}>
            <LayoutContainer
              p={2}
              width="33.333333%"
              display="flex"
              alignItems="center"
              textAlign="left"
              fontSize="xsm"
              color="LABEL"
            >
              {cell.column.mobileHeader
                ? cell.column.mobileHeader
                : cell.column.render("Header")}
            </LayoutContainer>
            <StyledCell
              theme={theme.cell}
              p={2}
              width="66.666667%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {cell.render("Cell")}
              {i === 0 &&
                row.cells
                  .filter((cell) => cell.column.id === CONTEXT_MENU_ID_DOTS)
                  .map((cell) => (
                    <LayoutContainer
                      position="absolute"
                      right={0}
                      key={cell.getCellProps().key}
                    >
                      <StyledCell
                        theme={theme.cell}
                        justifyContent="flex-end"
                        pr={1}
                      >
                        {cell.render("Cell")}
                      </StyledCell>
                    </LayoutContainer>
                  ))}
            </StyledCell>
          </LayoutContainer>
        ))}
      {row.cells
        .filter((cell) => cell.column.id === EXPANDED_CELL_INDICATOR_ID)
        .map((cell) => (
          <StyledMobileBorderCell
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
            key={cell.getCellProps().key}
          >
            {row.isExpanded ? (
              <StyledIcon size={24} />
            ) : (
              <StyledIcon as={ChevronDown} size={24} />
            )}
          </StyledMobileBorderCell>
        ))}
    </StyledPointerLayoutContainer>
  );
};
