import React, { FunctionComponent } from "react";

import { ChevronRight, Clock, ExternalLink } from "react-feather";
import styled from "styled-components";

import { EntityTypeIconFactory } from "../../atoms/icons/EntityTypeIconFactory";
import { AtomLocation, LabelledAtom } from "../../generated/graphql.generated";
import { useGetAtomBreadcrumbDetailsQuery } from "../../operations/atom/queries/getAtomBreadcrumbDetails.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING, SPECIFIC_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { localeBasedShortDateWithTimeFirst } from "../../utils/date";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { ActorIcon } from "../ActorIcon";
import { AtomSharingFigure } from "../AtomSharingFigure";
import { Box } from "../Box";
import { VendorIcon } from "../VendorIcons";
import { LayoutContainer } from "../layout/LayoutContainer";
import { LoadingSpinner } from "../loading/LoadingSpinner";

interface ILocationCard extends Partial<LabelledAtom> {
  loading?: boolean;
  isInPopover?: boolean;
}

const StyledLayoutContainer = styled(LayoutContainer)`
  border-bottom-color: ${COLOR.LINE_2};
  border-bottom-width: 1px;
`;

const StyledVendorIcon = styled(VendorIcon)`
  position: absolute;
  bottom: -7px;
  right: -7px;
`;

const StyledTypeIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  border-color: rgba(0, 0, 0, 0.05);
  border-width: 1px;
`;

const StyledClockIcon = styled(Clock)`
  color: ${COLOR.LABEL};
`;

const StyledLinkIcon = styled(ExternalLink)`
  color: ${COLOR.PRIMARY};
`;

const StyledGridLayoutContainer = styled(LayoutContainer)`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
  font-size: ${theme.fontSizes.xsm};
`;

const LayoutBox1 = styled(LayoutContainer)`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
`;

const LayoutBox2 = styled(LayoutContainer)`
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1;
`;

const LayoutBox3 = styled(LayoutContainer)`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
`;

const LayoutBox4 = styled(LayoutContainer)`
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 2;
`;

const StyledHeading = styled.h4`
  color: ${COLOR.LABEL};
  padding-bottom: ${APPLICATION_SPACING(1)};
  font-size: ${theme.fontSizes.xsm};
`;

const StyledBreadcrumbLink = styled.a`
  margin-left: 0;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const ParentBreadcrumbsWithFetcher = ({ id }: { id: string }) => {
  const [result] = useGetAtomBreadcrumbDetailsQuery({
    variables: {
      id,
    },
    context: React.useMemo(
      () => ({
        onError: (error) => {},
      }),
      []
    ),
  });

  const iconType = result.data?.getLabelledAtomById?.iconType;
  const label =
    result.data?.getLabelledAtomById?.location?.label ||
    result.data?.getLabelledAtomById?.iconType;

  if (result.error || !result.data?.getLabelledAtomById) {
    return null;
  }

  return result.fetching ? (
    <LayoutContainer mr={1} display="flex" alignItems="center">
      <LayoutContainer mr={1}>
        <LoadingSpinner isSmall />
      </LayoutContainer>
      <LayoutContainer ml={label ? SPECIFIC_SPACING["4px"] : 0}>
        <ChevronRight size={14} />
      </LayoutContainer>
    </LayoutContainer>
  ) : (
    <StyledBreadcrumbLink
      href={defaultTo(result.data?.getLabelledAtomById?.location?.deeplink, "")}
      target="_blank"
      rel="noopener noreferrer"
      key={label}
    >
      <LayoutContainer mr={label ? SPECIFIC_SPACING["4px"] : 0}>
        <EntityTypeIconFactory type={defaultTo(iconType, "")} size={12} />
      </LayoutContainer>
      {label}
      <LayoutContainer
        ml={label ? SPECIFIC_SPACING["4px"] : 0}
        mr={SPECIFIC_SPACING["4px"]}
      >
        <ChevronRight size={14} />
      </LayoutContainer>
    </StyledBreadcrumbLink>
  );
};

export const LocationCard: FunctionComponent<ILocationCard> = ({
  location,
  createdAt,
  exposure,
  loading,
  iconType,
  isInPopover,
}) => {
  const {
    label,
    deeplink,
    lastEditedBy,
    createdBy,
    entityType,
    appType,
    parentId,
  } = defaultTo(location, {} as AtomLocation);

  return (
    <Box
      minWidth="400px"
      minHeight="282px"
      maxWidth={isInPopover ? "400px" : "auto"}
    >
      {loading ? (
        <LayoutContainer
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          minHeight="282px"
        >
          <LoadingSpinner />
        </LayoutContainer>
      ) : (
        <LayoutContainer>
          <StyledLayoutContainer
            px={2}
            py={1}
            fontSize="xsm"
            display="flex"
            alignItems="center"
            color="LABEL"
          >
            {parentId && <ParentBreadcrumbsWithFetcher id={parentId} />}
            <StyledBreadcrumbLink
              rel="noopener noreferrer"
              href={defaultTo(deeplink, "")}
              target="_blank"
              key={label}
            >
              <LayoutContainer mr={SPECIFIC_SPACING["4px"]}>
                <EntityTypeIconFactory
                  type={defaultTo(iconType, "")}
                  size={12}
                />
              </LayoutContainer>
              {label}{" "}
            </StyledBreadcrumbLink>
          </StyledLayoutContainer>
          <StyledLayoutContainer
            alignItems="center"
            px={2}
            py={2}
            display="flex"
            flexWrap="nowrap"
          >
            <StyledTypeIconContainer>
              <EntityTypeIconFactory type={defaultTo(iconType, "")} size={16} />
              <StyledVendorIcon width="20px" vendor={defaultTo(appType, "")} />
            </StyledTypeIconContainer>

            <LayoutContainer
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              ml={2}
            >
              <LayoutContainer as="h3" color="LABEL" fontSize="sm">
                {entityType}
              </LayoutContainer>
              {label && (
                <LayoutContainer as="h3" color="BODY" fontSize="sm">
                  {label}
                </LayoutContainer>
              )}
            </LayoutContainer>
            {deeplink && (
              <LayoutContainer pr={2}>
                <a href={deeplink} rel="noreferrer" target="_blank">
                  <StyledLinkIcon size={16} />
                </a>
              </LayoutContainer>
            )}
          </StyledLayoutContainer>
          <StyledGridLayoutContainer pt={3}>
            <LayoutBox1 pl={4} pb={3}>
              <StyledHeading>Created at</StyledHeading>
              <LayoutContainer display="flex" alignItems="center">
                <StyledClockIcon size={16} />{" "}
                <LayoutContainer ml={1}>
                  {localeBasedShortDateWithTimeFirst(
                    new Date(createdAt || Date.now())
                  )}
                </LayoutContainer>
              </LayoutContainer>
            </LayoutBox1>
            <LayoutBox2 pl={4} pr={2} pb={3}>
              <StyledHeading>Visibility</StyledHeading>
              <LayoutContainer display="flex" alignItems="center" flex={1}>
                <AtomSharingFigure {...exposure} />
              </LayoutContainer>
            </LayoutBox2>
            <LayoutBox3 pl={4} pb={3}>
              <StyledHeading>Created by</StyledHeading>
              <LayoutContainer display="flex" alignItems="center" flex={1}>
                <ActorIcon
                  imageUrl={defaultTo(createdBy?.imageUrl, "")}
                  displayName={defaultTo(createdBy?.displayName, "")}
                />
                <LayoutContainer ml={1}>
                  {createdBy?.displayName}
                </LayoutContainer>
              </LayoutContainer>
            </LayoutBox3>
            <LayoutBox4 pl={4} pr={2} pb={3}>
              <StyledHeading>Last Edited By</StyledHeading>
              <LayoutContainer display="flex" alignItems="center" flex={1}>
                <ActorIcon
                  imageUrl={defaultTo(createdBy?.imageUrl, "")}
                  displayName={defaultTo(createdBy?.displayName, "")}
                />

                <LayoutContainer ml={1}>
                  {lastEditedBy?.displayName}
                </LayoutContainer>
              </LayoutContainer>
            </LayoutBox4>
          </StyledGridLayoutContainer>
        </LayoutContainer>
      )}
    </Box>
  );
};
