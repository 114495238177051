import React from "react";

import { useMutation } from "urql";

import { useToast } from "../../../recoil/toasts/provider";
import { BULK_CHANGE_VIOLATION_STATUS } from "../../violations/mutations/bulkChangeViolationStatus";
import { DETECTION_ACTIONS } from "../definitions";
import { REDACT_DETECTION_MUTATION } from "../mutations/redactDetection";
import { RESOLVE_VIOLATION_MUTATION } from "../mutations/resolveViolation";

//TODO: This is just awful, but it's very old and we didn't have types or anything when this was built so it's all around demo bits

interface IViolationActionMutation {
  updateErrorState?: React.Dispatch<React.SetStateAction<any>>;
  onFinishedUpdate?: Function;
  isViolation?: boolean;
}

const getNotificationReducer = (action: string) => {
  switch (action) {
    case DETECTION_ACTIONS.REDACT:
      return "Successfully redacted";
    case DETECTION_ACTIONS.NOTIFY:
      return "Notification Sent";
    case DETECTION_ACTIONS.MARK_RESOLVED:
      return "Successfully marked as resolved";
    case DETECTION_ACTIONS.MARK_FALSE_POSITIVE:
      return "Successfully marked as a false positive";
    case DETECTION_ACTIONS.CANCEL:
      return "Successfully cancelled action";
    default:
      return "";
  }
};

export const useViolationAction = ({
  updateErrorState,
  onFinishedUpdate,
  isViolation,
}: IViolationActionMutation) => {
  const addToast = useToast();

  const [violationActionMutation, violationAction] = useMutation(
    RESOLVE_VIOLATION_MUTATION
  );

  const [redactDetectionMutation, redactDetection] = useMutation(
    REDACT_DETECTION_MUTATION
  );

  const [bulkViolationActionMutation, bulkViolationAction] = useMutation(
    BULK_CHANGE_VIOLATION_STATUS
  );

  const violationActionOp = async ({
    id,
    ids,
    action,
    permissionGiven,
    resolveAllViolationsInEntity,
  }: {
    id?: string;
    ids?: string[];
    action: string;
    permissionGiven?: boolean;
    resolveAllViolationsInEntity?: boolean;
  }) => {
    const violationPayload = {
      id,
      action,
      permissionGiven,
      resolveAllViolationsInEntity,
    };

    const detectionPayload = {
      id,
      action,
      permissionGiven,
    };

    const mutationFn = ids
      ? async () =>
          bulkViolationAction({
            violationIds: ids,
            status: "resolved",
          })
      : async () =>
          isViolation
            ? violationAction(violationPayload)
            : redactDetection(detectionPayload);

    return new Promise<void>((resolve): void => {
      mutationFn().then((result) => {
        if (onFinishedUpdate) {
          onFinishedUpdate();
        }

        const message = getNotificationReducer(action);

        if (!result.error) {
          addToast({
            type: "success",
            message,
          });
        }

        resolve();
      });
    });
  };

  return {
    violationAction: violationActionOp,
    error:
      (isViolation
        ? violationActionMutation.error
        : redactDetectionMutation.error) ?? bulkViolationActionMutation.error,
    loading:
      (isViolation
        ? violationActionMutation.fetching
        : redactDetectionMutation.fetching) ??
      bulkViolationActionMutation.fetching,
  };
};
