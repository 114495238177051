import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelBulkActionMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"];
}>;

export type CancelBulkActionMutation = {
  __typename?: "RootMutationType";
  cancelBulkAction?:
    | { __typename?: "BulkAction"; id?: string | null | undefined }
    | null
    | undefined;
};

export const CancelBulkActionDocument = gql`
  mutation CancelBulkAction($id: ID!) {
    cancelBulkAction(id: $id) {
      id
    }
  }
`;

export function useCancelBulkActionMutation() {
  return Urql.useMutation<
    CancelBulkActionMutation,
    CancelBulkActionMutationVariables
  >(CancelBulkActionDocument);
}
