import React, { FunctionComponent } from "react";

import { ActorIcon } from "../../../components/ActorIcon";
import { LayoutContainer } from "../../../components/layout/LayoutContainer";
import { BulkAction } from "../../../generated/graphql.generated";
import { SPECIFIC_SPACING } from "../../../styling/spacing";
import { defaultTo, getMaybeArrayValue } from "../../../utils/maybe/defaultTo";
import {
  getActionIcon,
  getActionSummaryAppTypes,
  getActionSummaryDescription,
} from "../../BulkActions/columns";
import { BulkActionHistoryDetailPanel } from "./BulkActionHistoryDetailPanel";

interface IActionHistorySidePanel {
  selectedBulkAction?: BulkAction;
  pollForUpdates: boolean;
}

export const BulkActionHistorySidePanel: FunctionComponent<
  IActionHistorySidePanel
> = (sidePanel) => (
  <LayoutContainer height="100%">
    <LayoutContainer
      backgroundColor="BACKGROUND"
      px={2}
      py={3}
      width="100%"
      display="flex"
      alignItems="center"
      top={0}
    >
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius={"2px" as any}
        backgroundColor="GRAYSCALE_UNEDITABLE"
        px={1}
        mr={2}
        height="28px"
        flexWrap="nowrap"
      >
        {getActionIcon(
          getMaybeArrayValue(sidePanel.selectedBulkAction?.summary?.commands)
        )}
      </LayoutContainer>
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius={"2px" as any}
        flexWrap="nowrap"
        fontSize="sm"
      >
        {getActionSummaryAppTypes(
          getMaybeArrayValue(sidePanel.selectedBulkAction?.summary?.commands)
        )}
        <LayoutContainer ml={1}>
          {getActionSummaryDescription(
            getMaybeArrayValue(sidePanel.selectedBulkAction?.summary?.commands)
          )}
        </LayoutContainer>
      </LayoutContainer>
      <LayoutContainer
        fontSize="xsm"
        display="flex"
        alignItems="center"
        ml="auto"
      >
        <LayoutContainer mr={SPECIFIC_SPACING["12px"]}>
          <ActorIcon
            imageUrl={defaultTo(
              sidePanel.selectedBulkAction?.createdByUser?.avatarUrl,
              ""
            )}
            displayName={defaultTo(
              sidePanel.selectedBulkAction?.createdByUser?.nickname,
              ""
            )}
          />
        </LayoutContainer>
        {sidePanel.selectedBulkAction?.createdByUser?.nickname &&
        sidePanel.selectedBulkAction?.createdByUser?.nickname?.length > 30
          ? `${sidePanel.selectedBulkAction?.createdByUser?.nickname?.slice(
              0,
              30
            )}...`
          : sidePanel.selectedBulkAction?.createdByUser?.nickname}
      </LayoutContainer>
    </LayoutContainer>
    <LayoutContainer height="calc(100% - 76px)" px={2}>
      <BulkActionHistoryDetailPanel
        key={sidePanel.selectedBulkAction?.id}
        bulkActionId={defaultTo(sidePanel.selectedBulkAction?.id, "")}
        pollForUpdates={sidePanel.pollForUpdates}
      />
    </LayoutContainer>
  </LayoutContainer>
);
