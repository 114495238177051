import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ListRiskGroupsQueryVariables = Types.Exact<{
  afterCursor?: Types.InputMaybe<Types.Scalars["String"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type ListRiskGroupsQuery = {
  __typename?: "RootQueryType";
  listRiskGroups?:
    | {
        __typename: "RiskGroupConnection";
        edges?:
          | Array<
              | {
                  __typename?: "RiskGroupEdge";
                  node?:
                    | {
                        __typename?: "RiskGroup";
                        id?: string | null | undefined;
                        createdAt?: any | null | undefined;
                        appType?: string | null | undefined;
                        entityType?: string | null | undefined;
                        field?: string | null | undefined;
                        description?: string | null | undefined;
                        cumulativeSdf?: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | {
              __typename?: "PageInfo";
              hasNextPage?: boolean | null | undefined;
              endCursor?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ListRiskGroupsDocument = gql`
  query ListRiskGroups($afterCursor: String, $limit: Int) {
    listRiskGroups(pagination: { limit: $limit, cursor: $afterCursor }) {
      __typename
      edges {
        node {
          id
          createdAt
          appType
          entityType
          field
          description
          cumulativeSdf
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function useListRiskGroupsQuery(
  options: Omit<Urql.UseQueryArgs<ListRiskGroupsQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<ListRiskGroupsQuery>({
    query: ListRiskGroupsDocument,
    ...options,
  });
}
