import { COLOR } from "../../../styling/colors";

// TODO: Work out what on earth the styling props are for these things.

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    fontSize: "0.75rem",
    borderTop: `1px solid ${COLOR.LINE}`,
    background: "#f5f6fa",
    cursor: "pointer",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginBottom: 0,
    "&:hover": {
      background: "#EFF0F6",
    },
    color: state.isSelected ? "#58469B" : provided.color,
  }),
  control: (provided: any, state: any) => {
    let borderColor = COLOR.LINE;
    let textColor = COLOR.LABEL;

    if (state.menuIsOpen) {
      borderColor = "#6E7191";
      textColor = COLOR.LINE;
    } else if (state.hasValue) {
      borderColor = "#58469B";
      textColor = "#58469B";
    } else if (state.isFocused) {
      borderColor = "#A0A3BD";
      textColor = "#58469B";
    }

    return {
      ...provided,
      borderRadius: 0,
      minHeight: "41px",
      fontSize: "0.75rem",
      border: `1px solid ${borderColor}`,
      color: textColor,
      background: "#f5f6fa",
      "&:hover": {
        borderColor: "#A0A3BD",
        textColor: COLOR.LINE,
      },
    };
  },
  menu: (provided: any, state: any) => ({
    ...provided,
    marginTop: "2px",
    borderRadius: 0,
    background: "#f5f6fa",
    paddingBottom: 0,
    borderBottom: 0,
    marginBottom: 0,
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const customStylesImageOption = {
  ...customStyles,
  control: (provided: any, state: any) => {
    let borderColor = COLOR.LINE;
    let textColor = COLOR.LINE;

    if (state.menuIsOpen) {
      borderColor = "#6E7191";
      textColor = COLOR.LINE;
    } else if (state.hasValue) {
      borderColor = "#58469B";
      textColor = "#58469B";
    } else if (state.isFocused) {
      borderColor = "#A0A3BD";
      textColor = "#58469B";
    }

    return {
      ...provided,
      height: "40px",
      borderRadius: 0,
      fontSize: "0.75rem",
      border: `1px solid ${borderColor}`,
      color: textColor,
      background: "#f5f6fa",
      "&:hover": {
        borderColor: "#A0A3BD",
        textColor: COLOR.LINE,
      },
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    fontSize: "0.75rem",
    borderTop: `1px solid ${COLOR.LINE}`,
    background: "#f5f6fa",
    cursor: "pointer",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
    marginBottom: 0,
    "&:hover": {
      background: "#EFF0F6",
    },
    color: state.isSelected ? COLOR.LABEL : COLOR.LABEL,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "40px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
  }),
  menu: (provided: any) => ({
    ...provided,
    minWidth: "150px",
  }),
};

export const customStylesReferenceSelector = {
  ...customStyles,
  ...customStylesImageOption,
  control: (provided: any, state: any) => {
    return {
      ...provided,
      ...customStylesImageOption.control(provided, state),
      borderLeft: 0,
    };
  },
  group: () => {
    return {
      marginTop: 0,
      borderBottom: "1px solid",
      borderColor: COLOR.LINE,
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: "nowrap",
    display: "flex",
  }),
};

export const customStylesSingleJoinedSelector = {
  ...customStyles,
  ...customStylesImageOption,
  control: (provided: any, state: any) => {
    return {
      ...provided,
      ...customStylesImageOption.control(provided, state),
      borderRightColor: COLOR.LINE,
      color: COLOR.BODY,
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  dummyInput: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    flexWrap: "nowrap",
    display: "flex",
  }),
};

export const customStylesFixedValue = {
  ...customStyles,
  control: (provided: any, state: any) => {
    let borderColor = COLOR.LINE;
    let textColor = COLOR.LINE;

    if (state.menuIsOpen) {
      borderColor = "#6E7191";
      textColor = COLOR.LINE;
    } else if (state.hasValue) {
      borderColor = "#58469B";
      textColor = "#58469B";
    } else if (state.isFocused) {
      borderColor = "#A0A3BD";
      textColor = "#58469B";
    }

    return {
      ...provided,
      height: "40px",
      borderRadius: 0,
      fontSize: "0.75rem",
      border: `1px solid ${borderColor}`,
      color: textColor,
      background: "#f5f6fa",
      "&:hover": {
        borderColor: "#A0A3BD",
        textColor: COLOR.LINE,
      },
    };
  },
  valueContainer: (provided: any) => ({
    ...provided,
    height: "40px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};
