import { useQuery } from "urql";

import { getIntegrationById } from "../helpers";
import {
  IIntegration,
  LIST_INTEGRATIONS_QUERY,
} from "../queries/listIntegrations";

export const useIntegrationMeta = () => {
  const [data] = useQuery({
    query: LIST_INTEGRATIONS_QUERY,
    variables: { isAccountRestricted: false },
  });

  const integrations = data.data?.listIntegrations;

  return {
    getIntegrationMeta: (id: string): IIntegration | undefined =>
      integrations
        ? getIntegrationById({ id, integrationsList: integrations })
        : ({} as IIntegration),
  };
};
