import React from "react";

import styled from "styled-components";

import { RiskLevel } from "../../definitions/types/risk-level";
import { RiskSdfLevelEnum } from "../../generated/graphql.generated";
import { SDF_SENSITIVITY_BOUNDS } from "../../operations/sdf/constants";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";

interface RiskDotProps extends React.SVGProps<SVGSVGElement> {
  sdf: {
    level: RiskSdfLevelEnum;
  };
}

const riskColorDict = {
  CRITICAL: COLOR.SDF_CRITICAL,
  HIGH: COLOR.SDF_HIGH,
  MEDIUM: COLOR.SDF_MEDIUM,
  LOW: COLOR.SDF_LOW,
};

const IconPathCritical = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  border-width: 6px;
  border-style: solid;
  border-color: ${COLOR.SDF_CRITICAL};
  transform: rotate(45deg);
`;

const IconPathHigh = styled(IconPathCritical)`
  border-color: ${COLOR.SDF_HIGH} ${COLOR.SDF_HIGH} ${COLOR.SDF_HIGH}
    transparent;
`;

const IconPathMedium = styled(IconPathCritical)`
  border-color: ${COLOR.SDF_MEDIUM} ${COLOR.SDF_MEDIUM} transparent transparent;
`;

const IconPathLow = styled(IconPathCritical)`
  border-color: ${COLOR.SDF_LOW} transparent transparent transparent;
`;

const StyledIconWrapper = styled.div<{ riskLevel: RiskSdfLevelEnum }>`
  padding: 3px;
  border-color: ${({ riskLevel }) => riskColorDict[riskLevel]};
  border-width: 2px;
  border-radius: 9999px;
  margin-right: ${APPLICATION_SPACING(1)};
`;

const riskIconDict = {
  CRITICAL: <IconPathCritical />,
  HIGH: <IconPathHigh />,
  MEDIUM: <IconPathMedium />,
  LOW: <IconPathLow />,
};

// This is just to be used for sensitivity as the risk level for SDF score is fetched from backend.
export const calculateRiskLevelForSensitivity = (
  sensistiviy: number
): RiskLevel => {
  const percentage = (sensistiviy / SDF_SENSITIVITY_BOUNDS.high) * 100;

  switch (true) {
    case percentage < 25:
      return RiskLevel.LOW;
    case percentage >= 25 && percentage < 50:
      return RiskLevel.MEDIUM;
    case percentage >= 50 && percentage < 75:
      return RiskLevel.HIGH;
    case percentage >= 75:
      return RiskLevel.CRITICAL;
    default:
      return RiskLevel.CRITICAL;
  }
};

// This is currently used in two places:
// * Risk Groups table for each row. This is a hack and should be removed:
//   TODO(MET-1318) get rid of this and fetch from backend.
// * SDF Counter on the Summary page when not much space is available. This is probably fine for that.
export const calculateRiskLevelForPercentage = (
  percentage: number
): RiskSdfLevelEnum => {
  switch (true) {
    case percentage < 25:
      return RiskSdfLevelEnum.Low;
    case percentage >= 25 && percentage < 50:
      return RiskSdfLevelEnum.Medium;
    case percentage >= 50 && percentage < 75:
      return RiskSdfLevelEnum.High;
    case percentage >= 75:
      return RiskSdfLevelEnum.Critical;
    default:
      return RiskSdfLevelEnum.Critical;
  }
};

const getRiskIcon = (riskLevel: RiskSdfLevelEnum) =>
  riskIconDict[riskLevel] ?? <IconPathCritical />;

export function RiskDot(props: RiskDotProps) {
  const { level } = props.sdf;
  return (
    <StyledIconWrapper riskLevel={level}>
      {getRiskIcon(level)}
    </StyledIconWrapper>
  );
}
