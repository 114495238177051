import { gql } from "urql";

import { FULL_DETECTION_FIELDS_FRAGMENT } from "../fragments";

export const GET_DETECTION_BY_HASH = gql`
  ${FULL_DETECTION_FIELDS_FRAGMENT}
  query FindSingleDetectionByHash(
    $hash: String!
    $canUseDetectionActions: Boolean!
    $canReadDeepLink: Boolean!
  ) {
    findDetectionsForDatapoint(detectionHash: $hash, pagination: { limit: 1 }) {
      edges {
        node {
          ...FullDetectionFields
        }
      }
    }
  }
`;
