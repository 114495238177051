import React, { FunctionComponent } from "react";

import styled, { css } from "styled-components";

import { ITextInput, TextInput } from "../../../atoms/form/TextInput";
import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import { LayoutContainer } from "../../layout/LayoutContainer";

interface IHeadedTextInput extends ITextInput {
  containerClass?: string;
  noMargin?: boolean;
  hideLabel?: boolean;
}

const StyledTextInput = styled(TextInput)<{ hasValue?: boolean }>`
  border: 1px solid;
  border-color: ${({ hasValue }) => (hasValue ? COLOR.PRIMARY : "#d6d8e7")};

  &:focus {
    border-color: #a0a3bd;
    color: "#58469B";
  }
`;

const StyledContainer = styled.div<{ noMargin?: boolean }>`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-bottom: ${APPLICATION_SPACING(3)};
    `}
`;

export const HeadedTextInput: FunctionComponent<IHeadedTextInput> = (props) => {
  return (
    <StyledContainer className={props.containerClass} noMargin={props.noMargin}>
      {!props.hideLabel && (
        <LayoutContainer
          as="label"
          fontSize="xsm"
          color="LABEL"
          mb={1}
          display="block"
        >
          {props.heading}
        </LayoutContainer>
      )}
      <StyledTextInput
        {...props}
        className={props.className}
        hasValue={Boolean(props.value)}
      />
    </StyledContainer>
  );
};
