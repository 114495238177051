import React, { HTMLAttributes, MouseEventHandler } from "react";

import {
  Icon,
  X as XIcon,
  CheckCircle,
  AlertTriangle,
  Info,
  AlertCircle,
} from "react-feather";
import styled from "styled-components";

import { TOAST_TYPES_UNION } from "../../recoil/toasts/atom";
import { COLOR } from "../../styling/colors";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { Box } from "../Box";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IRootToast extends HTMLAttributes<HTMLDivElement> {
  toastType: TOAST_TYPES_UNION;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
}

type IToastTemplate = {
  [key in TOAST_TYPES_UNION]: {
    style: {
      closeColor?: string;
      backgroundColor: string;
      borderColor: string;
      color: string;
    };
    Icon: Icon;
  };
};

const TOAST_TEMPLATES: IToastTemplate = {
  warning: {
    style: {
      backgroundColor: COLOR.BODY,
      borderColor: COLOR.WARNING,
      color: COLOR.OFF_WHITE,
    },
    Icon: AlertTriangle,
  },
  info: {
    style: {
      backgroundColor: COLOR.BODY,
      borderColor: COLOR.PRIMARY,
      color: COLOR.OFF_WHITE,
      closeColor: COLOR.OFF_WHITE,
    },
    Icon: Info,
  },
  error: {
    style: {
      backgroundColor: COLOR.BODY,
      borderColor: COLOR.ERROR,
      color: COLOR.OFF_WHITE,
    },
    Icon: AlertCircle,
  },
  success: {
    style: {
      backgroundColor: COLOR.BODY,
      borderColor: COLOR.SUCCESS,
      color: COLOR.OFF_WHITE,
    },
    Icon: CheckCircle,
  },
};

const StyledRootToast = styled(Box)`
  width: 560px;
  max-width: 90%;
  overflow: hidden;
`;

const StyledButton = styled.button`
  margin-left: auto;
  padding: ${SPECIFIC_SPACING["4px"]};
`;

export const RootToast = (props: IRootToast) => {
  const template = TOAST_TEMPLATES[props.toastType];
  const Icon = template.Icon;
  return (
    <StyledRootToast
      display="flex"
      alignItems="center"
      pr={1}
      fontSize="sm"
      position="relative"
      mt={SPECIFIC_SPACING["12px"]}
      boxShadow
      style={template.style}
    >
      <LayoutContainer
        display="flex"
        minHeight="100%"
        alignItems="center"
        justifyContent="center"
        p={SPECIFIC_SPACING["12px"]}
        style={{ background: template.style.borderColor }}
        alignSelf="stretch"
      >
        <Icon size={20} style={{ color: COLOR.OFF_WHITE }} />
      </LayoutContainer>
      <LayoutContainer
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
        color="OFF_WHITE"
        pl={2}
      >
        {props.children}
        {props.onCloseClick && (
          <StyledButton onClick={props.onCloseClick}>
            <XIcon
              size={16}
              style={{
                color: template.style.closeColor || template.style.borderColor,
              }}
            />
          </StyledButton>
        )}
      </LayoutContainer>
    </StyledRootToast>
  );
};
