import React, { FunctionComponent } from "react";

import styled, { css } from "styled-components";

import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

// Colors need to be more strictly defined once we have an idea what the range will be

export interface IOutlineColorPill {
  uppercase?: boolean;
  className?: string;
  backgroundColor?: string;
  color?: string;
}

const StyledColourPill = styled.span<{ uppercase?: boolean }>`
  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  font-size: ${theme.fontSizes.xxsm};
  padding: 0.1rem ${APPLICATION_SPACING(1)};
  display: inline-flex;
  align-items: center;
  line-height: 1rem;
  border-width: 1px;
  border-radius: 9999px;
`;

export const OutlineColorPill: FunctionComponent<IOutlineColorPill> = ({
  children,
  uppercase,
  className,
  backgroundColor = "transparent",
  color,
}) => (
  <StyledColourPill
    uppercase={uppercase}
    style={{
      backgroundColor,
      color: color ? color : "inherit",
      borderColor: color ? color : "currentColor",
    }}
    className={className}
  >
    {children}
  </StyledColourPill>
);
