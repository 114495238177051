export const DEVELOPMENT_AUTH0_CONFIG = {
  domain: "metomic-staging.eu.auth0.com",
  clientId: "NI2QmFfEB4wrzqr9f09T44QfxRvXWnfI",
  redirectUri: window.location.origin,
  audience: "https://web-api.metomic.io",
};

export const PROD_AUTH0_CONFIG = {
  domain: "metomic-prod.eu.auth0.com",
  clientId: "0NWdqCcySsLL0YjWQ6q5l7Q4Kjep3wtk",
  redirectUri: window.location.origin,
  audience: "https://web-api.metomic.io",
};

export const AUTH0_CONFIG =
  process.env.REACT_APP_AUTH0_CONFIG === "production"
    ? PROD_AUTH0_CONFIG
    : DEVELOPMENT_AUTH0_CONFIG;

export const AUTH0_AUDIENCE = "https://web-api.metomic.io";
