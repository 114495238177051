import gql from "graphql-tag";
import * as Urql from "urql";

import * as Types from "../../../generated/graphql.generated";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetRiskActionHistoryActiveQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars["String"]>;
  limit: Types.Scalars["Int"];
}>;

export type GetRiskActionHistoryActiveQuery = {
  __typename?: "RootQueryType";
  listActiveBulkActions?:
    | {
        __typename?: "BulkActionConnection";
        edges?:
          | Array<
              | {
                  __typename?: "BulkActionEdge";
                  node?:
                    | {
                        __typename?: "BulkAction";
                        id?: string | null | undefined;
                        startedAt?: any | null | undefined;
                        accountId?: string | null | undefined;
                        cancelledAt?: any | null | undefined;
                        status?: string | null | undefined;
                        createdByUser?:
                          | {
                              __typename?: "User";
                              id?: string | null | undefined;
                              avatarUrl?: string | null | undefined;
                              nickname?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        summary?:
                          | {
                              __typename?: "BulkActionSummary";
                              bulkActionId?: string | null | undefined;
                              detectionCount?: number | null | undefined;
                              commands?:
                                | Array<
                                    | {
                                        __typename?: "BulkActionSummaryCommandCount";
                                        appType?: string | null | undefined;
                                        count?: number | null | undefined;
                                        command?:
                                          | Types.RiskActionCommandEnum
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        pageInfo?:
          | {
              __typename?: "PageInfo";
              endCursor?: string | null | undefined;
              hasNextPage?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GetRiskActionHistoryActiveDocument = gql`
  query GetRiskActionHistoryActive($cursor: String, $limit: Int!) {
    listActiveBulkActions: listBulkActions(
      filters: { statuses: [ACTIVE] }
      pagination: { limit: $limit, cursor: $cursor }
    ) {
      edges {
        node {
          id
          startedAt
          accountId
          cancelledAt
          status
          createdByUser {
            id
            avatarUrl
            nickname
          }
          summary {
            bulkActionId
            detectionCount
            commands {
              appType
              count
              command
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export function useGetRiskActionHistoryActiveQuery(
  options: Omit<
    Urql.UseQueryArgs<GetRiskActionHistoryActiveQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetRiskActionHistoryActiveQuery>({
    query: GetRiskActionHistoryActiveDocument,
    ...options,
  });
}
