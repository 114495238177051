import React, { FunctionComponent, HTMLAttributes } from "react";

import classnames from "classnames";
import styled, { css } from "styled-components";

import { APPLICATION_SPACING } from "../../styling/spacing";
import { ShowMoreButton } from "../ShowMoreButton";
import { LayoutContainer } from "../layout/LayoutContainer";

interface IConnectionShowMoreWrapper extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  hideButton: boolean;
  showMore: () => void;
  showLoadingIndicator: boolean;
  withButtonSpacing?: boolean;
}

const StyledShowMore = styled(ShowMoreButton)<{ withButtonSpacing?: boolean }>`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.withButtonSpacing &&
    css`
      margin-top: ${APPLICATION_SPACING(2)};
      margin-bottom: 1.75rem;
    `}
`;

//TODO: THIS COMPONENT IS COMPLETELY SUPERFLUOUS NOW, ALL YOU NEED IS A BUTTON

export const ConnectionShowMoreWrapper: FunctionComponent<
  IConnectionShowMoreWrapper
> = ({
  className,
  children,
  loading,
  showMore,
  showLoadingIndicator,
  hideButton,
  withButtonSpacing,
}) => (
  <LayoutContainer className={classnames(className)}>
    {children}
    {!hideButton && (
      <LayoutContainer
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <StyledShowMore
          withButtonSpacing={withButtonSpacing}
          onClick={() => {
            if (!loading) {
              showMore();
            }
          }}
          loading={loading}
          shouldShowLoader={showLoadingIndicator}
        />
      </LayoutContainer>
    )}
  </LayoutContainer>
);
