import { Zap } from "react-feather";
import { Column } from "react-table";
import { css } from "styled-components";

import { RiskDot } from "../../atoms/icons/RiskDot";
import { Popover } from "../../components/Popover";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { IMonitor } from "../../definitions/types/monitors";
import {
  monitorRiskLevelToRiskLevel,
  monitorRiskLevelToRiskSdfLevel,
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
} from "../../definitions/types/risk-level";
import { sumViolations } from "../../page/Violations/Violations";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { kFormatter } from "../../utils/number/kformatter";

export const columns: Column<IMonitor>[] = [
  {
    Header: "Name",
    accessor: "name",
    width: 300,
    minWidth: 300,
    maxWidth: 2000,
    columnStyles: css`
      padding-left: 1.5rem;
    `,
    Cell: (data) => {
      return (
        <LayoutContainer display="flex" alignItems="center" fontSize="sm">
          {" "}
          <LayoutContainer mr={2}>
            <RiskDot
              sdf={{
                level:
                  monitorRiskLevelToRiskSdfLevel[data.row.original.riskLevel],
              }}
            />
          </LayoutContainer>
          {data.cell.value}
        </LayoutContainer>
      );
    },
  },
  {
    Header: () => null,
    accessor: "violationSummary",
    width: 55,
    disableResizing: true,
    Cell: (data) => (
      <LayoutContainer
        height="100%"
        width="100%"
        color="OFF_WHITE"
        fontSize="sm"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          borderRadius: "0 4px 4px 0",
          backgroundColor:
            RISK_LEVEL_TEMPLATES[
              defaultTo(
                monitorRiskLevelToRiskLevel[data.row.original.riskLevel],
                RiskLevel.LOW
              ) as RiskLevel
            ].backgroundColor,
        }}
      >
        <Popover content="Violations">
          <LayoutContainer display="flex" alignItems="center">
            <LayoutContainer color="OFF_WHITE" mr={SPECIFIC_SPACING["4px"]}>
              <Zap size={16} />
            </LayoutContainer>
            {kFormatter(sumViolations(data.cell.value?.currentViolations))}
          </LayoutContainer>
        </Popover>
      </LayoutContainer>
    ),
  },
];
