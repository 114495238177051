import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import { AlertTriangle } from "react-feather";
import { RecoilRoot } from "recoil";
import styled, { ThemeProvider } from "styled-components";

import { ButtonOutlinePrimary } from "./atoms/Button";
import { Heading } from "./atoms/Heading";
import { AUTH0_CONFIG } from "./config.auth0";
import { ModalProvider } from "./contexts/modals";
import { ToastContextProvider } from "./recoil/toasts/provider";
import { AuthGate } from "./routing/index";
import { COLOR } from "./styling/colors";
import { APPLICATION_SPACING } from "./styling/spacing";
import theme from "./styling/theme";

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(3)};
  color: ${COLOR.PRIMARY};
  max-width: 580px;
  text-align: center;
`;

const StyledAlertIcon = styled(AlertTriangle)`
  margin-bottom: ${APPLICATION_SPACING(2)};
  color: ${COLOR.ERROR};
`;

const StyledErrorBoundaryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: ${COLOR.OFF_WHITE};
`;

function App() {
  const urlParams = new URLSearchParams(window.location.search);

  return (
    <Auth0Provider
      {...AUTH0_CONFIG}
      {...Object.fromEntries(urlParams.entries())}
    >
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <ToastContextProvider>
            <ModalProvider>
              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => (
                  <StyledErrorBoundaryContainer role="alert">
                    <StyledAlertIcon size={60} />
                    <StyledHeading
                      Element="h1"
                      type="display-medium"
                      isBodyColor
                    >
                      Something went wrong. <br /> Please try again
                    </StyledHeading>
                    <ButtonOutlinePrimary
                      onClick={() => {
                        resetErrorBoundary();
                      }}
                    >
                      Try Again
                    </ButtonOutlinePrimary>
                  </StyledErrorBoundaryContainer>
                )}
              >
                <AuthGate />
              </ErrorBoundary>
            </ModalProvider>
          </ToastContextProvider>
        </RecoilRoot>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;
