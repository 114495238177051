import React from "react";

import styled from "styled-components";

import { ParagraphNode } from "../../../../definitions/types/integration";
import { APPLICATION_SPACING } from "../../../../styling/spacing";
import theme from "../../../../styling/theme";

const newLineText = (text: string) => {
  const newText = text.split("\n").map((str, i) => <p key={i}>{str}</p>);

  return newText;
};

const StyledParagraphContainer = styled.p`
  margin-bottom: ${APPLICATION_SPACING(3)};
  font-size: ${theme.fontSizes.sm};
  display: block;
`;

export const IntegrationNodeParagraph = (node: ParagraphNode) => (
  <StyledParagraphContainer>
    {newLineText(node.config.text)}
  </StyledParagraphContainer>
);
