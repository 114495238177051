import React, { ButtonHTMLAttributes, FunctionComponent } from "react";

import { Link, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";

import { HorizontalLoader } from "../../components/loading/HorizontalLoader";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  loading?: boolean;
  smallLoader?: boolean;
  $isWhiteLoader?: boolean;
  icon?: JSX.Element;
}

interface ISimpleReactRouterLink extends LinkProps {
  loading?: boolean;
}

const StyledIconWrapper = styled.div`
  padding-right: ${APPLICATION_SPACING(2)};
`;

const StyledLoadingHider = styled.div<{
  $loading?: boolean;
  $hasIcon?: boolean;
}>`
  opacity: ${(props) => (props.$loading ? 0 : 1)};
  ${(props) =>
    props.$hasIcon &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const StyledLoadingSpinner = styled(HorizontalLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled.button`
  position: relative;
  white-space: nowrap;
`;

export const Button: FunctionComponent<IButton> = ({
  onClick,
  className,
  children,
  loading,
  smallLoader,
  $isWhiteLoader,
  icon,
  ...rest
}) => (
  <StyledButton className={className} onClick={onClick} {...rest}>
    {loading && <StyledLoadingSpinner isWhite={$isWhiteLoader} />}
    <StyledLoadingHider $loading={loading} $hasIcon={Boolean(icon)}>
      {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
      {children}
    </StyledLoadingHider>
  </StyledButton>
);

export const ReactRouterLink: FunctionComponent<ISimpleReactRouterLink> = ({
  className,
  children,
  loading,
  to,
  ...rest
}) => (
  <Link to={to} className={className} {...rest}>
    {loading ? <LoadingSpinner /> : children}
  </Link>
);

export const DefaultButton = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  background: ${COLOR.PRIMARY};
  display: flex;
  flex: 1;
  width: 100%;
  font-size: ${theme.fontSizes.md};
  align-items: center;
  color: #ffffff;
  justify-content: center;
`;

export const ButtonSimple = styled(Button).attrs({
  $isWhiteLoader: false,
})`
  font-weight: 300;
  padding: 0 ${APPLICATION_SPACING(2)};
  color: ${COLOR.BODY};
  &:hover {
    background: ${COLOR.GRAYSCALE_BACKGROUND};
  }
`;

export const ContextMenuButton = styled(ButtonSimple)`
  width: 100%;
  font-size: ${theme.fontSizes.xsm};
  height: ${APPLICATION_SPACING(6)};
  text-align: left;
`;

export const ButtonSmall = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  font-size: ${theme.fontSizes.xsm};
`;

export const ButtonPillPrimary = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  padding: 0 ${APPLICATION_SPACING(2)};
  font-size: ${theme.fontSizes.xsm};
  height: ${APPLICATION_SPACING(4)};
  background: ${COLOR.PRIMARY};
  color: ${COLOR.OFF_WHITE};
  border-radius: 9999px;

  &:hover {
    background: ${COLOR.PRIMARY_HOVER};
  }
`;

export const ButtonPillAlternate = styled(Button)`
  padding: 0 ${APPLICATION_SPACING(1)};
  font-size: ${theme.fontSizes.xsm};
  background: ${COLOR.OFF_WHITE};
  color: ${COLOR.PRIMARY};
  border-radius: 9999px;
  height: ${APPLICATION_SPACING(4)};
  border-width: 1px;
  border-color: ${COLOR.LINE};

  &:hover {
    background: ${COLOR.BG_DARK_MODE};
  }
`;

export const ButtonPillAlternateHeavy = styled(Button)`
  padding: 0 ${APPLICATION_SPACING(2)};
  padding-right: ${APPLICATION_SPACING(1)};
  font-size: ${theme.fontSizes.xsm};
  background: ${COLOR.OFF_WHITE};
  color: ${COLOR.PRIMARY};
  border-radius: 9999px;
  height: ${APPLICATION_SPACING(5)};
  border-width: 2px;
  border-color: ${COLOR.PRIMARY};
  text-transform: uppercase;

  &:hover {
    background: ${COLOR.BG_DARK_MODE};
  }
`;

export const ButtonOutlinePrimary = styled(Button)`
  padding: 0 ${APPLICATION_SPACING(2)};
  font-size: ${theme.fontSizes.sm};
  background: transparent;
  color: ${COLOR.PRIMARY};
  border-radius: 9999px;
  height: ${APPLICATION_SPACING(5)};
  border-width: 2px;
  border-color: ${COLOR.PRIMARY};

  &:hover {
    border-color: ${COLOR.PRIMARY_HOVER};
    color: ${COLOR.PRIMARY_HOVER};
  }
`;

export const ButtonOutlineOffWhite = styled(ButtonOutlinePrimary)`
  color: ${COLOR.OFF_WHITE};
  border-color: ${COLOR.OFF_WHITE};

  &:hover {
    border-color: ${COLOR.OFF_WHITE_DARKER};
    color: ${COLOR.OFF_WHITE_DARKER};
  }
`;

export const ButtonOutlinePrimaryUppercase = styled(ButtonOutlinePrimary)`
  text-transform: uppercase;
`;

export const ButtonWithIcon = styled(Button)`
  width: 100%;
  padding: 0 ${APPLICATION_SPACING(2)};
  color: ${COLOR.LABEL};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TextButtonSimple = styled(Button)`
  color: ${COLOR.PRIMARY};
  font-size: ${theme.fontSizes.xsm};
  cursor: pointer;

  &:hover {
    color: ${COLOR.LABEL};
  }
`;

export const HorizontalNavigationButton = styled(Button)`
  display: flex;
  align-items: center;
  height: ${APPLICATION_SPACING(4)};
  padding: 0 ${APPLICATION_SPACING(1)};
  color: ${COLOR.PLACEHOLDER};
  font-size: ${theme.fontSizes.sm};
`;

export const ClearButton = styled(Button)`
  display: flex;
  font-size: ${theme.fontSizes.xsm};
  align-items: center;
  padding: 0 ${APPLICATION_SPACING(3)};
  color: ${COLOR.ERROR};
`;

export const PrimarySquareButton = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  background-color: ${COLOR.PRIMARY};
  padding: 0 ${APPLICATION_SPACING(2)};
  height: 35px;
  color: ${COLOR.OFF_WHITE};
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${theme.fontSizes.xsm};
  align-items: center;
  white-space: nowrap;
  line-height: 0rem;

  opacity: ${(props) => props.disabled && "0.5"};
  display: inline-flex;
  align-items: center;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${COLOR.PRIMARY_HOVER};
      `}
  }
`;

export const PrimaryDisabledButton = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  background-color: ${COLOR.GRAYSCALE_UNEDITABLE};
  padding: 0 ${APPLICATION_SPACING(2)};
  height: 35px;
  color: ${COLOR.OFF_WHITE};
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${theme.fontSizes.xsm};
`;

export const OutlineErrorButton = styled(Button).attrs({
  $isWhiteLoader: true,
})`
  padding: 0 ${APPLICATION_SPACING(2)};
  height: 35px;
  color: ${COLOR.ERROR};
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${theme.fontSizes.xsm};
  border-color: ${COLOR.ERROR};
  border-width: 1px;
`;

export const FullWidthTextButton = styled(Button)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR.PRIMARY};
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xsm};
  width: 100%;

  &:hover {
    background-color: ${""};
    color: ${""};
  }
`;

export const OutlineWhiteButton = styled(Button).attrs({
  $isWhiteLoader: false,
})`
  display: inline-flex;
  align-self: flex-start;
  background-color: transparent;
  padding: 0 ${APPLICATION_SPACING(2)};
  height: 35px;
  color: ${COLOR.OFF_WHITE};
  border-color: rgba(250, 250, 250, 0.5);
  text-transform: uppercase;
  border-radius: 4px;
  font-size: ${theme.fontSizes.xsm};
  align-items: center;
  white-space: nowrap;
  line-height: 0rem;
  border-width: 1px;

  opacity: ${(props) => props.disabled && "0.5"};
  display: inline-flex;
  align-items: center;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        border-color: rgba(250, 250, 250, 1);
      `}
  }
`;
