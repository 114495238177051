import React, {
  FunctionComponent,
  HTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  useCallback,
} from "react";

import * as ContextMenu from "@radix-ui/react-popover";
import { ChevronDown, MoreHorizontal, MoreVertical } from "react-feather";
import styled, { FlattenSimpleInterpolation } from "styled-components";

import { IButton } from "../../atoms/Button";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { Box } from "../Box";
import { ContextMenuProvider, useContextMenuControl } from "./context";

interface IContextMenuContainer extends HTMLAttributes<HTMLDivElement> {
  buttonText?: string;
  buttonStyles?: FlattenSimpleInterpolation;
  isUnderneath?: boolean;
  isLeftAligned?: boolean;
  isOnlyDots?: boolean;
  isHorizontalDots?: boolean;
  Button?: React.ComponentType<IButton>;
}

const StyledContextMenuWrapper = styled.div`
  position: relative;
  color: ${COLOR.PLACEHOLDER};
`;

const ContextMenuItemContainer = styled.div`
  position: relative;
  color: ${COLOR.PLACEHOLDER};
  height: ${APPLICATION_SPACING(3)};
  width: ${APPLICATION_SPACING(3)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IContextMenuInner {
  isUnderneath?: boolean;
  isLeftAligned?: boolean;
}

const ContextMenuInner = styled.div<IContextMenuInner>`
  font-size: ${theme.fontSizes.xsm};
  width: ${APPLICATION_SPACING(22)};
`;

const StyledIcon = styled(ChevronDown)`
  color: ${COLOR.BODY};
  margin-bottom: -0.1rem;
`;

const StyledTrigger = styled(ContextMenu.Trigger)`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button<{
  overrideStyles?: FlattenSimpleInterpolation;
}>`
  ${({ overrideStyles }) => overrideStyles}
`;

export const ContextMenuRoot: FunctionComponent<IContextMenuContainer> = ({
  children,
  buttonText,
  buttonStyles,
  isUnderneath,
  isLeftAligned,
  isOnlyDots,
  isHorizontalDots,
  className,
  Button,
}) => {
  const { visible, setVisibility } = useContextMenuControl();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setVisibility(!visible);
    },
    [setVisibility, visible]
  );

  return (
    <StyledContextMenuWrapper className={className}>
      <ContextMenu.Root
        open={visible}
        onOpenChange={(isOpen) => setVisibility(isOpen)}
      >
        {Button && (
          <StyledTrigger asChild>
            <span>
              <Button onClick={handleClick}>{buttonText}</Button>
            </span>
          </StyledTrigger>
        )}
        {!Button && (
          <StyledTrigger asChild>
            <StyledButton overrideStyles={buttonStyles} onClick={handleClick}>
              {buttonText ? (
                buttonText
              ) : isOnlyDots || !isUnderneath ? (
                isHorizontalDots ? (
                  <MoreHorizontal />
                ) : (
                  <MoreVertical />
                )
              ) : (
                <ContextMenuItemContainer>
                  <StyledIcon size={14} />
                </ContextMenuItemContainer>
              )}
            </StyledButton>
          </StyledTrigger>
        )}
        <ContextMenu.Content
          onOpenAutoFocus={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onCloseAutoFocus={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onFocusOutside={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          side={isUnderneath ? "bottom" : "top"}
          align={isLeftAligned ? "start" : "end"}
        >
          <ContextMenuInner>
            <Box boxShadow>{children}</Box>
          </ContextMenuInner>
        </ContextMenu.Content>
      </ContextMenu.Root>
    </StyledContextMenuWrapper>
  );
};

export const withContextMenuControls =
  <T,>(Component: React.ComponentType<T>) =>
  (props: PropsWithChildren<T>) =>
    (
      <ContextMenuProvider>
        <Component {...props} />
      </ContextMenuProvider>
    );

export const ContextMenuContainer = withContextMenuControls(ContextMenuRoot);
