import React, { FunctionComponent } from "react";

import styled from "styled-components";
import { useQuery } from "urql";

import { IUser } from "../../definitions/types/users";
import {
  InlineDateDropdown,
  ITimeboundDropdownState,
} from "../../page/Overview";
import { RouteSuspendWrapper } from "../../routing/RouteWithAuthentication/JSONDefinedRouter";
import { ApplicationRoute } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { BreadcrumbHeader } from "../BreadcrumbHeader";
import { GET_ME_QUERY } from "../Navigation/queries";
import { TabNavigation } from "../TabNavigation";
import { UserProfileBlock } from "../UserProfileBlock";
import { LayoutContainer } from "../layout/LayoutContainer";

type IPageHeaderWrapper = {
  className?: string;
  breadcrumb?: string[];
  nestedRoutes?: {
    [key: string]: ApplicationRoute;
  };

  showTimeFilter?: boolean;
  time?: ITimeboundDropdownState;
  setTime?: (time: ITimeboundDropdownState) => void;
};

export interface IMeData {
  me: IUser;
}

const StyledHeader = styled.header`
  height: 65px;
  background: ${COLOR.GRAYSCALE_BACKGROUND};
  display: flex;
  flex: 1;
  border-bottom-width: 1px;
  border-color: ${COLOR.LINE};
  color: ${COLOR.PRIMARY_DARK_MODE};
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 40;
`;

const StyledUserProfileBlockContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 ${APPLICATION_SPACING(3)};
  border-color: rgba(250, 250, 250, 0.1);
  color: rgba(160, 163, 189, 0.5);
  font-weight: 300;
  font-size: ${theme.fontSizes.xsm};
  padding-left: calc((150px - 20px) / 2);
  height: 50px;
  height: 65px;
  position: relative;
  text-align: left;
  margin-left: auto;
`;

export const PageHeaderWrapper: FunctionComponent<IPageHeaderWrapper> = ({
  className,
  children,
  breadcrumb,
  nestedRoutes,
  showTimeFilter,
  time,
  setTime,
}) => {
  const [response] = useQuery({ query: GET_ME_QUERY });

  const { data } = response;
  const user = data?.me;

  return (
    <LayoutContainer position="relative">
      <StyledHeader>
        <LayoutContainer
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          pl={[5, 0]}
        >
          {breadcrumb && breadcrumb.length > 0 && (
            <BreadcrumbHeader breadcrumb={breadcrumb}>
              {showTimeFilter && time && setTime && (
                <LayoutContainer as="span">
                  last <InlineDateDropdown value={time} onSelect={setTime} />
                </LayoutContainer>
              )}
            </BreadcrumbHeader>
          )}
          {nestedRoutes && Object.keys(nestedRoutes).length > 0 && (
            <TabNavigation routes={nestedRoutes} />
          )}
        </LayoutContainer>
        <LayoutContainer display="flex" ml="auto" alignItems="center">
          <StyledUserProfileBlockContainer>
            <UserProfileBlock {...user} />
          </StyledUserProfileBlockContainer>
        </LayoutContainer>
      </StyledHeader>
      <div>
        <RouteSuspendWrapper>{children}</RouteSuspendWrapper>
      </div>
    </LayoutContainer>
  );
};
