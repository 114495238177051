import React, { FunctionComponent, HTMLAttributes, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import classnames from "classnames";
import { useLocation } from "react-router";

import IntegrationConfigModal from "../../../components/modals/Integration/IntegrationConfigModal";
import IntegrationModal, {
  IIntegrationModal,
} from "../../../components/modals/Integration/IntegrationModal";
import { useModal } from "../../../contexts/modals";
import { useQueryParams } from "../../../operations/filters/helpers";
import { INTEGRATION_API } from "../../../operations/integrations/api";

interface IContinueInstallation extends HTMLAttributes<HTMLDivElement> {
  refetchInstallations: () => void;
}

export const ContinueInstallation: FunctionComponent<IContinueInstallation> = ({
  className,
  children,
  refetchInstallations,
}) => {
  const location = useLocation();
  const params = useQueryParams(location.search);
  const installationId = params.get("installationId");
  const nextStep = params.get("nextStep");
  const { integrationModal, integrationConfigModal } = useModal();
  const { getAccessTokenSilently } = useAuth0();
  const { setVisible } = integrationModal;

  useEffect(() => {
    const loadInstallationDetails = async (installationId: string) => {
      const data = await INTEGRATION_API.getCurrentInstallationStep({
        getAccessToken: getAccessTokenSilently,
        setIsError: (error: string) => {},
        installationId,
      });

      const currentStepData = await INTEGRATION_API.getInstallationStepDetails({
        getAccessToken: getAccessTokenSilently,
        setIsError: (error: string) => {},
        installationId,
        stepNumber: data.currentStepNumber,
      });

      return currentStepData.data;
    };

    if (installationId && nextStep !== "null") {
      loadInstallationDetails(installationId).then(
        (setup: IIntegrationModal) => {
          setVisible(setup);
        }
      );
    }
  }, [installationId, getAccessTokenSilently, setVisible, nextStep]);

  return (
    <div className={classnames(className)}>
      {children}
      <IntegrationModal
        data={integrationModal.data}
        showModal={integrationModal.visible}
        toggleModal={integrationModal.toggle}
        setModalData={integrationModal.setModalData}
        refetchInstallations={refetchInstallations}
      />
      <IntegrationConfigModal
        data={integrationConfigModal.data}
        showModal={integrationConfigModal.visible}
        toggleModal={integrationConfigModal.toggle}
        setModalData={integrationConfigModal.setModalData}
        refetchInstallations={refetchInstallations}
      />
    </div>
  );
};
