import gql from "graphql-tag";

import * as Types from "../../../generated/graphql.generated";

export type FullRiskFieldsFragment = {
  __typename?: "Risk";
  id?: string | null | undefined;
  atomId?: string | null | undefined;
  sdf?: number | null | undefined;
  sdfLevel?: Types.RiskSdfLevelEnum | null | undefined;
  createdAt?: any | null | undefined;
  sharedAt?: any | null | undefined;
  shortCode?: string | null | undefined;
  isDirty?: boolean | null | undefined;
  atom?:
    | {
        __typename?: "LabelledAtom";
        appType?: string | null | undefined;
        entityType?: string | null | undefined;
        entityId?: string | null | undefined;
        location?:
          | {
              __typename?: "AtomLocation";
              label?: string | null | undefined;
              deeplink?: string | null | undefined;
              entityType?: string | null | undefined;
              appType?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FullRiskFieldsFragmentDoc = gql`
  fragment FullRiskFields on Risk {
    id
    atomId
    atom {
      appType
      entityType
      entityId
      location {
        label
        deeplink
        entityType
        appType
      }
    }
    sdf
    sdfLevel
    createdAt
    sharedAt
    shortCode
    isDirty
  }
`;
