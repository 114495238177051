import { defaultTo as RamdaDefaultTo } from "ramda";

export const defaultTo = <T, U>(item: T, defaultResult: U) =>
  RamdaDefaultTo(defaultResult)(item) as NonNullable<T> | U;

export const getMaybeArrayValue = <T>(items?: T[] | null) =>
  items
    ? items
        .map((item) => defaultTo(item, undefined) as NonNullable<T>)
        .filter((item) => item !== undefined)
    : [];
