import React, { FunctionComponent, HTMLAttributes } from "react";

import pluralize from "pluralize";
import styled from "styled-components";

import {
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
} from "../../definitions/types/risk-level";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { kFormatter } from "../../utils/number/kformatter";
import { LayoutContainer, Span } from "../layout/LayoutContainer";

interface IRiskSummaryBlock
  extends HTMLAttributes<HTMLDivElement>,
    Record<RiskLevel, number> {
  activeRules?: number;
}

const NoWrapLayoutContainer = styled(LayoutContainer)`
  white-space: nowrap;
`;

export const RiskSummaryBlock: FunctionComponent<IRiskSummaryBlock> = ({
  CRITICAL,
  MEDIUM,
  HIGH,
  LOW,
  className,
}) => (
  <LayoutContainer
    color="BODY"
    display="flex"
    justifyContent="space-between"
    flexDirection="column"
    className={className}
    fontSize="xsm"
    pl={1}
    maxWidth="280px"
    width="100%"
  >
    <NoWrapLayoutContainer display="flex" mb={1} alignItems="center">
      <Span
        mr={1}
        height="0.5rem"
        px={SPECIFIC_SPACING["4px"]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="OFF_WHITE"
        style={{
          borderRadius: "4px",
          minWidth: "1rem",
          backgroundColor: RISK_LEVEL_TEMPLATES["CRITICAL"].backgroundColor,
        }}
      ></Span>
      <LayoutContainer mr={1}>
        Critical {pluralize("risks", CRITICAL)}
      </LayoutContainer>
      <LayoutContainer ml="auto" textAlign="right">
        {kFormatter(CRITICAL)}
      </LayoutContainer>
    </NoWrapLayoutContainer>
    <NoWrapLayoutContainer display="flex" mb={1} alignItems="center">
      <Span
        mr={1}
        height="0.5rem"
        px={SPECIFIC_SPACING["4px"]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="OFF_WHITE"
        style={{
          borderRadius: "4px",
          minWidth: "1rem",
          backgroundColor: RISK_LEVEL_TEMPLATES["HIGH"].backgroundColor,
        }}
      ></Span>
      <LayoutContainer mr={1}>High {pluralize("risks", HIGH)}</LayoutContainer>
      <LayoutContainer ml="auto" textAlign="right">
        {kFormatter(HIGH)}
      </LayoutContainer>
    </NoWrapLayoutContainer>
    <NoWrapLayoutContainer display="flex" mb={1} alignItems="center">
      <Span
        mr={1}
        height="0.5rem"
        px={SPECIFIC_SPACING["4px"]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="OFF_WHITE"
        style={{
          borderRadius: "4px",
          minWidth: "1rem",
          backgroundColor: RISK_LEVEL_TEMPLATES["MEDIUM"].backgroundColor,
        }}
      ></Span>
      <LayoutContainer mr={1}>
        Medium {pluralize("risks", MEDIUM)}
      </LayoutContainer>
      <LayoutContainer ml="auto" textAlign="right">
        {kFormatter(MEDIUM)}
      </LayoutContainer>
    </NoWrapLayoutContainer>
    <NoWrapLayoutContainer display="flex" alignItems="center">
      <Span
        mr={1}
        height="0.5rem"
        px={SPECIFIC_SPACING["4px"]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="OFF_WHITE"
        style={{
          borderRadius: "4px",
          minWidth: "1rem",
          backgroundColor: RISK_LEVEL_TEMPLATES["LOW"].backgroundColor,
        }}
      ></Span>
      <LayoutContainer mr={1}>Low {pluralize("risks", LOW)}</LayoutContainer>
      <LayoutContainer ml="auto" textAlign="right">
        {kFormatter(LOW)}
      </LayoutContainer>
    </NoWrapLayoutContainer>
  </LayoutContainer>
);
