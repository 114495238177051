import { gql } from "urql";

import {
  ManageUsersListDocument,
  ManageUsersListQuery,
} from "../../features/ManageUsersConnectionTable/query.generated";
import {
  GraphCacheUpdaters,
  RootQueryTypeListAccountMembershipsArgs,
} from "../../generated/graphql.generated";
import { getMaybeArrayValue } from "../../utils/maybe/defaultTo";

export const updates: GraphCacheUpdaters = {
  Mutation: {
    markRiskAsResolved(_result, args, cache) {
      cache.invalidate(`RiskEdge:${args.id}`);
      cache.invalidate("RootQueryType", "getSDFTotal");
    },
    deleteMonitor(_result, args, cache, _info) {
      const id = args.id;

      if (id) {
        cache.invalidate({
          __typename: "Monitor",
          id,
        });
      }
    },
    updateAlertChannel(_result, args, cache) {
      cache.invalidate("RootQueryType", "listAlertChannels");
    },
    createAlertChannel(_result, args, cache) {
      cache.invalidate("RootQueryType", "listAlertChannels");
    },
    updateWebhookConfiguration(_result, args, cache, _info) {
      cache.invalidate("RootQueryType", "webhookConfiguration");
    },
    createMonitor(_result, args, cache, _info) {
      cache.invalidate("RootQueryType", "listMonitors");
    },
    inviteUser(_result, args, cache) {
      const accountMembershipsDoc = ManageUsersListDocument;

      cache
        .inspectFields("RootQueryType")
        .filter((field) => field.fieldName === "listAccountMemberships")
        .forEach((field) => {
          const argsCast =
            field.arguments as RootQueryTypeListAccountMembershipsArgs;

          cache.updateQuery(
            {
              query: accountMembershipsDoc,
              variables: {
                afterCursor: argsCast.pagination?.cursor,
              },
            },
            (data) => {
              const castData = data as ManageUsersListQuery;
              if (castData.listAccountMemberships) {
                castData.listAccountMemberships.edges = [
                  ...getMaybeArrayValue(
                    castData?.listAccountMemberships?.edges
                  ),
                  {
                    node: _result.inviteUser,
                    __typename: "AccountMembershipEdge",
                  },
                ];
              }

              return castData as any;
            }
          );
        });
    },
    suspendUser(_result, args, cache) {
      const { id } = args;
      cache.invalidate(`AccountMembershipEdge:${id}`);
    },
    createQuery(_result, args, cache) {
      cache.invalidate("RootQueryType", "listQueries");
    },
    createGroup(_result, args, cache) {
      cache.invalidate("RootQueryType", "listGroups");
    },
    removeGroup(_result, args, cache) {
      const id = args.id;

      if (id) {
        cache.invalidate({
          __typename: "Group",
          id,
        });
      }
    },
    insertActorsIntoGroups(_result, args, cache) {
      const actorIds = args.actorIds;
      const groupIds = args.groupIds;

      try {
        if (!actorIds || !groupIds) return;

        actorIds.forEach((actorId) => {
          const actorGroups = cache.readFragment(
            gql`
              fragment _ on Actor {
                id
                groups {
                  id
                  name
                }
              }
            `,
            { id: actorId }
          );

          const uniqueGroupIds = new Set([...actorGroups.groups, groupIds]);

          const linkedUniqueEntities = [...uniqueGroupIds].map((id) =>
            cache.keyOfEntity({
              __typename: "Group",
              id: id,
            })
          );

          if (actorId) {
            cache.link(
              cache.keyOfEntity({
                __typename: "Actor",
                id: actorId,
              }),
              "groups",
              linkedUniqueEntities
            );
          }
        });
      } catch (error) {
        console.error("Something went wrong inserting items into cache");
      }
    },
    bulkCreateRiskAction(_result, args, cache) {
      args.actions?.forEach((action) => {
        const fragment = gql`
          fragment IsDirtyRiskFragment on Risk {
            id
            isDirty
          }
        `;

        cache.writeFragment(fragment, { id: action?.riskId, isDirty: true });
      });

      const dirtyRiskCountFragment = gql`
        fragment DirtyRiskCountFragment on DirtyRiskCount {
          total
        }
      `;

      cache.writeFragment(dirtyRiskCountFragment, {
        id: "dirtyRiskCount",
        total: args.actions?.length || 0,
      });
    },
    updateQuery(_result, args, cache) {
      cache.invalidate("RootQueryType", "listQueries");
    },
    deleteQuery(_result, args, cache) {
      cache.invalidate("RootQueryType", "listQueries");
    },
    membershipAcceptInvitation(_result, args, cache) {
      cache.invalidate("RootQueryType", "me");
    },
    demoResolveViolation(_result, args, cache) {
      cache.invalidate("RootQueryType", "listMonitors");
      cache.invalidate("RootQueryType", "violationSummary");

      const id = args.id;

      if (
        args.resolveAllViolationsInEntity &&
        _result.demoResolveViolation?.idsToInvalidate
      ) {
        _result.demoResolveViolation.idsToInvalidate.forEach(
          (idToInvalidate) => {
            cache.invalidate(`MonitorViolationEdge:${idToInvalidate}`);
          }
        );
      } else {
        cache.invalidate(`MonitorViolationEdge:${id}`);
      }
    },
    cancelBulkAction(_result, args, cache) {
      const id = args.id;

      if (id) {
        cache.invalidate(`BulkActionEdge:${id}`);
      }
    },
    modifyGDrivePermissions(_result, args, cache, info) {
      cache.invalidate("RootQueryType", "listMonitors");
      cache.invalidate("RootQueryType", "violationSummary");

      const id = args.violationId;
      cache.invalidate(`MonitorViolationEdge:${id}`);

      if (
        args.resolveAllViolationsInFile &&
        _result.modifyGDrivePermissions?.idsToInvalidate
      ) {
        _result.modifyGDrivePermissions.idsToInvalidate.forEach(
          (idToInvalidate) => {
            cache.invalidate(`MonitorViolationEdge:${idToInvalidate}`);
          }
        );
      } else {
        cache.invalidate(`MonitorViolationEdge:${id}`);
      }
    },
    modifyGDrivePermissionsForRisk(_result, args, cache) {
      cache.invalidate("RootQueryType", "getSDFTotal");
      cache.invalidate("RootQueryType", "listRisks");
      const id = _result.modifyGDrivePermissionsForRisk?.atomId;

      if (id) {
        cache.invalidate(`LabelledAtom:${id}`);
      }
    },
    updateRuleActionNotifyOwner(_result, args, cache) {
      if (_result.updateRuleActionNotifyOwner && args.ruleId) {
        cache.link(
          cache.keyOfEntity({
            __typename: "MonitorActions",
            id: args.ruleId,
          }),
          "notifyOwner",
          _result.updateRuleActionNotifyOwner
        );
      }
    },
    updateRuleActionAlertAdmin(_result, args, cache) {
      if (_result.updateRuleActionAlertAdmin && args.ruleId) {
        cache.link(
          cache.keyOfEntity({
            __typename: "MonitorActions",
            id: args.ruleId,
          }),
          "alertAdmin",
          _result.updateRuleActionAlertAdmin
        );
      }
    },
    updateRuleActionRedact(_result, args, cache) {
      if (_result.updateRuleActionRedact && args.ruleId) {
        cache.link(
          cache.keyOfEntity({
            __typename: "MonitorActions",
            id: args.ruleId,
          }),
          "redact",
          _result.updateRuleActionRedact
        );
      }
    },
    updateRuleActionRevokeFilePermissions(_result, args, cache) {
      if (args.ruleId && _result.updateRuleActionRevokeFilePermissions) {
        cache.link(
          cache.keyOfEntity({
            __typename: "MonitorActions",
            id: args.ruleId,
          }),
          "reduceExposure",
          _result.updateRuleActionRevokeFilePermissions
        );
      }
    },
  } as GraphCacheUpdaters["Mutation"],
};
