import { useMemo } from "react";

import { MaybeTIncomingRelay } from "../../urql/pagination/definitions/definitions";

export type UnknownTransformDef<T extends MaybeTIncomingRelay<any, any>> = (
  data: T
) => any;

interface IUsePaginatedTableData<
  T extends MaybeTIncomingRelay<any, any>,
  F extends UnknownTransformDef<T>
> {
  data: T;
  transformer: F;
}

interface IUsePaginatedTableDataReturnType<T> {
  rows: T;
  hasNextPage: boolean;
  endCursor: string;
}

export const usePaginatedTableData = <
  T extends MaybeTIncomingRelay<any, any>,
  F extends UnknownTransformDef<T>
>({
  data,
  transformer,
}: IUsePaginatedTableData<T, F>): IUsePaginatedTableDataReturnType<
  ReturnType<typeof transformer>
> => {
  const edges = data?.edges;
  const hasNextPage = !!data?.pageInfo?.hasNextPage;
  const endCursor = data?.pageInfo?.endCursor || "";

  return {
    hasNextPage,
    endCursor,
    // You should treat the transformer function as pure, do not attempt to pass in variables from the enclosing function.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    rows: useMemo(() => transformer(data), [edges, data]),
  };
};
