import React, { FunctionComponent } from "react";

import { LocationCard } from "../../components/LocationCard";
import { useGetLocationSummaryForAtomQuery } from "../../operations/atom/queries/getLocationSummaryForAtom.generated";

interface IAtomLocationCard {
  atomId: string;
  popover?: boolean;
}

export const AtomLocationCard: FunctionComponent<IAtomLocationCard> = ({
  atomId,
  popover,
}) => {
  const [result] = useGetLocationSummaryForAtomQuery({
    variables: { id: atomId },
  });

  return (
    <LocationCard
      isInPopover={popover}
      loading={result.fetching}
      {...result.data?.getLabelledAtomById}
    />
  );
};
