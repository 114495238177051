import { Dispatch, SetStateAction } from "react";

import { ConfigNodes } from "../../../../definitions/types/integration";
import { IntegrationCopyBox } from "../components/IntegrationCopyBox";
import { IntegrationNodeHeader } from "../components/IntegrationNodeHeader";
import { IntegrationNodeImage } from "../components/IntegrationNodeImage";
import { IntegrationNodeParagraph } from "../components/IntegrationNodeParagraph";
import { IntegrationFormFactory } from "./IntegrationFormFactory";

export const IntegrationNodeFactory = (
  node: ConfigNodes,
  setDeploymentData: Dispatch<SetStateAction<Record<string, any>>>,
  stepPayload: Record<string, any>
): JSX.Element | null => {
  switch (node.type) {
    case "paragraph":
      return <IntegrationNodeParagraph key={node.id} {...node} />;
    case "image":
      return <IntegrationNodeImage key={node.id} {...node} />;
    case "section-header":
      return <IntegrationNodeHeader key={node.id} {...node} />;
    case "section":
      return (
        <div>
          {node.config.nodes.map((nestedNode) =>
            IntegrationNodeFactory(nestedNode, setDeploymentData, stepPayload)
          )}
        </div>
      );
    case "copy_box":
      return <IntegrationCopyBox key={node.id} {...node} />;

    case "input":
      return IntegrationFormFactory(node, setDeploymentData, stepPayload);
    default:
      return null;
  }
};
