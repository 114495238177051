import React, { FunctionComponent } from "react";

import { IUser } from "../../definitions/types/users";
import { ActorIcon } from "../ActorIcon";
import { LayoutContainer } from "../layout/LayoutContainer";
import { ContextMenu } from "./ContextMenu";

export const UserProfileBlock: FunctionComponent<
  IUser & { className?: string }
> = ({ avatarUrl, nickname, className }) => (
  <LayoutContainer
    as="section"
    display="flex"
    color="BODY"
    minWidth="130px"
    className={className}
  >
    <LayoutContainer display="flex" alignItems="center" justifyContent="center">
      <ActorIcon imageUrl={avatarUrl} displayName={nickname} />
      <LayoutContainer ml={1}>{nickname}</LayoutContainer>
    </LayoutContainer>
    <LayoutContainer fontSize="sm" display="flex" alignItems="center">
      <ContextMenu />
    </LayoutContainer>
  </LayoutContainer>
);
