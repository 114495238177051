import React, { useState } from "react";

import styled from "styled-components";

import { Box } from "../../components/Box";
import { FeedbackBar } from "../../components/FeedbackBar";
import { LocationContextPill } from "../../components/LocationContextPill";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import RiskFeedbackModal from "../../components/modals/RiskFeedbackModal";
import { VENDORS } from "../../definitions/types/vendors";
import { Risk } from "../../generated/graphql.generated";
import { useExtendedMutation } from "../../operations/hooks/useExtendedMutation";
import { LEAVE_SDF_FEEDBACK } from "../../operations/sdf/mutations/leaveSdfFeedback/leaveSdfFeedback";
import { useLeaveSdfFeedbackForRiskMutation } from "../../operations/sdf/mutations/leaveSdfFeedback/leaveSdfFeedback.generated";
import { SPECIFIC_SPACING } from "../../styling/spacing";
import { defaultTo } from "../../utils/maybe/defaultTo";
import { AtomExposurePanel } from "../AtomExposurePanel";
import { AtomLocationCard } from "../AtomLocationCard";
import { RiskDatapointCard } from "../RiskDatapointCard";
import { ContextMenu } from "./ContextMenu";

const StyledFeedbackBar = styled(FeedbackBar)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 10;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
`;

export const RiskDetailPanel = ({
  selectedRisk,
  clearSelectedRow,
  hideTopBar,
}: {
  selectedRisk?: Risk;
  hideTopBar?: boolean;
  clearSelectedRow: () => void;
}) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const getSdfFeedbackForRisk = useExtendedMutation(
    useLeaveSdfFeedbackForRiskMutation,
    {
      initialState: {},
      definition: LEAVE_SDF_FEEDBACK,
    }
  );

  const { success } = getSdfFeedbackForRisk.run();

  return selectedRisk ? (
    <>
      <LayoutContainer
        minHeight="100%"
        pb={8}
        backgroundColor="INSET_SPLIT_BACKGROUND"
      >
        {!hideTopBar && (
          <LayoutContainer
            position="absolute"
            top={0}
            width="100%"
            pt={SPECIFIC_SPACING["12px"]}
            pb={SPECIFIC_SPACING["12px"]}
            mb={2}
            px={2}
            backgroundColor="INPUT_BACKGROUND"
            borderBottom="TABLE_BORDER"
            borderBottomWidth={1}
            zIndex={11}
            display="flex"
            alignItems="center"
          >
            <LayoutContainer mr="auto">
              <LayoutContainer display="flex" alignItems="center">
                <LayoutContainer mr={2} color="PLACEHOLDER" fontSize="xsm">
                  {selectedRisk.shortCode}
                </LayoutContainer>
                {selectedRisk.atom?.location && (
                  <LocationContextPill
                    disablePopover
                    atomId={defaultTo(selectedRisk.atomId, "")}
                    location={{
                      appType: selectedRisk.atom?.location.appType as VENDORS,
                      temp_canonicalLabel: defaultTo(
                        selectedRisk.atom?.location.label,
                        ""
                      ),
                      temp_entityDeeplink: defaultTo(
                        selectedRisk.atom?.location.deeplink,
                        ""
                      ),
                      entityType: defaultTo(
                        selectedRisk.atom?.location.entityType,
                        ""
                      ),
                    }}
                  />
                )}
              </LayoutContainer>
            </LayoutContainer>
            <ContextMenu
              selectedRisk={selectedRisk}
              clearSelectedRow={clearSelectedRow}
            />
          </LayoutContainer>
        )}
        <LayoutContainer px={2} pt={hideTopBar ? 2 : 10}>
          {hideTopBar && (
            <Box
              position="sticky"
              top={0}
              width="100%"
              pt={SPECIFIC_SPACING["12px"]}
              pb={SPECIFIC_SPACING["12px"]}
              mb={2}
              px={2}
              zIndex={11}
              display="flex"
              alignItems="center"
            >
              <LayoutContainer mr="auto">
                <LayoutContainer display="flex" alignItems="center">
                  <LayoutContainer mr={2} color="PLACEHOLDER" fontSize="xsm">
                    {selectedRisk.shortCode}
                  </LayoutContainer>
                  {selectedRisk.atom?.location && (
                    <LocationContextPill
                      disablePopover
                      atomId={defaultTo(selectedRisk.atomId, "")}
                      location={{
                        appType: selectedRisk.atom?.location.appType as VENDORS,
                        temp_canonicalLabel: defaultTo(
                          selectedRisk.atom?.location.label,
                          ""
                        ),
                        temp_entityDeeplink: defaultTo(
                          selectedRisk.atom?.location.deeplink,
                          ""
                        ),
                        entityType: defaultTo(
                          selectedRisk.atom?.location.entityType,
                          ""
                        ),
                      }}
                    />
                  )}
                </LayoutContainer>
              </LayoutContainer>
              <ContextMenu
                selectedRisk={selectedRisk}
                clearSelectedRow={clearSelectedRow}
              />
            </Box>
          )}
          <AtomExposurePanel
            riskId={defaultTo(selectedRisk.id, "")}
            atomId={defaultTo(selectedRisk.atomId, "")}
          />
          <LayoutContainer mb={2}>
            <AtomLocationCard atomId={defaultTo(selectedRisk.atomId, "")} />
          </LayoutContainer>
          <LayoutContainer mb={2}>
            <RiskDatapointCard
              id={defaultTo(selectedRisk.id, "")}
              location={selectedRisk.atom?.location}
              showDetectionList
            />
          </LayoutContainer>
        </LayoutContainer>
      </LayoutContainer>
      <StyledFeedbackBar
        successLoading={getSdfFeedbackForRisk.fetching}
        onSuccessClick={() => {
          success({ riskId: defaultTo(selectedRisk.id, "") });
        }}
        onRejectClick={() => {
          setShowFeedbackModal(true);
        }}
        text="Do you want to see more events like this?"
      />
      <RiskFeedbackModal
        riskId={defaultTo(selectedRisk.id, "")}
        showModal={showFeedbackModal}
        toggleModal={() => setShowFeedbackModal((s) => !s)}
      />
    </>
  ) : (
    <LayoutContainer
      minHeight="100%"
      pb={2}
      pt={2}
      backgroundColor="INSET_SPLIT_BACKGROUND"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="LABEL"
    >
      Select an event to reveal more details
    </LayoutContainer>
  );
};
