import React, { PropsWithChildren, useEffect } from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import {
  MOBILE_BREAKPOINT,
  useMediaQuery,
} from "../../../utils/responsive/useMediaQuery";
import { LoadingSpinner } from "../../loading/LoadingSpinner";
import { TableProvider, useTableContext } from "./TableProvider";
import { NonVirtualisedTableInner } from "./components/NonVirtualisedInner";
import { VirtualisedTableInner } from "./components/VirtualisedInner";
import {
  DefaultRowType,
  InnerTableType,
  NoExternalProviderTable,
} from "./definitions";

export const EXPANDED_CELL_INDICATOR_ID = "expander";
export const CONTEXT_MENU_ID_BOTTOM = "context-menu-button";
export const CONTEXT_MENU_ID_DOTS = "context-menu-dots";

const StyledTable = styled.div<{
  theme?: FlattenSimpleInterpolation;
  themeOverrides?: FlattenSimpleInterpolation;
  columnOverrides?: FlattenSimpleInterpolation;
  selectedRowStyles?: FlattenSimpleInterpolation;
  selectedRowId?: string;
}>`
  ${({ theme }) => theme};
  ${({ themeOverrides }) => themeOverrides};
  ${({ columnOverrides }) => columnOverrides};
  ${({ selectedRowId, selectedRowStyles }) =>
    selectedRowId &&
    selectedRowStyles &&
    css`
      [data-performant-styling-id="${selectedRowId}"] {
        ${selectedRowStyles}
      }
    `};
`;
const StyledHeader = styled.div<{
  theme?: FlattenSimpleInterpolation;
  themeOverrides?: FlattenSimpleInterpolation;
  columnOverrides?: FlattenSimpleInterpolation;
}>`
  ${({ theme }) => theme};
  ${({ themeOverrides }) => themeOverrides};
  ${({ columnOverrides }) => columnOverrides};
`;

const StyledHeaderContainer = styled.div<{
  theme?: FlattenSimpleInterpolation;
  themeOverrides?: FlattenSimpleInterpolation;
  columnOverrides?: FlattenSimpleInterpolation;
}>`
  ${({ theme }) => theme};
  ${({ themeOverrides }) => themeOverrides};
  ${({ columnOverrides }) => columnOverrides};
`;

const StyledFullHeightLoadingContainer = styled(LoadingSpinner)`
  padding: 1.75rem;
  height: 100%;
`;

export const ContextFedTableRenderer = <T extends DefaultRowType = {}>(
  props: PropsWithChildren<InnerTableType<T>>
) => {
  const isLargeLayout = useMediaQuery(MOBILE_BREAKPOINT);
  const isMobileLayout = !isLargeLayout;

  const {
    virtualised,
    onRowSelected,
    onTableScrolled,
    canInfiniteScroll,
    onScrollEnd,
    loading,
    selectedRowId,
    theme,
    themeOverrides,
  } = props;

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: Boolean(loading),
    hasNextPage: Boolean(canInfiniteScroll),
    onLoadMore: () => {
      if (onScrollEnd) {
        onScrollEnd();
      }
    },
    rootMargin: "0px 0px 400px 0px",
  });

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    onVirtualisedItemsChange,
  } = useTableContext<T>();

  useEffect(() => {
    if (onRowSelected) {
      onRowSelected(selectedFlatRows);
    }
  }, [onRowSelected, selectedFlatRows]);

  return (
    <StyledTable
      selectedRowId={selectedRowId}
      theme={theme.table}
      themeOverrides={themeOverrides?.table}
      selectedRowStyles={themeOverrides?.selectedRowStyles}
      ref={rootRef}
      {...(!isMobileLayout ? getTableProps() : {})}
      onScroll={() => {
        onTableScrolled && onTableScrolled();
      }}
      style={{
        width: "100%",
      }}
    >
      {!isMobileLayout && (
        <StyledHeaderContainer
          theme={theme.headerContainer}
          themeOverrides={themeOverrides?.headerContainer}
        >
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <StyledHeader
                  theme={theme.header}
                  themeOverrides={themeOverrides?.header}
                  columnOverrides={column.headerStyles}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </StyledHeader>
              ))}
            </div>
          ))}
        </StyledHeaderContainer>
      )}
      {virtualised ? (
        <VirtualisedTableInner
          isMobileLayout={isMobileLayout}
          {...props}
          prepareRow={prepareRow}
          rows={rows}
          onVirtualisedItemsChange={onVirtualisedItemsChange}
        />
      ) : (
        <NonVirtualisedTableInner
          {...props}
          isMobileLayout={isMobileLayout}
          prepareRow={prepareRow}
          rows={rows}
        >
          {canInfiniteScroll && (
            <div ref={sentryRef}>
              <StyledFullHeightLoadingContainer />
            </div>
          )}
        </NonVirtualisedTableInner>
      )}
    </StyledTable>
  );
};

export const TableRenderer = <T extends DefaultRowType = {}>(
  props: PropsWithChildren<NoExternalProviderTable<T>>
) => {
  const {
    columns,
    autoResetExpanded = false,
    data,
    SelectableContextMenu,
    selectable,
    canSelectAllRows,
    onVirtualisedItemsChange,
  } = props;

  const providerProps = {
    columns,
    autoResetExpanded,
    data,
    SelectableContextMenu,
    selectable,
    canSelectAllRows,
    onVirtualisedItemsChange,
  };

  return (
    <TableProvider {...providerProps}>
      <ContextFedTableRenderer {...props} />
    </TableProvider>
  );
};
