import React, { FunctionComponent } from "react";

import { css } from "styled-components";

import { FullWidthTextButton } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { GenericTable } from "../../components/tables/GenericTable";
import { IGenericTable } from "../../components/tables/GenericTable/definitions";
import { BulkAction } from "../../generated/graphql.generated";

export interface IActionHistoryTable {
  className?: string;
  onRowClick?: (data: BulkAction) => void;
  selectedRowId?: string;
}

type ITable = IGenericTable & {
  heading: string;
  className?: string;
  onNextPage: () => void;
  hasNextPage?: boolean;
  tableLoading?: boolean;
  buttonLoading?: boolean;
};

export const selectedRowStyles = {
  selectedRowStyles: css`
    background-color: #edeef5;
  `,
};

export const Table: FunctionComponent<ITable> = (props) => {
  const {
    heading,
    className,
    onNextPage,
    tableLoading,
    buttonLoading,
    hasNextPage,
    ...rest
  } = props;

  return (
    <LayoutContainer className={className}>
      <LayoutContainer p={3}>
        <Heading Element="h2" type="text-md-bold" isBodyColor>
          {heading}
        </Heading>
      </LayoutContainer>
      <GenericTable loading={tableLoading} {...rest} />
      {hasNextPage && (
        <FullWidthTextButton loading={buttonLoading} onClick={onNextPage}>
          Show More
        </FullWidthTextButton>
      )}
    </LayoutContainer>
  );
};
