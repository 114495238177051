import React, { FunctionComponent } from "react";

import { File, Folder, Hash, MessageSquare, Paperclip } from "react-feather";

import { defaultTo } from "../../utils/maybe/defaultTo";

interface IEntityTypeIconFactory {
  type: string;
  size?: number;
  color?: string;
}

const ICON_MAP: {
  [key: string]: (size?: number, color?: string) => JSX.Element;
} = {
  File: (size, color) => <File size={defaultTo(size, 16)} color={color} />,
  Message: (size, color) => (
    <MessageSquare size={defaultTo(size, 16)} color={color} />
  ),
  Folder: (size, color) => <Folder size={defaultTo(size, 16)} color={color} />,
  Channel: (size, color) => <Hash size={defaultTo(size, 16)} color={color} />,
  Attachment: (size, color) => (
    <Paperclip size={defaultTo(size, 16)} color={color} />
  ),
};

const getIcon = ({ size, type, color }: IEntityTypeIconFactory) => {
  return ICON_MAP[type]?.(size, color) ?? <File size={size} color={color} />;
};

export const EntityTypeIconFactory: FunctionComponent<
  IEntityTypeIconFactory
> = (args) => getIcon(args);
