import { TIncomingRelay } from "../../../urql/pagination/definitions/definitions";

export const useConnectionData = <N, E>(data?: TIncomingRelay<N, E>) => {
  const edges = data?.edges;
  const hasNextPage = !!data?.pageInfo?.hasNextPage;
  const endCursor = data?.pageInfo?.endCursor || "";
  const isFirstLoad = !data?.pageInfo;
  const hasData = Boolean(data);

  return {
    hasData,
    edges,
    hasNextPage,
    endCursor,
    isFirstLoad,
  };
};
