import React, { FunctionComponent } from "react";

import { IActionHistoryTable, selectedRowStyles, Table } from ".";
import { TABLE_THEMES } from "../../components/tables/themes";
import { BulkAction } from "../../generated/graphql.generated";
import { useGetRiskActionHistoryCompletedQuery } from "../../operations/risks/queries/getRiskActionHistoryCompleted.generated";
import { usePollPaginatedTableList } from "../../utils/table/pollPaginatedTableList";
import { usePaginatedTableData } from "../../utils/table/usePaginatedTableData";
import { usePaginatedTableState } from "../../utils/table/useTableState";
import { columns } from "../BulkActions/columns";

export const BulkActionHistoryTableCompleted: FunctionComponent<
  IActionHistoryTable
> = ({ onRowClick, selectedRowId }) => {
  const [tableState, setTableState] = usePaginatedTableState({
    cursor: "",
    shouldShowFullLoader: true,
    limit: 10,
  });

  const [result, executeQuery] = useGetRiskActionHistoryCompletedQuery({
    variables: {
      cursor: tableState.cursor,
      limit: tableState.limit,
    },
    requestPolicy: "network-only",
  });

  const { rows, hasNextPage, endCursor } = usePaginatedTableData({
    data: result.data?.listCompletedBulkActions,
    transformer: (data) =>
      data?.edges?.map((edge) => {
        const node = edge?.node;

        return {
          ...(node as BulkAction),
        };
      }) || [],
  });

  usePollPaginatedTableList({
    tableStateGetterSetter: [tableState, setTableState],
    initialPageSize: 10,
    numberOfResults: rows.length,
    frequency: 5000,
    runQuery: executeQuery,
  });

  return (
    <Table
      heading="Completed actions"
      theme={TABLE_THEMES.SPACED_HEADER_ROW_TABLE}
      tableLoading={result.fetching && tableState.shouldShowFullLoader}
      buttonLoading={result.fetching}
      columns={columns}
      data={rows}
      hasNextPage={hasNextPage}
      selectedRowId={selectedRowId}
      themeOverrides={selectedRowStyles}
      onNextPage={() => {
        setTableState((s) => ({
          ...s,
          cursor: endCursor,
          limit: 10,
          shouldShowFullLoader: false,
        }));
      }}
      useExternalContext={false}
      onRowClick={onRowClick}
    />
  );
};
