import React, { FunctionComponent } from "react";

import { generatePath } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useInitialUserState } from "../../operations/user/hooks/initialUserState";
import { routePermittedByPermission } from "../../operations/user/permissions";
import { ApplicationRoute } from "../../routing/routes";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { useRoutePermittedByFeatureFlag } from "../../utils/feature-flags/hooks/useRoutePermittedByFeatureFlag";
import { getMaybeArrayValue } from "../../utils/maybe/defaultTo";

export interface ITabNavigation {
  className?: string;
  routes: {
    [key: string]: ApplicationRoute;
  };
}

const StyledNavLink = styled(NavLink)`
  align-items: center;
  display: flex;
  margin-right: ${APPLICATION_SPACING(2)};
  padding-bottom: ${APPLICATION_SPACING(1)};
  color: rgba(160, 163, 189, 1);
  font-size: ${theme.fontSizes.xsm};
  border-bottom-width: 1px;
  border-color: transparent;

  &:hover {
    color: rgba(88, 70, 155, 1);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  border-bottom-width: 1px;
  border-color: rgba(255, 255, 255, 0.3);
  font-size: ${theme.fontSizes.md};
  padding: 0 ${APPLICATION_SPACING(3)};
  margin-bottom: -2px;
`;

export const TabNavigation: FunctionComponent<ITabNavigation> = ({
  routes,
  className,
}) => {
  const featureFlagRouteFilter = useRoutePermittedByFeatureFlag();
  const { user } = useInitialUserState();
  const routeDefinitions = Object.values(routes);

  const nestedRouteMatch = useRouteMatch(
    routeDefinitions ? routeDefinitions?.map((route) => route.url) : ""
  );

  return (
    <nav className={className}>
      <StyledContainer>
        {routeDefinitions!
          .filter((route) => !route.hideTab)
          .filter(
            routePermittedByPermission(getMaybeArrayValue(user?.permissions))
          )
          .filter(featureFlagRouteFilter)
          .map((route) => (
            <StyledNavLink
              key={route.url}
              to={generatePath(route.url, nestedRouteMatch?.params)}
              activeStyle={{
                borderColor: COLOR.PRIMARY,
                color: COLOR.PRIMARY,
              }}
            >
              {route.displayName}
            </StyledNavLink>
          ))}
      </StyledContainer>
    </nav>
  );
};
