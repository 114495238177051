import { FunctionComponent } from "react";

import { Root as Group, Item, Indicator } from "@radix-ui/react-radio-group";
import styled from "styled-components";

import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { LayoutContainer } from "../layout/LayoutContainer";

export interface IRadioGroup {
  onChange: (value: string) => void;
  options: { value: string; label: string; id?: string }[];
  name: string;
}

const StyledRadio = styled(Item)`
  all: unset;
  background-color: ${COLOR.TITLE_FLIP};
  min-width: 20px;
  min-height: 20px;
  border-radius: 100%;
  position: relative;
  border: 1px solid #d6d8e7;
`;

const StyledIndicator = styled(Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #22A6B3;
    top: -3px;
    bottom: 0;
    right: 0;
    left: -3px;
  },

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    top: 2px;
    bottom: 0;
    right: 0;
    left: 2px;
  },
`;

const StyledRadioLabel = styled.label`
  font-size: ${theme.fontSizes.sm};
  margin-left: ${APPLICATION_SPACING(1)};
`;

export const RadioGroup: FunctionComponent<IRadioGroup> = ({
  onChange,
  name,
  options,
}) => {
  return (
    <Group aria-label={name} onValueChange={onChange}>
      {options.map(({ id, value, label }) => (
        <LayoutContainer
          key={id ?? value}
          px={1}
          pb={2}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <StyledRadio value={value} id={id ?? value}>
            <StyledIndicator />
          </StyledRadio>
          <StyledRadioLabel htmlFor={value}>{label}</StyledRadioLabel>
        </LayoutContainer>
      ))}
    </Group>
  );
};
