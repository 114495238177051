import React, { PropsWithChildren } from "react";

import styled from "styled-components";

import { COLOR } from "../../../styling/colors";
import { APPLICATION_SPACING } from "../../../styling/spacing";
import theme from "../../../styling/theme";
import { LoadingSpinner } from "../../loading/LoadingSpinner";
import { TableProvider, useTableContext } from "./TableProvider";
import { ContextFedTableRenderer } from "./TableRenderer";
import { DefaultNoResults } from "./components/DefaultNoResults";
import { IGenericTable } from "./definitions";

// TODO: This is now a monster, also, performant styling hacks in here for the big SDF table, we should replace this with a way that is able to pass that id down with context

export const EXPANDED_CELL_INDICATOR_ID = "expander";
export const CONTEXT_MENU_ID_BOTTOM = "context-menu-button";
export const CONTEXT_MENU_ID_DOTS = "context-menu-dots";

const StyledErrorContainer = styled.div`
  padding: ${APPLICATION_SPACING(4)};
  font-size: ${theme.fontSizes.md};
  border-color: ${COLOR.ERROR};
  border-width: 1px;
  color: ${COLOR.ERROR};
  margin-top: ${APPLICATION_SPACING(3)};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledLoadingContainer = styled(LoadingSpinner)`
  padding: 1.75rem;
  height: 100%;
`;

export function LoadingGate<T extends object = {}>(
  props: PropsWithChildren<IGenericTable<T>>
) {
  const { rows } = useTableContext<T>();

  const { loading, error, noResults, canInfiniteScroll, loadingSpinner } =
    props;

  if (loading && !canInfiniteScroll) {
    return loadingSpinner ? loadingSpinner : <StyledLoadingContainer />;
  }

  if (error) {
    return <StyledErrorContainer>{`${error}`}</StyledErrorContainer>;
  }

  if (!rows || !rows.length) {
    return !noResults || typeof noResults === "string" ? (
      <DefaultNoResults message={noResults} />
    ) : (
      noResults
    );
  }

  return <ContextFedTableRenderer {...props} />;
}

export const GenericTable = <T extends object = {}>(
  props: PropsWithChildren<IGenericTable<T>>
) => {
  const {
    columns = [],
    autoResetExpanded = false,
    data = [],
    SelectableContextMenu,
    selectable,
    canSelectAllRows,
    useExternalContext,
    stopCheckboxPropagation,
  } = props;

  const providerProps = {
    columns,
    autoResetExpanded,
    data,
    SelectableContextMenu,
    selectable,
    canSelectAllRows,
    stopCheckboxPropagation,
  };

  return !useExternalContext ? (
    <TableProvider {...providerProps}>
      <LoadingGate {...props} />
    </TableProvider>
  ) : (
    <LoadingGate {...props} />
  );
};
