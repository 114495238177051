import React, { useState, useCallback } from "react";

import { Lens } from "@metomic/darwin-shared-lib/dist/shared-lib/filters/lensing/Lens";
import { NodeTypes } from "@metomic/darwin-shared-lib/dist/shared-lib/filters/lensing/filter.types";
import styled, { css } from "styled-components";
import { useQuery } from "urql";

import { Heading } from "../../atoms/Heading";
import { GET_ME_QUERY } from "../../components/Navigation/queries";
import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { GenericTable } from "../../components/tables/GenericTable";
import { TABLE_THEMES } from "../../components/tables/themes";
import { IDetection } from "../../definitions/types/detection";
import { canReadDeepLink } from "../../operations/user/permissions";
import { APPLICATION_SPACING } from "../../styling/spacing";
import theme from "../../styling/theme";
import { TIncomingRelay } from "../../urql/pagination/definitions/definitions";
import { useConnectionData } from "../../utils/pagination/hooks/useConnectionData";
import { IDetectionWithContextRow } from "../DetectionSearch/columns";
import { atomDetectionCardColumns } from "./columns";
import { ATOM_DETECTION_SEARCH_QUERY } from "./query";

interface ISearchVariables {
  filters?: Lens<NodeTypes.all>;
  isNestedTable?: boolean;
}

const tableThemeOverrides = {
  table: css`
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 0;
  `,
  headerContainer: css`
    display: none;
  `,
  row: css`
    border-top: 0;
    border-bottom: 0;
    padding: 0.5rem 1.3rem;
    padding-left: 1.85rem; // brings the redaction text visually in line with the donut
  `,
};

const StyledHeading = styled(Heading)`
  color: ${theme.colors.LABEL};
  margin-bottom: ${APPLICATION_SPACING(1)};
`;

export const AtomDetectionList = ({
  filters,
  isNestedTable,
}: ISearchVariables) => {
  const [afterCursor, setAfterCursor] = useState("");
  const searchVariables = {
    filters,
    limit: 10,
  };

  const [response] = useQuery({ query: GET_ME_QUERY });

  const { data, fetching } = response;

  const user = data?.me;

  const permissions = user?.permissions;

  const userCanReadDeepLink = canReadDeepLink(permissions);

  const [result] = useQuery<{
    detectionSearch: TIncomingRelay<IDetection, {}>;
  }>({
    requestPolicy: "network-only",
    query: ATOM_DETECTION_SEARCH_QUERY,
    variables: {
      ...searchVariables,
      filters: filters?.toJSON(),
      afterCursor,
      canReadDeepLink: userCanReadDeepLink,
      canUseDetectionActions: true,
      pause: fetching,
    },
  });

  const { hasData, edges, hasNextPage, endCursor, isFirstLoad } =
    useConnectionData(result.data?.detectionSearch);

  const onScrollEnd = useCallback(() => {
    if (!result.fetching && hasNextPage) {
      setAfterCursor(endCursor);
    }
  }, [endCursor, result.fetching, hasNextPage]);

  if (fetching) {
    return null;
  }

  const results = edges?.map((edge) => ({
    ...edge.node,
  }));

  return (
    <GenericTable
      noResults={
        <LayoutContainer
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          p={4}
        >
          <div>
            <StyledHeading Element="h1" type="display-small">
              No previous detections found
            </StyledHeading>
          </div>
        </LayoutContainer>
      }
      loading={(result.fetching || !hasData) && isFirstLoad}
      data={results as IDetectionWithContextRow[]}
      error={result.error}
      columns={atomDetectionCardColumns}
      theme={TABLE_THEMES.BORDER_ROW_TABLE}
      themeOverrides={tableThemeOverrides}
      onScrollEnd={onScrollEnd}
      canInfiniteScroll={hasNextPage}
    />
  );
};
