import React, { FunctionComponent, HTMLAttributes } from "react";

import styled from "styled-components";

import {
  RiskLevel,
  RISK_LEVEL_TEMPLATES,
} from "../../definitions/types/risk-level";
import { MonitorRiskLevel } from "../../generated/graphql.generated";
import { COLOR } from "../../styling/colors";
import { APPLICATION_SPACING } from "../../styling/spacing";

interface IRiskPill extends HTMLAttributes<HTMLDivElement> {
  riskLevel: MonitorRiskLevel;
}

const StyledRiskPillContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RiskPillSegment = styled.div`
  height: ${APPLICATION_SPACING(1)};
  width: 1.25rem;
  margin-right: 0.1rem;
  border-width: 1px;
  border-color: ${COLOR.LINE};
`;

export const RiskPill: FunctionComponent<IRiskPill> = ({
  riskLevel = RiskLevel.CRITICAL,
  className,
}) => {
  const backgroundColor = RISK_LEVEL_TEMPLATES[riskLevel].backgroundColor;
  return (
    <StyledRiskPillContainer className={className}>
      <RiskPillSegment
        style={{
          backgroundColor,
          borderTopLeftRadius: "9999px",
          borderBottomLeftRadius: "9999px",
        }}
      />
      <RiskPillSegment
        style={{
          backgroundColor:
            riskLevel === "MEDIUM" ||
            riskLevel === "HIGH" ||
            riskLevel === "CRITICAL"
              ? backgroundColor
              : "transparent",
        }}
      />
      <RiskPillSegment
        style={{
          backgroundColor:
            riskLevel === "HIGH" || riskLevel === "CRITICAL"
              ? backgroundColor
              : "transparent",
        }}
      />
      <RiskPillSegment
        style={{
          backgroundColor:
            riskLevel === "CRITICAL" ? backgroundColor : "transparent",
          borderTopRightRadius: "9999px",
          borderBottomRightRadius: "9999px",
          marginRight: 0,
        }}
      />
    </StyledRiskPillContainer>
  );
};
