import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import styled from "styled-components";

import missingUser from "../../assets/placeholder/missing-user.png";
import { defaultTo } from "../../utils/maybe/defaultTo";

export interface IActorIcon {
  imageUrl?: string;
  displayName?: string;
}

const StyledActorIcon = styled.img`
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-width: 1px;
`;

export const ActorIcon: FunctionComponent<IActorIcon> = ({
  imageUrl,
  displayName,
}) => {
  const firstRender = useRef(true);
  const [useImageUrl, setImageUrl] = useState(imageUrl || missingUser);

  useEffect(() => {
    if (!firstRender.current) {
      setImageUrl(defaultTo(imageUrl, ""));
    } else {
      firstRender.current = false;
    }
  }, [imageUrl]);

  return (
    <StyledActorIcon
      onError={() => {
        setImageUrl(missingUser);
      }}
      src={useImageUrl}
      alt={`${displayName}`}
    />
  );
};
