import { gql } from "urql";

export const MONITOR_VIOLATION_SUMMARY_FIELDS_FRAGMENT = gql`
  fragment MonitorViolationSummaryFields on Monitor {
    id
    name
    riskLevel
    violationSummary {
      currentThreatLevel
      currentViolations {
        low
        medium
        high
        critical
      }
    }
  }
`;
