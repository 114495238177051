import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { ButtonPillPrimary, ButtonSmall } from "../../atoms/Button";
import { Heading } from "../../atoms/Heading";
import { useExtendedMutation } from "../../operations/hooks/useExtendedMutation";
import { LEAVE_SDF_FEEDBACK } from "../../operations/sdf/mutations/leaveSdfFeedback/leaveSdfFeedback";
import { useLeaveSdfFeedbackForRiskMutation } from "../../operations/sdf/mutations/leaveSdfFeedback/leaveSdfFeedback.generated";
import { APPLICATION_SPACING } from "../../styling/spacing";
import { LayoutContainer } from "../layout/LayoutContainer";
import { IModalBase, ModalWrapper } from "./BaseModal";
import {
  LargeStyledTextArea,
  StyledErrorContainer,
  StyledModalBox,
} from "./shared";

interface IRiskFeedbackModal extends IModalBase {
  riskId: string;
}

const StyledSaveButton = styled(ButtonPillPrimary)`
  margin-left: ${APPLICATION_SPACING(3)};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${APPLICATION_SPACING(1)};
`;

const RiskFeedbackModal = ({
  showModal,
  toggleModal,
  riskId,
}: IRiskFeedbackModal) => {
  const [feedbackComments, setFeedbackComments] = useState("");

  const sendSDFFeedbackForRisk = useExtendedMutation(
    useLeaveSdfFeedbackForRiskMutation,
    {
      initialState: {},
      definition: LEAVE_SDF_FEEDBACK,
    }
  );

  const { reject } = sendSDFFeedbackForRisk.run();

  useEffect(() => {
    setFeedbackComments("");
  }, [riskId]);

  return (
    <ModalWrapper showModal={showModal} toggleModal={toggleModal}>
      <StyledModalBox>
        {sendSDFFeedbackForRisk.error && (
          <StyledErrorContainer
            p={4}
            fontSize="md"
            color="ERROR"
            mb={3}
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            {`${sendSDFFeedbackForRisk.error}`}
          </StyledErrorContainer>
        )}
        <StyledHeading type="text-md" Element="h1" isBodyColor>
          Feedback
        </StyledHeading>
        <LayoutContainer as="p" mb={2} fontSize="xsm">
          Please let us know why this event was irrelevant to you
        </LayoutContainer>
        <LargeStyledTextArea
          hideLabel
          placeholder="Feedback"
          value={feedbackComments}
          onChange={(event) => {
            const value = event.target.value;

            setFeedbackComments(value as string);
          }}
        />
        <LayoutContainer
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ButtonSmall
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </ButtonSmall>
          <StyledSaveButton
            smallLoader
            loading={sendSDFFeedbackForRisk.fetching}
            onClick={async () => {
              await reject({ riskId, comments: feedbackComments });
              toggleModal();
              setFeedbackComments("");
            }}
          >
            Submit
          </StyledSaveButton>
        </LayoutContainer>
      </StyledModalBox>
    </ModalWrapper>
  );
};

export default RiskFeedbackModal;
