import React from "react";
import { FunctionComponent, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { devtoolsExchange } from "@urql/devtools";
import { createClient, Provider, fetchExchange, dedupExchange } from "urql";
import { pipe, map } from "wonka";

import { useToast } from "../recoil/toasts/provider";
import { cache } from "./cache";
import { GRAPHQL_URL } from "./config";
import { auth } from "./exchanges/auth";
import { graphqlErrorHandler } from "./exchanges/error";
import { toastExchange } from "./exchanges/toast";

export const AuthorizedUrqlProvider: FunctionComponent = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const addToast = useToast();

  const client = useMemo(() => {
    return createClient({
      url: GRAPHQL_URL,
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        cache,
        graphqlErrorHandler(addToast),
        auth(getAccessTokenSilently, logout),
        toastExchange(addToast),
        ({ forward }) => {
          return (operations$) => {
            // Prevent Graphcache from caching data on an Error, we definitely don't currently support this.

            const queries = pipe(
              forward(operations$),
              map((operation$) => {
                if (operation$.error && operation$.operation.kind === "query") {
                  const { data, ...rest } = operation$;
                  return {
                    ...rest,
                    data: null,
                  };
                } else {
                  return operation$;
                }
              })
            );

            return queries;
          };
        },
        fetchExchange,
      ],
      maskTypename: true,
    });
  }, [addToast, getAccessTokenSilently, logout]);

  return <Provider value={client}>{children}</Provider>;
};
