import React, { useState, useCallback, useMemo } from "react";

import { Group } from "@visx/group";
import Pie from "@visx/shape/lib/shapes/Pie";
import { PieArcDatum } from "d3";
import styled from "styled-components";

import { HoverLegendInner } from "../../HoverLegend";
import { LayoutContainer } from "../../layout/LayoutContainer";
import { AnimatedPie } from "../primitives/AnimatedPie";
import { usePieLayout } from "./hooks";

export interface IDoughnutGraphData {
  id: string;
  color: string;
  count: number;
  name: string;
}

const countAccessor = (d: IDoughnutGraphData) => d.count;
const colorAccessor = (d: PieArcDatum<IDoughnutGraphData>) => d.data.color;
const transparentArcAccessor = (d: PieArcDatum<IDoughnutGraphData>) =>
  "transparent";
const keyAccessor = (d: PieArcDatum<IDoughnutGraphData>) => d.data.name;

const StyledChartContainer = styled.svg`
  max-width: 120px;
  width: 100%;

  path {
    cursor: pointer;
  }
`;

const ChartLegendContainer = styled.div`
  position: absolute;
  pointer-events: none;
  transform: translateY(-100%);
`;

const noResults = [
  {
    id: "none",
    count: 1,
    name: "none",
    color: "#C0C2D3",
  },
];

export const DoughnutChart = ({
  graphData,
  onSegmentClick,
  width = 120,
  height = 120,
}: {
  graphData: IDoughnutGraphData[];
  onSegmentClick?: (data: IDoughnutGraphData) => void;
  width?: number;
  height?: number;
}) => {
  const { radius, top, left, donutOffsetRadius } = usePieLayout({
    width,
    height,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    thickness: 8,
  });

  const [pieState, setPieState] = useState({
    hoveredSegmentKey: "",
    tooltipVisible: false,
    tooltipData: [] as IDoughnutGraphData[],
  });

  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const onMouseEnter = useCallback(
    (d: PieArcDatum<IDoughnutGraphData>) => {
      setPieState((s) => ({
        ...s,
        tooltipVisible: true,
        hoveredSegmentKey: d.data.name,
        tooltipData: [d.data],
      }));
    },
    [setPieState]
  );

  const onMouseLeave = useCallback(
    (d: PieArcDatum<IDoughnutGraphData>) => {
      setPieState((s) => ({
        ...s,
        tooltipVisible: false,
        hoveredSegmentKey: "",
        tooltipData: [],
      }));
    },
    [setPieState]
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setTooltipPosition({
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      });
    },
    [setTooltipPosition]
  );

  const graphDataOrNoResults = useMemo(
    () => (graphData.length ? graphData : noResults),
    [graphData]
  );

  return (
    <LayoutContainer position="relative" zIndex={10} onMouseMove={onMouseMove}>
      <StyledChartContainer
        viewBox={`0 0 ${width} ${height}`}
        overflow="visible"
      >
        <Group top={top} left={left}>
          <Pie
            data={graphDataOrNoResults}
            outerRadius={radius}
            innerRadius={donutOffsetRadius}
            padAngle={0.02}
            pieValue={countAccessor}
          >
            {(pie) => (
              <AnimatedPie<IDoughnutGraphData>
                {...pie}
                outerRadius={radius}
                innerRadius={donutOffsetRadius}
                getKey={keyAccessor}
                getColor={colorAccessor}
                onMouseEnter={onMouseEnter}
                onMouseExit={onMouseLeave}
                onSegmentClick={onSegmentClick}
                hoveredSegment={pieState.hoveredSegmentKey}
              />
            )}
          </Pie>
          {/* Wooahh why are there two pies, top is the outer ring you see, the bottom is the 
          transparent pie that provides hover interactions in the dead space on the inside of the donut */}
          <Pie
            data={graphDataOrNoResults}
            outerRadius={donutOffsetRadius}
            innerRadius={0}
            padAngle={0.02}
            pieValue={countAccessor}
          >
            {(pie) => (
              <AnimatedPie<IDoughnutGraphData>
                {...pie}
                outerRadius={donutOffsetRadius}
                innerRadius={0}
                getKey={keyAccessor}
                getColor={transparentArcAccessor}
                onMouseEnter={onMouseEnter}
                onMouseExit={onMouseLeave}
                onSegmentClick={onSegmentClick}
                hoveredSegment={pieState.hoveredSegmentKey}
              />
            )}
          </Pie>
        </Group>
      </StyledChartContainer>
      {pieState.tooltipVisible && (
        <ChartLegendContainer
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
          }}
        >
          <HoverLegendInner legendKeys={pieState.tooltipData} />
        </ChartLegendContainer>
      )}
    </LayoutContainer>
  );
};
