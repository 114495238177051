import React, { FunctionComponent, HTMLAttributes } from "react";

import classnames from "classnames";
import styled from "styled-components";
import { useQuery } from "urql";

import { LayoutContainer } from "../../components/layout/LayoutContainer";
import { Grid } from "../../components/layout/grid";
import { LoadingSpinner } from "../../components/loading/LoadingSpinner";
import { useModal } from "../../contexts/modals";
import {
  IListInstallations,
  IInstallation,
  LIST_INSTALLATIONS_QUERY,
} from "../../operations/installations/queries/listInstallations";
import {
  IListIntegrations,
  IIntegration,
  LIST_INTEGRATIONS_QUERY,
} from "../../operations/integrations/queries/listIntegrations";
import { useAnimatedListFromBottom } from "../../utils/animation/hooks/list/useAnimatedListFromBottom";
import { ContinueInstallation } from "./ContinueInstallation";
import { Installed } from "./Installed";
import { NotInstalled } from "./NotInstalled";

interface IIntegrations extends HTMLAttributes<HTMLDivElement> {
  isOnboarding?: boolean;
  children?: (data: {
    installations?: IInstallation[];
    integrations?: IIntegration[];
  }) => JSX.Element;
}

const StyledGrid = styled(Grid)`
  width: 80%;
  max-width: 1022px;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLayoutContainer = styled(LayoutContainer)`
  min-height: calc(100vh - 22rem);
`;

export const Integrations: FunctionComponent<IIntegrations> = ({
  className,
  children,
  isOnboarding = false,
}) => {
  const { integrationModal, integrationConfigModal } = useModal();
  const { toggle } = integrationModal;

  const [integrationsQueryResult] = useQuery<IListIntegrations>({
    query: LIST_INTEGRATIONS_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      isAccountRestricted: isOnboarding,
    },
  });

  const [installationsQueryResult, refetchInstallations] =
    useQuery<IListInstallations>({
      query: LIST_INSTALLATIONS_QUERY,
      requestPolicy: "network-only",
    });

  const isLoading =
    integrationsQueryResult.fetching || installationsQueryResult.fetching;

  const installations = installationsQueryResult.data?.listInstallations;
  const integrations = integrationsQueryResult.data?.listIntegrations;

  const mapIntegrationsByInstallation = integrations?.map((integration) => {
    const isInstalled = installations?.find(
      (installation) =>
        installation.integration.id === integration.id &&
        installation.status === "ACTIVE"
    );

    return {
      ...integration,
      isInstalled,
      installationId: isInstalled?.id,
      status: isInstalled?.status,
    };
  });

  const animatedList = useAnimatedListFromBottom({
    listOfElements:
      !isLoading && mapIntegrationsByInstallation
        ? mapIntegrationsByInstallation.map((integration) =>
            integration.installationId ? (
              <LayoutContainer key={integration.id} pt="100%">
                <LayoutContainer
                  position="absolute"
                  width="100%"
                  height="100%"
                  top={0}
                  left={0}
                >
                  <Installed
                    integration={integration}
                    toggleModal={toggle}
                    toggleConfigModal={integrationConfigModal.toggle}
                  />
                </LayoutContainer>
              </LayoutContainer>
            ) : (
              <LayoutContainer key={integration.id} pt="100%">
                <LayoutContainer
                  position="absolute"
                  width="100%"
                  height="100%"
                  top={0}
                  left={0}
                >
                  <NotInstalled
                    integration={integration}
                    toggleModal={toggle}
                  />
                </LayoutContainer>
              </LayoutContainer>
            )
          )
        : [],
  });

  if (isLoading) {
    return (
      <StyledLayoutContainer position="relative">
        <StyledLoadingSpinner isLarge />
      </StyledLayoutContainer>
    );
  }

  return (
    <>
      <ContinueInstallation refetchInstallations={refetchInstallations}>
        <LayoutContainer
          display="flex"
          justifyContent="center"
          className={classnames(className)}
        >
          <StyledGrid mb={2} responsive>
            {animatedList}
          </StyledGrid>
        </LayoutContainer>
      </ContinueInstallation>
      {children && children({ installations, integrations })}
    </>
  );
};
