// @Mike, you can do with this / move this wherever you want
import React from "react";

export const useStateLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] => {
  const namespacedKey = `state:${key}`;
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(namespacedKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Failed to get ${key} state from localstorage`, error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        typeof value === "function" ? value(storedValue) : value;
      setStoredValue(value);
      window.localStorage.setItem(namespacedKey, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(`Failed to store local state for "${key}"`, { value });
    }
  };

  return [storedValue, setValue];
};
